import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  getCustomTokens,
  getFullTokens,
  getImportTokens,
  getTokenLists,
  getTokens,
} from '../configure-store/selectors';
import { EmptyToken, Token } from '../types';

/**
 *
 * @returns 从 tokenList 中获取到最新的 token 详情
 */
export function useQueryTokenByAllToken({
  includeAllChain,
  chainId,
}: {
  includeAllChain?: boolean;
  chainId?: number;
} = {}) {
  const tokens = useSelector(getTokens);
  const customTokens = useSelector(getCustomTokens);
  const tokenLists = useSelector(getTokenLists);
  const importTokens = useSelector(getImportTokens);
  const fullTokens = useSelector(getFullTokens);

  const getToken = useCallback(
    (address: string) => {
      if (
        !address ||
        address.length === 0 ||
        (!tokens.size &&
          !customTokens.size &&
          includeAllChain &&
          !fullTokens.length)
      ) {
        return EmptyToken;
      }
      let token: Token | undefined;
      if (chainId) {
        token = fullTokens.find(
          (t) =>
            t.get('chainId') === chainId &&
            t.get('address').toLowerCase() === address.toLowerCase(),
        );
      }
      if (!token) {
        token = tokens.find(
          (t) => t.get('address').toLowerCase() === address.toLowerCase(),
        );
      }
      if (!token) {
        token = customTokens.find(
          (t) => t.get('address').toLowerCase() === address.toLowerCase(),
        );
      }
      if (includeAllChain && !token) {
        token = fullTokens.find(
          (t) => t.get('address').toLowerCase() === address.toLowerCase(),
        );
      }

      // 从单个导入的 token list 中查询
      if (!token) {
        if (importTokens.has(address)) {
          token = importTokens.get(address);
        }
      }

      // 最后从数量最大的第三方 TokenList 中查询
      if (!token) {
        tokenLists.some((list) => {
          const isEnabled = list.get('enabled');
          if (isEnabled) {
            const tokenListTokens = list.get('tokens');

            // 直接使用 get 方法，性能优于 find 方法，不区分地址大小写（如果出现 logo 不显示，再查找原因）
            const findToken = tokenListTokens.get(address);
            if (findToken) {
              token = findToken;
              return true;
            }
          }
          return false;
        });
      }

      return token;
    },

    [
      tokens,
      customTokens,
      includeAllChain,
      fullTokens,
      tokenLists,
      importTokens,
      chainId,
    ],
  );

  return getToken;
}
