import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * tablet: 768
 * 交易首页左侧导航栏加上中间部分至少需要 236px+450px
 * @see https://mui.com/customization/breakpoints/#default-breakpoints
 */
export function useDevices() {
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('tablet'),
  );
  const isLaptop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('desktop'),
  );

  return {
    isMobile,
    isLaptop,
  };
}
