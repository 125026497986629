import { ContractConfigDataI } from '../type';

export const GOR_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0x696E25A5e2AEd1C55E6d6Cfa0532Bbda9020165d',
  DODO_TOKEN: '',

  ERC20_HELPER: '0x24549FC74B3076A962624A26370ed556c467F74C',
  ERC20V3_FACTORY: '0xde1eCf0f7C8141FfC2149405947206f76951816e',

  DODO_APPROVE: '0xC9143e54021f4a6d33b9b89DBB9F458AaEdd56FB',

  DODO_PROXY: '0xbce44767af0a53A108b3B7ba4F740E03D228Ec0A',
  DODO_DSP_PROXY: '0x8d9ccc26CbF3D49bbA76E9920C3E6ed29e06b5cc',
  DODO_DPP_PROXY: '0xc4A1a152812dE96b2B1861E433f42290CDD7f113',
  DODO_CP_PROXY_V2: '0x7Dd8c909E1F48a68FfEF03492761AF17B1496EDc',
  DODO_MINEV3_PROXY: '0x99d69565EdA438E3ebAA0627A407968ABD0f755b',
  DODO_STARTER_PROXY: '',

  DVM_FACTORY: '0x7449Cd63C2b1A06C36945eD83f0626D303781B6E',
  DSP_FACTORY: '0x9244EAa27266128610bad748625f512275A41a96',
  CP_FACTORY_V2: '0x1F076a800005c758a505E759720eb6737136e893',

  ROUTE_V2_DATA_FETCH: '0x7E9c460d0A10bd0605B15F0d0388e307d34a62E6',
  ROUTE_V1_DATA_FETCH: '0xAC716E87b0853C0712674e8E3a8435a489F276b4',
  CALLEE_HELPER: '0x5B2fd46E6E3Ff1E479d651ec0b210882Aa9871dF',

  FEE_RATE_IMPL: '0xfcA520C94078b65F8237d4F566c438a9468917A1',
};

export const GOR_SWAPINFO = [];
