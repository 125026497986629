import { prepareChainEndpointUrl } from '../selectors';

export const getChainIdWithNetworkName = (network: string): number => {
  switch (network && network.toLowerCase()) {
    case 'mainnet':
    case 'ethereum-mainnet':
      return 1;
    case 'ropsten':
      return 3;
    case 'rinkeby':
      return 4;
    case 'gor':
      return 5;
    case 'optimistic-ethereum':
    case 'optimism':
      return 10;
    case 'boba-mainnet':
      return 288;
    case 'bsc-mainnet':
    case 'bsc':
      return 56;
    case 'okchain':
      return 66;
    case 'bsc-testnet':
      return 97;
    case 'heco':
    case 'heco-mainnet':
      return 128;
    case 'matic':
    case 'matic-mainnet':
    case 'polygon':
      return 137;
    case 'kcs':
      return 321;
    case 'moonriver':
      return 1285;
    case 'arbitrum':
      return 42161;
    case 'arb-rinkeby':
      return 421611;
    case 'aurora':
      return 1313161554;
    case 'avalanche':
      return 43114;
    default:
      return 0;
  }
};

export const getNetworkNameWithChainId = (chainId: number | string): string => {
  switch (Number(chainId)) {
    case 1:
      return 'Mainnet';
    case 3:
      return 'Ropsten';
    case 4:
      return 'Rinkeby';
    case 5:
      return 'gor';
    case 10:
      return 'Optimism';
    case 288:
      return 'BOBA-Mainnet';
    case 56:
      return 'BSC-Mainnet';
    case 66:
      return 'OKChain';
    case 86:
      return 'BSC-Mainnet';
    case 97:
      return 'BSC-Testnet';
    case 128:
      return 'Heco';
    case 137:
      return 'Polygon';
    case 321:
      return 'kcs';
    case 1285:
      return 'Moonriver';
    case 42161:
      return 'Arbitrum';
    case 421611:
      return 'arb-rinkeby';
    case 1313161554:
      return 'Aurora';
    case 43114:
      return 'Avalanche';
    default:
      return 'Unknown';
  }
};

export function timePromise<T>(
  promise: Promise<T>,
  timeout?: number,
): Promise<[number, T] | null> {
  const start = performance.now();
  const mapped = promise.then(
    (t) => [performance.now() - start, t] as [number, T],
  );
  if (timeout === null) return mapped;
  return Promise.race([
    mapped,
    new Promise((resolve) => setTimeout(() => resolve(null), timeout)),
  ]) as Promise<[number, T] | null>;
}

export const isETHOrBSCChain = (
  chainId?: number,
): {
  /** ETH mainnet */
  isMainnet: boolean;
  isRinkeby: boolean;
  /** BSC main net */
  isMainnetBSC: boolean;
  /** BSC test net */
  isTestnetBSC: boolean;
  isTestNet: boolean;
  isETH: boolean;
  isBSC: boolean;
  isHeco: boolean;
  isMatic: boolean;
  isArbitrum: boolean;
  isArbRinkeby: boolean;
  isMoonRiver: boolean;
  isOKChain: boolean;
  isOptimism: boolean;
  isBoba: boolean;
  isAurora: boolean;
  isAvalanche: boolean;
  isKCS: boolean;
  // 是否时 ETH layer1 对应的 layer2 节点，某些场景下需要特殊处理，比如使用 layer1 的块高计算挖矿
  isLayer2: boolean;
} => {
  const result = {
    isMainnet: chainId === 1,
    isRinkeby: chainId === 4,
    isGor: chainId === 5,
    isMainnetBSC: chainId === 56 || chainId === 86,
    isTestnetBSC: chainId === 97,
    isOKChain: chainId === 66,
    isHeco: chainId === 128,
    isMatic: chainId === 137,
    isArbitrum: chainId === 42161,
    isArbRinkeby: chainId === 421611,
    isOptimism: chainId === 10,
    isBoba: chainId === 288,
    isMoonRiver: chainId === 1285,
    isAurora: chainId === 1313161554,
    isAvalanche: chainId === 43114,
    isKCS: chainId === 321,
  };
  return {
    ...result,
    isETH: result.isMainnet || result.isRinkeby || result.isGor,
    isBSC: result.isMainnetBSC || result.isTestnetBSC,
    isTestNet:
      result.isArbRinkeby ||
      result.isRinkeby ||
      result.isGor ||
      result.isTestnetBSC,
    isLayer2:
      result.isArbitrum ||
      result.isArbRinkeby ||
      result.isBoba ||
      result.isOptimism,
  };
};

/**
 * 获取 layer2 对应 layer1 的 chainId
 */
export function getLayer1ChainIdForLayer2(chainId: number) {
  const { isArbRinkeby } = isETHOrBSCChain(chainId);
  if (isArbRinkeby) {
    return 4;
  }
  return 1;
}

export const getFastestEthProvider = (chainId: number): string => {
  /*
   * 亚太地区：https://api.taichi.network:10001/rpc/public
   * 欧洲：https://api-eu.taichi.network:10001/rpc/public
   * 北美：https://api-us.taichi.network:10001/rpc/public
   * cloudflare https://eth.dodoex.io
   */
  const endpointUrl = prepareChainEndpointUrl(chainId, true);
  return endpointUrl;
  // test others and return fastest next time
};
