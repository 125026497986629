import { ContractConfigDataI } from '../type';

export const ARB_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0xF718F2bd590E5621e53f7b89398e52f7Acced8ca',
  DODO_TOKEN: '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',

  ERC20_HELPER: '0x7C062B9C584fA6eC2504270790D38240A2c5fE72',
  ERC20V3_FACTORY: '0x5CA5e03228a3C16EF262d29E588Adb6c2445fDbC',

  DODO_APPROVE: '0xA867241cDC8d3b0C07C85cC06F25a0cD3b5474d8',

  DODO_PROXY: '0x88CBf433471A0CD8240D2a12354362988b4593E5',
  DODO_DSP_PROXY: '0x36E5238B4479d1ba0bFE47550B0B8e4f4f500EAA',
  DODO_DPP_PROXY: '0xE8C9A78725D0451FA19878D5f8A3dC0D55FECF25',
  DODO_V1_PAIR_PROXY: '0x49186E32fEd50fd6B5604A2618c7B0b03Cd41414',
  DODO_CP_PROXY_V2: '0x467b6A4352924A4475e3deF0fb2910839D8D0F1a',
  DODO_MINEV3_PROXY: '0x9A74B169798bE874EF1C23b4092e5689969eF45E',
  DODO_STARTER_PROXY: '0x02b7011De29961dE1412dC3C31973909775406eD',

  DVM_FACTORY: '0xDa4c4411c55B0785e501332354A036c04833B72b',
  DSP_FACTORY: '0xC8fE2440744dcd733246a4dB14093664DEFD5A53',
  CP_FACTORY_V2: '0x9A8C0e8B27FFf5d00402733ca67432b6d64FaFF4',

  ROUTE_V2_DATA_FETCH: '0x0C420970b2e5efcBB6e833601772D6E707155eA6',
  ROUTE_V1_DATA_FETCH: '0x4EE6398898F7FC3e648b3f6bA458310ac29cD352',
  CALLEE_HELPER: '0xe3B40F8D8346d428EAB28d9Fd672b784d921cfBD',
  FEE_RATE_IMPL: '0x6de4d882a84A98f4CCD5D33ea6b3C99A07BAbeB1',

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0xFcbd98cc89b2cf60362d3533463C1309f39c539D',
  DODO_NFT_PROXY: '0x6A51C8A1139B9233C31331D2Cf442E0652329f23',
  BUYOUT_MODEL: '0x23B06D1557e8cAe3f03f71DCbB3123E071693af7',
  DODO_NFT_REGISTER: '0x8327b093caf1a32985887CE89BD6c8F80A6B7535',
  DODO_NFT_ERC721: '0x4ec636C32195DD4E4Be1D37c0838DB1a8E3E4E30',
  DODO_NFT_ERC1155: '0x3Ec5a174DAfF0A91DCD0935866ED9E6514441Ba8',

  DODO_NFTPOOL_PROXY: '0xE55154D09265b18aC7CDAC6E646672A5460389a1',
  DODO_NFT_APPROVE: '0xaeB5CF31b97dce6134e416129845e01106fFB177',
};

export const ARB_SWAPINFO = [
  {
    name: 'SushiSwap',
    factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    initHash:
      '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  },
];
