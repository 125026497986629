import { ContractConfigDataI } from '../type';

export const ARBTEST_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0x696E25A5e2AEd1C55E6d6Cfa0532Bbda9020165d',
  DODO_TOKEN: '',

  ERC20_HELPER: '0x5B2fd46E6E3Ff1E479d651ec0b210882Aa9871dF',
  ERC20V3_FACTORY: '0x585adbB35d0eE28B0d2AdC7213284D37bab7EA4B',

  DODO_APPROVE: '0x5BaF16d57620Cb361F622232F3cb4090e35F3da2',

  DODO_PROXY: '0x7Dd8c909E1F48a68FfEF03492761AF17B1496EDc',
  DODO_DSP_PROXY: '0x99d69565EdA438E3ebAA0627A407968ABD0f755b',
  DODO_DPP_PROXY: '0xa95cE97a04f61efb201c08da41e4bBF7E7106770',
  DODO_CP_PROXY_V2: '0x14F7B8Fb0c1447DdE7bcf92a8d9BB058c8A5FE64',
  DODO_MINEV3_PROXY: '0x3037e79FCe8817A6F21196d8D93C80F53ABB9267',
  DODO_STARTER_PROXY: '',

  DVM_FACTORY: '0xC77392396Be1FB5143f1f66A3Ae67dD03fbaBA27',
  DSP_FACTORY: '0xc7d7CC1e9f5E823887980c9C51F9c418ee3A3e28',
  CP_FACTORY_V2: '0xe05dd51e4eB5636f4f0E8e7Fbe82eA31a2ecef16',

  ROUTE_V2_DATA_FETCH: '0xD63c8Bf9ACaFfE6da2a4043F1C94CC3a55F28117',
  ROUTE_V1_DATA_FETCH: '0x64842A3EbC09bB69429c1a34ae181375fea5f17F',
  CALLEE_HELPER: '0xAC716E87b0853C0712674e8E3a8435a489F276b4',

  FEE_RATE_IMPL: '0x6D208e3dD3ba8dc7b0D23D6Bf15fef9324643984',
};

export const ARBTEST_SWAPINFO = [];
