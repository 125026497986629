/* eslint-disable react/jsx-props-no-spreading */
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  styled,
  Box,
} from '@mui/material';
import { ReactComponent as CheckBoxDoneIcon } from '../icons/suggested/done.svg';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  borderStyle: 'solid',
  borderWidth: 1,
  flex: 1,
  borderColor: theme.palette.text.secondary,
  '.Mui-focusVisible &': {
    outline: 'none',
    outlineOffset: 2,
  },
  '.Mui-checked &&': {
    borderColor: theme.palette.custom.button.main,
  },
  'input:disabled ~ &': {
    borderColor: theme.palette.text.secondary,
  },
}));

const BpCheckedIconStyle = styled(BpIcon)(({ theme }) => ({
  backgroundColor: theme.palette.custom.button.main,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

function BpCheckedIcon() {
  return (
    <BpCheckedIconStyle>
      <Box
        component={CheckBoxDoneIcon}
        sx={{
          width: '75%',
          height: '75%',
          '& path:last-child': {
            fill: (theme) => theme.palette.custom.button.mainContrast,
          },
        }}
      />
    </BpCheckedIconStyle>
  );
}

export function Checkbox(props: CheckboxProps) {
  const { size } = props;
  return (
    <MuiCheckbox
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      sx={{
        width: size === 'medium' ? 18 : 16,
        height: size === 'medium' ? 18 : 16,
        position: 'relative',
        top: 2,
        p: 0,
        '&:hover > span': {
          borderColor: 'text.primary',
        },
      }}
      {...props}
    />
  );
}
