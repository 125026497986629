import { SDKToken, TokenInterface } from '@dodoex/utils';
import { Token } from '@dodoex/token';
import { Map } from 'immutable';

export * from './useGetAllowance';
export * from './useGetBalance';
export * from './useGetBalanceByToken';
export * from './useGetTokenStatus';
export * from './useLoadTokenBalancesOrAllowances';
export * from './useQueryTokenByAddress';
export * from './useQueryTokenListWithSlippage';
export * from './queryCoinmarketcapTokenList';
export * from './queryThirdPartyTokenList';

export const EmptyToken = Map({
  name: '',
  symbol: '',
  address: '',
  decimals: 0,
  showDecimals: 0,
}) as Token;

export const convertToTokenInterface = (
  from: SDKToken,
): TokenInterface & { [key: string]: unknown } => {
  return {
    showDecimals: from?.decimals > 6 ? 6 : 4,
    ...from,
  };
};

export const convertToToken = (from: SDKToken): Token => {
  return Map(convertToTokenInterface(from)) as Token;
};

export const getTokenShowDecimal = (from: SDKToken): number => {
  const token = convertToToken(from);
  return token.get('showDecimals');
};

export const getTokenSymbolDisplay = (baseToken: Token): string => {
  let originSymbol = baseToken.get('symbol');

  /*
   * Now we are displaying WETH as is
  if (originSymbol.indexOf('WETH') > -1) {
    return 'ETH';
  }
  */
  if (originSymbol.indexOf('DLP_') > -1) {
    originSymbol = originSymbol.replace('DLP_', '');
  }
  // https://app.asana.com/0/1199598807906421/1201524249152019/f
  if (originSymbol.indexOf('DLP') > -1) {
    originSymbol = originSymbol.replace('DLP', 'LP');
  }

  return originSymbol;
};

export const convertToSDKToken = (token: {
  id: string;
  symbol: string;
  name: string;
  decimals: string | number;
  usdPrice?: string | null | number;
}) => {
  return {
    ...token,
    address: token.id,
    decimals: +token.decimals,
  };
};
