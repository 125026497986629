import { useApolloClient } from '@apollo/client';
import { getChain, LpTokenPlatformID } from '@dodoex/utils';
import { getWeb3, runAllV2, Submission } from '@dodoex/wallet';
import BigNumber from 'bignumber.js';
import { List } from 'immutable';
import { isEqual, uniqBy, uniqWith } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { isAddress } from 'web3-utils';
import { RowMarket } from '../../../setting/getConfig/types';
import { MinePool_orderBy } from '../../../__generated__/graphql-global-types';
import { SchemaName } from '../../apollo/query';
import {
  fetchAggregateMinePools,
  fetchUserStakes,
} from '../../mining/data/queries';
import {
  FetchAggregateMinePools,
  FetchAggregateMinePoolsVariables,
  FetchAggregateMinePools_poolIn,
} from '../../mining/data/__generated__/FetchAggregateMinePools';
import {
  FetchUserStakes,
  FetchUserStakesVariables,
} from '../../mining/data/__generated__/FetchUserStakes';
import {
  MiningStatusE,
  MiningWithStatusI,
  RewardTokenWithBlockI,
  TabMiningI,
} from '../types';
import { generateRewardTokenInfosABI, getMiningStatus } from '../utils';

const { useSubmission } = Submission;

/**
 * 手动操作停止的挖矿合约
 */
const manualStopMiningList: Record<string, string> = {
  '0x72A957D95843cbB3b379E4D43112F88687e99B58': '16891290',
};

/**
 * 将挖矿活动列表分为 Active 和 Ended 两个列表
 *
 * Active 包括未开始的和正在进行中的
 * Ended 包括已结束的，或者 v2 版本未在 online-configs 里面配置 startBlock 和 endBlock，或者是 v3 版本起止区块查询失败的
 *
 * @see https://www.notion.so/dodotopia/7b59397b1c114eba8c59a9269f9aff35
 */
export function useTabMiningList({
  markets,
  chainId,
  blockNumber,
  searchText,
  account,
}: {
  markets: List<RowMarket>;
  chainId: number;
  blockNumber: BigNumber;
  searchText?: string;
  account?: string;
}) {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [miningList, setMiningList] = useState<TabMiningI[]>([]);

  // 往 第三方挖矿 中添加资金后，userStakes 不能快速同步导致用户看不到刚添加资金的第三方挖矿项目，这里从前端 tx 列表中获取到挖矿合约地址，主动触发查询，跳过 userStakes
  const { requests } = useSubmission();
  const [
    unofficialMiningContractAddresses,
    setUnofficialMiningContractAddresses,
  ] = useState<string[]>([]);
  useEffect(() => {
    const depositOrWithdrawMiningTx = requests.filter(
      (_v, k) => k.metadata?.depositOrWithdrawMining,
    );
    const nextMiningContractAddresses: string[] = [];
    depositOrWithdrawMiningTx.forEach((_v, k) => {
      if (k.metadata && k.metadata.id) {
        const [miningContractAddress] = k.metadata.id.split('-');
        if (
          miningContractAddress &&
          !nextMiningContractAddresses.includes(miningContractAddress)
        ) {
          nextMiningContractAddresses.push(miningContractAddress);
        }
      }
    });
    setUnofficialMiningContractAddresses((prev) => {
      if (isEqual(prev, nextMiningContractAddresses)) {
        return prev;
      }
      return nextMiningContractAddresses;
    });
  }, [requests]);

  useEffect(() => {
    let ignore = false;

    // 从链上查询配置的挖矿项目的奖励代币释放信息
    async function requestMiningListWithBlock() {
      try {
        const v3RowMarket: RowMarket[] = [];
        for (const [, rowMarket] of markets.entries()) {
          if (rowMarket.get('version') === '3') {
            v3RowMarket.push(rowMarket);
          }
        }
        if (v3RowMarket.length === 0) {
          return [];
        }
        const web3 = await getWeb3();
        type RewardTokenInfosParameters = {
          endBlock: string;
          rewardPerBlock: string;
          rewardToken: string;
          startBlock: string;
        };
        const thunk: {
          callData: {
            data: string;
            to: string;
          };
          processor: (v: string) => {
            result: RewardTokenInfosParameters;
            index: number;
            mining: string;
          };
        }[] = [];
        v3RowMarket.forEach((rowMarket) => {
          const mining = rowMarket.get('mining');
          const rewardNum = rowMarket.get('rewardNum');
          if (mining && rewardNum) {
            for (let index = 0; index < rewardNum; index++) {
              thunk.push(
                generateRewardTokenInfosABI({
                  web3,
                  mining,
                  index,
                }),
              );
            }
          }

          const baseLpTokenMining = rowMarket.get('baseLpTokenMining');
          const baseLpTokenRewardNum = rowMarket.get('baseLpTokenRewardNum');
          if (baseLpTokenMining && baseLpTokenRewardNum) {
            for (let index = 0; index < baseLpTokenRewardNum; index++) {
              thunk.push(
                generateRewardTokenInfosABI({
                  web3,
                  mining: baseLpTokenMining,
                  index,
                }),
              );
            }
          }

          const quoteLpTokenMining = rowMarket.get('quoteLpTokenMining');
          const quoteLpTokenRewardNum = rowMarket.get('quoteLpTokenRewardNum');
          if (quoteLpTokenMining && quoteLpTokenRewardNum) {
            for (let index = 0; index < quoteLpTokenRewardNum; index++) {
              thunk.push(
                generateRewardTokenInfosABI({
                  web3,
                  mining: quoteLpTokenMining,
                  index,
                }),
              );
            }
          }
        });
        if (thunk.length === 0) {
          return [];
        }
        const [v3MiningRewardTokenInfos] = await runAllV2<
          {
            result: RewardTokenInfosParameters;
            index: number;
            mining: string;
          }[]
        >(chainId, thunk);
        const v3MiningRewardTokenInfosMap = new Map<
          string,
          RewardTokenInfosParameters[]
        >();
        for (const { mining, result } of v3MiningRewardTokenInfos) {
          if (!v3MiningRewardTokenInfosMap.has(mining)) {
            v3MiningRewardTokenInfosMap.set(mining, []);
          }
          const currentValue = v3MiningRewardTokenInfosMap.get(mining);
          if (currentValue) {
            const { endBlock, rewardPerBlock, rewardToken, startBlock } =
              result;
            currentValue.push({
              endBlock,
              rewardPerBlock,
              rewardToken,
              startBlock,
            });
          }
        }

        const miningListWithBlock: TabMiningI[] = [];
        for (const [, rowMarket] of markets.entries()) {
          const version = rowMarket.get('version');
          const type = rowMarket.get('type');
          const vdodoDODOTokenAddress = rowMarket.get('vdodoDODOTokenAddress');
          const name = rowMarket.get('pair') ?? undefined;
          let stakeToken = rowMarket.get('address');
          if (version === '3') {
            stakeToken = rowMarket.get('pool');
            if (type === 'single' || type === 'vdodo') {
              stakeToken = rowMarket.get('token');
            }
          }
          const miningContractAddress =
            rowMarket.get('mining') ?? rowMarket.get('baseLpTokenMining');
          const quoteLpTokenMiningContractAddress =
            rowMarket.get('quoteLpTokenMining');
          const rewardTokenInfoList: RewardTokenWithBlockI[] = [];
          let quoteLpTokenRewardTokenInfoList:
            | RewardTokenWithBlockI[]
            | undefined;

          if (version === '2') {
            const rewardTokenAddress = rowMarket.get('rewardTokenAddress');
            const rewardTokenSymbol = rowMarket.get('rewardTokenSymbol');
            const startBlock = rowMarket.get('startBlock');
            const endBlock = rowMarket.get('endBlock');
            if (rewardTokenAddress) {
              rewardTokenInfoList.push({
                rewardToken: rewardTokenAddress,
                startBlock: startBlock ? new BigNumber(startBlock) : null,
                endBlock: endBlock ? new BigNumber(endBlock) : null,
                rewardPerBlock: new BigNumber(0),
                rewardTokenSymbol,
              });

              if (stakeToken && miningContractAddress) {
                miningListWithBlock.push({
                  version,
                  type,
                  name,
                  stakeToken,
                  miningContractAddress,
                  rewardTokenInfoList,
                  source: 'official',
                  lpTokenPlatformID: LpTokenPlatformID.dodo,
                });
              }
            } else {
              console.error(
                `[requestMiningListWithBlock] rewardTokenAddress is null, miningContractAddress is ${miningContractAddress}`,
              );
            }
          }

          if (version === '3') {
            if (miningContractAddress && stakeToken) {
              const v3MiningRewardTokenInfoList =
                v3MiningRewardTokenInfosMap.get(miningContractAddress);
              if (v3MiningRewardTokenInfoList) {
                for (const {
                  rewardPerBlock,
                  startBlock,
                  endBlock,
                  rewardToken,
                } of v3MiningRewardTokenInfoList) {
                  rewardTokenInfoList.push({
                    rewardToken,
                    startBlock: new BigNumber(startBlock),
                    endBlock: new BigNumber(
                      manualStopMiningList[miningContractAddress] ?? endBlock,
                    ),
                    rewardPerBlock: new BigNumber(rewardPerBlock),
                  });
                }
              }
              if (quoteLpTokenMiningContractAddress) {
                const v3QuoteLpTokenMiningRewardTokenInfoList =
                  v3MiningRewardTokenInfosMap.get(
                    quoteLpTokenMiningContractAddress,
                  );
                if (v3QuoteLpTokenMiningRewardTokenInfoList) {
                  quoteLpTokenRewardTokenInfoList = [];
                  for (const {
                    rewardPerBlock,
                    startBlock,
                    endBlock,
                    rewardToken,
                  } of v3QuoteLpTokenMiningRewardTokenInfoList) {
                    quoteLpTokenRewardTokenInfoList.push({
                      rewardToken,
                      startBlock: new BigNumber(startBlock),
                      endBlock: new BigNumber(endBlock),
                      rewardPerBlock: new BigNumber(rewardPerBlock),
                    });
                  }
                }
              }
              miningListWithBlock.push({
                version,
                type,
                vdodoDODOTokenAddress,
                name,
                stakeToken,
                miningContractAddress,
                quoteLpTokenMiningContractAddress,
                source: 'official',
                lpTokenPlatformID: LpTokenPlatformID.dodo,
                rewardTokenInfoList,
                quoteLpTokenRewardTokenInfoList,
              });
            }
          }
        }
        return miningListWithBlock;
      } catch (error) {
        console.error('[mining list query failed from chain] ', error);
      }
      return [];
    }

    /**
     * 从 thegraph 查询第三方挖矿项目
     * @param param0 addressSearchText 为一个合约地址，做为 minePools 参数查询；可能为资金池地址也可能为挖矿合约地址
     */
    async function requestV3MiningListBySearch({
      addressSearchText,
    }: {
      addressSearchText?: string;
    }) {
      try {
        // 1 userStakes 查询用户参与的第三方挖矿项目
        const { thegraphKey } = getChain(chainId);
        const miningContractAddresses: string[] = [
          ...unofficialMiningContractAddresses,
        ];
        const stakeTokenAddresses: string[] = [];
        if (addressSearchText) {
          miningContractAddresses.push(addressSearchText);
          stakeTokenAddresses.push(addressSearchText);
        }
        if (account) {
          const { data } = await client.query<
            FetchUserStakes,
            FetchUserStakesVariables
          >({
            query: fetchUserStakes,
            variables: {
              where: {
                schemaName: SchemaName.mine,
                chain: thegraphKey,
                user: account,
                // balance_gt: 0,
              },
            },
            fetchPolicy: 'network-only',
          });
          if (data.userStakes) {
            // 用户质押的挖矿合约地址
            const userStakeMiningContractAddresses = data.userStakes.map(
              ({ pool }) => pool as string,
            );
            miningContractAddresses.push(...userStakeMiningContractAddresses);
          }
        }

        if (
          miningContractAddresses.length === 0 &&
          stakeTokenAddresses.length === 0
        ) {
          return [];
        }

        // 2 将 addressSearchText 拼接到 userStakes 的结果中查询 minePools
        const { data } = await client.query<
          FetchAggregateMinePools,
          FetchAggregateMinePoolsVariables
        >({
          query: fetchAggregateMinePools,
          variables: {
            first: 100,
            orderBy: 'timestamp' as MinePool_orderBy,
            poolInWhere: {
              schemaName: SchemaName.mine,
              chain: thegraphKey,
              pool_in: miningContractAddresses,
            },
            stakeTokenInWhere: {
              schemaName: SchemaName.mine,
              chain: thegraphKey,
              stakeToken_in: stakeTokenAddresses,
            },
          },
        });
        const { poolIn, stakeTokenIn } = data;
        const minePools: FetchAggregateMinePools_poolIn[] = uniqBy(
          (poolIn ?? []).concat(stakeTokenIn ?? []),
          'pool',
        );

        const miningListWithBlock: TabMiningI[] = [];
        for (const {
          isLpToken,
          pool,
          stakeToken,
          rewardDetail,
          platform,
        } of minePools) {
          if (stakeToken) {
            const rewardTokenInfoList: RewardTokenWithBlockI[] = [];
            for (const {
              rewardPerBlock,
              startBlock,
              endBlock,
              token,
            } of rewardDetail) {
              rewardTokenInfoList.push({
                rewardToken: token,
                startBlock: new BigNumber(startBlock),
                endBlock: new BigNumber(endBlock),
                rewardPerBlock: new BigNumber(rewardPerBlock),
              });
            }
            miningListWithBlock.push({
              version: '3',
              type: isLpToken ? 'lptoken' : 'single',
              stakeToken,
              miningContractAddress: pool,
              source: 'unofficial',
              rewardTokenInfoList,
              lpTokenPlatformID:
                platform !== null && platform !== undefined
                  ? Number(platform)
                  : LpTokenPlatformID.dodo,
            });
          }
        }

        return miningListWithBlock;
      } catch (error) {
        console.error('[mining list query failed from thegraph] ', error);
      }
      return [];
    }

    async function compositeMiningList({
      addressSearchText,
    }: {
      addressSearchText?: string;
    }) {
      setLoading(true);
      try {
        const miningListPL: Promise<TabMiningI[]>[] = [];
        miningListPL.push(requestMiningListWithBlock());
        miningListPL.push(
          requestV3MiningListBySearch({
            addressSearchText,
          }),
        );
        if (ignore) {
          return;
        }
        const [miningListWithBlock, miningListBySearch] = await Promise.all(
          miningListPL,
        );
        // console.log('v2 miningListWithBlock', miningListWithBlock);
        // console.log('v2 miningListBySearch', miningListBySearch);
        setMiningList((prev) => {
          const next = uniqBy(
            miningListWithBlock.concat(miningListBySearch),
            (item) =>
              `${item.miningContractAddress.toLowerCase()}-${item.stakeToken.toLowerCase()}`,
          );
          console.log('v2 miningList', next);
          if (isEqual(next, prev)) {
            return prev;
          }
          return next;
        });
        // 配置的挖矿活动和搜索出来的第三方挖矿活动有可能重复：如果把自主创建的挖矿活动重复配置在了 online-configs 里面
      } catch (error) {
        console.error('[mining list query fail] ', error);
      }
      setLoading(false);
    }
    if (!searchText) {
      compositeMiningList({
        addressSearchText: undefined,
      });
    }
    if (searchText && isAddress(searchText)) {
      compositeMiningList({
        addressSearchText: searchText,
      });
    }
    return () => {
      ignore = true;
    };
  }, [
    account,
    chainId,
    client,
    markets,
    searchText,
    unofficialMiningContractAddresses,
  ]);

  const [miningListBySearch, activeMiningList, endedMiningList] =
    useMemo(() => {
      let miningWithStatusList = miningList.map<MiningWithStatusI>((m) => {
        const { rewardTokenInfoList, quoteLpTokenRewardTokenInfoList } = m;
        const { status: baseLpTokenStatus, startBlockHeight } = getMiningStatus(
          {
            rewardTokenInfoList,
            currentBlocKNumber: blockNumber,
          },
        );
        if (
          quoteLpTokenRewardTokenInfoList &&
          quoteLpTokenRewardTokenInfoList.length > 0
        ) {
          const {
            status: quoteLpTokenStatus,
            startBlockHeight: quoteLpTokenStartBlockHeight,
          } = getMiningStatus({
            rewardTokenInfoList: quoteLpTokenRewardTokenInfoList,
            currentBlocKNumber: blockNumber,
          });
          let status = MiningStatusE.active;
          if (
            (baseLpTokenStatus === MiningStatusE.upcoming &&
              quoteLpTokenStatus === MiningStatusE.upcoming) ||
            (baseLpTokenStatus === MiningStatusE.upcoming &&
              quoteLpTokenStatus === MiningStatusE.ended) ||
            (baseLpTokenStatus === MiningStatusE.ended &&
              quoteLpTokenStatus === MiningStatusE.upcoming)
          ) {
            status = MiningStatusE.upcoming;
          }
          if (
            baseLpTokenStatus === MiningStatusE.ended &&
            quoteLpTokenStatus === MiningStatusE.ended
          ) {
            status = MiningStatusE.ended;
          }
          return {
            ...m,
            status,
            baseLpTokenStatus,
            quoteLpTokenStatus,
            startBlockHeight,
            quoteLpTokenStartBlockHeight,
            currentBlockHeight: blockNumber,
          };
        }
        return {
          ...m,
          status: baseLpTokenStatus,
          baseLpTokenStatus,
          quoteLpTokenStatus: baseLpTokenStatus,
          startBlockHeight,
          currentBlockHeight: blockNumber,
        };
      });
      // 精确匹配到挖矿合约地址或 stakeToken 地址，用于打开对应的 tab 页面
      if (searchText && isAddress(searchText)) {
        const lowerSearchText = searchText.toLowerCase();
        miningWithStatusList = miningWithStatusList.filter(
          ({ stakeToken, miningContractAddress }) => {
            return (
              stakeToken.toLowerCase() === lowerSearchText ||
              miningContractAddress.toLowerCase() === lowerSearchText
            );
          },
        );
      }
      return [
        miningWithStatusList,
        miningWithStatusList.filter(
          (m) =>
            m.status === MiningStatusE.upcoming ||
            m.status === MiningStatusE.active,
        ),
        miningWithStatusList.filter((m) => m.status === MiningStatusE.ended),
      ];
    }, [blockNumber, miningList, searchText]);

  return {
    loading,
    miningList,
    miningListBySearch,
    activeMiningList,
    endedMiningList,
  };
}
