import { getServiceDomain } from '../domains';
import {
  kArbitrumCandidates,
  kArbRinkebyCandidates,
  kAuroraCandidates,
  kBobaCandidates,
  kBSCCandidates,
  kCandidates,
  kGorCandidates,
  kHecoCandidates,
  kKCSCandidates,
  kMaticCandidates,
  kMoonriverCandidates,
  kOkchainCandidates,
  kOptimismCandidates,
} from './candidates';
import { DexKey, dexListObj } from './dexConfig';
import { Chain, ThegraphKeyMap } from './type';

export const DEFAULT_CHAIN_ID = 1;

export const kGoodPrice = 40000000000;
export const kGreatPrice = 30000000000;

export const kBSCGoodPrice = 20000000000;
export const kBSCGreatPrice = 15000000000;

export function getChain(chainId: number): Chain {
  // https://docs.bnbchain.org/docs/rpc#mainnet-chainid-0x38-56-in-decimal
  const BSCChain: Chain = {
    enable: true,
    name: 'Binance Smart Chain Mainnet',
    chainId: 56,
    chain: 'BSC',
    network: 'BSC',
    platformId: 'bsc',
    networkId: 56,
    ledgerAppName: 'Binance Smart Chain',
    configUrl: `https://config.${getServiceDomain()}/configs/bsc.json`,
    nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/bsc.json`,
    thegraphKey: ThegraphKeyMap.bsc,
    fromTokenInit: {
      name: 'BUSD Token',
      symbol: 'BUSD',
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      decimals: 18,
      showDecimals: 6,
    },
    toTokenInit: {
      symbol: 'USDT',
      address: '0x55d398326f99059ff775485246999027b3197955',
      name: 'Tether USD',
      decimals: 18,
      showDecimals: 6,
    },
    scanUrlDomain: 'bscscan.com',
    blockTime: 3000,
    bscTradeMiningEnable: false,
    providerCandidates: kBSCCandidates,
    gaspriceStandard: {
      good: kBSCGoodPrice,
      great: kBSCGreatPrice,
    },
    viewOnScanKey: 'common.viewOnBscScan',
    dspPoolEnable: true,
    gasSelectSpeeds: {
      rapid: '3s',
      fast: '3s',
      medium: '6s',
    },
    nftEnable: true,
    limitOrderContract: '0xdc5E86654e768d21f7D298690687eA02db7b2a04',
    limitOrderBotAddress: '0x187da347dEbf4221B861EeAFC9808d8Cf89cF5fE',
    RFQContract: '0xBbD59b9316eE65526DbBdEc2A748Cc05A285d54C',
    dexKeys: [
      'pancakeV2',
      'pancakeV1',
      'MDEX',
      'bakery',
      'biSwap',
      'apeSwap',
      'babySwap',
    ],
    rfqEnable: true,
    addChainParameters: {
      chainId: '0x38',
      chainName: 'Binance Smart Chain',
      rpcUrls: [
        'https://bsc-dataseed1.binance.org',
        'https://bsc-dataseed2.binance.org',
        'https://bsc-dataseed3.binance.org',
        'https://bsc-dataseed4.binance.org',
        'https://bsc-dataseed1.defibit.io',
        'https://bsc-dataseed2.defibit.io',
        'https://bsc-dataseed3.defibit.io',
        'https://bsc-dataseed4.defibit.io',
        'https://bsc-dataseed1.ninicoin.io',
        'https://bsc-dataseed2.ninicoin.io',
        'https://bsc-dataseed3.ninicoin.io',
        'https://bsc-dataseed4.ninicoin.io',
        'wss://bsc-ws-node.nariox.org',
        'https://bsc-dataseed1.defibit.io',
        'https://bsc.nodereal.io',
      ],
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      blockExplorerUrls: ['https://bscscan.com/'],
    },
    routeV2Enable: true,
  };

  /**
   * @see https://chainlist.org/zh
   * @see https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-1.json
   */
  const chainIdToChainConfig: Record<number, Chain> = {
    // ETH
    // mainnet
    1: {
      enable: true,
      name: 'Ethereum Mainnet',
      chainId: 1,
      chain: 'ETH',
      network: 'ETH',
      networkId: 1,
      platformId: 'ethereum',
      ledgerAppName: 'Ethereum',
      configUrl: `https://config.${getServiceDomain()}/configs/mainnet.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/mainnet.json`,
      thegraphKey: ThegraphKeyMap.eth,
      cirAddress: '0x357c5E9cfA8B834EDcef7C7aAbD8F9Db09119d11',
      fromTokenInit: {
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'Ether',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'USDC',
        address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        name: 'USD Coin',
        decimals: 6,
        source: 'dodo',
      },
      scanUrlDomain: 'etherscan.io',
      blockTime: 13000,
      vDODOEnable: true,
      preminingEnable: true,
      providerCandidates: kCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '15s',
        fast: '1min',
        medium: '3min',
      },
      nftEnable: true,
      limitOrderContract: '0x093b68BFe0859D3C857Fc3529952897C30dD17EB',
      limitOrderBotAddress: '0xD9B825d16E09f28D0c715fe004364046E5524Dbb',
      RFQContract: '0x628E5081bA93b1c4F58E54e7175088B1ACe58852',
      dexKeys: ['uniswapV2', 'sushiSwap', 'shibaSwap'],
      rfqEnable: true,
      addChainParameters: {
        chainId: '0x1',
      },
      routeV2Enable: true,
    },
    // rinkeby
    4: {
      enable: true,
      name: 'Ethereum Testnet Rinkeby',
      chainId: 4,
      chain: 'ETH',
      network: 'Rinkeby',
      networkId: 4,
      platformId: 'rinkeby',
      configUrl: 'https://config.dodoex.io/configs/rinkeby.json',
      nftConfigUrl: 'https://config.dodoex.io/nft-configs/rinkeby.json',
      thegraphKey: ThegraphKeyMap.rinkeby,
      cirAddress: '0xe4Aec985debDDbbCB2358e8C8F9384DD6421d163',
      fromTokenInit: {
        symbol: 'WETH',
        address: '0xB26c0d8Be2960c70641A95A9195BE1f59Ac83aC0',
        name: 'WETH',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'USDC',
        address: '0xab0733588776B8881F7712f6AbCa98F510e6B63D',
        name: 'USD Coin',
        decimals: 6,
        source: 'dodo',
      },
      scanUrlDomain: 'rinkeby.etherscan.io',
      blockTime: 13000,
      vDODOEnable: false,
      providerCandidates: kCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '3s',
        fast: '3s',
        medium: '6s',
      },
      nftEnable: true,
      limitOrderContract: '0xFC8fb4CD3A77F138E53F92F5EC483e9887508CCe',
      limitOrderBotAddress: '0xa2c1eA946dA567248c70F792b64D5F2551f29204',
      RFQContract: '0x3dd629473a2ed7f3c6299ffd9f3e0c283d073f11',
      dexKeys: ['uniswapV2', 'sushiSwap', 'shibaSwap'],
      rfqEnable: true,
      addChainParameters: {
        chainId: '0x4',
      },
      isTestNet: true,
      testWhichChain: 1,
    },
    // goerli
    // https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-5.json
    // https://docs.etherscan.io/v/goerli-etherscan/
    5: {
      enable: true,
      name: 'Goerli',
      chainId: 5,
      chain: 'ETH',
      network: 'Goerli',
      networkId: 5,
      platformId: 'gor',
      configUrl: 'https://config.dodoex.io/configs/gor.json',
      nftConfigUrl: 'https://config.dodoex.io/nft-configs/gor.json',
      thegraphKey: ThegraphKeyMap.gor,
      fromTokenInit: {
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        decimals: 18,
        name: 'ETH',
      },
      toTokenInit: {
        symbol: 'USDC',
        address: '0xC4106029d03c33731Ca01Ba59b5A6368c660E596',
        decimals: 6,
        name: 'USDC',
      },
      scanUrlDomain: 'goerli.etherscan.io',
      blockTime: 13000,
      vDODOEnable: false,
      providerCandidates: kGorCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '3s',
        fast: '3s',
        medium: '6s',
      },
      nftEnable: true,
      limitOrderContract: '0x65934e2f228Fde069A6255F46E6A15Bc9DE38545',
      limitOrderBotAddress: '0x4aAe1d041C01078725dB016BA4D4F72455CaF931',
      rfqEnable: true,
      RFQContract: '0x628E5081bA93b1c4F58E54e7175088B1ACe58852',
      dexKeys: [],
      addChainParameters: {
        chainId: '0x5',
      },
      isTestNet: true,
      testWhichChain: 1,
    },
    /**
     * optimistic ethereum
     * @see https://community.optimism.io/docs/useful-tools/networks/
     */
    10: {
      enable: true,
      name: 'Optimism',
      chainId: 10,
      chain: 'Optimism',
      network: 'Optimism',
      networkId: 10,
      platformId: 'optimism',
      configUrl: `https://config.${getServiceDomain()}/configs/optimism.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/optimism.json`,
      thegraphKey: ThegraphKeyMap.optimism,
      fromTokenInit: {
        symbol: 'WETH',
        address: '0x4200000000000000000000000000000000000006',
        name: 'Wrapped Ether',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        address: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        decimals: 6,
        symbol: 'USDT',
        name: 'Tether USD',
        source: 'DODO',
      },
      scanUrlDomain: 'optimistic.etherscan.io',
      // L2
      blockTime: 13000,
      bscTradeMiningEnable: false,
      providerCandidates: kOptimismCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      nftEnable: false,
      limitOrderContract: '',
      limitOrderBotAddress: '',
      RFQContract: '',
      rfqEnable: false,
      dexKeys: [],
      addChainParameters: {
        chainId: '0xa',
        chainName: 'Optimism',
        rpcUrls: [
          // 以下两个节点在 2022.08.05 上午都无法使用
          'https://mainnet.optimism.io',
          'https://optimism-mainnet.public.blastapi.io',
          // alchemy 私人节点，用来测试移动端 metamask 添加 optimism 链
          // 'https://opt-mainnet.g.alchemy.com/v2/NND6JvlZtqzFPXPAJ37IXsMALnD88CNS',
        ],
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        blockExplorerUrls: ['https://optimistic.etherscan.io'],
      },
      routeV2Enable: true,
    },
    // boba mainnet
    288: {
      enable: true,
      chainId: 288,
      name: 'BOBA L2',
      platformId: 'boba',
      configUrl: `https://config.${getServiceDomain()}/configs/boba.json`,
      fromTokenInit: {
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'ETH',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'USDC',
        address: '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
        name: 'USD Coin',
        decimals: 6,
        source: 'dodo',
      },
      thegraphKey: ThegraphKeyMap.boba,
      scanUrlDomain: 'blockexplorer.boba.network',
      blockTime: 13000,
      providerCandidates: kBobaCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '3s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x00971e21955F1bb8021a5eF2546beC2Ff896EE42',
      limitOrderBotAddress: '0xA867241cDC8d3b0C07C85cC06F25a0cD3b5474d8',
      dexKeys: ['ooLongSwap'],
      addChainParameters: {
        chainId: '0x120',
        chainName: 'BOBA L2',
        rpcUrls: [
          'https://mainnet.boba.network',
          'https://boba-mainnet.gateway.pokt.network/v1/lb/623ad21b20354900396fed7f',
          'https://lightning-replica.boba.network',
        ],
        nativeCurrency: {
          name: 'ETH',
          symbol: 'ETH',
          decimals: 18,
        },
        blockExplorerUrls: ['https://blockexplorer.boba.network'],
      },
    },
    // BSC
    // mainnet
    56: BSCChain,
    86: BSCChain,
    // Binance Smart Chain Test Network
    97: {
      enable: false,
      name: 'Binance Smart Chain Test Network',
      chainId: 97,
      chain: 'BSC',
      network: 'BSC',
      platformId: 'bsc',
      networkId: 97,
      configUrl: `https://config.${getServiceDomain()}/configs/bsc.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/bsc.json`,
      thegraphKey: ThegraphKeyMap.bsc,
      fromTokenInit: {
        name: 'BUSD Token',
        symbol: 'BUSD',
        address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        decimals: 18,
        showDecimals: 6,
      },
      toTokenInit: {
        symbol: 'USDT',
        address: '0x55d398326f99059ff775485246999027b3197955',
        name: 'Tether USD',
        decimals: 18,
        showDecimals: 6,
      },
      scanUrlDomain: 'bscscan.com',
      blockTime: 3000,
      bscTradeMiningEnable: false,
      providerCandidates: kBSCCandidates,
      gaspriceStandard: {
        good: kBSCGoodPrice,
        great: kBSCGreatPrice,
      },
      viewOnScanKey: 'common.viewOnBscScan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '3s',
        fast: '3s',
        medium: '6s',
      },
      nftEnable: true,
      limitOrderContract: '0xdc5E86654e768d21f7D298690687eA02db7b2a04',
      limitOrderBotAddress: '0x187da347dEbf4221B861EeAFC9808d8Cf89cF5fE',
      RFQContract: '0xBbD59b9316eE65526DbBdEc2A748Cc05A285d54C',
      dexKeys: [
        'pancakeV2',
        'pancakeV1',
        'MDEX',
        'bakery',
        'biSwap',
        'apeSwap',
        'babySwap',
      ],
      rfqEnable: true,
      addChainParameters: {
        chainId: '0x61',
      },
    },
    // OKEXChain
    // https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-66.json
    66: {
      enable: true,
      name: 'OKEXChain',
      chainId: 66,
      chain: 'OKT',
      network: 'OKChain',
      networkId: 66,
      platformId: 'okex-chain',
      configUrl: `https://config.${getServiceDomain()}/configs/okchain.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/okchain.json`,
      thegraphKey: ThegraphKeyMap.okchain,
      fromTokenInit: {
        symbol: 'OKT',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'OKT',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'USDT',
        address: '0x382bB369d343125BfB2117af9c149795C6C65C50',
        name: 'USDT',
        decimals: 18,
        source: 'other',
      },
      scanUrlDomain: 'www.oklink.com/okexchain',
      blockTime: 3000,
      vDODOEnable: false,
      providerCandidates: kOkchainCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnOKTExplorer',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '15s',
        fast: '1min',
        medium: '3min',
      },
      limitOrderContract: '0x329654d697A831C2C9aceD56265Ed1E4bD5C85A0',
      limitOrderBotAddress: '0x4BFa49b0a0B6B08D3D82b8AB45feC0c737880155',
      addChainParameters: {
        chainId: '0x42',
        chainName: 'OKC',
        rpcUrls: [
          'https://exchainrpc.okex.org',
          'https://okc-mainnet.gateway.pokt.network/v1/lb/6275309bea1b320039c893ff',
        ],
        nativeCurrency: {
          name: 'OKT',
          symbol: 'OKT',
          decimals: 18,
        },
        blockExplorerUrls: ['https://www.oklink.com/okexchain/'],
      },
    },
    /**
     * Heco 主网
     * https://docs.hecochain.com/#/mainnet
     */
    128: {
      enable: true,
      name: 'Huobi ECO Chain Mainnet',
      chainId: 128,
      chain: 'Heco',
      network: 'Heco',
      platformId: 'heco',
      networkId: 128,
      configUrl: `https://config.${getServiceDomain()}/configs/heco.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/heco.json`,
      fromTokenInit: {
        symbol: 'WHT',
        name: 'Wrapped HT',
        address: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
        decimals: 18,
        showDecimals: 6,
      },
      toTokenInit: {
        symbol: 'HUSD',
        name: 'Heco-Peg HUSD Token',
        address: '0x0298c2b32eae4da002a15f36fdf7615bea3da047',
        decimals: 8,
        showDecimals: 6,
      },
      scanUrlDomain: 'hecoinfo.com',
      thegraphKey: ThegraphKeyMap.heco,
      // https://docs.hecochain.com/#/intro?id=%e7%81%ab%e5%b8%81%e7%94%9f%e6%80%81%e9%93%beheco%e6%80%a7%e8%83%bd
      blockTime: 3000,
      providerCandidates: kHecoCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnHecoScan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '3s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x3c3a0793406F9A88c331860BE4dd55DACa5b6d79',
      limitOrderBotAddress: '0x09F5FcDD8D379D5bAC139E6DC3522b5053cebB02',
      dexKeys: ['MDEX'],
      /**
       *
       * @returns 注册 heco 网络
       * @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
       * @see https://www.defibox.com/defiStrategy/113
       * @see https://chainid.network/chains.json
       */
      addChainParameters: {
        chainId: '0x80',
        chainName: 'Huobi ECO Chain Mainnet',
        rpcUrls: [
          'https://http-mainnet.hecochain.com',
          'wss://ws-mainnet.hecochain.com',
          'https://pub001.hg.network/rpc',
        ],
        nativeCurrency: {
          name: 'Huobi ECO Chain Native Token',
          symbol: 'HT',
          decimals: 18,
        },
        blockExplorerUrls: ['https://hecoinfo.com'],
      },
    },
    /**
     * Polygon matic 主网
     * https://docs.matic.network/docs/develop/network-details/network
     */
    137: {
      enable: true,
      name: 'Polygon Matic Mainnet',
      chainId: 137,
      chain: 'Polygon',
      network: 'Polygon',
      networkId: 137,
      platformId: 'polygon',
      ledgerAppName: 'Polygon',
      configUrl: `https://config.${getServiceDomain()}/configs/matic.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/matic.json`,
      fromTokenInit: {
        symbol: 'MATIC',
        name: 'MATIC Token',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        decimals: 18,
        showDecimals: 6,
      },
      toTokenInit: {
        symbol: 'DODO',
        name: 'DODO bird',
        address: '0xe4bf2864ebec7b7fdf6eeca9bacae7cdfdaffe78',
        decimals: 18,
        showDecimals: 6,
      },
      scanUrlDomain: 'polygonscan.com',
      thegraphKey: ThegraphKeyMap.polygon,
      // https://docs.hecochain.com/#/intro?id=%e7%81%ab%e5%b8%81%e7%94%9f%e6%80%81%e9%93%beheco%e6%80%a7%e8%83%bd
      blockTime: 2200,
      providerCandidates: kMaticCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnMatic',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x5F43046eAD98012044CfC1C3427A1bcEf921d3f3',
      limitOrderBotAddress: '0xA7263eb38b9A61B72397c884b5f9bFb5C34A7840',
      dexKeys: ['quickSwap', 'sushiSwap'],
      /**
       *
       * @see https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask#add-the-polygon-network-manually
       * @see https://medium.com/stakingbits/setting-up-metamask-for-polygon-matic-network-838058f6d844
       */
      addChainParameters: {
        chainId: '0x89',
        chainName: 'Polygon Mainnet',
        rpcUrls: [
          'https://polygon-rpc.com',
          'https://rpc-mainnet.matic.network',
          'https://matic-mainnet.chainstacklabs.com',
          'https://rpc-mainnet.maticvigil.com',
          'https://rpc-mainnet.matic.quiknode.pro',
          'https://matic-mainnet-full-rpc.bwarelabs.com',
          'https://polygon-bor.publicnode.com',
        ],
        nativeCurrency: {
          name: 'MATIC Token',
          symbol: 'MATIC',
          decimals: 18,
        },
        blockExplorerUrls: ['https://polygonscan.com/'],
      },
      routeV2Enable: true,
    },
    /**
     * KCC Mainnet
     * @link https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-321.json
     * @link https://docs.kcc.io/#/en-us/?id=how-to-configure-metamask-wallet
     */
    321: {
      enable: true,
      chainId: 321,
      name: 'KCC Mainnet',
      platformId: 'kcs',
      configUrl: `https://config.${getServiceDomain()}/configs/kcs.json`,
      fromTokenInit: {
        symbol: 'KCS',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'KuCoin Token',
        decimals: 18,
      },
      toTokenInit: {
        symbol: 'USDT',
        address: '0x0039f574ee5cc39bdd162e9a88e3eb1f111baf48',
        name: 'KCC-Peg Tether USD',
        decimals: 18,
      },
      scanUrlDomain: 'explorer.kcc.io/en',
      thegraphKey: ThegraphKeyMap.kcs,
      blockTime: 3000,
      providerCandidates: kKCSCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      limitOrderContract: '0xf5Ec1a19e1570bDf0A3AaA6585274f27027270b1',
      limitOrderBotAddress: '0x8eA40e8Da3ae64Bad5E77a5f7DB346499F543baC',
      rfqEnable: true,
      RFQContract: '0xa7b9C3a116b20bEDDdBE4d90ff97157f67F0bD97',
      dexKeys: [],
      addChainParameters: {
        chainId: '0x141',
        chainName: 'KCC Mainnet',
        rpcUrls: [
          'https://rpc-mainnet.kcc.network',
          'https://kcc.mytokenpocket.vip',
          'https://public-rpc.blockpi.io/http/kcc',
        ],
        nativeCurrency: {
          name: 'KuCoin Token',
          symbol: 'KCS',
          decimals: 18,
        },
        blockExplorerUrls: ['https://explorer.kcc.io/en/'],
      },
    },
    // Moonriver
    1285: {
      enable: true,
      chainId: 1285,
      name: 'Moonriver',
      platformId: 'moonriver',
      configUrl: `https://config.${getServiceDomain()}/configs/moonriver.json`,
      fromTokenInit: {
        symbol: 'MOVR',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'MOVR',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'WMOVR',
        address: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
        name: 'WMOVR',
        decimals: 18,
        source: 'dodo',
      },
      scanUrlDomain: 'moonriver.moonscan.io',
      thegraphKey: ThegraphKeyMap.moonriver,
      blockTime: 12500,
      providerCandidates: kMoonriverCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x8D4111c075CEbA32dD20EC82ecb586B85A8ca467',
      limitOrderBotAddress: '0x800A526b9aB8f2d13cE2ab9Cf000A8D64b0ab866',
      dexKeys: ['sushiSwap', 'solarBeam'],
      addChainParameters: {
        chainId: '0x505',
        chainName: 'Moonriver',
        rpcUrls: [
          'https://rpc.api.moonriver.moonbeam.network',
          'wss://wss.api.moonriver.moonbeam.network',
        ],
        nativeCurrency: {
          name: 'MOVR',
          symbol: 'MOVR',
          decimals: 18,
        },
        blockExplorerUrls: ['https://moonriver.moonscan.io'],
      },
    },
    // Arbitrum
    42161: {
      enable: true,
      name: 'Arbitrum Mainnet',
      chainId: 42161,
      chain: 'Arbitrum',
      network: 'Arbitrum',
      networkId: 42161,
      platformId: 'arbitrum', // needs confirm
      configUrl: `https://config.${getServiceDomain()}/configs/arbitrum.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/arbitrum.json`,
      fromTokenInit: {
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'Ether',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'WETH',
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        name: 'Wrap Eth',
        decimals: 18,
        source: 'dodo',
      },
      scanUrlDomain: 'arbiscan.io',
      thegraphKey: ThegraphKeyMap.arbitrum,
      blockTime: 13000, // not stable
      providerCandidates: kArbitrumCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnArbitrum',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x91FbD0C9dbA8C42B7Fa636CC60344c72E7D065c9',
      limitOrderBotAddress: '0x0F278Ee5FDd139f9aE8c6498Cca0f2c2208684a2',
      dexKeys: ['sushiSwap'],
      addChainParameters: {
        chainId: '0xa4b1',
        chainName: 'Arbitrum one',
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        nativeCurrency: {
          name: 'Ethereum',
          symbol: 'ETH',
          decimals: 18,
        },
        blockExplorerUrls: ['https://arbiscan.io'],
      },
      routeV2Enable: true,
    },
    // Arbitrum rinkeby
    // @see https://developer.offchainlabs.com/docs/public_chains
    421611: {
      enable: true,
      name: 'Arbitrum Rinkeby',
      chainId: 421611,
      chain: 'ETH',
      network: 'arb-rinkeby',
      networkId: 421611,
      platformId: 'arb-rinkeby', // needs confirm
      configUrl: `https://config.${getServiceDomain()}/configs/arbitrum-rinkeby.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/arbitrum.json`,
      fromTokenInit: {
        symbol: 'WETH',
        address: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
        name: 'Wrapped Ether',
        decimals: 18,
      },
      toTokenInit: {
        symbol: 'USDC',
        address: '0xAa57a54382B6De98f01Fe0a66cb7f5a2361BF1e3',
        name: 'USDC',
        decimals: 6,
      },
      scanUrlDomain: 'testnet.arbiscan.io',
      thegraphKey: ThegraphKeyMap.arbRinkeby,
      blockTime: 13000, // not stable
      providerCandidates: kArbRinkebyCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnArbitrum',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x8eA40e8Da3ae64Bad5E77a5f7DB346499F543baC',
      limitOrderBotAddress: '0xB5c7BA1EAde74800cD6cf5F56b1c4562De373780',
      rfqEnable: true,
      RFQContract: '0xa7b9C3a116b20bEDDdBE4d90ff97157f67F0bD97',
      dexKeys: ['sushiSwap'],
      addChainParameters: {
        chainId: '0x66eeb',
        chainName: 'Arbitrum Rinkeby',
        rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
        nativeCurrency: {
          name: 'Arbitrum Rinkeby Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        blockExplorerUrls: ['https://testnet.arbiscan.io'],
      },
      isTestNet: true,
      testWhichChain: 42161,
    },
    // Aurora
    1313161554: {
      enable: true,
      name: 'Aurora',
      chainId: 1313161554,
      platformId: 'aurora', // needs confirm
      configUrl: `https://config.${getServiceDomain()}/configs/aurora.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/aurora.json`,
      fromTokenInit: {
        symbol: 'ETH',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'Ether',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'WETH',
        address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
        name: 'Wrap Eth',
        decimals: 18,
        source: 'dodo',
      },
      scanUrlDomain: 'aurorascan.dev',
      thegraphKey: ThegraphKeyMap.aurora,
      blockTime: 1000, // not stable
      providerCandidates: kAuroraCandidates,
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x53a51a124EFCDA74fC607a272615e7e93f661a94',
      limitOrderBotAddress: '0x27566bf9504466F6f3a1571E1863Da42fff4D25E',
      dexKeys: ['trisolaris', 'wannaSwap'],
      addChainParameters: {
        chainId: '0x4e454152',
        chainName: 'Aurora',
        rpcUrls: ['https://mainnet.aurora.dev'],
        nativeCurrency: {
          name: 'Ethereum',
          symbol: 'ETH',
          decimals: 18,
        },
        blockExplorerUrls: ['https://aurorascan.dev/'],
      },
    },
    // Avalanche
    43114: {
      enable: true,
      name: 'Avalanche',
      chainId: 43114,
      platformId: 'avalanche', // needs confirm
      configUrl: `https://config.${getServiceDomain()}/configs/avalanche.json`,
      nftConfigUrl: `https://config.${getServiceDomain()}/nft-configs/avalanche.json`,
      fromTokenInit: {
        symbol: 'AVAX',
        address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        name: 'Avalanche',
        decimals: 18,
        source: 'dodo',
      },
      toTokenInit: {
        symbol: 'WAVAX',
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        name: 'Wrapped AVAX',
        decimals: 18,
        source: 'dodo',
      },
      scanUrlDomain: 'snowtrace.io',
      thegraphKey: ThegraphKeyMap.avalanche,
      blockTime: 2000, // not stable
      providerCandidates: [],
      gaspriceStandard: {
        good: kGoodPrice,
        great: kGreatPrice,
      },
      viewOnScanKey: 'common.viewOnEtherscan',
      dspPoolEnable: true,
      gasSelectSpeeds: {
        rapid: '1s',
        fast: '3s',
        medium: '6s',
      },
      limitOrderContract: '0x5Cd953fC2001487D74142B9c8B74eE95A95b7139',
      limitOrderBotAddress: '0xe3B40F8D8346d428EAB28d9Fd672b784d921cfBD',
      dexKeys: ['traderJoe', 'pangolin', 'sushi'],
      addChainParameters: {
        chainId: '0xa86a',
        chainName: 'Avalanche Network',
        rpcUrls: [
          'https://api.avax.network/ext/bc/C/rpc',
          'https://rpc.ankr.com/avalanche',
          'https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc',
        ],
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18,
        },
        blockExplorerUrls: ['https://snowtrace.io/'],
      },
      routeV2Enable: true,
    },
  };

  if (chainIdToChainConfig[chainId]) {
    return chainIdToChainConfig[chainId];
  }
  console.error('unsupported chain: ', chainId);
  return {
    ...chainIdToChainConfig[DEFAULT_CHAIN_ID],
    vDODOEnable: false,
    dspPoolEnable: false,
  };
}

export const getChainDexList = (
  chainId: number,
  filterCreateMiningEnable?: boolean,
) => {
  const { dexKeys } = getChain(chainId);
  const defaultDexList = [
    {
      key: 'dodo' as DexKey,
      value: dexListObj.dodo.name,
      icon: dexListObj.dodo.icon,
    },
  ];
  if (!dexKeys) return defaultDexList;
  return [
    ...defaultDexList,
    ...dexKeys
      .filter((key) => {
        if (filterCreateMiningEnable) {
          return dexListObj[key].createMiningEnable;
        }
        return true;
      })
      .map((key) => ({
        key,
        value: dexListObj[key].name,
        icon: dexListObj[key].icon,
      })),
  ];
};

/**
 * 一天预估产生多少区块
 */
export function getBlocksCountPerDay(chainId: number) {
  const { blockTime } = getChain(chainId);
  return (24 * 60 * 60 * 1000) / blockTime;
}

export function getBlocksCountPerYear(chainId: number) {
  return getBlocksCountPerDay(chainId) * 365;
}
