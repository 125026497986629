import { darkScrollbar, PaletteMode } from '@mui/material';
import { alpha, PaletteOptions, ThemeOptions } from '@mui/material/styles';
import { languageBtnTextMapKey } from 'src/i18n';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    custom: {
      text: {
        // 主题色背景下搭配的字体颜色
        contrastText: string;
        // 辅助文字，其他文字类型在自带的 text 下
        weak: string;
        // developer风格紫色文字
        purple: string;
      };
      background: {
        // main 主题背景色
        main: string;
        // paper 的反色，吸底按钮背景
        paperContrast: string;
        // 重一点的 paper 的反色，有时 paperContrast 不够满足
        paperDarkContrast: string;
        // 废弃
        card: string;
        // 禁用
        disabled: string;
        // 辅助按钮
        tag: string;
        // 辅助按钮禁用
        tagDisabled: string;
        // 列表 hover(常用于backgroundImage: `linear-gradient(${theme.palette.custom.background.listHover},${theme.palette.custom.background.listHover})`叠加)
        listHover: string;
        /** 渐变色，目前用于 more 导航 */
        gradient: string;
      };
      border: {
        default: string;
        disabled: string;
      };
      button: {
        main: string;
        mainContrast: string;
        tag: string;
      };
      input: {
        background: string;
        placeholder: string;
      };
      banner: {
        background: string;
        contrastText: string;
        button: string;
        buttonText: string;
      };
      // 辅助色
      status: {
        purple: {
          // 辅助色
          default: string;
          // 次辅助色
          secondary: string;
        };
        green: {
          default: string;
          secondary: string;
        };
        red: {
          default: string;
          secondary: string;
        };
        yellow: {
          default: string;
          secondary: string;
        };
      };
      shadow: {
        default: string;
        tableFixed: string;
        tableFixedBC: string;
      };
      statBar: {
        start: string;
        stop: string;
      };
    };
  }
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
    largeDesktop: true;
  }
}

export const darkPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    // 主题色
    main: '#FFE804',
    // 轻一点的主题色
    light: alpha('#FFFFFF', 0.5),
    // 重一点的主题色
    dark: '#FFFFFF',
    // 反色
    contrastText: '#1A1A1B',
  },
  divider: '#454851',
  secondary: {
    main: '#FFE804',
    dark: '#FFE804',
  },
  error: {
    // 同 status.red.default
    main: '#E94D6A',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF9553',
    contrastText: '#1A1A1B',
  },
  background: {
    default: '#252831',
    // 弹窗、卡片、表格等背景色
    // paper: '#33363F',
    paper: 'rgb(14, 17, 26)',
  },
  text: {
    // 主要文字
    primary: '#fff',
    // 次要文字
    secondary: alpha('#fff', 0.5),
    // 禁用
    disabled: alpha('#fff', 0.3),
  },
  custom: {
    text: {
      weak: alpha('#fff', 0.3),
      purple: '#BC9CFF',
      contrastText: '#1A1A1B',
    },
    background: {
      main: '#FFE804',
      // paper 的反色，吸底按钮背景
      paperContrast: '#41454F',
      paperDarkContrast: alpha('#fff', 0.1),
      // 废弃
      card: '#1a1a1b',
      disabled: alpha('#fff', 0.1),
      tag: alpha('#fff', 0.1),
      tagDisabled: alpha('#fff', 0.05),
      listHover: alpha('#fff', 0.04),
      gradient:
        'linear-gradient(223.24deg, rgba(255, 232, 0, 0.16) 8.3%, rgba(255, 112, 23, 0.2) 93.28%)',
    },
    status: {
      purple: {
        default: '#BC9CFF',
        secondary: '#BC9CFF',
      },
      green: {
        default: '#55F9C7',
        secondary: '#55F9C7',
      },
      red: {
        default: '#FF6187',
        secondary: '#FF6187',
      },
      yellow: {
        default: '#FF9553',
        // https://www.figma.com/file/XYOnVwr3WrBx6LMIT7ickX/%E6%96%B0%E7%89%88%E4%BA%AE%E8%89%B2%2F%E6%9A%97%E8%89%B2%E6%A8%A1%E5%BC%8F%E7%BB%84%E4%BB%B6%E6%9B%B4%E6%96%B0-%E9%BB%84?node-id=0%3A1
        // 警告型文本(文字)
        secondary: '#EB8D27',
      },
    },
    border: {
      default: '#454851',
      disabled: alpha('#fff', 0.1),
    },
    button: {
      main: '#FFE804',
      mainContrast: '#1A1A1B',
      tag: '#fff',
    },
    input: {
      background: '#252831',
      placeholder: alpha('#fff', 0.3),
    },
    banner: {
      background: '#33363F',
      contrastText: '#FFFFFF',
      button: '#BC9CFF',
      buttonText: '#1A1A1B',
    },
    shadow: {
      default: alpha('#1a1a1b', 0.1),
      tableFixed: '#1a1a1b',
      tableFixedBC: alpha('#1a1a1b', 0.5),
    },
    statBar: {
      start: '#FFE804',
      stop: 'rgba(255, 232, 4, 0.04)',
    },
  },
};

export const lightPalette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#1A1A1B',
    // 轻一点的主题色
    light: alpha('#1A1A1B', 0.5),
    // 重一点的主题色
    dark: '#E9F021',
    // 反色
    contrastText: '#fff',
  },
  divider: alpha('#454851', 0.1),
  secondary: {
    main: '#FFE804',
    dark: '#FFE804',
    // 反色
    contrastText: '#fff',
  },
  error: {
    // 同 status.red.default
    main: '#E94D6A',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FF9553',
    contrastText: '#1A1A1B',
  },
  background: {
    default: '#F9F6E8',
    paper: '#FFFFFF',
  },
  text: {
    primary: '#1a1a1b',
    secondary: alpha('#1A1A1B', 0.5),
    disabled: alpha('#1A1A1B', 0.3),
  },
  custom: {
    text: {
      weak: alpha('#1A1A1B', 0.3),
      purple: '#6851B4',
      contrastText: '#1A1A1B',
    },
    background: {
      main: '#FFE804',
      card: '#fff',
      disabled: alpha('#1A1A1B', 0.1),
      tag: alpha('#1A1A1B', 0.1),
      tagDisabled: alpha('#1A1A1B', 0.05),
      paperContrast: '#E9E9E9',
      paperDarkContrast: alpha('#1A1A1B', 0.1),
      listHover: alpha('#1A1A1B', 0.04),
      gradient:
        'linear-gradient(223.24deg, rgba(255, 232, 0, 0.16) 8.3%, rgba(255, 112, 23, 0.2) 93.28%)',
    },
    status: {
      purple: {
        default: '#6851B4',
        secondary: '#6851B4',
      },
      green: {
        default: '#2FBA90',
        secondary: '#2FBA90',
      },
      red: {
        default: '#EC5A7D',
        secondary: '#EC5A7D',
      },
      yellow: {
        default: '#B15600',
        secondary: '#B15600',
      },
    },
    border: {
      default: alpha('#454851', 0.1),
      disabled: alpha('#454851', 0.1),
    },
    button: {
      main: '#FFE804',
      mainContrast: '#1A1A1B',
      tag: '#1A1A1B',
    },
    input: {
      background: '#F0F0F0',
      placeholder: alpha('#1A1A1B', 0.3),
    },
    banner: {
      background: '#6850B4',
      contrastText: '#FFFFFF',
      button: '#FFFFFF',
      buttonText: '#6850B4',
    },
    shadow: {
      default: alpha('#1a1a1b', 0.1),
      tableFixed: alpha('#1a1a1b', 0.1),
      tableFixedBC: alpha('#1a1a1b', 0.05),
    },
    statBar: {
      start: '#FFE804',
      stop: 'rgba(255, 232, 4, 0.04)',
    },
  },
};

export const getDesignTokens = (
  mode: PaletteMode,
  normalFontWeight: number,
  lang: languageBtnTextMapKey,
): ThemeOptions => {
  const isLight = mode === 'light';
  return {
    palette: isLight ? lightPalette : darkPalette,
    spacing: 1,
    shape: {
      borderRadius: 1,
    },
    breakpoints: {
      values: {
        mobile: 0,
        tablet: 768,
        laptop: 1024,
        desktop: 1200,
        largeDesktop: 1536,
      },
    },
    typography: {
      fontFamily: [
        'Manrope',
        ...(lang === 'jaJP' ? ['Meiryo'] : []),
        'Poppins',
        'Inter',
        'PingFangSC-Regular',
        '"Microsoft YaHei"',
        'sans-serif',
      ].join(','),
      fontSize: 16,
      h1: {
        fontSize: 36,
        lineHeight: '49px',
        fontWeight: 600,
      },
      h2: {
        fontSize: 32,
        fontWeight: 600,
        lineHeight: '44px',
      },
      h3: {
        fontSize: 28,
        fontWeight: 600,
        lineHeight: '38px',
      },
      h4: {
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '33px',
      },
      caption: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: '28px',
      },
      h5: {
        fontSize: 18,
        lineHeight: '24px',
        fontWeight: 600,
      },
      body1: {
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: normalFontWeight,
      },
      body2: {
        fontSize: 14,
        lineHeight: '19px',
        fontWeight: normalFontWeight,
      },
      h6: {
        fontSize: 12,
        lineHeight: '17px',
        fontWeight: normalFontWeight,
      },
      button: {
        fontSize: 16,
        lineHeight: 1,
        fontWeight: 600,
      },
    },
    // https://mui.com/components/css-baseline/#scrollbars
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            overflow: 'auto!important' /* fix scrolling bug in mobile device */,
            ...(isLight ? {} : darkScrollbar()),
          },
          // global css
          // https://mui.com/customization/how-to-customize/#5-global-css-override
          a: {
            color: 'inherit',
            textDecoration: 'none',
          },
          '#WEB3_CONNECT_MODAL_ID': {
            display: 'none',
          },
          '.ps__rail-y': {
            zIndex: 1,
          },
          '.ps__rail-y:hover > .ps__thumb-y,.ps__rail-y:focus > .ps__thumb-y,.ps__rail-y.ps--clicking .ps__thumb-y':
            {
              backgroundColor: isLight ? '#999' : '#6b6b6b',
            },
          '.ps .ps__rail-x:hover,.ps .ps__rail-y:hover,.ps .ps__rail-x:focus,.ps .ps__rail-y:focus,.ps .ps__rail-x.ps--clicking,.ps .ps__rail-y.ps--clicking':
            {
              backgroundColor: isLight ? '#eee' : '#2b2b2b',
            },
          // 设计要求修改：https://app.asana.com/0/0/1201813232966729/f
          '.MuiModal-root:not(.MuiMenu-root):not(.MuiPopover-root) > .MuiBackdrop-root':
            {
              backgroundColor: 'rgba(0,0,0,0.9)',
            },
          /** z-index 覆盖 */
          '.page-confirm-modal.MuiModal-root': {
            zIndex: 5000,
          },
          // transak z-index
          '#transak_modal-overlay': {
            zIndex: 5000,
          },
          '#transak_modal': {
            zIndex: 5001,
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple!
          disableTouchRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          // The props to change the default for.
          disableRipple: true, // No more ripple!
          disableTouchRipple: true,
          disableFocusRipple: true,
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableTouchRipple: true,
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'div',
            caption: 'h5',
            subtitle1: 'h5',
            subtitle2: 'h5',
            body1: 'div',
            body2: 'div',
          },
        },
      },
    },
  };
};
