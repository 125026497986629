import { gql } from '@apollo/client';

export const fetchProposalList = gql`
  query FetchProposalList($first: Int, $where: ProposalWhere) {
    proposals(
      first: $first
      where: $where
      orderBy: "start"
      orderDirection: desc
    ) {
      id
      title
      state
      start
      snapshot
      strategies {
        name
        params
      }
    }
  }
`;

export const fetchVotesList = gql`
  query FetchVotesList($where: VoteWhere, $first: Int, $skip: Int) {
    votes(first: $first, skip: $skip, where: $where) {
      voter
      choice
      proposal {
        id
      }
    }
  }
`;
