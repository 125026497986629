import { ContractConfigDataI } from '../type';

export const AURORA_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
    decimals: 18,
    name: 'Wrapped Ethereum',
    source: 'dodo',
  },
  MULTI_CALL: '0x989DcAA95801C527C5B73AA65d3962dF9aCe1b0C',
  DODO_TOKEN: '0xe301eD8C7630C9678c39E4E45193D1e7Dfb914f7',
  ERC20_HELPER: '0xE8C9A78725D0451FA19878D5f8A3dC0D55FECF25',
  ERC20V3_FACTORY: '0xD6Bd9f3d4ad1b4464e8DdfF2da2bcAC1ff55D868',

  DODO_APPROVE: '0x335aC99bb3E51BDbF22025f092Ebc1Cf2c5cC619',

  DODO_PROXY: '0xd9deC7c3C06e62a4c1BeEB07CadF568f496b14c2',
  DODO_DSP_PROXY: '0x53233ABD9108761258a4B6B7822abFD6716B129B',
  DODO_DPP_PROXY: '0x357c5E9cfA8B834EDcef7C7aAbD8F9Db09119d11',
  DODO_CP_PROXY_V2: '0x471e1A083D76C4FC9e088FD259F64Eff0A37DAbD',
  DODO_MINEV3_PROXY: '0x4775b1858f1e417C9609D455C3Ad8751ec01daC4',
  DODO_STARTER_PROXY: '0x800A526b9aB8f2d13cE2ab9Cf000A8D64b0ab866',

  DVM_FACTORY: '0x5515363c0412AdD5c72d3E302fE1bD7dCBCF93Fe',
  DSP_FACTORY: '0xbe9a66e49503e84ae59a4d0545365AABedf33b40',
  CP_FACTORY_V2: '0x8e2f666F316b614c76676215F16F0A9746f96a90',

  ROUTE_V2_DATA_FETCH: '0x49eAB58AAE044F31d3b136212058a00b282b2845',
  ROUTE_V1_DATA_FETCH: '0xbAb9F4ff4A19a0e8EEBC56b06750253228ffAc6E',
  CALLEE_HELPER: '0x738Ebf387A0CE0eb46b0eF8Fa5DEa2EaE6B1Df51',
  FEE_RATE_IMPL: '0x16601C94Ad6E26904a519C08a7A1fCBC8e1F9D9c',
};

export const AURORA_SWAPINFO = [
  {
    // price comparison
    name: 'Trisolaris',
    factoryAddress: '0xc66F594268041dB60507F00703b152492fb176E7',
    initHash:
      '0x754e1d90e536e4c1df81b7f030f47b4ca80c87120e145c294f098c83a6cb5ace',
  },
  {
    name: 'wannaSwap',
    factoryAddress: '0x7928D4FeA7b2c90C732c10aFF59cf403f0C38246',
    initHash:
      '0xa06b8b0642cf6a9298322d0c8ac3c68c291ca24dc66245cf23aa2abc33b57e21',
    fee: 20,
  },
];
