/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  MenuItem,
  PaperProps,
  Select as SelectOrigin,
  SelectProps,
} from '@mui/material';
import { alpha, useTheme, Theme } from '@mui/system';
import { merge } from 'lodash';
import React from 'react';
import { Input } from '../Input';

interface StyleProps {
  fullWidth?: boolean;
  /**
   * 因为 select 组件右方图标不存在于 MuiSelect-select 下，然后又加了一些特别的边距。
   * 直接用 sx 比较难以计算左右内边距，所以提供一个 px 属性，计算左右内边距(默认20)
   */
  px?: number;
  /**
   * 为了和 px 统一，将 py 也提升到 props(默认12)
   */
  py?: number;
  /**
   * select 有边图标距离文字的左边距(默认8)
   */
  selectIconSpacing?: number;
  sx?: SelectProps['sx'];
  theme: Theme;
}

type Option = {
  key: number | string;
  value: string | React.ReactNode;
} & {
  [key in string]?: any;
};

interface Props extends StyleProps, SelectProps {
  disabled?: boolean;
  /**
   * 使用 key + value 数组来展示下拉数据
   * 要注意 value 必须放在一个 dom 下。因为 li 加了 justifyContent: 'space-between' ，让已选项图标定位在末尾(默认20)
   */
  options?: Option[];
  /**
   * 自定义渲染单个 item
   */
  renderItem?: (option: Option) => JSX.Element;
  /**
   * MenuItem 的样式
   */
  menuSx?: SelectProps['sx'];
  /**
   * 如果需要全新的自定义 menu 样式，可以直接使用 MenuProps 覆盖。
   * 但有时需要在现有的基础上增加或覆盖，所以提供一个 PaperProps 单独设置 merge
   */
  paperProps?: PaperProps;
  /** 新的 select 激活 样式 */
  activeV2?: boolean;
}

const computeStyles = ({
  fullWidth,
  sx,
  px,
  py,
  selectIconSpacing,
  theme,
}: StyleProps) => {
  let style: SelectProps['sx'] = {
    borderRadius: 8,
    '&&& .MuiSelect-select': {
      pl: px,
      // 8 是 右边图标的大小
      pr: (selectIconSpacing as number) + 8 + (px as number),
    },
    '& > .MuiSelect-select': {
      py,
    },
    '& > svg.MuiSelect-icon': {
      m: 0,
    },
    '&:hover': {
      '.MuiSelect-icon': {
        borderTopColor: alpha(theme.palette.text.primary, 0.5),
      },
    },
  };

  if (fullWidth) {
    style = {
      ...style,
      width: '100%',
    };
  }

  return merge(style, sx);
};

export function Select({
  disabled,
  fullWidth,
  sx,
  px = 20,
  py = 12,
  selectIconSpacing = 8,
  options,
  renderItem,
  menuSx,
  paperProps,
  activeV2,
  ...attrs
}: Props) {
  const theme = useTheme();

  const menuProps: SelectProps['MenuProps'] = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    marginThreshold: 12,
    PaperProps: merge(
      {
        sx: {
          mt: 8,
          borderRadius: 8,
          // 下拉框默认有个 backgroundImage，覆盖方便设置 backgroundColor
          backgroundImage: 'none',
          boxShadow: `0 2px 8px 2px ${
            theme.palette.mode === 'dark'
              ? alpha('#000', 0.2)
              : alpha('#1a1a1b', 0.2)
          }`,
        },
      },
      paperProps,
    ),
  };

  return (
    <SelectOrigin
      disabled={disabled}
      MenuProps={menuProps}
      input={<Input />}
      IconComponent={(props: { className: string | undefined }) => (
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            width: 0,
            height: 0,
            mr: 0,
            borderStyle: 'solid',
            borderWidth: '6px 4px 0 4px',
            borderColor: 'transparent',
            borderTopColor: 'text.primary',
            '&&&': {
              mr: 0,
              top: 'calc(50% - 2px)',
              right: px || 20,
            },
          }}
          {...props}
        />
      )}
      sx={computeStyles({
        fullWidth,
        sx,
        px,
        py,
        selectIconSpacing,
        theme,
      })}
      {...attrs}
    >
      {options
        ? options.map((option) => (
            <MenuItem
              key={option.key}
              value={option.key}
              sx={merge(
                {
                  color: 'text.secondary',
                  justifyContent: 'space-between',
                  px: 16,
                  backgroundColor: 'transparent',
                  '&::after': {
                    content: '""',
                    display: 'inline-block',
                    width: 8,
                    height: 8,
                    borderRadius: 4,
                    ml: 12,
                    backgroundColor: 'primary.main',
                    visibility: 'hidden',
                  },
                  '&:hover, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible:hover':
                    {
                      backgroundColor: 'custom.background.listHover',
                      color: 'text.primary',
                    },
                  '&.Mui-selected, &.Mui-selected.Mui-focusVisible': {
                    backgroundColor: 'transparent',
                    color: 'text.primary',
                    '&::after': {
                      visibility: 'visible',
                    },
                  },
                  '& > div.target-item::before': {
                    visibility: 'visible',
                  },
                },
                activeV2
                  ? merge(
                      {
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          display: 'inline-block',
                          left: -10,
                          width: 2,
                          height: 18,
                          borderRadius: 0,
                          ml: 0,
                          backgroundColor: 'text.primary',
                        },
                        mx: 12,
                        px: 16,
                        minWidth: 178,
                        borderRadius: 8,
                      },
                      menuSx,
                    )
                  : menuSx,
              )}
            >
              {renderItem ? renderItem(option) : option.value}
            </MenuItem>
          ))
        : ''}
    </SelectOrigin>
  );
}

Select.Option = Option;

export default Select;
