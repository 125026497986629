import { GetConfig } from '@dodoex/utils';
import BigNumber from 'bignumber.js';
import { Map } from 'immutable';
import Web3 from 'web3';
import { sentry } from './core';
import { getCurrentChainId, getLatestBlockNumber } from './selectors';
import { getTokenBlackList } from './tokenBlackList';
import { contractQueryParamsList } from './contractQuery';
import { ABIName } from './contractQuery/queueWorker/contract';

export interface ImmutableMap<T> extends Map<string, T[keyof T]> {
  get<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): T[K];
  set<K extends Extract<keyof T, string>>(key: K, value: T[K]): this;
  delete<K extends Extract<keyof T, string>>(key: K): this;

  update<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    notSetValue: V,
    updater: (value: V) => V,
  ): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    updater: (value: V) => V,
  ): this;
}

export type SimpleToken = ImmutableMap<{
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  showDecimals: number;
  logoUrl?: string;
}>;

type TokenResult = {
  address: string;
  balance: BigNumber;
  allowance: BigNumber;
  decimals: number;
  symbol: string;
  name: string;
};

const ALLOWANCE_FAILING_TOKENS = [
  '0xa74476443119a942de498590fe1f2454d7d4ac0d', // GNT on mainnet
  '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
];
/**
 * {
 *  blockNumber: {
 *    account: {
 *      spender: {
 *        address: TokenResult
 *      }
 *    }
 *  }
 * }
 */
let currentFetchingToken = Map<
  BigNumber,
  Map<string, Map<string, Map<string, TokenResult>>>
>();
export async function fetchTokensByAddress(
  addresses: string[],
  chainIdProps?: number,
  account = '0xbC7814de9e42945C9fFd89D2BFff1a45e07Bdb10',
  spender?: string,
  forced?: boolean,
) {
  const chainId = chainIdProps || getCurrentChainId();
  const correctTokens: TokenResult[] = [];
  const proxyAddress = spender || GetConfig(chainId).DODO_APPROVE;
  if (!proxyAddress) {
    console.error('spender is undefined');
    return correctTokens;
  }

  const blockNumber = getLatestBlockNumber();
  const currentBlockFetchingToken = currentFetchingToken.get(blockNumber);

  let registry: Map<string, TokenResult> = Map();
  if (currentBlockFetchingToken) {
    const registryRaw = currentBlockFetchingToken.getIn([
      account,
      proxyAddress,
    ]);
    if (registryRaw) {
      registry = registryRaw as Map<string, TokenResult>;
    }
  }

  const tokenAddressSet = new Set<string>(
    addresses.map((item) => item.toLocaleLowerCase()),
  );
  let tokenAddresses = Array.from(tokenAddressSet).filter((address) =>
    Web3.utils.isAddress(address),
  );

  tokenAddresses = tokenAddresses.filter((lowerAddr) => {
    if (ALLOWANCE_FAILING_TOKENS.includes(lowerAddr)) return false;

    const original = registry.get(lowerAddr);
    if (!forced && original) {
      correctTokens.push(original);
      return false;
    }
    return true;
  });
  const paramsList = tokenAddresses.map((tokenAddress) => {
    return {
      // isERC20 方法传入 spender 参数传入任意地址避免报错
      params: [tokenAddress, account, proxyAddress],
      callback: (raw: any) => {
        if (raw.name) {
          correctTokens.push(raw);
        }
      },
    };
  });
  await contractQueryParamsList(chainId, {
    method: 'isERC20',
    abiName: ABIName.erc20Helper,
    paramsList,
  });

  registry = registry.withMutations((map) => {
    correctTokens.forEach((token) => {
      const lowerAddr = token.address.toLocaleLowerCase();
      const original = registry.get(lowerAddr);
      if (!original) {
        map.set(lowerAddr, token);
      }
    });
  });
  currentFetchingToken = currentFetchingToken.setIn(
    [blockNumber, account, proxyAddress],
    registry,
  );

  // currentFetchingToken 对象会随着块高不断增长，需要及时清理释放内存，只保留最近两个区块的记录
  const currentFetchingTokenKeys = Array.from(currentFetchingToken.keys());
  currentFetchingTokenKeys.sort((a, b) => a.toNumber() - b.toNumber());
  if (currentFetchingTokenKeys.length >= 3) {
    currentFetchingToken = currentFetchingToken.delete(
      currentFetchingTokenKeys[0],
    );
  }

  // 如果 correctTokens 数量过少，怀疑网络问题，上报异常
  if (
    correctTokens.length * 2 < tokenAddresses.length &&
    correctTokens.length < 100 &&
    correctTokens.length > 1
  ) {
    const api = '[fetchTokensByAddress] length mismatch';
    const params = {
      correctTokensLen: correctTokens.length,
      tokenAddressesLen: tokenAddresses.length,
    };
    console.error(api, params, paramsList, correctTokens);
    sentry.withScope(function (scope: any) {
      scope.setTag('api', api);
      scope.setExtra('params', {
        chainId,
        account,
        tokenAddresses,
        ...params,
      });
    });
  }
  return correctTokens;
}
