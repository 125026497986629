import BigNumber from 'bignumber.js';
import { store } from '../init';
import { TokenState } from './types';
import { Token } from '../types';

export const getAccountBalances = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('accountBalances');
};

export const getAccountChainBalances = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('accountChainBalances');
};

export const getEthBalance = (
  account?: string,
  state?: TokenState,
): BigNumber => {
  return (state ?? store.getState()).dodoToken.getIn(
    ['accountBalances', account, 'ethBalance'],
    new BigNumber(NaN),
  ) as BigNumber;
};

export const getTokenBalance = (
  account?: string,
  tokenAddress?: string,
  state?: TokenState,
): BigNumber => {
  return (state ?? store.getState()).dodoToken.getIn(
    ['accountBalances', account, 'tokenBalances', tokenAddress],
    new BigNumber(NaN),
  ) as BigNumber;
};

export const getTokenAllowance = (
  account: string,
  tokenAddress: string,
  proxyAddress: string,
  state?: TokenState,
) => {
  return (state ?? store.getState()).dodoToken.getIn(
    ['accountBalances', account, 'tokenAllowances', proxyAddress, tokenAddress],
    new BigNumber(NaN),
  );
};

export const getTokens = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('tokens');
};

export const getTokenLists = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('tokenLists');
};
export const getTokenListStatus = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('tokenListsStatus');
};
export const getFullTokens = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('fullTokens');
};

export const getCustomTokens = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('customTokens');
};

export const getImportTokens = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('importTokens');
};

export const getAllTokens = (state?: TokenState) => {
  return getTokens(state).mergeDeepIn(getCustomTokens(state));
};

export const getCheckTokenAddresses = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('checkTokenAddresses');
};

export const getToken = (
  tokenAddress: string,
  state?: TokenState,
): Token | undefined => {
  return (state ?? store.getState()).dodoToken.getIn([
    'tokens',
    tokenAddress,
  ]) as Token | undefined;
};

export const getBalanceLoadings = (state?: TokenState) => {
  return (state ?? store.getState()).dodoToken.get('balanceLoadings');
};
