import { ContractConfigDataI } from '../type';

export const ETH_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  DODO_TOKEN: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',

  ERC20_HELPER: '0xD9ef2d1583e8Aa196123e773BE38B539a4d149df',
  ERC20V3_FACTORY: '0x7466C6FE28180c33e2a35989FD6833C8dD5A7E16',

  DODO_APPROVE: '0xCB859eA579b28e02B87A1FDE08d087ab9dbE5149',

  DODO_PROXY: '0xa356867fDCEa8e71AEaF87805808803806231FdC',
  DODO_DSP_PROXY: '0x4599ed18F34cFE06820E3684bF0aACB8D75c644d',
  DODO_DPP_PROXY: '0xfF7C8F518e6f1435957ed3d3E0692C94676dAE7a',
  DODO_V1_PAIR_PROXY: '0x8dD0Fea5FA2f7df535F87f312641Cc15d8B151BA',
  DODO_CP_PROXY_V2: '0x283e02d0D906F1395777799f0a153c20A83d0ef2',
  DODO_MINEV3_PROXY: '0x0d9685D4037580F68D9F77B08971f17E1000bBdc',
  DODO_STARTER_PROXY: '0xeB28dAc5ffD6e46d344D488189B501469F4Af561',

  DVM_FACTORY: '0x72d220cE168C4f361dD4deE5D826a01AD8598f6C',
  DSP_FACTORY: '0x6fdDB76c93299D985f4d3FC7ac468F9A168577A4',
  CP_FACTORY_V2: '0x1E5D8EE8fb7f0d791475c59391DB8f6CD06Aa656',

  ROUTE_V2_DATA_FETCH: '0x9337cA090c390667984869193e10c3D4f9a17da8',
  ROUTE_V1_DATA_FETCH: '0x6373ceB657C83C91088d328622573FB766064Ac4',
  CALLEE_HELPER: '0x45a7E2E9D780613E047f7e78a9d3902ff854B522',

  vDODO: '0xc4436fBAE6eBa5d95bf7d53Ae515F8A707Bd402A',
  vDODO_DODO_TEAM: '0x95C4F5b83aA70810D4f142d58e5F7242Bd891CB0',
  FEE_RATE_IMPL: '0x97bBF5BB1dcfC93A8c67e97E50Bea19DB3416A83',

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0x90294216e8C79e812d3F1965f5c8362F0EFB7603',
  DODO_NFT_PROXY: '0x987e2a3A65A526C66ac66B6F8c84120055363625',
  BUYOUT_MODEL: '0x102739e36Bc412CF79ff62f3e1efABA9F8296426',
  DODO_NFT_REGISTER: '0xA7263eb38b9A61B72397c884b5f9bFb5C34A7840',
  DODO_NFT_ERC721: '0x945497d9A3e0C1600478e466E2a38Ff8E1623E55',
  DODO_NFT_ERC1155: '0x3Ab9b8bcC97DB111BF14b04eBd4c08dce94CFD5b',

  DODO_NFTPOOL_PROXY: '0x9D4c342298Ba0C77E75CfD379a27F1420B3Ce45D',
  DODO_NFT_APPROVE: '0xf740253BDF035620E12f55D61C092aa294E2E5F3',
};

export const ETH_SWAPINFO = [
  {
    name: 'Uniswap V2',
    factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
    initHash:
      '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
  },
  {
    name: 'SushiSwap',
    factoryAddress: '0xC0AEe478e3658e2610c5F7A4A2E1777cE9e4f2Ac',
    initHash:
      '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  },
  {
    name: 'ShibaSwap',
    factoryAddress: '0x115934131916C8b277DD010Ee02de363c09d037c',
    initHash:
      '0x65d1a3b1e46c6e4f1be1ad5f99ef14dc488ae0549dc97db9b30afe2241ce1c7a',
  },
];
