import { gql } from '@apollo/client';

export const fetchTokenListWidthSlippage = gql`
  query FetchTokenListWidthSlippage($where: ManagechainFilter) {
    manage_slippage_tolerance_list(where: $where) {
      name
      chain
      slippage_tolerance
      tokens {
        symbol
        token
      }
    }
  }
`;

export const fetchCoinmarketcapTokenList = gql`
  query FetchCoinmarketcapTokenList(
    $where: Coinmarketcap_tokenCoinmarketcapTokenListFilter
  ) {
    coinmarketcap_token_list(where: $where) {
      id
      name
      address
      symbol
      decimals
      logoURI
      chainId
    }
  }
`;

export const fetchThirdPartyTokenList = gql`
  query FetchThirdPartyTokenList($where: Third_party_tokenlistData) {
    third_party_token_list(data: $where) {
      address
      decimals
      from
      logoURI
      name
      symbol
    }
  }
`;
