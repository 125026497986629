import { ContractConfigDataI } from '../type';

export const RINKEBY_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xB26c0d8Be2960c70641A95A9195BE1f59Ac83aC0',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0xb7E1C577f95959a3eD11f9281702850328b4e0e4',
  DODO_TOKEN: '0xeaa70c2a40820dF9D38149C84dd943CFcB562587',

  ERC20_HELPER: '0x6A42091d713A67E25a1D11aeE14E7AEB96E0331f',
  ERC20V3_FACTORY: '0x8C4690eF31a0F6f0c1C645E62abF420Fe7dC4f81',

  DODO_APPROVE: '0xcC8d87A7C747eeE4242045C47Ef25e0A81D56ae3',

  DODO_PROXY: '0xba001E96AF87bF9d8D0BDA667067A9921FE6d294',
  DODO_DSP_PROXY: '0x0f6345D1d07C134BB0973AD102F38eA9195F6f78',
  DODO_DPP_PROXY: '0x45136c2455Dd2631E31ab884cf167eC618CCf39a',
  DODO_CP_PROXY_V2: '0x66c45FF040e86DC613F239123A5E21FFdC3A3fEC',
  DODO_MINEV3_PROXY: '0x5776C34652Ba1Bb2E0fb678923452285A24C9Be6',
  DODO_STARTER_PROXY: '0x451E07405B79eDEEA87ccFa57e1BaF184Bea6773',

  DVM_FACTORY: '0x17DddEFA0c82E0c850a4Adac2aFE3F1fe977A242',
  DSP_FACTORY: '0xa1ab675cB49BA0DC3F39fA4C20E216572A8dD3c8',
  CP_FACTORY_V2: '0x46AF6b152F2cb02a3cFcc74014C2617BC4F6cD5C',

  ROUTE_V2_DATA_FETCH: '0x35150B1C26Eb48eA13Cd7686706e333c9f544BE7',
  ROUTE_V1_DATA_FETCH: '0xEb06236b035f1Db7F1D181Efd635Edd621874472',
  CALLEE_HELPER: '0x87C296100df953aFa324ABB870891baD9dDBf0fC',

  vDODO: '0x8751f874eCd2874f2a5ced95A08364C203e4146A',
  vDODO_DODO_TEAM: '0x7e83d9d94837eE82F0cc18a691da6f42F03F1d86',
  FEE_RATE_IMPL: '0x6de4d882a84A98f4CCD5D33ea6b3C99A07BAbeB1',

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0xC08D918400859272442CC71fc8cC3b1a69835B4a',
  DODO_NFT_PROXY: '0xc83c4aFdF216C7D0E15D50B9e1658298320A9551',
  BUYOUT_MODEL: '0x1A18Ccf68040f660Ac7f83B4911d17398eDbC79f',
  DODO_NFT_REGISTER: '0xfa391c0Ed6898e0C6186605d69e877f1317Bb506',
  DODO_NFT_ERC721: '0x78B7AFf2E5fA95B1E7E16679645FB65a850ed6AB',
  DODO_NFT_ERC1155: '0x4C455532af01bc34a0Ec60fDd63c68FE41068c63',
  DODO_NFTPOOL_PROXY: '0x81AD954B2Ed65d85d3023Eeb2D8DF6A512D4cd59',
  DODO_NFT_APPROVE: '0x5a93021C4a2072E0F3daA646deBab0C9A8E3feE6',
  // DODO_DROPS_PROXY: "0xa968a8B14174395c922347Ab593a0CD7EFf30cf1",
};

export const RINKEBY_SWAPINFO = [];
