import { logWallet, WalletEventLabel } from '@dodoex/mixpanel/dist/wallet';
import {
  ApolloGQLClientDevEndpoint,
  ApolloGQLClientEndpoint,
  Chain,
  getIsDevEnvironment,
  request,
} from '@dodoex/utils';
import { BigNumber } from 'bignumber.js';
import { bufferToHex } from 'ethereumjs-util';
import { TransactionConfig, TransactionReceipt } from 'web3-core';
import { toHex } from 'web3-utils';
import { setBaseFee, setGasPriceInfo } from '.';
import erc20ABI from './abis/erc20ABI';
import { getChainConfig } from './chainConfigs';
import { accessToken, mixpanel, store, fetchGasPrice } from './core';
import {
  getCurrentAccountType,
  getCurrentAccount,
  getCurrentChainId,
} from './selectors';
import { AccountType } from './reducer';
import { StateText, SwapType } from './Submission';
import {
  getCachedContract,
  getTransactionCount,
  getWeb3,
  getWeb3BynotAccount,
  loadLatestBlockNumber,
} from './web3';
import {
  getIsSafe,
  getSafeTxHash,
  getSafeTxStatus,
} from './injects/wallet/safeWalletConnect';

export interface TransactionConfigExt
  extends Omit<
    Omit<TransactionConfig, 'maxPriorityFeePerGas'>,
    'maxFeePerGas'
  > {
  gasLimit?: string;
  // 禁止 sendTransaction 强行将 gas 设置加入到 params 中，导致 metamask 里面出现站点推荐的 gas 设置而导致上链报错
  maxPriorityFeePerGas: null;
  maxFeePerGas: null;
}

export const approve = async (
  tokenAddress: string,
  accountAddress: string,
  contractAddress: string,
  allowance: BigNumber,
): Promise<string> => {
  const contract = await getCachedContract(tokenAddress, erc20ABI);
  const data = contract.methods
    .approve(contractAddress, allowance.toFixed())
    .encodeABI();
  const params: TransactionConfigExt = {
    from: accountAddress,
    to: tokenAddress,
    data,
    value: `0x${new BigNumber('0').toString(16)}`,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  };

  const gasLimit = await getEstimateGas(params);
  if (gasLimit === null) {
    throw new Error('getGasLimitError');
  }
  params.gasLimit = toHex(gasLimit);
  const transactionId = await sendTransaction(params);

  return transactionId;
};

export const WalletErrorsWords = [
  'SafeERC20',
  'User denied',
  'insufficient',
  'cancel',
  'nonce too low',
  'User rejected',
  'Cancel',
  'External Swap',
  'Return amount is not enough',
];

export const isInKnownErrors = (message: string) => {
  return !!WalletErrorsWords.find((word) => message.includes(word));
};

export async function getAccountType(chainId: number, account: string) {
  const web3 = await getWeb3();
  const code = await web3.eth.getCode(account);
  let accountType = AccountType.EOA;
  if (code !== '0x') {
    accountType = AccountType.contract;
    const isSafe = await getIsSafe(chainId, account);
    if (isSafe) {
      accountType = AccountType.safe;
    }
  }
  return accountType;
}

export const sendTransaction = (
  params: TransactionConfigExt,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    getWeb3().then(async (web3) => {
      const chainId = await web3.eth.getChainId();
      if (params.chainId && params.chainId !== chainId) {
        reject(new Error('Network not mismatch'));
        return;
      }
      web3.eth.sendTransaction(params, (err, transactionId) => {
        if (err) {
          reject(err);
        } else {
          const account = getCurrentAccount();
          const accountType = getCurrentAccountType();
          if (accountType === AccountType.safe && account) {
            getSafeTxHash(chainId, account, transactionId).then(resolve);
          } else {
            resolve(transactionId);
          }
        }
      });
    });
  });
};

export const getEstimateGas = (params: any): Promise<number | null> => {
  const estimateTarget = {
    from: params.from,
    to: params.to,
    value: params.value,
    data: params.data,
  };
  return new Promise((resolve, reject) => {
    getWeb3().then((web3) => {
      web3.eth.estimateGas(estimateTarget, (err, result) => {
        if (err) {
          // estimate gas failed
          reject(err);
          web3.eth.call(params, (e) => {
            try {
              let error = e.message;
              if (e.message.startsWith('0x')) {
                error = web3.utils.toAscii(
                  e.message.replace(/(.|\n)+0x/, '0x'),
                );
                console.log('Estimate Gas Error: ', error);
              }
              if (mixpanel && err.message && !isInKnownErrors(err.message)) {
                logWallet(WalletEventLabel.errorEstimateGas, {
                  ...estimateTarget,
                  error,
                });
              }
            } catch (error) {
              console.log('estimate gas error', error);
            }
          });
        } else {
          resolve(Number(new BigNumber(result).plus(50000).toFixed(0))); // cover all dodo trade cases
        }
      });
    });
  });
};

type WatchTxConfig = {
  interval: number;
  killSwitch: boolean;
  fastKillSwitch: boolean;
};

export type WatchTransactionReturn = {
  /** 前端的上链状态 */
  status: WatchResult;
  /** 用于提交给后端上链状态 */
  statusText: StateText;
  transactionReceipt:
    | (Pick<TransactionReceipt, 'status' | 'transactionHash'> & {
        blockNumber: number | null;
      })
    | null;
};

/** Meow: trust web3 / etherscan result (注意 failed 是 0) */
export enum WatchResult {
  Failed = 0, // 0:失败
  Success, // 1:成功
  Warning, // 2:警告(当前nonce的交易被覆盖，如用户在钱包使用加速/取消)
}

export async function watchPrivacyOrder(
  txId: string,
): Promise<WatchTransactionReturn['transactionReceipt']> {
  const isDev = getIsDevEnvironment();
  const url = isDev ? ApolloGQLClientDevEndpoint : ApolloGQLClientEndpoint;

  const headers = accessToken
    ? {
        'access-token': accessToken,
      }
    : undefined;
  const params = {
    query:
      'query QueryPrivateOrder($where: Limit_and_rfqgetPrivateOrderParam) {\n  limit_and_rfq_getPrivateOrder(where: $where) {\n    id\n    network\n    txid\n    progress\n    createdAt\n  }\n}\n',
    variables: {
      where: {
        hash: txId,
      },
    },
    operationName: 'QueryPrivateOrder',
  };
  const res = await request({
    url: `${url}?opname=QueryPrivateOrder`,
    method: 'post',
    headers,
    data: params,
  });
  console.log('[TX Watch] PrivateOrder', res, res.data, res.data.data);
  if (
    res &&
    res.data &&
    res.data.data &&
    res.data.data.limit_and_rfq_getPrivateOrder
  ) {
    const { progress } = res.data.data.limit_and_rfq_getPrivateOrder;
    if (progress === 'FINISHED') {
      return {
        status: true,
        transactionHash: txId,
        blockNumber: null,
      };
    }
    if (progress === 'FAILED') {
      return {
        status: false,
        transactionHash: txId,
        blockNumber: null,
      };
    }
  }

  // FRESH 等待同步
  return null;
}

export async function watchTxWeb3(
  txid: string,
  config: WatchTxConfig,
  nonce?: number,
  addr?: string,
  swapType?: SwapType | undefined,
): Promise<{
  status: WatchResult;
  transactionReceipt: WatchTransactionReturn['transactionReceipt'];
} | null> {
  const web3 = await getWeb3();
  const chainId = getCurrentChainId();
  const account = getCurrentAccount();
  let accountType = AccountType.EOA;
  let getRespFunction: (txId: string) => ReturnType<typeof watchPrivacyOrder>;
  if (swapType === SwapType.Privacy) {
    getRespFunction = watchPrivacyOrder;
  } else {
    if (account) {
      accountType = await getAccountType(chainId, account);
    }
    getRespFunction = web3.eth.getTransactionReceipt;
  }

  while (!config.killSwitch) {
    try {
      let status: WatchResult | undefined;
      if (accountType !== AccountType.safe) {
        // eslint-disable-next-line no-await-in-loop
        const [respOrigin, currentNonce] = await Promise.all([
          getRespFunction(txid),
          getTransactionCount(addr),
        ]);
        console.log(
          '[TX Watch] Web3',
          respOrigin,
          '/Current Nonce ',
          currentNonce,
          '/TX Nonce',
          nonce,
        ); // Bitkeep 钱包直接返回了 rpc 响应的数据，这里特殊处理下
        const resp: WatchTransactionReturn['transactionReceipt'] = (
          respOrigin as any
        )?.jsonrpc
          ? (respOrigin as any).result
          : respOrigin;
        if (resp)
          status = resp.status ? WatchResult.Success : WatchResult.Failed;
        /**
         * 因为 nonce 是递进的关系，而且上一个 nonce 未完成，下一个不可能会完成。
         * 所以这里判断当下一个已经完成了，上一个未完成的 nonce ，判定为 已经被重置
         * @注意 WatchResult.Failed = 0，不要直接使用 boolean 来判断
         */
        if (
          status === undefined &&
          nonce &&
          currentNonce &&
          currentNonce > nonce
        )
          status = WatchResult.Warning;
        if (status !== undefined) {
          return {
            status,
            transactionReceipt: resp,
          };
        }
        // 因为 safe 钱包状态接口比 nonce 慢，所以用上面的方法来判定 reset 是不行的
      } else if (account) {
        // eslint-disable-next-line no-await-in-loop
        const safeTxStatus = await getSafeTxStatus(chainId, txid, account);
        if (safeTxStatus === 'rejected') {
          return {
            status: WatchResult.Warning,
            transactionReceipt: null,
          };
        }
        if (safeTxStatus)
          status = safeTxStatus.status
            ? WatchResult.Success
            : WatchResult.Failed;
        if (status !== undefined) {
          return {
            status,
            transactionReceipt: safeTxStatus,
          };
        }
      }
    } catch (e) {
      // @ts-ignore
      console.error('[TX Watch] Error', e.message);
    }
    await new Promise((resolve) => setTimeout(resolve, config.interval));
  }
  return null;
}

// Input multiple promises, returns the last one that resolves when all finishes or timed-out
// If everybody timed-out, this promise resolves with null
function lastResult<T>(
  promises: Promise<T>[],
  timeout: number | null,
  trigger: ((result: T, forceKill: () => void) => void) | null,
): Promise<T | null> {
  let lastResult: T | null = null;
  let left = promises.length;
  let resolved = false;
  return new Promise((resolve, reject) => {
    const kill = () => {
      if (!resolved) {
        resolved = true;
        resolve(lastResult);
      }
    };

    for (const p of promises) {
      p.then((result) => {
        if (result !== null) {
          lastResult = result;
          if (trigger) trigger(result, kill);
        }

        --left;
        if (left === 0) {
          resolved = true;
          resolve(lastResult);
        }
      }).catch((e) => {
        console.log('[TX Watch]  Error', e);
        if (!resolved) {
          resolved = true;
          reject();
        }
      });

      if (timeout !== null) setTimeout(kill, timeout);
    }
  });
}

export const watchTransaction = async (
  txId: string,
  nonce?: number,
  addr?: string,
  /** Privacy 隐私交易需要特殊的监听方法 */
  swapType?: SwapType | undefined,
): Promise<WatchTransactionReturn> => {
  const start = performance.now();
  const chainId = getCurrentChainId();
  const { watchTxTime } = getChainConfig(chainId);
  const config = {
    killSwitch: false,
    fastKillSwitch: false,
    interval: watchTxTime || 3000,
  };
  const statusTextMap = {
    [WatchResult.Failed]: StateText.Failed,
    [WatchResult.Success]: StateText.Success,
    [WatchResult.Warning]: StateText.Warning,
  };

  async function watchBlocknumber({
    targetBlocknumber,
  }: {
    targetBlocknumber: number | null;
  }) {
    const currentBlocknumber = await loadLatestBlockNumber(chainId, {
      notCheckTime: true,
    });

    if (
      currentBlocknumber != null &&
      targetBlocknumber != null &&
      currentBlocknumber.lt(targetBlocknumber)
    ) {
      console.warn(
        '[blockNumber error]: rpc delay too much, waiting...',
        currentBlocknumber.toString(),
        targetBlocknumber,
      );

      await new Promise((resolve) => {
        setTimeout(async () => {
          await watchBlocknumber({
            targetBlocknumber,
          });
          resolve({});
        }, config.interval);
      });
    }
  }

  try {
    /* Four-way select */
    const web3Result = watchTxWeb3(txId, config, nonce, addr, swapType);
    const sources = [web3Result];
    const fallbackResult = new Promise<WatchTransactionReturn>((resolve) =>
      // eslint-disable-next-line no-promise-executor-return
      setTimeout(
        () =>
          resolve({
            status: WatchResult.Failed,
            statusText: statusTextMap[WatchResult.Failed],
            transactionReceipt: null,
          }),
        3600000,
      ),
    ); // 1h
    sources.push(fallbackResult);
    const result = await lastResult(sources, null, (ret, kill) => {
      console.log('Got result, triggering kill switch', ret);
      // Kill nonce immediately
      config.fastKillSwitch = true;
      setTimeout(() => {
        kill();
        config.killSwitch = true;
      }, 5000);
    });
    // 因为快速链 blockNumber 更新不及时，所以在上链完成后更新一次
    // 加上 await 使块高更新完毕之后才弹窗通知上链成功和并触发后续其他查询任务。偶尔会出现弹窗通知之后立即查询还是查询到旧区块的数据，造成前端数据不自洽，所以这里修改为同步。see https://app.asana.com/0/0/1204027861211163/f
    // 如果查询到的快高小于这一次上链成功返回的 tx 的快高，那可能节点有延迟，不断查询直至区块高度一致，保证后续查询都与链上保持一致
    await watchBlocknumber({
      targetBlocknumber: result?.transactionReceipt?.blockNumber ?? null,
    });

    if (mixpanel)
      logWallet(WalletEventLabel.eventTransactionPendingTime, {
        duration: performance.now() - start,
      });
    return result === null
      ? {
          status: WatchResult.Failed,
          statusText: statusTextMap[WatchResult.Failed],
          transactionReceipt: null,
        }
      : {
          ...result,
          statusText: statusTextMap[result.status],
        };
  } catch (e) {
    console.log('[TX Watch] Error', e);
    return {
      status: WatchResult.Failed,
      statusText: statusTextMap[WatchResult.Failed],
      transactionReceipt: null,
    };
  }
};

export const loadBaseFee = async () => {
  try {
    const web3 = await getWeb3BynotAccount();
    const block = await web3.eth.getBlock('pending');
    // @ts-ignore
    if (block && block.baseFeePerGas) {
      // @ts-ignore
      store.dispatch(setBaseFee(new BigNumber(block.baseFeePerGas)));
    }
  } catch (e) {
    console.error(e);
  }
};

export const loadGasPrice = async () => {
  try {
    if (fetchGasPrice) {
      const chainId = getCurrentChainId();
      const gasPriceInfo = await fetchGasPrice(chainId);
      if (!gasPriceInfo) {
        store.dispatch(
          setGasPriceInfo({
            gasPrice: 0,
          }),
        );
      } else {
        store.dispatch(setGasPriceInfo(gasPriceInfo));
      }
    } else {
      const web3 = await getWeb3BynotAccount();
      const gasPrice = Number(await web3.eth.getGasPrice());
      store.dispatch(
        setGasPriceInfo({
          gasPrice,
        }),
      );
    }
  } catch (e) {
    console.error(e);
  }
};

/**
 * 添加自定义代币到 metamask
 * https://docs.metamask.io/guide/registering-your-token.html#registering-tokens-with-users
 */
export async function registerTokenWithMetamask(token: {
  address: string;
  symbol: string;
  decimals: number;
  logoUrl: string;
}): Promise<{ result: boolean; failMsg?: string }> {
  if (!window.ethereum) return { result: false };
  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: token.address, // The address that the token is at.
          symbol: token.symbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: token.decimals, // The number of decimals in the token
          image: token.logoUrl, // A string url of the token logo
        },
      },
    });

    return {
      result: wasAdded,
    };
  } catch (error) {
    console.error(error);
    return {
      result: false,
      // @ts-ignore
      failMsg: error?.message,
    };
  }
}

/**
 * 切换节点，如果节点不存在则添加节点到 metamask
 * 注意：节点不存在自动添加后 metamask 会提示切换，如果用户拒绝切换则只会把节点添加进去而不会切换到目标链，但是该过程无法捕获，此种场景下该方法依然返回 true
 *
 * @see https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain
 * @bug 移动端 metamask 无法添加 optimism 节点 https://app.asana.com/0/1201249472074782/1202725311612469/f
 */
export async function registerNetworkWithMetamask({
  addChainParameters,
  provider = window.ethereum,
}: {
  addChainParameters: Chain['addChainParameters'];
  provider?: any;
}): Promise<{
  result: boolean;
  failMsg?: string;
}> {
  if (!provider) {
    return {
      result: false,
    };
  }
  const { chainId } = addChainParameters;
  // 使用 metamask 推荐的方式切换链
  // https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    return {
      result: true,
    };
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask. -32603 是移动端报的错
    const { code } = switchError as { code: number };
    if (code === 4902 || code === -32603) {
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [addChainParameters],
        });
        return {
          result: true,
        };
      } catch (addError) {
        // handle "add" error
        console.error(
          `[failed to add ${chainId}]: `,
          addChainParameters,
          addError,
        );
      }
    }
    // handle other "switch" errors
    console.error(
      `[failed to switch ${chainId}]: `,
      addChainParameters,
      switchError,
    );
  }
  return {
    result: false,
  };
}

export async function personalSign(message: string, account: string) {
  const web3 = await getWeb3();
  const msg = bufferToHex(new Buffer(message, 'utf8'));
  const signature: string = await new Promise((resolve, reject) => {
    (web3.currentProvider as any).sendAsync(
      {
        method: 'personal_sign',
        params: [msg, account],
        account,
      },
      (err: Error, result: any) => {
        if (err) {
          reject(err);
        }
        resolve(result.result);
      },
    );
  });

  return signature;
}
