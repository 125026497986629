import { PaletteMode } from '@mui/material';
import { AnyAction } from 'redux';
import { languageBtnTextMapKey } from 'src/i18n';

export type ColorMode = PaletteMode | 'system';

export type State = {
  language: languageBtnTextMapKey;
  colorMode: ColorMode;
};

export const initialState: State = {
  language: 'enUS',
  colorMode: 'system',
};

export default (state: State = initialState, action: AnyAction): State => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SET_COLOR_MODE':
      return {
        ...state,
        colorMode: action.payload,
      };
    default:
      return state;
  }
};
