import type { SearchParams } from './searchWorker';

export type { SearchParams } from './searchWorker';

const searchTokensWorker = new Worker(
  new URL('./searchWorker', import.meta.url),
);

let id = 0;

export const getFuzzySearchTokensSort = (
  params: SearchParams,
): Promise<Map<string, number>> => {
  return new Promise((resolve) => {
    if (!params.search) {
      resolve(new Map());
      return;
    }
    id += 1;
    const currentId = id;
    searchTokensWorker.postMessage({
      id: currentId,
      params,
    });
    const callback = ({
      data: { result, id: sourceId },
    }: {
      data: {
        result: Map<string, number>;
        id: number;
      };
    }) => {
      if (sourceId === currentId) {
        resolve(result);
        searchTokensWorker.removeEventListener('message', callback);
      }
    };
    searchTokensWorker.addEventListener('message', callback);
  });
};
