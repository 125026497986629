import i18n from 'i18next';

export const getIsMobile = () => document.body.clientWidth < 768;

export const renderTitle = (
  title: string | undefined,
  translation: (key: string) => string,
) => {
  if (!title) return '';

  return title.split(' ').reduce((pre, next) => {
    pre += translation(next);
    return pre;
  }, '');
};

export const renderSubtitle = (
  subTitle: string | undefined,
  translation: (key: string, params: Record<string, unknown>) => string,
) => {
  if (!subTitle) return subTitle;

  let data: { key: string; [propsName: string]: any };

  try {
    data = JSON.parse(subTitle);
  } catch (error) {
    return subTitle;
  }

  const { key, ...params } = data;

  if (key && typeof key === 'string') {
    return translation(key, params);
  }
  throw new TypeError('key is not a string');
};

export const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const currentLanguage = (): string => {
  const supportLanguages = ['enUS', 'zhCN' /* 'jaJP' */];
  let lang = i18n.language;
  lang = lang ? lang.replace('-', '') : 'enUS';
  const supportLangs = supportLanguages.filter((l) => {
    return l.indexOf(lang) > -1;
  });
  let supportLang = 'enUS';
  if (supportLangs.length >= 1) {
    supportLang = supportLangs[0];
  }
  return supportLang;
};
