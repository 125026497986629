import { List, ListItem, styled, useTheme, Box } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HoverAddBackground } from '../Hover';
import { MenuItem } from './types';

const StyledNavLink = styled(NavLink)(({ theme }) => {
  return {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: '22px',
    fontWeight: 600,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(8),
    // margin: theme.spacing(12, 12),
    borderRadius: 8,
    '&.active': {
      // backgroundColor: theme.palette.custom.button.main,
      color: theme.palette.custom.button.mainContrast,
      transition: 'color 0.4s',
      '& svg': {
        color: theme.palette.custom.button.mainContrast,
        transition: 'color 0.4s',
      },
    },
  };
});

export function NavList({
  menuList,
  activeMenuId,
}: {
  menuList: MenuItem[];
  activeMenuId: string;
}) {
  const NAV_TRANSITION = 'top 300ms cubic-bezier(0.4,0,0.2,1) 0ms';
  const ACTIVE_TOP_INIT = -950;

  const [activeTop, setActiveTop] = useState(ACTIVE_TOP_INIT);
  const [activeNavHight, setActiveNavHight] = useState(40);
  const [navTransition, setNavTransition] = useState(NAV_TRANSITION);
  const theme = useTheme();
  const space = 12;
  const borderRadius = 8;

  useEffect(() => {
    let navsHeight = 0;

    let activeIndex = -1;
    menuList.some((menu, index) => {
      const navDom = document.getElementById(`nav-${index}`);
      navsHeight += navDom?.clientHeight || 40;

      if (menu.id === activeMenuId) {
        const activeNavDom = document.getElementById(`nav-${index}`);
        navsHeight = navsHeight - (activeNavDom?.clientHeight || 40) + space;
        setActiveNavHight(activeNavDom?.clientHeight || 40);
        activeIndex = index;
        return true;
      }
      return false;
    });

    if (activeTop === ACTIVE_TOP_INIT) {
      setNavTransition('');
    } else {
      setNavTransition(NAV_TRANSITION);
    }
    if (activeIndex !== -1) {
      const res = navsHeight + activeIndex * space * 2;
      setActiveTop(res);
    } else {
      setActiveTop(ACTIVE_TOP_INIT);
    }
  }, [activeMenuId, menuList]);

  return (
    <List
      sx={{
        margin: theme.spacing(39, 0, 45, 0),
        paddingTop: 0,
        paddingBottom: 0,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: activeTop,
          left: space,
          right: space,
          backgroundColor: 'custom.button.main',
          height: activeNavHight,
          borderRadius,
          transition: navTransition,
        }}
      />
      {menuList.map((menu, index) => {
        const { id, Icon, title, outerLink } = menu;
        return (
          <ListItem disablePadding key={id}>
            <HoverAddBackground
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                margin: space,
                p: 0,
                borderRadius,
              }}
            >
              <StyledNavLink
                id={`nav-${index}`}
                to={id}
                onClick={(evt) => {
                  if (outerLink) {
                    evt.preventDefault();
                    window.open(outerLink, '_blank', 'noopener,noreferrer');
                  }
                }}
                className={activeMenuId === id ? 'active' : 'nav-link-class'}
                sx={{
                  '.arrow': {
                    display: 'none',
                  },
                  '&:hover .arrow': {
                    display: 'inline-block',
                  },
                }}
              >
                <Box
                  component={Icon}
                  style={{
                    width: 24,
                    margin: theme.spacing(0, 8, 0, 0),
                  }}
                />
                {title}
                {outerLink && (
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="arrow"
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <path
                      d="M4.22703 12.7124L10.6811 6.25825L4.75206 6.25825L4.75736 4.75741H13.2426V13.2427H11.7471L11.7418 7.31891L5.28769 13.773L4.22703 12.7124Z"
                      fill="currentColor"
                      fillOpacity="0.5"
                    />
                  </svg>
                )}
              </StyledNavLink>
            </HoverAddBackground>
          </ListItem>
        );
      })}
    </List>
  );
}
