import { store, WalletState } from './core';
import { getChainConfig } from './chainConfigs';

export const getCurrentAccount = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('currentAccount');
};

export const getCurrentChainId = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('chainId');
};

export const getCurrentChainIdAndSrc = (state?: WalletState) => {
  return [
    (state ?? store.getState()).dodoWallet.get('chainId'),
    (state ?? store.getState()).dodoWallet.get('chainIdSource'),
  ];
};

export const getProxyAddress = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('proxyAddress');
};

export const getLatestBlockNumber = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('latestBlockNumber');
};

export const getETHLatestBlockNumber = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('latestETHBlockNumber');
};

export const getUpdateTimestamp = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('updateTimestamp');
};

export const getEndpoints = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('endpoints');
};

export const getEndpointSelect = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('endpointSelect');
};

export const getEndpointRTTs = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('endpointRTTs');
};

export const getCurrentBaseFee = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('currentBaseFee');
};

export const getCurrentGasPrice = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('gasPriceInfo').gasPrice;
};

export const getCurrentGasPriceInfo = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('gasPriceInfo');
};

export const getShowAccountModal = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('showAccountModal');
};

export const getBuyCryptoListAutoOpen = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('buyCryptoListAutoOpen');
};

export const getEndpointBlockHeights = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('endpointBlockHeights');
};

export const prepareChainEndpointUrl = (
  chainId: number,
  readonly = false,
  state?: WalletState,
) => {
  if (readonly) {
    const selectChainId = getCurrentChainId(state);
    const endpoints = getEndpoints(state);
    const select = getEndpointSelect(state);
    if (
      selectChainId === chainId &&
      endpoints &&
      select &&
      endpoints.get(select)
    )
      return endpoints.get(select)!;

    const mapped = getChainConfig(chainId).defaultEndpointUrl;
    if (mapped) return mapped;
  }

  const { defaultEndpointUrl } = getChainConfig(chainId);
  return defaultEndpointUrl;
};

export const getChainMismatchNotice = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('chainMismatchNotice');
};

export const getIsChinaMainland = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('isChinaMainland');
};

export const getCurrentAccountType = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('accountType');
};

export const getBlockNumberStatus = (state?: WalletState) => {
  return (state ?? store.getState()).dodoWallet.get('latestBlockNumberStatus');
};
