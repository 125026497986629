import { ChartDataType } from './chartTypes';
import { K_BAR_NUMBER } from './useKChartData';

export const formatChartData = (data: any[]): ChartDataType[] => {
  const chartData = data
    ? data.map((item) => {
      // start time: item[0]
      // end time: item[5]
      return {
        time: Number(item[0]),
        volume: Number(item[6]),
        open: Number(item[2]),
        close: Number(item[4]),
        high: Number(item[1]),
        low: Number(item[3]),
      };
    })
    : [];
  return chartData.slice(-K_BAR_NUMBER).sort((a, b) => a.time - b.time);
};

/**
 * 取TimeScale最近的整数倍时间,
 * 开始时间必须为timeScale的整数倍
 */
export const fixTimeStampByTimeScale = (
  timeStamp: number,
  timeScale: number,
): number => timeStamp - (timeStamp % timeScale);
