/* eslint-disable no-nested-ternary */
import {
  FailedList,
  Input,
  TabPanel,
  TabSwitchButton,
  TabSwitchButtons,
} from '@dodoex/components';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useManageTokenList,
  tokenListsSort,
} from '../../hooks/useManageTokenList';
import DeleteTokenListConfirm from './DeleteTokenListConfirm';
import { ImportTokenListDialog } from './ImportTokenListDialog';
import ManageTokens, { ManageTokensProps } from './ManageTokens';
import { SkeletonTokenListItem } from './SkeletonTokenListItem';
import { TokenListItemWrapper } from './TokenListItemWrapper';
import { TokenList } from '../../types';

enum ManageTab {
  list = 1,
  tokens,
}

export default function ManageTokenLists({
  account,
  logOutClick,
  chainId,
  RightIconPopper,
}: {
  account?: string;
  /** 未连接钱包时的回调，如不传，则不做处理 */
  logOutClick?: () => void;
  chainId: number;
  RightIconPopper?: ManageTokensProps['RightIconPopper'];
}) {
  const [manageTab, setManageTab] = useState<ManageTab>(ManageTab.list);
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const {
    loading,
    tokenLists: tokenListsOrigin,
    tokenListFailedRefresh,
    searchError,
    searchTokenList,
    importTokenList,
    searchImported,
    importSearchLoading,
    toggleTokenList,
    deleteTokenList,
  } = useManageTokenList({
    search,
    chainId,
  });
  const [tokenLists, setTokenLists] = useState<TokenList[]>([]);
  const [showImportConfirm, setShowImportConfirm] = useState(false);
  const [deleteTokenListId, setDeleteTokenListId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (!tokenLists.length) {
      setTokenLists(tokenListsSort(tokenListsOrigin));
    } else {
      setTokenLists(tokenListsOrigin);
    }
  }, [tokenListsOrigin]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
      }}
    >
      <Box
        sx={{
          px: 24,
          pt: 32,
        }}
      >
        <TabSwitchButtons
          value={manageTab}
          variant="fullWidth"
          onChange={(evt, val) => {
            setManageTab(val);
            if (val === ManageTab.list) {
              setTokenLists(tokenListsSort(tokenListsOrigin));
            }
          }}
        >
          <TabSwitchButton
            value={ManageTab.list}
            label={t('iop.tokenlist.manage.tab.lists')}
          />
          <TabSwitchButton
            value={ManageTab.tokens}
            label={t('iop.tokenlist.manage.tab.tokens')}
          />
        </TabSwitchButtons>
      </Box>
      <TabPanel
        index={ManageTab.list}
        value={manageTab}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <>
          <Box
            sx={{
              px: 24,
              pt: 12,
              pb: searchError ? 0 : 12,
              border: 'solid 1px transparent',
              borderBottomColor: 'custom.border.default',
              overflowY: 'auto',
            }}
          >
            <Input
              height={48}
              fullWidth
              error={searchError}
              placeholder={t('iop.tokenlist.manage.source-input.placeholder')}
              value={search}
              readOnly={!account && !!logOutClick}
              onClick={() => {
                if (!account && logOutClick) {
                  logOutClick();
                }
              }}
              onChange={(evt) => {
                setSearch(evt.target.value);
              }}
            />
            {searchError ? (
              <Typography
                variant="body2"
                sx={{
                  color: 'error.main',
                  my: 8,
                }}
              >
                {t('iop.tokenlist.manage.source-input.error-importing-list')}
              </Typography>
            ) : importSearchLoading ? (
              <SkeletonTokenListItem
                sx={{
                  mt: 12,
                }}
              />
            ) : searchTokenList ? (
              <TokenListItemWrapper
                imported={searchImported}
                list={searchTokenList}
                sx={{
                  mt: 12,
                }}
                handleImport={() => {
                  setShowImportConfirm(true);
                }}
              />
            ) : (
              ''
            )}
          </Box>
          <Box
            sx={{
              p: 20,
              flex: 1,
              overflowY: 'auto',
              '& > div + div': {
                mt: 12,
              },
            }}
          >
            <TokenListItemWrapper isDODO />
            {loading ? (
              <SkeletonTokenListItem />
            ) : tokenListFailedRefresh ? (
              <FailedList refresh={tokenListFailedRefresh} />
            ) : (
              tokenLists.map((list) => (
                <TokenListItemWrapper
                  key={list.get('name')}
                  list={list}
                  showSetting
                  toggleTokenList={toggleTokenList}
                  showDeleteConfirm={setDeleteTokenListId}
                  needConnect={!account}
                />
              ))
            )}
          </Box>
        </>
      </TabPanel>
      <TabPanel
        index={ManageTab.tokens}
        value={manageTab}
        sx={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <ManageTokens
          chainId={chainId}
          account={account}
          RightIconPopper={RightIconPopper}
        />
      </TabPanel>
      <ImportTokenListDialog
        on={showImportConfirm}
        importTokenList={searchTokenList}
        onChoose={(isChoose: boolean) => {
          if (!isChoose) {
            setShowImportConfirm(false);
          } else {
            importTokenList();
            setSearch('');
            setShowImportConfirm(false);
          }
        }}
      />
      <DeleteTokenListConfirm
        on={!!deleteTokenListId}
        onClose={() => setDeleteTokenListId(undefined)}
        onConfirm={() => {
          if (!deleteTokenListId) return;
          deleteTokenList(deleteTokenListId);
        }}
      />
    </Box>
  );
}
