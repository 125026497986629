import { IProviderOptions } from 'web3modal';
import {
  Bitkeep,
  Frontier,
  KuCoin,
  Nabox,
  OKXWallet,
  OneKey,
} from './constant';
import { WalletType } from './types';

export async function ProviderConnector(ProviderPackage: any) {
  try {
    await ProviderPackage.request({ method: 'eth_requestAccounts' });
  } catch (error) {
    throw new Error('User Rejected');
  }
  return ProviderPackage;
}

export function getInjectProvider(type: WalletType) {
  switch (type) {
    case WalletType.Nabox:
      return window.NaboxWallet;
    case WalletType.openBlock:
      // 需要确定 openBlock sdk 已经 import，否则会是一个 undefined
      if (!window.openblock) {
        console.error('openblock is undefined');
      }
      return window.openblock;
    case WalletType.openBlockIframe:
      return window.obethereum;
    case WalletType.Bitkeep:
      return window.bitkeep?.ethereum;
    case WalletType.OneKey:
      return window.$onekey?.ethereum;
    case WalletType.OKX:
      return window.okxwallet;
    case WalletType.kuCoinWallet:
      return window.kucoin;
    case WalletType.frontier:
      return window.frontier?.ethereum;

    default:
      break;
  }
  return window.ethereum;
}

export function injectProviderOptions() {
  const providerOptions = {} as IProviderOptions;

  const options = {
    options: {},
    connector: ProviderConnector,
  };
  [Nabox, Bitkeep, OneKey, OKXWallet, KuCoin, Frontier].forEach((wallet) => {
    const provider = getInjectProvider(wallet.type) || window.ethereum;
    if (provider) {
      // eslint-disable-next-line no-param-reassign
      providerOptions[wallet.type] = {
        ...options,
        display: {
          name: wallet.showName,
        },
        package: provider,
      };
    }
  });
  return providerOptions;
}
