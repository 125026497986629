import { ContractConfigDataI } from '../type';

export const OK_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WOKT',
    address: '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',
    decimals: 18,
    name: 'Wrapped OKT',
    source: 'dodo',
  },
  MULTI_CALL: '0x5e84190a270333aCe5B9202a3F4ceBf11b81bB01',
  DODO_TOKEN: '',

  ERC20_HELPER: '0x4775b1858f1e417C9609D455C3Ad8751ec01daC4',
  ERC20V3_FACTORY: '0x48D77f44416fD0b08f1Eca90Bc437D0a3e4e550d',

  DODO_APPROVE: '0x7737fd30535c69545deeEa54AB8Dd590ccaEBD3c',

  DODO_PROXY: '0x6B4Fa0bc61Eddc928e0Df9c7f01e407BfcD3e5EF',
  DODO_DSP_PROXY: '0x5515363c0412AdD5c72d3E302fE1bD7dCBCF93Fe',
  DODO_DPP_PROXY: '0xa222e6a71D1A1Dd5F279805fbe38d5329C1d0e70',
  DODO_CP_PROXY_V2: '0x3CA78557F1399e04eBCa7FFc8F15890840F10876',
  DODO_MINEV3_PROXY: '0x958f79e2998DFe417208b9A07D799265B0298e58',
  DODO_STARTER_PROXY: '0x8e2f666F316b614c76676215F16F0A9746f96a90',

  DVM_FACTORY: '0x9aE501385Bc7996A2A4a1FBb00c8d3820611BCB5',
  DSP_FACTORY: '0x44D5dF24d5Ef52A791D6436Fa45A8D426f6de34e',
  CP_FACTORY_V2: '0x5333Eb1E32522F1893B7C9feA3c263807A02d561',

  ROUTE_V2_DATA_FETCH: '0x10Dd6d8A29D489BEDE472CC1b22dc695c144c5c7',
  ROUTE_V1_DATA_FETCH: '0xDfaf9584F5d229A9DBE5978523317820A8897C5A',
  CALLEE_HELPER: '0x3CD6D7F5fF977bf8069548eA1F9441b061162b42',
  FEE_RATE_IMPL: '0x44024B60575cf5D032F80A55Da37924f123B4151',

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0xF4489F010Bfe8955A17c512a106e76FCF7BDE7D4',
  DODO_NFT_PROXY: '0x326c788c4C236f2bceC9476C66F8593Aa31be4Fc',
  BUYOUT_MODEL: '0x0c4b4F1D5F5c989457cdD6f5102308b33c922281',
  DODO_NFT_REGISTER: '0x327344B382EE1b44FB0a72945fCDCC7243200dD7',
  DODO_NFT_ERC721: '0x42ddEc68db70F5992eB7AB22dfaD8A57109841C9',
  DODO_NFT_ERC1155: '0x43C49f8DD240e1545F147211Ec9f917376Ac1e87',
  DODO_NFTPOOL_PROXY: '0x4599ed18F34cFE06820E3684bF0aACB8D75c644d',
  DODO_NFT_APPROVE: '0x0E3CA67AdB97E8FD07E516AFd869d1886E932F59',
};

export const OK_SWAPINFO = [
  {
    name: 'CherrySwap',
    factoryAddress: '0x709102921812B3276A65092Fe79eDfc76c4D4AFe',
    initHash:
      '0xe3ae0327539fda6ee87492b9ce166b7419808c231acd1fe54dd3fbf7754704f5',
  }, // like uniswap V2
  {
    name: 'KSwap',
    factoryAddress: '0x60DCD4a2406Be12dbe3Bb2AaDa12cFb762A418c1',
    initHash:
      '0x73fce53e0c877f17bc03bb34eead12c2c3f30d3493cff1259744d0c0dfcb3a92',
  }, // like uniswap V2
  /*
    {
        name: "AISwap",
        factoryAddress: "0x65728c1D0e545DB117940d5745089c256516ad43",
        initHash: "0x2b090dd2252bb5f19bacc4b509dcc9f64c49b151028561918ec29cf409a7093e", 
    }, // like uniswap V2, some pair isn't pair contract,like WOKT-USDT; some approach lending volumn?
    */
];
