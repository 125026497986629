import { gql } from '@apollo/client';

export const fetchLiquidityList = gql`
  query FetchLiquidityList($where: Liquiditylist_filter) {
    liquidity_list(where: $where) {
      currentPage
      pageSize
      totalCount
      lqList {
        id
        isPrivatePool
        isMyLiquidity
        pair {
          id
          type
          baseReserve
          quoteReserve
          lastTradePrice
          lpFeeRate
          i
          baseLpToken {
            id
            decimals
          }
          quoteLpToken {
            id
            decimals
          }
          baseToken {
            id
            symbol
            name
            decimals
            usdPrice
          }
          quoteToken {
            id
            symbol
            name
            decimals
            usdPrice
          }
          tvl
          apy {
            miningBaseApy
            miningQuoteApy
            transactionBaseApy
            transactionQuoteApy
          }
        }
      }
    }
  }
`;

export const fetchPoolApyList = gql`
  query FetchPoolApyList($where: Liquiditylist_filter) {
    liquidity_list(where: $where) {
      lqList {
        id
        pair {
          id
          type
          apy {
            miningBaseApy
            miningQuoteApy
            transactionBaseApy
            transactionQuoteApy
          }
        }
      }
    }
  }
`;
