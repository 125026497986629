import { createWorkerFactory, expose } from '@shopify/web-worker';
import { GetConfig } from '@dodoex/utils';
import { sentry } from '../core';
import {
  getCurrentAccount,
  getCurrentChainId,
  getLatestBlockNumber,
  prepareChainEndpointUrl,
} from '../selectors';
import type { Query, ParamsListQuery } from './queueWorker/queueManager';
import { getWeb3 } from '../web3';
import contractQueryProcess from './contractQueryProcess';

const createWorker = createWorkerFactory(
  () => import(/* webpackChunkName: 'worker' */ './queueWorker'),
);
const queueWorker = createWorker();

expose(queueWorker, {
  singleSend: async (
    contractAddress: string,
    ABI: any,
    method: string,
    params: any[] = [],
  ) => {
    const account = getCurrentAccount();
    if (!account) return undefined;
    const web3 = await getWeb3();
    const contract = new web3.eth.Contract(ABI, contractAddress);
    return contract.methods[method](...params).call();
  },
  batchSend: async (chainId: number, to: string, data: string) => {
    const currentChainId = getCurrentChainId();
    if (chainId !== currentChainId) return undefined;
    const account = getCurrentAccount();
    if (!account) return undefined;
    const web3 = await getWeb3();
    return web3.eth.call({
      to,
      data,
    });
  },
  getEndpointUrl: (chainId: number) => {
    return prepareChainEndpointUrl(chainId, true);
  },
  getConfig: (chainId: number) => {
    return GetConfig(chainId);
  },
  getLatestBlockNumber: () => {
    return getLatestBlockNumber().toNumber();
  },
});

/**
 * 单个查询
 */
export async function contractQuery<T = any>(
  chainId: number,
  query: Query<T>,
): Promise<T | undefined> {
  if (!query) return undefined;
  try {
    const [result] = await queueWorker.addMultiMethodQueue(
      chainId,
      JSON.stringify([query]),
    );
    const r = contractQueryProcess({
      query,
      result,
    });
    if (query.callback) {
      return query.callback(r);
    }
    return r as T;
  } catch (error) {
    const api = '[contractQuery] error';
    const params = {
      chainId,
      query,
    };
    console.error(api, params, error);
    sentry.withScope(function (scope: any) {
      scope.setTag('api', api);
      scope.setExtra('params', {
        params,
        error,
      });
    });
    return undefined;
  }
}

/**
 * 查询不同合约方法列表
 */
export async function contractQueryList(chainId: number, queryList: Query[]) {
  if (!queryList.length) return [];
  try {
    const result = await queueWorker.addMultiMethodQueue(
      chainId,
      JSON.stringify(queryList),
    );
    return queryList.map((query, index) => {
      const r = contractQueryProcess({
        query,
        result: result[index],
      });
      if (query.callback) {
        query.callback(r);
      }
      return r;
    });
  } catch (error) {
    const api = '[contractQueryList] error';
    const params = {
      chainId,
      queryList,
    };
    console.error(api, params, error);
    sentry.withScope(function (scope: any) {
      scope.setTag('api', api);
      scope.setExtra('params', {
        params,
        error,
      });
    });
    return [];
  }
}

/**
 * 查询相同合约方法，不同参数的列表
 */
export async function contractQueryParamsList<T = any>(
  chainId: number,
  query: ParamsListQuery,
) {
  if (!query.paramsList.length) return [];
  try {
    const result = await queueWorker.addSingleMethodQueue(
      chainId,
      JSON.stringify(query),
    );
    if (!result || result.length !== query.paramsList.length) {
      throw new Error('result id not valid');
    }
    return query.paramsList.map(({ callback, params }, index) => {
      const r = contractQueryProcess({
        query: {
          ...query,
          params,
        },
        result: result[index],
      }) as T;
      if (callback) {
        callback(r);
      }
      return r;
    });
  } catch (error) {
    const api = '[contractQueryParamsList] error';
    const params = {
      chainId,
      query,
    };
    console.error(api, params, error);
    sentry.withScope(function (scope: any) {
      scope.setTag('api', api);
      scope.setExtra('params', {
        params,
        error,
      });
    });
    return [];
  }
}
