/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  getChain,
  GetConfig,
  getIsDevEnvironment,
  getServiceDomainDev,
  IDLE_FREE_TIME,
  MAX_UINT256,
  ThegraphKeyMap,
} from '@dodoex/utils';
import { defaultAbiCoder } from '@ethersproject/abi';
import BigNumber from 'bignumber.js';
import { chunk } from 'lodash';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import multicallABI from './abis/multicalABI';
import { setBlockNumberStatus } from './actions';
import { getChainConfig } from './chainConfigs';
import { alertEndpointStatus, sentry, store } from './core';
import { prepareChainEndpointUrl } from './selectors';
import { getCachedContract, getWeb3, getWeb3BynotAccount } from './web3';

export interface Web3CallData {
  to: string;
  data: string;
}

interface JsonRPCResp {
  result: any;
}

interface Fetched {
  req: Web3CallData;
  resp: JsonRPCResp;
}

interface RequestThunk<T> {
  callData: Web3CallData | null; // When null, processor is called with null
  processor: (_: any, index: number) => T;
}

type BatchThunk<T> = RequestThunk<T>[];

type BatchThunkAll<Ts extends any[]> = {
  [Tidx in keyof Ts]: Ts[Tidx] extends Ts[number]
    ? BatchThunk<Ts[Tidx]>
    : never;
};

type BatchThunkAllResult<Ts extends any[]> = {
  [Tidx in keyof Ts]: Ts[Tidx] extends Ts[number] ? Array<Ts[Tidx]> : never;
};

export type BalanceResult = { address: string; balance: BigNumber };
export type AllowanceResult = { address: string; allowance: BigNumber };

export async function loadEthBalance(account: string): Promise<BigNumber> {
  const web3 = await getWeb3();
  const balance = await web3.eth.getBalance(account);
  return new BigNumber(balance).div(1e18);
}

/**
 * 批量获取钱包地址的余额（目前还没做到批量，因为 rpc 节点有问题。批量返回的是个对象，格式应该是数组才对）
 * @param accountList 钱包地址
 * @param chainId chainId
 * @returns 钱包地址对应的余额 map
 */
export async function loadAccountListEthBalance(
  accountList: string[],
  chainId?: number,
): Promise<Map<string, BigNumber | null>> {
  const web3 = await getWeb3BynotAccount(chainId, true);
  const balanceMap: Map<string, BigNumber | null> = new Map();
  const promiseList = accountList.map(async (account) => {
    const balance = await web3.eth.getBalance(account);
    const balanceRes = new BigNumber(balance).div(1e18);
    balanceMap.set(account, balanceRes);
  });
  await Promise.all(promiseList);
  return balanceMap;
  // 等修复了 rpc 节点，才能使用下面的方法
  // const batch = new web3.BatchRequest();
  // return new Promise((resolve) => {
  //   const balanceMap: Map<string, BigNumber | null> = new Map();
  //   accountList.forEach((account) => {
  //     batch.add(
  //       // @ts-ignore
  //       web3.eth.getBalance.request(account, 'latest', (error, res) => {
  //         const balance = res ? new BigNumber(res).div(1e18) : new BigNumber(0);
  //         balanceMap.set(account, balance);
  //         if (balanceMap.size === accountList.length) {
  //           resolve(balanceMap);
  //         }
  //       }),
  //     );
  //   });
  //   batch.execute();
  // });
}

let loadBlockNumberFailedCount = 0;
const loadBlockNumberMaxFailedCount = 3;
export const loadBlockNumber = async (): Promise<number> => {
  try {
    store.dispatch(
      setBlockNumberStatus({
        loading: true,
        failed: false,
      }),
    );
    const web3 = await getWeb3({
      notWaitConnect: true,
    });
    let time: NodeJS.Timeout | undefined;
    const result = await Promise.race([
      web3.eth.getBlockNumber(),
      new Promise((_, reject) => {
        time = setTimeout(() => {
          reject();
        }, 8000);
      }),
    ]);
    const res = Number(result);
    clearTimeout(time);
    if (res) {
      store.dispatch(
        setBlockNumberStatus({
          loading: false,
          failed: false,
        }),
      );
      loadBlockNumberFailedCount = 0;
      alertEndpointStatus(true);
      return res;
    }
    throw new Error('block number not found');
  } catch (e) {
    store.dispatch(
      setBlockNumberStatus({
        loading: false,
        failed: true,
      }),
    );
    loadBlockNumberFailedCount += 1;
    if (loadBlockNumberFailedCount >= loadBlockNumberMaxFailedCount) {
      loadBlockNumberFailedCount = 0;
      alertEndpointStatus(false);
    }
    console.error(
      `loadBlockNumberFailedCount: ${loadBlockNumberFailedCount}`,
      e,
    );
    return 0;
  }
};

export const loadETHBlockNumber = async (chainId: number): Promise<number> => {
  const defaultEndpointUrl = prepareChainEndpointUrl(chainId);
  const result = await requestEndpointBlockNumber(defaultEndpointUrl);
  return result;
};

const requestEndpointBlockNumber = async (
  endpoint: string,
): Promise<number> => {
  const web3 = new Web3(endpoint);
  const result = await web3.eth.getBlockNumber();
  return Number(result);
};

export async function runAll<Ts extends any[]>(
  chainId: number,
  ...batches: BatchThunkAll<Ts>
): Promise<BatchThunkAllResult<Ts>> {
  // Collect all rpcs
  const callDatas: Web3CallData[] = [];
  for (const batch of batches)
    for (const req of batch) if (req.callData) callDatas.push(req.callData);
  const executed = await sendReq(callDatas, chainId);
  const mapper = new Map();
  for (const { req, resp } of executed) mapper.set(req, resp?.result);
  return batches.map(<T>(batch: BatchThunk<T>) => {
    const collected = batch
      .filter(
        (req: RequestThunk<T>) => !req.callData || mapper.get(req.callData),
      )
      .map((req: RequestThunk<T>, index: number) =>
        req.processor(req.callData ? mapper.get(req.callData) : null, index),
      );
    return collected;
  }) as BatchThunkAllResult<Ts>; // Sorry, type hack
}

/**
 * 使用 rpc-proxy 发起 rpc 请求调用合约，只读；如果已连接钱包且连接的链等于目标链，则不使用 rpc-proxy, 直接使用钱包发起 rpc 调用
 * @param chainId
 * @param walletAddress
 * @param batches
 * @returns
 * @deprecated
 */
export async function runAllWithRpcProxy<Ts extends any[]>(
  chainId: number,
  walletAddress: string,
  useRpcProxy: boolean,
  ...batches: BatchThunkAll<Ts>
): Promise<BatchThunkAllResult<Ts> | any> {
  const isDev = getIsDevEnvironment();
  // 如果连接的链等于目标链，则不使用 rpc-proxy, 直接使用钱包发起 rpc 调用
  const endpoint = isDev
    ? `https://rpc.${getServiceDomainDev()}/rpc/${chainId}?useCache=false`
    : getChainConfig(chainId).defaultEndpointUrl;
  const web3WithRpcProxy = useRpcProxy ? new Web3(endpoint) : await getWeb3();

  if (!batches?.length) {
    // 返回主代币余额
    const { basicToken } = getChainConfig(chainId);
    const basicTokenBalance = await web3WithRpcProxy.eth.getBalance(
      walletAddress,
    );

    return [
      [
        {
          balance: new BigNumber(basicTokenBalance).div(1e18),
          allowance: new BigNumber(MAX_UINT256),
          symbol: basicToken?.symbol,
          name: basicToken?.name,
          address: basicToken?.address,
          basicToken,
        },
      ],
    ];
  }

  // Collect all rpcs
  const callDatas: Web3CallData[] = [];
  for (const batch of batches)
    for (const req of batch) if (req.callData) callDatas.push(req.callData);
  const executed = await sendReq(callDatas, chainId, web3WithRpcProxy);
  const mapper = new Map();
  for (const { req, resp } of executed) mapper.set(req, resp?.result);
  const collectedList = batches.map(<T>(batch: BatchThunk<T>) => {
    const collected = batch
      .filter(
        (req: RequestThunk<T>) => !req.callData || mapper.get(req.callData),
      )
      .map((req: RequestThunk<T>, index: number) =>
        req.processor(req.callData ? mapper.get(req.callData) : null, index),
      );
    return collected;
  }) as BatchThunkAllResult<Ts>; // Sorry, type hack

  return collectedList;
}

async function sendReq(
  rpcReqs: Web3CallData[],
  chainId: number,
  web3WithRpcProxy?: Web3,
): Promise<Fetched[]> {
  let { MULTI_CALL } = GetConfig(chainId);
  // 临时加的，如果后续支持 97了，可以删除
  if (chainId === 97) {
    MULTI_CALL = '0xa7b9C3a116b20bEDDdBE4d90ff97157f67F0bD97';
  }
  const resps = await sendCallReqs(
    rpcReqs,
    chainId,
    MULTI_CALL,
    web3WithRpcProxy,
  );
  const mapping = new Map();
  for (const { req, resp } of resps) mapping.set(req, resp);
  return rpcReqs.map((e) => ({ req: e, resp: mapping.get(e) }));
}

function encodeABIIdle({
  contract,
  calls,
}: {
  contract: Contract;
  calls: [string, string][];
}) {
  return new Promise((resolve) => {
    function cb(deadline: IdleDeadline) {
      if (deadline.timeRemaining() > IDLE_FREE_TIME) {
        const encoded = contract.methods.aggregate(calls).encodeABI();
        resolve(encoded);
        return;
      }
      requestIdleCallback(cb);
    }
    requestIdleCallback(cb);
  });
}

// 记录连续错误次数，避免因为网络问题导致的不断重试
let errorCount = 0;

async function sendCallReqs(
  rpcReqs: Web3CallData[],
  chainId: number,
  addr: string,
  web3WithRpcProxy?: Web3,
): Promise<Fetched[]> {
  const { thegraphKey } = getChain(chainId);
  const maxLen = thegraphKey === ThegraphKeyMap.bsc ? 500 : 700;
  let chunkRpcReqs: Array<Web3CallData>[] = [rpcReqs];
  if (rpcReqs.length > maxLen) {
    chunkRpcReqs = chunk(rpcReqs, maxLen);
  }
  let res: Fetched[] = [];

  const contract = await getCachedContract(
    addr,
    multicallABI,
    web3WithRpcProxy,
  );

  const chunkPromiseList = chunkRpcReqs.map(async (currentRpcReqs) => {
    const calls: Array<[string, string]> = currentRpcReqs.map((e) => [
      e.to,
      e.data,
    ]);
    let encoded: any;
    if (calls.length >= 100) {
      // calls 数组越大，encodeABI 运行耗时越长：数组长度 303，耗时 30-40ms
      // 在浏览器空闲处理，同步转异步，分散长任务
      // @see https://app.asana.com/0/0/1203174503953069/1203223217219758/f
      encoded = await encodeABIIdle({
        contract,
        calls,
      });
    } else {
      encoded = contract.methods.aggregate(calls).encodeABI();
    }
    const callData: Web3CallData = {
      data: encoded,
      to: addr,
    };
    const web3 = web3WithRpcProxy || (await getWeb3());
    let raw: any;
    try {
      raw = await web3.eth.call(callData);
    } catch (error: any) {
      // Request Entity Too Large 是因为单次请求 token 中有非标 token，还是需要分拆来排除的
      if (error.message.indexOf('Request Entity Too Large') === -1) {
        errorCount += 1;
      }
      if (errorCount > 3) {
        sentry.withScope(function (scope: any) {
          const api = '[sendCallReqs] Request Entity Too Large';
          scope.setTag('api', api);
          scope.setExtra('params', {
            error,
            currentRpcLen,
            chunkLen,
            currentRpcReqs: currentRpcReqs.slice(0, 50),
          });
          sentry.captureException(error);
        });
        throw new Error('Unexpected batch result');
      }
      const chunkLen = chunkRpcReqs.length;
      const currentRpcLen = currentRpcReqs.length;
      const api = '[sendCallReqs] error';
      console.error(api, error);
      sentry.withScope(function (scope: any) {
        scope.setTag('api', api);
        scope.setExtra('params', {
          error,
          currentRpcLen,
          chunkLen,
          currentRpcReqs: currentRpcReqs.slice(0, 50),
        });
        sentry.captureException(error);
      });
    }
    if (raw) {
      errorCount = 0;
    }

    if (raw === undefined) throw new Error('Unexpected batch result');
    const [blkNum, vals] = defaultAbiCoder.decode(['uint256', 'bytes[]'], raw);

    // Fuse with original request
    if (currentRpcReqs.length !== vals.length)
      throw new Error('Unexpected length mismatch');

    // Ignore blkNum
    void blkNum;

    // Fake JSONRPC resp
    return vals.map((row: any, idx: any) => {
      const req = currentRpcReqs[idx];
      const resp = {
        result: row,
      };
      return { resp, req };
    });
  });
  const chunkRes = await Promise.allSettled(chunkPromiseList);
  const fulfilledRes = chunkRes.filter(
    (item) => item.status === 'fulfilled',
  ) as PromiseFulfilledResult<Fetched[]>[];
  fulfilledRes.forEach(({ value }) => {
    res = [...res, ...value];
  });

  return res;
}

const flatten = (array: any[]): any[] => {
  const flatArray: any[] = [];
  array.forEach((a) => {
    if (Array.isArray(a)) {
      flatArray.push(...flatten(a));
    } else {
      flatArray.push(a);
    }
  });
  return flatArray;
};

// 不链接钱包，只使用 rpc 节点
export async function runAllWithoutWallet<Ts extends any[]>(
  chainId: number,
  ...batches: BatchThunkAll<Ts>
): Promise<BatchThunkAllResult<Ts>> {
  const customWeb3 = new Web3(getChainConfig(chainId).defaultEndpointUrl);
  const callDatas: Web3CallData[] = [];
  for (const batch of batches)
    for (const req of batch) if (req.callData) callDatas.push(req.callData);
  const executed = await sendReq(callDatas, chainId, customWeb3);
  const mapper = new Map();
  for (const { req, resp } of executed) mapper.set(req, resp?.result);
  return batches.map(<T>(batch: BatchThunk<T>) => {
    const collected = batch
      .filter(
        (req: RequestThunk<T>) => !req.callData || mapper.get(req.callData),
      )
      .map((req: RequestThunk<T>, index: number) =>
        req.processor(req.callData ? mapper.get(req.callData) : null, index),
      );
    return collected;
  }) as BatchThunkAllResult<Ts>; // Sorry, type hack
}
