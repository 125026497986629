import Immutable from 'immutable';
import { State } from './types';

export const initialState = Immutable.Map({
  markets: Immutable.List([]),
  legacyMarkets: Immutable.List([]),
  customs: Immutable.List([]),
  mineOrgMap: Immutable.Map({}),
}) as unknown as State;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (state: State = initialState, action: any): State => {
  let newState: State;
  switch (action.type) {
    case 'SET_MARKETS':
      newState = state
        .set('markets', action.payload.markets)
        .set('legacyMarkets', action.payload.legacyMarkets)
        .set('mineOrgMap', action.payload.mineOrgMap);
      return newState;
    case 'SET_CUSTOMS':
      newState = state.set('customs', action.payload.customs);
      return newState;
    default:
      return state;
  }
};
