import * as chainListOrigin from './constant';
import { Chain } from './types';

export const chainList = Object.values(chainListOrigin).sort(
  (a, b) => (a.sort || 1) - (b.sort || 1),
) as Chain[];

export * from './types';
/**
 * 获取 chainList 中匹配链的信息
 * @param chainId chainId
 * @param matchTestChain 匹配测试链，将 showName 和 chainIds 第一位展示成匹配的测试链。如果为 false 或不传， 仍然会显示为主网信息
 * @returns 匹配的 Chain 信息
 */
export const getChain = (chainId: number, matchTestChain?: boolean) => {
  const findChain = chainList.find((chain) => chain.chainIds.includes(chainId));
  if (!findChain) {
    throw new Error(`getChain chainId is not valid.chainId:${chainId}`);
  }
  if (matchTestChain) {
    const matchChain = findChain.matchChainList?.find(
      (chain) => chain.chainId === chainId,
    );
    if (matchChain) {
      return {
        ...findChain,
        showName: matchChain.showName,
        // 将匹配的 chainId 排在第一位，命中对应关系
        chainIds: [...findChain.chainIds].sort((a) => (a === chainId ? -1 : 1)),
      } as Chain;
    }
  }
  return findChain;
};
