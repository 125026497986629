/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateProxyUrl } from '@dodoex/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Identicon from 'identicon.js';
import { useDispatch } from 'react-redux';
import { Set } from 'immutable';
import { Typography, Box, BoxProps } from '@mui/material';
import { setCheckTokenAddresses } from '../../configure-store/actions';
import { getTokenLogoUrl } from './getTokenLogoUrl';
import { Token } from '../../types';

export interface TokenLogoByTokenProps {
  token?: Token;
  /** 用来在还没获取到 token 时的兜底方案 */
  address?: string;
  width: number;
  height: number;
  marginRight?: number;
  url?: string;
  zIndex?: number;
  cross?: boolean;
  /** 跳过 proxy 直接使用传入的 url */
  skipProxy?: boolean;
  // 请求到 logo url 后，或者验证查不到 logo 的回调
  onLoadedLogoUrl?: (isLoaded: boolean) => void;
  /** 不记录在 checkTokenAddresses */
  notCheckToken?: boolean;
  sx?: BoxProps['sx'];
}

function toDataURL(url: URL | string, callback: (v?: any) => void) {
  // return callback(url); // TODO 临时使用本地cache图片
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.onerror = function () {
    callback();
  };
  let urlObj: any;
  try {
    // eslint-disable-next-line no-param-reassign
    urlObj = new URL(url);
    if (urlObj.hostname === 'dodoex.coding.net') {
      urlObj.hostname = 'eth-proxy.dodoex.io';
    }
  } catch (e) {
    // catch掉本地路径
  } finally {
    xhr.open('GET', urlObj || url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}

export function TokenLogoByToken({
  token,
  width,
  height,
  marginRight,
  url,
  zIndex,
  cross,
  skipProxy,
  onLoadedLogoUrl,
  notCheckToken,
  address,
  sx,
}: TokenLogoByTokenProps): React.ReactElement {
  const [loaded, setLoaded] = useState(false);
  const [crossLogoUrl, setCrossLogoUrl] = useState('');
  const [defaultUrl, setDefaultUrl] = useState('');
  const [error, setError] = useState(false);
  const onLoad = useCallback(() => setLoaded(true), []);

  const addressRes = address || token?.get('address');
  const symbol = token?.get('symbol');
  const initial = symbol?.charAt(0).toUpperCase();
  const tokenUrl = addressRes ? getTokenLogoUrl(addressRes, symbol, token) : '';

  const dispatch = useDispatch();
  useEffect(() => {
    if (!notCheckToken && addressRes) {
      dispatch(setCheckTokenAddresses(Set([addressRes])));
    }
  }, [addressRes, dispatch, notCheckToken]);

  useEffect(() => {
    if (!tokenUrl && onLoadedLogoUrl) {
      onLoadedLogoUrl(false);
    }
    if (addressRes && tokenUrl) {
      if (onLoadedLogoUrl) {
        onLoadedLogoUrl(true);
      }
    }
    setError(false);
  }, [addressRes, tokenUrl, onLoadedLogoUrl]);

  const logoUrl = useMemo(() => {
    if (skipProxy && url) {
      return url;
    }
    const targetUrl = url || tokenUrl;
    if (targetUrl) {
      // return generateProxyUrl({
      //   url: targetUrl,
      //   height: `${height}`,
      //   width: `${width}`,
      // });
      if (!cross && onLoadedLogoUrl) {
        onLoadedLogoUrl(true);
      }
      return generateProxyUrl({
        url: targetUrl,
        height: '96',
        width: '96',
      });
    }
    return '';
  }, [skipProxy, url, tokenUrl, cross, onLoadedLogoUrl]);

  useEffect(() => {
    if (!cross) return;
    if (!logoUrl) {
      setCrossLogoUrl('');
      return;
    }
    toDataURL(logoUrl, (result: React.SetStateAction<string>) => {
      setCrossLogoUrl(result);
      if (onLoadedLogoUrl) {
        onLoadedLogoUrl(true);
      }
    });
  }, [logoUrl, cross, onLoadedLogoUrl]);

  useEffect(() => {
    // 当地址传了空字符串的时候会报错：A hash of at least 15 characters is required.
    try {
      let addr = addressRes;
      if (addr && addr.length < 15) {
        addr = addr.padEnd(15, '0');
      }
      if (addr) {
        const data = new Identicon(addr, {
          size: width,
          format: 'svg',
          margin: 0.2,
          background: [255, 234, 4, 255],
        }).toString();
        setDefaultUrl(`data:image/svg+xml;base64,${data}`);
      }
    } catch (err) {
      console.error('generate Identicon error: ', err);
    }
  }, [addressRes, width]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: width,
        width,
        height,
        marginRight,
        zIndex,
        ...(sx || {}),
      }}
      style={{
        width,
        height,
      }}
    >
      {!loaded && (
        <Typography
          variant="h5"
          sx={{
            height: '100%',
            width: '100%',
            borderRadius: '50%',
            border: 'transparent 2px solid',
            borderColor: 'text.primary',
            color: 'text.primary',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {initial}
        </Typography>
      )}
      <Box
        component="img"
        src={
          !(cross ? crossLogoUrl : logoUrl) || error
            ? defaultUrl
            : cross
            ? crossLogoUrl
            : logoUrl
        }
        onLoad={onLoad}
        onError={(e: any) => {
          // TODO(meow): should we use react to handle the failover?
          const target = e.target as HTMLImageElement;
          if (address && defaultUrl) {
            setError(true);
            if (onLoadedLogoUrl) {
              onLoadedLogoUrl(true);
            }
          }
          target.onerror = null;
          // 因为如果没有 address 的情况下，使用 target.src 赋值了。会导致后续上面 src 的传值失败。所以注释掉
          // if (cross) {
          //   toDataURL(defaultUrl, (result: string) => {
          //     target.src = result;
          //   });
          // } else {
          //   target.src = defaultUrl;
          // }
        }}
        sx={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          borderRadius: '50%',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      />
    </Box>
  );
}
