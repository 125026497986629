import { Tabs as TabsOrigin, Tab as TabOrigin, alpha } from '@mui/material';
import { styled } from '@mui/system';

// export const Tabs = ({ children }: { children: React.ReactNode }) => {
//   return <TabsOrigin>{children}</TabsOrigin>;
// };

export const TabButtons = styled(TabsOrigin)(({ theme }) => {
  const { palette } = theme;
  const { background } = palette;
  const { button } = palette.custom;
  return {
    minHeight: 'auto',
    width: 'max-content',
    backgroundColor: background.paper,
    borderRadius: 8,
    '& .MuiTabs-indicator': {
      // opacity: 0,
      height: '100%',
      backgroundColor: button.main,
      color: button.mainContrast,
      borderRadius: 8,
    },
  };
});

export const TabButton = styled(TabOrigin)(({ theme }) => {
  const { spacing, palette } = theme;
  const { button } = palette.custom;
  return {
    position: 'relative',
    zIndex: 1,
    padding: spacing(12, 16),
    extTransform: 'none',
    backgroundColor: 'transparent',
    borderRadius: 8,
    textTransform: 'capitalize',
    minWidth: 75,
    transition: 'color 0.3s',
    '&.Mui-selected': {
      // backgroundColor: button.main,
      color: button.mainContrast,
    },
    minHeight: 'auto',
    height: 44,
    [theme.breakpoints.down('tablet')]: {
      height: 40,
      lineHeight: '19px',
      fontSize: 14,
    },
    '&:not(.Mui-selected):hover': {
      color: alpha(palette.text.secondary, 1),
    },
  };
});
