import { clog, dbGet, dbSet, LogType } from '@dodoex/utils';
import { useLiveQuery } from 'dexie-react-hooks';
import { Client } from '@dodoex/message-ws';
import { OrderedMap } from 'immutable';
import { useCallback, useEffect, useMemo } from 'react';
import { sentry } from '../core';
import { walletDb, WalletTx } from '../db';
import { PERSISTENT_TX_HISTORY } from '../localstorage';
import {
  Metadata,
  DeleteSyncTxType,
  RequestOptions,
  State,
  Request,
  MetadataFlag,
} from './types';

const operationNameConfig: { [key in MetadataFlag]?: string } = {
  [MetadataFlag.createToken]: 'NewERC20',
  [MetadataFlag.submissionCreateMetaKey]: 'NewMineV3',
  [MetadataFlag.createCP]: 'NewCP',
  [MetadataFlag.addLiquidity]: 'BuyShares',
  [MetadataFlag.removeLiquidity]: 'SellShares',
  [MetadataFlag.createDPPPool]: 'NewDPP',
  [MetadataFlag.createDSPPool]: 'NewDSP',
  [MetadataFlag.createDVMPool]: 'NewDVM',
};

export function getOperateName(metadata?: Metadata) {
  if (!metadata || !Object.keys(metadata).length) return undefined;
  let operationName: string | undefined;
  Object.keys(metadata).some((flag) => {
    const name = operationNameConfig[+flag as MetadataFlag];
    if (name) {
      operationName = name;
      return true;
    }
    return false;
  });
  return operationName;
}

export const useRequests = ({
  chainId,
  account,
  messageClient,
}: {
  chainId: number;
  account?: string;
  messageClient?: Client | undefined;
}) => {
  const requestsOrigin = useLiveQuery(() => {
    if (!account) {
      return Promise.resolve(undefined);
    }
    return walletDb.walletTxList
      .where('[account+chainId]')
      .equals([account, chainId])
      .toArray()
      .catch((e) => {
        console.error(e);
        return [];
      });
  }, [account, chainId]) as unknown as WalletTx[] | undefined;
  const requests = useMemo(() => {
    return requestsOrigin
      ? OrderedMap<string, [Request, State]>(
          requestsOrigin.map((tuple) => [tuple.tx, [tuple, tuple.state]]),
        )
      : (OrderedMap() as OrderedMap<string, [Request, State]>);
  }, [requestsOrigin]);
  const successRequests = useMemo(
    () => requestsOrigin?.filter((item) => item.state === State.Success),
    [requestsOrigin],
  );
  const waitingRequests = useMemo(
    () => requestsOrigin?.filter((item) => item.state === State.Running),
    [requestsOrigin],
  );

  const getSubmitStatusOption = useCallback(
    (tx: string, metadata?: Metadata, options?: RequestOptions) => {
      // 获取当前 meteDataFlag 对应的 operationName
      const operationName = getOperateName(metadata);
      const submitStatusOption =
        options?.needSync && operationName
          ? {
              operationName,
              tx,
            }
          : undefined;

      return submitStatusOption;
    },
    [],
  );

  const deleteSyncTx: DeleteSyncTxType = useCallback(
    (tx: string) => {
      walletDb.walletTxList
        .where('tx')
        .equals(tx)
        .modify((val, ref) => {
          delete ref.value.options;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [walletDb.walletTxList],
  );

  const subscribeSubmitStatus = useCallback(
    (submitStatusOption: { operationName: string; tx: string } | undefined) => {
      if (!submitStatusOption) return;

      const { operationName, tx } = submitStatusOption;
      const submitStatusParams = {
        operationName,
        hash: tx,
        chainId,
      };
      if (messageClient) {
        clog(LogType.graphql, 'submitStatus', operationName, tx);
        messageClient?.submitStatus(submitStatusParams, {
          completeStatus: (data) => {
            clog(
              LogType.graphql,
              'submitStatus => completeStatus',
              operationName,
              tx,
              data,
            );
            if (data?.data?.result) {
              deleteSyncTx(tx);
            }
          },
          error: (error: Error | CloseEvent) => {
            if (error instanceof CloseEvent && !error.code) {
              return;
            }
            if (
              error instanceof Error &&
              // 这个和 yongjun 确定不需要记录，是后端去索引数据的数据，在这个交易里面没有查到相关的合约事件（后端有重试且有记录）
              error.message.includes('getEvent not found')
            ) {
              return;
            }
            let errorInfo = '';
            try {
              errorInfo = JSON.stringify(error, [
                'message',
                'arguments',
                'type',
                'name',
                'code',
                'reason',
              ]);
            } catch (e) {
              // empty
            }
            sentry?.withScope(function (scope: any) {
              const api = `messageClient.submitStatus error`;
              scope.setTag('api', api);
              scope.setExtra('params', {
                submitStatusParams,
                error: errorInfo,
              });
              if (error instanceof CloseEvent) {
                const errorApi = `[CloseEvent]: type: ${error.type}, code: ${error.code}, reason: ${error.reason}, wasClean: ${error.wasClean}`;
                console.error(errorApi);
                if (
                  !error.code ||
                  // 4403: 在 useInitMessageClient 已经处理，无需再次记录
                  error.code === 4403 ||
                  // 后端会重试，这里没有消息记录为什么报错，没必要记录
                  (error.code === 1006 && !error.reason)
                ) {
                  return;
                }
                sentry?.captureException(new Error(errorApi));
              } else {
                sentry?.captureException(error);
              }
              console.error(
                'api:',
                api,
                'params:',
                submitStatusParams,
                'error:',
                error,
              );
            });
          },
          complete: () => {
            // empty
          },
          next: () => {
            // empty
          },
        });
      } else {
        sentry?.withScope(function (scope: any) {
          const api = `messageClient undefined`;
          scope.setTag('api', api);
          scope.setExtra('params', submitStatusParams);
          console.error('api:', api, 'params:', submitStatusParams);
        });
        clog(LogType.graphql, 'messageClient undefined', operationName, tx);
      }
    },
    [chainId, deleteSyncTx, messageClient],
  );

  useEffect(() => {
    successRequests?.forEach((k) => {
      const submitStatusOption = getSubmitStatusOption(
        k.tx,
        k.metadata,
        k.options,
      );
      subscribeSubmitStatus(submitStatusOption);
    });
  }, [successRequests]);

  // migrate
  useEffect(() => {
    const migrate = async () => {
      if (account) {
        const path = `${chainId}.${PERSISTENT_TX_HISTORY}.${account.toLowerCase()}`;
        const loaded: [Request, State][] = account
          ? dbGet({
              path,
              defaultValue: [],
            })
          : [];

        const allTxList = await walletDb.walletTxList.toArray().catch((e) => {
          console.error(e);
          return [];
        });
        const currentCacheList = allTxList.filter(
          (item) =>
            item.account.toLocaleLowerCase() === account.toLocaleLowerCase() &&
            chainId === item.chainId,
        );
        if (!currentCacheList.length && loaded.length) {
          const txSet = new Set<string>();
          const allTxSimpleList: {
            tx: string;
            account: string;
            chainId: number;
          }[] = [];
          allTxList.forEach((walletTx) => {
            txSet.add(walletTx.tx);
            allTxSimpleList.push({
              tx: walletTx.tx,
              account: walletTx.account,
              chainId: walletTx.chainId,
            });
          });
          const loadedFiltered = loaded.filter(([req]) => {
            if (txSet.has(req.tx)) {
              return false;
            }
            txSet.add(req.tx);
            return true;
          });
          let allTxStr = '';
          let loadedFilteredStr = '';
          let loadedStr = '';
          try {
            allTxStr = JSON.stringify(allTxSimpleList);
            loadedFilteredStr = JSON.stringify(
              loadedFiltered.map(([req]) => ({
                tx: req.tx,
              })),
            );
            loadedStr = JSON.stringify(
              loaded.map(([req]) => ({
                tx: req.tx,
              })),
            );
          } catch (error) {
            // empty
          }
          const migrateTxList = loadedFiltered.map(([req, state]) => ({
            ...req,
            state,
            chainId,
            account,
          }));
          walletDb.walletTxList
            .bulkAdd(migrateTxList)
            .then(() => {
              dbSet({
                path,
                value: [],
              });
            })
            .catch(async (error) => {
              const api = '[walletDb.walletTxList.bulkAdd] error';
              const newCurrentCacheList = await walletDb.walletTxList
                .where('[account+chainId]')
                .equals([account, chainId])
                .toArray();

              // sentry 记录有已经插入进去的情况，这种不需要再上报
              // https://sentry.io/organizations/dodoex/issues/3362901519/?project=6067971&query=is%3Aunresolved+walletTxList.bulkAdd%28%29&statsPeriod=14d
              if (newCurrentCacheList.length === migrateTxList.length) {
                return;
              }
              let newCurrentCacheListStr = '';
              try {
                if (newCurrentCacheList) {
                  newCurrentCacheListStr = JSON.stringify(
                    newCurrentCacheList.map((item) => ({
                      tx: item.tx,
                    })),
                  );
                }
              } catch (e) {
                // empty
              }
              sentry.withScope(function (scope: any) {
                scope.setTag('api', api);
                scope.setExtra('params', {
                  loadedFiltered: loadedFilteredStr,
                  allTx: allTxStr,
                  newCurrentCacheListStr,
                });
                console.error(api, error, loadedFiltered);
                sentry.captureException(error);
              });
            });
        } else {
          dbSet({
            path,
            value: [],
          });
        }
      }
    };
    migrate();
  }, [account, chainId]);

  return {
    requests,
    waitingRequests,
    getSubmitStatusOption,
    deleteSyncTx,
    subscribeSubmitStatus,
  };
};
