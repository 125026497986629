import { useEffect, useState } from 'react';

let isOpenBlockIframe = false;
let id = 1;
const OPENBLOCK_CONTENT = 'openblock-content-integration';
const messageCallbacks: {
  [key in number]: () => void;
} = {};
// 如果已经 load 1 秒，就没必要再去监听了
let isLoaded = false;

window.addEventListener('message', (event) => {
  if (event.data.from === OPENBLOCK_CONTENT) {
    isOpenBlockIframe = true;
    Object.values(messageCallbacks).map((fc) => fc());
  }
});

window.addEventListener(
  'load',
  () => {
    setTimeout(() => {
      isLoaded = true;
      Object.values(messageCallbacks).map((fc) => fc());
    }, 1000);
  },
  {
    once: true,
  },
);

export const getIsOpenBlockIframe: () => Promise<boolean> = async () => {
  return new Promise((resolve) => {
    if (isOpenBlockIframe) {
      resolve(true);
    } else if (window.parent === window || isLoaded) {
      resolve(false);
    } else {
      id++;
      messageCallbacks[id] = () => {
        resolve(isOpenBlockIframe);
        delete messageCallbacks[id];
      };
    }
  });
};

export const useIsOpenBlockIframe = () => {
  const [res, setRes] = useState(false);

  useEffect(() => {
    getIsOpenBlockIframe().then(setRes);
  }, []);

  return res;
};
