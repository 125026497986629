import {
  ApolloGQLClientDevEndpoint,
  ApolloGQLClientEndpoint,
  clog,
  LogType,
  dbSet,
  EtherTokenWithChainId,
  getChain,
  openEtherscanPage,
  renderTitle,
  request,
  BIG_ALLOWANCE,
  getIsDevEnvironment,
} from '@dodoex/utils';
import { serialize } from '@ethersproject/transactions';
import { Transaction } from 'ethereumjs-tx';
import { OrderedMap } from 'immutable';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toHex } from 'web3-utils';
import { Client } from '@dodoex/message-ws';
import { logWallet, WalletEventLabel } from '@dodoex/mixpanel/dist/wallet';
import { useLiveQuery } from 'dexie-react-hooks';
import { approve, PERSISTENT_TX_HISTORY, WatchResult } from '../index';
import {
  sendTransaction,
  watchTransaction,
  getEstimateGas,
  WatchTransactionReturn,
} from '../wallet';
import {
  getCurrentChainId,
  getCurrentAccount,
  getCurrentAccountType,
} from '../selectors';
import { getTransactionCount, getWeb3, signTypedData } from '../web3';
import { OpCode, Step as StepSpec, SwapType, TXStep } from './spec';
import { accessToken, sentry } from '../core';
import { walletDb, WalletTx } from '../db';
import { useSubmitUserTxTrackingRetry } from './useSubmitUserTxTrackingRetry';
import { submitUserTxTracking } from './submitUserTxTracking';
import { getOperateName, useRequests } from './useRequests';
import { AccountType } from '../reducer';
import {
  Metadata,
  DeleteSyncTxType,
  RequestOptions,
  State,
  Request,
  MetadataFlag,
  StateText,
} from './types';
import { getSafeHashDetailUrl } from '../injects/wallet/safeWalletConnect';

export * from './types';
export * from './spec';

export enum ExecutionResult {
  // User canceled the op
  Canceled = 'canceled',
  // Op failed on chain
  Failed = 'failed',
  // Op confirmed on chain
  Success = 'success',

  // Op submitted on chain
  // Only when called with early return mode enabled
  Submitted = 'submitted',
}

type TextUpdater = (request: Request) => null | {
  brief: string;
  subtitle?: string;
  metadata?: Metadata;
  fromAmount?: string;
  resAmount?: string;
};

interface ExecutionOptions {
  requestOptions?: RequestOptions;
}

export type ExecutionCtx = {
  /**
   * Execute an on-chain operation
   * @param breif: TX title. e.g.: "Swap"
   * @param spec: TX specification.
   * @param subtitle: Additional hint text. e.g.: "10 USDT to 10 USDC"
   * @param early: When given, the returned promise resolves when user confirmed in their wallet.
   * @param metadata
   *   Useful if the caller wants to track the tx by itself. Also see: `useInflights`
   * @param mixpanelProps: mixpanel properties
   * @param submittedConfirmBack: 点击 “我知道了” 按钮后的回调
   */
  execute: (
    brief: string,
    spec: StepSpec,
    subtitle?: string,
    early?: boolean,
    metadata?: Metadata,
    submittedBack?: () => void,
    mixpanelProps?: Record<string, any>,
    submittedConfirmBack?: () => void,
    successBack?: (tx: string) => void,
    options?: ExecutionOptions,
  ) => Promise<ExecutionResult>;

  /**
   * Clear all history
   */
  clear: () => void;

  /**
   * Update request text
   */
  updateText: (upd: TextUpdater) => void;

  /**
   * All order history
   * uuid -> requst + state
   */
  requests: OrderedMap<Request, State>;
  setShowing?: React.Dispatch<
    React.SetStateAction<{
      brief: string;
      subtitle?: string | undefined;
      spec: StepSpec;
    } | null>
  >;
  /** 同步完成后，删除为了同步使用的附加信息 */
  deleteSyncTx: DeleteSyncTxType;
  /** 是否为等待提交的状态 */
  waitingSubmit: boolean;
};

export const ExecutionContext = React.createContext<ExecutionCtx>({
  execute: () => Promise.resolve(ExecutionResult.Canceled),
  clear: () => {
    /* Nothing */
  },
  updateText: () => {
    /* Nothing */
  },
  requests: OrderedMap(),
  deleteSyncTx: () => {
    /* Nothing */
  },
  waitingSubmit: false,
});

type ConfirmContinuation = {
  cont: (confirmed: boolean) => void;
};

interface KnownDialogError {
  error: string;
  title?: (type: string) => string;
  msg: string;
  metadata?: Metadata | string;
  warn?: boolean;
}

/**
 * Get the submission context
 */
export function useSubmission() {
  return useContext(ExecutionContext);
}

/**
 * Get a list of inflight requests
 */
export function useInflights() {
  const { requests } = useSubmission();
  return requests.filter((v) => v === State.Running);
}

export enum RefreshState {
  init = 1,
  loading,
  done,
}
/**
 * 获取刷新状态
 */
export function useRefreshStatus(loading: boolean, refresh?: () => void) {
  const [refreshStatus, setRefreshStatus] = useState(RefreshState.init);

  useEffect(() => {
    if (loading) {
      setRefreshStatus(RefreshState.loading);
    } else if (refreshStatus === RefreshState.loading) {
      setRefreshStatus(RefreshState.done);
      if (refresh) {
        refresh();
      }
    }
  }, [loading]);

  return refreshStatus === RefreshState.done;
}

/**
 * 根据 metadata 来记录 loading 及是否需要刷新的状态
 * @param metadataFlags 需要监听的 flag 数组（不会监听字段 change，当常量使用）
 * @returns { runningIdMap, isRefresh, needSyncRunningCount, needSyncSuccessList } { 记录正在loading 的 flag, 记录是否所有 loading 都结束, 记录需要等待后端数据库同步，当前正处于上链中的条数, 记录需要等待后端数据库同步，当前已上链成功的记录 }
 */
export function useRequestStatus(metadataFlags: MetadataFlag[] | string[]) {
  const [isRefresh, setIsRefresh] = useState(false);
  const [runningIdMap, setRunningIdMap] = useState<{
    [key: string]: {
      extraData?: any;
    };
  }>({});
  const [needSyncRunningCount, setNeedSyncRunningCount] = useState(0);
  const [needSyncSuccessList, setNeedSynSuccessList] = useState<
    {
      tx: string;
      extraData?: any;
    }[]
  >([]);
  const { requests } = useSubmission();

  useEffect(() => {
    const reqArr = requests.toArray().reverse();
    const result: { [key: string]: any } = {};
    const newNeedSyncSuccessList = [];
    let newNeedSyncRunningCount = 0;
    for (const [req, state] of reqArr) {
      if (req.metadata) {
        let matchFlag;
        metadataFlags.some((flag) => {
          if (req.metadata && req.metadata[flag]) {
            matchFlag = flag;
            return true;
          }
          return false;
        });
        if (matchFlag) {
          if (state === State.Running) {
            result[matchFlag] = {
              extraData: req.options?.extraData,
            };
            if (req.options?.needSync) {
              // eslint-disable-next-line no-plusplus
              newNeedSyncRunningCount++;
            }
          } else if (state === State.Success && req.options?.needSync) {
            newNeedSyncSuccessList.push({
              tx: req.tx,
              extraData: req.options?.extraData,
            });
          }
        }
      }
    }
    setNeedSyncRunningCount(newNeedSyncRunningCount);
    setNeedSynSuccessList(newNeedSyncSuccessList);
    if (Object.keys(runningIdMap).length && !Object.keys(result).length) {
      setIsRefresh(true);
    } else if (isRefresh) {
      setIsRefresh(false);
    }
    setRunningIdMap(result);
  }, [requests]);

  return {
    runningIdMap,
    isRefresh,
    needSyncRunningCount,
    needSyncSuccessList,
  };
}

function useClearLimitRequests(
  account: string | undefined,
  chainId: number,
  maxRequestLen?: number,
) {
  const hasStatusRequestsCollection = useLiveQuery(() => {
    if (!account) {
      return Promise.resolve(undefined);
    }
    return walletDb.walletTxList
      .where('[account+chainId]')
      .equals([account, chainId])
      .and((x) => x.state !== State.Running);
  }, [account, chainId]);

  useEffect(() => {
    const computed = async () => {
      if (maxRequestLen && hasStatusRequestsCollection) {
        const count = await hasStatusRequestsCollection.count();
        if (count > maxRequestLen) {
          hasStatusRequestsCollection.limit(count - maxRequestLen).delete();
        }
      }
    };
    computed();
  }, [maxRequestLen, hasStatusRequestsCollection]);
}

export const useExecution = ({
  showNotifs,
  toastFailed,
  toastWarn,
  messageClient,
  maxRequestLen,
}: {
  showNotifs: (options: {
    isSuccess: boolean;
    title: string;
    link: {
      text: string;
      onClick: () => any;
    };
    progress: number;
  }) => void;
  toastFailed: (msg: string, duration?: number, hint?: string) => void;
  toastWarn?: (msg: string, duration?: number, hint?: string) => void;
  messageClient?: Client | undefined;
  /** 最多缓存有结果 request 的条数， 不传或 0 则不限制 */
  maxRequestLen?: number;
}) => {
  clog(LogType.graphql, 'useExecution messageClient=>', messageClient);

  const [waitingSubmit, setWaitingSubmit] = useState(false);
  const { t } = useTranslation();
  const chainId = useSelector(getCurrentChainId);
  const account = useSelector(getCurrentAccount);
  const EtherTokenSymbol = useMemo(
    () => EtherTokenWithChainId[chainId]?.symbol,
    [chainId],
  );
  const { failedTracking, submitWaitingOrder, failedTrackingTimingSubmit } =
    useSubmitUserTxTrackingRetry();

  useEffect(() => {
    if (messageClient) {
      submitWaitingOrder(messageClient);
    }
  }, [messageClient, submitWaitingOrder]);

  useEffect(() => {
    let time: NodeJS.Timeout | undefined;
    const computed = async () => {
      time = await failedTrackingTimingSubmit(messageClient);
    };
    if (messageClient && failedTracking?.length) {
      computed();
    }
    return () => {
      clearTimeout(time);
    };
  }, [messageClient, failedTracking, failedTrackingTimingSubmit]);

  const KnownDialogErros = useMemo(
    () =>
      [
        {
          error: 'insufficient',
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.insufficient.hint', {
            EtherTokenSymbol,
          }),
        },
        {
          error: [
            'External Swap execution Failed',
            'Return amount is not enough',
            'deposit amount is not enough',
          ],
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.toast.swap'),
        },
        {
          error: 'SafeERC20: low-level call failed',
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.toast.deflationary'),
          metadata: MetadataFlag.submissionCreateMetaKey,
        },
        {
          error: 'SafeERC20: low-level call failed',
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.toast.unknown'),
        },
        {
          error: [
            'User denied',
            'cancel',
            'User rejected',
            'Transaction was rejected',
          ],
          title: (type) =>
            t('submission.error-message.toast.title-cancel', { type }),
          msg: t('submission.error-message.toast.cancel'),
          warn: true,
        },
        {
          error: ['QUOTE_CAP_INVALID'],
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.toast.slippage-token'),
        },
        {
          error: ['ALREADY_OVER_CAP'],
          title: (type) =>
            t('submission.error-message.toast.title-failed', { type }),
          msg: t('submission.error-message.toast.cp-over-cap'),
        },
        {
          error: [
            `Cannot set properties of undefined (setting 'loadingDefauIts'){"originalError":{`,
            `[ethjs-query]while formatting outputs from RPC'["value":["code":-32000,"message":"header not found"))`,
          ],
          msg: t('submission.error-message.toast.rpc-exception'),
          warn: true,
        },
        {
          error: [`execution reverted:FORCESTOP ["originalError"`],
          msg: t('submission.error-message.toast.force-stop-exception'),
        },
        {
          error: [
            'replacement transaction underpriced',
            'Gasprice too low',
            'transaction underprice',
          ],
          msg: t('submission.error-message.toast.gas-price-low'),
          warn: true,
        },
        {
          error: ['MINT_INVALID'],
          msg: t('submission.error-message.toast.force-stop-access'),
        },
        {
          error: ['NOT_PHASE_EXE'],
          msg: t('submission.error-message.toast.cooling-off-period'),
          warn: true,
        },
        {
          error: [
            'params specify an EIP-1559transaction but the currentnetwork does not support',
          ],
          msg: t('submission.error-message.toast.wallet-incompatibility'),
          warn: true,
        },
        {
          error: ['ALREADY_SETTLED'],
          msg: t('submission.error-message.toast.settled'),
        },
        {
          error: ['create RFQ order failed]:LESS_THAN_FEE_LIMIT'],
          msg: t('submission.error-message.toast.service-update'),
          warn: true,
        },
      ] as KnownDialogError[],
    [EtherTokenSymbol, t],
  );

  // Dialog status
  const [showing, setShowing] = useState<{
    brief: string;
    subtitle?: string;
    spec: StepSpec;
  } | null>(null);
  const [showingDone, setShowingDone] = useState(false);
  const [submittedConfirmBack, setSubmittedConfirmBack] =
    useState<() => void>();

  const { requests, waitingRequests, deleteSyncTx } = useRequests({
    chainId,
    account,
    messageClient,
  });

  useClearLimitRequests(account, chainId, maxRequestLen);

  const extRequests = useMemo(
    () => requests.mapEntries(([, [req, state]]) => [req, state]),
    [requests],
  );

  // Alert confirm if there is ongoing tx
  const [confirming, setConfirming] = useState<ConfirmContinuation | null>(
    null,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const hasInflight = useMemo(() => waitingRequests?.length, [waitingRequests]);
  const hasInflightRef = useRef(hasInflight);
  useEffect(() => {
    hasInflightRef.current = hasInflight;
  }, [hasInflight]);

  const registerDone = useCallback(
    async (tx: string, ret: WatchTransactionReturn) => {
      const { status: watchResult, statusText, transactionReceipt } = ret;
      const state =
        // eslint-disable-next-line no-nested-ternary
        watchResult === WatchResult.Success
          ? State.Success
          : watchResult === WatchResult.Failed
          ? State.Failed
          : State.Warning;
      const { viewOnScanKey } = getChain(chainId);

      await walletDb.transaction(
        'rw',
        walletDb.walletTxList,
        walletDb.submitTxTracking,
        async () => {
          walletDb.walletTxList
            .where('tx')
            .equals(tx)
            .modify((request, ref) => {
              if (
                watchResult === WatchResult.Success ||
                watchResult === WatchResult.Failed
              ) {
                showNotifs({
                  isSuccess: watchResult === WatchResult.Success,
                  title: renderTitle(request.brief, t),
                  link: {
                    text: t(viewOnScanKey),
                    onClick: () => {
                      if (request.safeTxHash) {
                        window.open(
                          getSafeHashDetailUrl(
                            request.safeTxHash,
                            request.chainId,
                            request.account,
                          ),
                        );
                      } else {
                        openEtherscanPage(`tx/${request.tx}`, chainId);
                      }
                    },
                  },
                  progress: ret ? 8000 : 0,
                });
              } else {
                // warning
                toastWarn?.(t('submission.warning-message.toast.title'), 0, '');
              }
              submitUserTxTracking(messageClient, {
                key: request.brief,
                hash: tx,
                chainId,
                from: account as string,
                to:
                  request.spec.opcode === OpCode.Approval
                    ? request.spec.contract
                    : (request.spec as TXStep).to,
                nonce: request.nonce as number,
                extra: {
                  status: statusText,
                  transactionHash: transactionReceipt?.transactionHash,
                  transactionReceipt,
                },
              });
              ref.value.state = state;
            })
            .catch((e) => {
              console.error(e);
            });
        },
      );
    },
    [chainId, showNotifs, t, toastWarn, messageClient, account],
  );

  const isCheckCache = useRef(false);
  useEffect(() => {
    if (isCheckCache.current || !account || !waitingRequests) return;
    isCheckCache.current = true;
    waitingRequests?.forEach((k) => {
      watchTransaction(
        k.tx,
        k.nonce,
        account ?? undefined,
        k.spec.opcode === OpCode.TX ? k.spec.swapType : undefined,
      ).then((ret) => {
        registerDone(k.tx, ret);
      });
    });
  }, [account, chainId, waitingRequests]);

  const handleSendTransaction = useCallback(
    async (params: any, swapType?: SwapType, timeout?: number) => {
      if (swapType === SwapType.Privacy) {
        const { from } = params;
        delete params.from;
        const transaction = new Transaction(params);
        const msgHash = `0x${transaction.hash(false).toString('hex')}`;
        const web3 = await getWeb3();
        const signedMessage = await web3.eth.sign(msgHash, from);
        const signedTransaction = serialize(params, signedMessage);
        const isDev = getIsDevEnvironment();
        const url = isDev
          ? ApolloGQLClientDevEndpoint
          : ApolloGQLClientEndpoint;
        const { thegraphKey } = getChain(chainId);
        const res = await request({
          url: `${url}?opname=UpdatePrivateOrder`,
          method: 'post',
          headers: accessToken
            ? {
                'access-token': accessToken,
              }
            : undefined,
          data: `{"query":"query UpdatePrivateOrder($where: Limit_and_rfqcreatePrivateOrderInfo) {  limit_and_rfq_createPrivateOrder(where: $where) }","variables":{"where":{"network":"${thegraphKey}","address":"${from}","transaction":"${signedTransaction}","timeout":${timeout}}},"operationName":"UpdatePrivateOrder"}`,
        });
        if (res && res.data) {
          const { data, errors } = res.data;
          if (errors) {
            console.error('[Private Swap error]: ', JSON.stringify(errors));
            if (Array.isArray(errors) && errors.length > 0) {
              const [error] = errors;
              if (error.message) {
                throw new Error(`[Private Swap error]: ${error.message}`);
              }
            }
          }
          return data?.limit_and_rfq_createPrivateOrder;
        }
        return null;
      }
      return sendTransaction(params);
    },
    [chainId],
  );
  const accountType = getCurrentAccountType();

  const handler = useCallback(
    async (
      brief: string,
      spec: StepSpec,
      subtitle?: string,
      early = false,
      metadata?: Metadata,
      submittedBack?: () => void,
      mixpanelProps?: Record<string, any>,
      submittedConfirmBack?: () => void,
      successBack?: (tx: string) => void,
      options?: ExecutionOptions,
    ) => {
      setSubmittedConfirmBack(() => submittedConfirmBack);
      // TODO: check if confirming is not undefined
      if (hasInflightRef.current) {
        const ret = await new Promise<boolean>((cont) => {
          // TODO: Can we actually keep an function in a state?
          setConfirming({ cont });
        });
        setConfirming(null);
        if (!ret) return ExecutionResult.Canceled;
      }

      console.log('[Submission] Execute: ', brief, spec, subtitle, early);

      setShowing({ spec, brief, subtitle });
      setShowingDone(false);
      let tx: string | undefined;
      let params: any;
      let nonce: number | undefined;
      setWaitingSubmit(false);
      if (!account)
        throw new Error('Cannot execute step when the wallet is disconnected');
      try {
        setWaitingSubmit(true);
        if (spec.opcode === OpCode.Approval) {
          tx = await approve(
            spec.token.address,
            account,
            spec.contract,
            spec.amt || BIG_ALLOWANCE,
          );
        } else if (spec.opcode === OpCode.TX) {
          // Sanity check
          if (spec.to === '') throw new Error('Submission: malformed to');
          if (spec.data.length === 0)
            throw new Error('Submission: malformed data');
          if (spec.data.indexOf('0x') === 0 && spec.data.length <= 2)
            throw new Error('Submission: malformed data');
          // Prepare gas, etc...
          nonce = await getTransactionCount();
          params = {
            value: spec.value,
            data: spec.data,
            to: spec.to,
            gasLimit: spec.gasLimit,
            from: account,
            chainId,
            gasPrice: toHex(String(spec.gasPrice)),
          };
          if (spec.maxFeePerGas && params.maxPriorityFeePerGas) {
            delete params.gasPrice;
            params.type = 2;
            params.maxFeePerGas = toHex(String(spec.maxFeePerGas));
            params.maxPriorityFeePerGas = toHex(
              String(spec.maxPriorityFeePerGas),
            );
          }

          if (!params.gasLimit) {
            const gasLimit = await getEstimateGas(params);
            if (!gasLimit) throw new Error(t('common.toast.getGasLimitError'));
            params.gasLimit = gasLimit;
          }

          if (params.gasLimit) {
            params.gasLimit = toHex(params.gasLimit);
          }

          if (!spec.swapType || spec.swapType === SwapType.Normal) {
            delete params.gasPrice;
            params.maxPriorityFeePerGas = null;
            params.maxFeePerGas = null;
          }

          tx = await handleSendTransaction(
            { ...params, nonce: `0x${nonce?.toString(16)}` },
            spec.swapType,
            spec.ddlSecRel,
          );
          if (!tx) throw new Error(`Unexpected tx: ${tx}`);
        } else if (spec.opcode === OpCode.TypedSign) {
          console.log('spec.typedData', spec.typedData);

          const signature = await signTypedData({
            account: spec.signer ?? account,
            typedData: spec.typedData,
          });
          if (!signature) {
            throw new Error(`signature is null`);
          }
          if (successBack && signature) {
            if (typeof signature === 'string') {
              successBack(signature);
            } else if (
              Object.prototype.hasOwnProperty.call(signature, 'result')
            ) {
              successBack((signature as { result: string }).result);
            } else {
              throw new Error(
                `Unexpected signature: ${JSON.stringify(signature)}`,
              );
            }
          }
          setShowingDone(true);
          return ExecutionResult.Success;
        } else {
          throw new Error(
            `Op ${(spec as { opcode: number }).opcode} not implemented!`,
          );
        }
      } catch (e: any) {
        setWaitingSubmit(false);
        setShowing(null);
        if (e.message) {
          const options = { error: e.message, brief };
          if (mixpanelProps) Object.assign(options, mixpanelProps);
          logWallet(WalletEventLabel.errorPopUp, options);

          const toastMsg = (item: KnownDialogError) => {
            const type = renderTitle(brief, t);
            let title = item.title ? item.title(type) : '';
            if (!title) {
              title = item.warn
                ? t('submission.error-message.toast.title-notice', { type })
                : t('submission.error-message.toast.title-failed', { type });
            }
            if (item.warn && toastWarn) {
              toastWarn(title, 0, item.msg);
            } else {
              toastFailed(title, 0, item.msg);
            }
          };
          const isMatchError = KnownDialogErros.some((item) => {
            if (
              item.metadata &&
              (!metadata || !metadata[item.metadata as string])
            )
              return false;

            if (Array.isArray(item.error)) {
              return item.error.some((error) => {
                if (e.message.indexOf(error) > -1) {
                  toastMsg(item);
                  return true;
                }
                return false;
              });
            }
            if (e.message.indexOf(item.error) > -1) {
              toastMsg(item);
              return true;
            }
            return false;
          });
          // 兜底
          if (!isMatchError) {
            toastFailed(e.message, 0);
            // setErrorMessage(e.message);
          }
        }
        return ExecutionResult.Canceled;
      }
      setWaitingSubmit(false);

      const reportInfo = {
        brief,
        ...spec,
        ...params,
        tx,
        nonce,
        subtitle,
        ...mixpanelProps,
      };
      let safeTxHash: string | undefined;
      if (accountType === AccountType.safe) {
        safeTxHash = tx;
        delete reportInfo.tx;
      }
      submitUserTxTracking(messageClient, {
        key: brief,
        hash: tx,
        chainId,
        from: account,
        to: spec.opcode === OpCode.Approval ? spec.contract : spec.to,
        nonce: nonce as number,
        extra: {
          safeTxHash,
          ...reportInfo,
          status: StateText.Running,
          operateName: getOperateName(metadata),
        },
      });
      logWallet(WalletEventLabel.eventSubmissionSuccess, reportInfo);
      setShowingDone(true);

      const request: Request = {
        brief,
        spec,
        tx,
        nonce,
        subtitle,
        metadata,
        options: options?.requestOptions,
      };

      const id = tx;
      const newItem = {
        ...request,
        state: State.Running,
        account,
        chainId,
        safeTxHash,
      };
      try {
        walletDb.walletTxList.add(newItem, id);
      } catch (error) {
        console.error(error);
        walletDb.close();
        walletDb.open().then(() => {
          walletDb.walletTxList
            .add(newItem, id)
            .then(() => {
              sentry?.withScope(function (scope: any) {
                const api = `[dexie-error-retry-success]`;
                scope.setTag('api', api);
                sentry?.captureException(error);
              });
            })
            .catch((e) => {
              sentry?.withScope(function (scope: any) {
                const api = `[dexie-error-retry-error]`;
                scope.setExtra('params', {
                  tx,
                  brief,
                  newItem,
                  id,
                });
                scope.setTag('api', api);
                sentry?.captureException(e);
              });
            });
        });
      }

      if (early) {
        if (successBack) {
          successBack(tx);
        }
        watchTransaction(
          tx,
          nonce,
          account,
          spec.opcode === OpCode.TX ? spec.swapType : undefined,
        ).then((ret) => {
          registerDone(id, ret);
        });
        return ExecutionResult.Submitted;
      }
      if (submittedBack) {
        submittedBack();
      }
      const result = await watchTransaction(
        tx,
        nonce,
        account,
        spec.opcode === OpCode.TX ? spec.swapType : undefined,
      );
      registerDone(id, result);
      if (result.status === WatchResult.Success) {
        if (successBack) {
          successBack(tx);
        }
        return ExecutionResult.Success;
      }
      return ExecutionResult.Failed;
    },
    [
      account,
      chainId,
      registerDone,
      t,
      setWaitingSubmit,
      messageClient,
      accountType,
    ],
  );

  const clear = useCallback(() => {
    if (account) {
      const path = `${chainId}.${PERSISTENT_TX_HISTORY}.${account.toLowerCase()}`;
      dbSet({
        path,
        value: [],
      });
    }
    walletDb.walletTxList.clear().catch((e) => {
      console.error(e);
    });
  }, [account, chainId]);

  /** 用这个方法更新交易记录，不会触发 等待弹窗文案的变更 */
  const updateText = useCallback(
    (upd: TextUpdater) => {
      const newRequests: WalletTx[] = [];
      requests.forEach(([req, state]) => {
        const updated = upd(req);
        if (!updated) {
          newRequests.push({
            account: account || '',
            chainId,
            ...req,
            state,
          });
        } else {
          newRequests.push({
            account: account || '',
            chainId,
            ...req,
            metadata: {
              ...req.metadata,
              ...updated.metadata,
            },
            brief: updated.brief,
            subtitle: updated.subtitle,
            state,
          });
          const extra: any = {
            subtitle: updated.subtitle,
          };
          if (updated.fromAmount) {
            extra.fromAmount = updated.fromAmount;
          }
          if (updated.resAmount) {
            extra.resAmount = updated.resAmount;
          }
          submitUserTxTracking(messageClient, {
            key: updated.brief,
            hash: req.tx,
            chainId,
            from: account as string,
            to:
              req.spec.opcode === OpCode.Approval
                ? req.spec.contract
                : (req.spec as TXStep).to,
            nonce: req.nonce as number,
            extra,
          });
        }
      });
      walletDb.walletTxList.bulkPut(newRequests).catch((e) => {
        console.error(e);
      });
    },
    [account, chainId, requests, messageClient],
  );

  const ctxVal = useMemo(
    () => ({
      execute: handler,
      clear,
      updateText,
      requests: extRequests,
      setShowing,
      deleteSyncTx,
      waitingSubmit,
    }),
    [handler, extRequests, clear, updateText, deleteSyncTx, waitingSubmit],
  );

  const closeShowing = useCallback(() => {
    setShowing(null);
    if (submittedConfirmBack) {
      submittedConfirmBack();
      setSubmittedConfirmBack(undefined);
    }
  }, [submittedConfirmBack]);

  return {
    showing,
    showingDone,
    confirming,
    errorMessage,
    setErrorMessage,
    closeShowing,
    ctxVal,
    EtherTokenSymbol,
  };
};
