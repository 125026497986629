import { ChainType } from '../chain';

export enum WalletType {
  injected = 'injected',
  BinanceChain = 'custom-bsc-injected',
  WalletConnect = 'custom-walletconnect',
  Portis = 'portis',
  Gnosis = 'custom-gnosis',
  WalletLink = 'custom-walletlink',
  LedgerUSB = 'custom-ledger-usb',
  Ledger = 'custom-ledger',
  openBlock = 'custom-open-block',
  openBlockIframe = 'custom-open-block-iframe',
  Nabox = 'custom-nabox',
  Bitkeep = 'custom-bitkeep',
  OneKey = 'custom-onekey',
  OKX = 'custom-okx',
  uAuth = 'custom-uauth',
  kuCoinWallet = 'custom-kucoin-wallet',
  frontier = 'custom-frontier',
}

export interface Wallet {
  type: WalletType;
  /** 连接钱包后，在钱包弹窗显示的名称 */
  typeName: string;
  /** 切换弹窗时选项的显示名称 */
  showName: string;
  logo: JSX.Element;
  checked: () => boolean;
  isSafe?: boolean;
  /** 支持 chain，不传则都支持 */
  chainTypes?: ChainType[];
  /** 排序，lite 版打包会错乱顺序 */
  sort?: number;
  /** 官方链接，让用户去官网下载 */
  link?: string;
  /** pc 端有自己的逻辑，移动端适用 injected 的规则（一般用在 constant.tsx ，但是移动端需要适配 injected) */
  isMobileInjected?: boolean;
  /** 和 metamask 类互相干扰，无法隔开。类似的都是共用 inject 的 provider，可点击状态也同步 */
  isFollowInject?: boolean;
  /** 当手机没有 injected 环境时显示的钱包 */
  supportMobile?: boolean;
  /** 移动端支持 deeplink 跳转到应用 */
  mobileDeepLink?: string;
  /** 移动端支持 deeplink 跳转到应用(安卓限定) */
  mobileAndroidDeepLink?: string;
  /** 移动端支持 deeplink 跳转到应用(IOS限定) */
  mobileIOSDeepLink?: string;
}
