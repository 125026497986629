import { WalletReducer, WalletState } from '@dodoex/wallet';
import {
  TokenReducer as dodoTokenReducer,
  TokenState as dodoTokenState,
} from '@dodoex/token';
import { combineReducers } from 'redux';
import { marketReducers, marketState } from '@dodoex/core';
import settings, { State as settingsState } from './settings';
import token, { TokenState } from './token';
import system, { State as systemState } from './system';

export interface RootState extends WalletState, dodoTokenState {
  market: marketState;
  settings: settingsState;
  token: TokenState;
  system: systemState;
}

export default () =>
  combineReducers({
    settings,
    token,
    ...WalletReducer,
    ...dodoTokenReducer,
    market: marketReducers,
    system,
  });
