import { GetConfig } from '@dodoex/utils';
import { getCachedContract } from '@dodoex/wallet';
import BigNumber from 'bignumber.js';
import { Contract } from 'web3-eth-contract';
import { PMMState } from '../../route/entities/PMMState';
import { PMMHelper } from '../../route/helper/pmmHelper';
import { padAddress } from '../../route/utils';
import multicallABI from '../ABIs/multicallABI';
import { PmmModelParams, PmmModelParamsBG } from './pmmModel';

export const DEFAULT_MID_PRICE = new BigNumber(0.000001);

export function handleUnitConvert(
  bitStr: string,
  decimals: number,
): {
  src: BigNumber;
  normal: number;
} {
  const result = new BigNumber(`0x${bitStr}`).div(
    new BigNumber(10 ** decimals),
  );
  const src = result.dp(decimals);
  return {
    src,
    normal: src.toNumber(),
  };
}

export type BatchGetMidPriceReturnType = Map<
  string,
  {
    midPrice?: BigNumber;
    pmmParams?: PmmModelParams;
    pmmParamsBG?: PmmModelParamsBG;
  }
>;

/**
 * 这个函数只有 CLASSICAL 池子，才能拿到 lpFeeRate 和 mtFeeRate
 */
export async function batchGetMidPriceAndPmmParams({
  chainId,
  poolList,
  isWalletConnected = true,
  hasDefaultMidPrice = true,
}: {
  chainId: number;
  poolList: {
    address: string;
    innerType: string;
    baseDecimals: number;
    quoteDecimals: number;
  }[];
  isWalletConnected?: boolean;
  hasDefaultMidPrice?: boolean;
}): Promise<BatchGetMidPriceReturnType> {
  const { MULTI_CALL, ROUTE_V1_DATA_FETCH } = GetConfig(chainId);
  // 未连接钱包时会获取不到 web3 实例，getCachedContract 会报错，不能直接调用
  const mappingMidPrice: BatchGetMidPriceReturnType = new Map();
  let multiInstance: Contract | undefined;
  try {
    if (isWalletConnected && MULTI_CALL) {
      multiInstance = await getCachedContract(MULTI_CALL, multicallABI);
    }
  } catch (error) {
    console.error('getCachedContract error', error);
    return mappingMidPrice;
  }
  if (multiInstance === undefined) {
    return mappingMidPrice;
  }
  if (poolList.length > 0) {
    const calls: {
      target: string;
      callData: string | null;
    }[] = [];
    // eslint-disable-next-line prefer-const
    for (let { address, innerType } of poolList) {
      if (
        innerType === 'DVM' ||
        innerType === 'DPP' ||
        innerType === 'LPTOKEN' ||
        innerType === 'DSP'
      ) {
        calls.push({
          target: address,
          // callData: web3.utils.soliditySha3('getPMMStateForCall()') 取前四个字节
          callData: '0xfd1ed7e9',
        });
      } else if (innerType === 'CLASSICAL') {
        calls.push({
          target: ROUTE_V1_DATA_FETCH,
          // callData: web3.utils.soliditySha3('getPairDetail(address)') + padAddress(address), 取前四个字节
          callData: `0x2bd8c5ac${padAddress(address)}`,
        });
      }
    }
    try {
      const { returnData } = await multiInstance.methods
        .aggregate(calls)
        .call();
      if (returnData && Array.isArray(returnData) && returnData.length > 0) {
        if (returnData.length !== poolList.length) {
          return mappingMidPrice;
        }
        for (let index = 0; index < poolList.length; index++) {
          const { address, baseDecimals, quoteDecimals, innerType } =
            poolList[index];
          const item = returnData[index].substring(2);
          let offset = 0;
          if (innerType === 'CLASSICAL') {
            offset = 64 + 64;
          }
          const i = handleUnitConvert(
            item.substring(offset, offset + 64),
            18 - baseDecimals + quoteDecimals,
          ); // 18 - base + quote
          const k = handleUnitConvert(
            item.substring(offset + 64 * 1, offset + 64 * 2),
            18,
          );
          const b = handleUnitConvert(
            item.substring(offset + 64 * 2, offset + 64 * 3),
            baseDecimals,
          );
          const q = handleUnitConvert(
            item.substring(offset + 64 * 3, offset + 64 * 4),
            quoteDecimals,
          );
          const b0 = handleUnitConvert(
            item.substring(offset + 64 * 4, offset + 64 * 5),
            baseDecimals,
          );
          const q0 = handleUnitConvert(
            item.substring(offset + 64 * 5, offset + 64 * 6),
            quoteDecimals,
          );
          // eslint-disable-next-line radix
          const R = parseInt(
            `0x${item.substring(offset + 64 * 6, offset + 64 * 7)}`,
          );
          const pmmParams: PmmModelParams = {
            i: i.normal,
            k: k.normal,
            b: b.normal,
            q: q.normal,
            b0: b0.normal,
            q0: q0.normal,
            R,
          };
          const pmmParamsBG: PmmModelParamsBG = {
            i: i.src,
            k: k.src,
            b: b.src,
            q: q.src,
            b0: b0.src,
            q0: q0.src,
            R,
          };
          if (innerType === 'CLASSICAL') {
            const lpFeeRate = handleUnitConvert(
              item.substring(offset + 64 * 7, offset + 64 * 8),
              18,
            );
            const mtFeeRate = handleUnitConvert(
              item.substring(offset + 64 * 8, offset + 64 * 9),
              18,
            );
            // 用于在添加流动性-选择资金池，显示 classical 池子的滑点系数和手续费率
            pmmParams.lpFeeRate = lpFeeRate.normal;
            pmmParams.mtFeeRate = mtFeeRate.normal;
            pmmParamsBG.lpFeeRate = lpFeeRate.src;
            pmmParamsBG.mtFeeRate = mtFeeRate.src;
          }
          let midPrice = hasDefaultMidPrice
            ? new BigNumber(DEFAULT_MID_PRICE)
            : undefined;
          if (
            (pmmParamsBG.q.isEqualTo(0) && pmmParams.R === 2) ||
            (pmmParamsBG.b.isEqualTo(0) &&
              (pmmParams.R === 1 || pmmParams.R === 0))
          ) {
            //
          } else {
            const pmm = new PMMHelper();
            const pmmState = new PMMState({
              Q: pmmParamsBG.q,
              B: pmmParamsBG.b,
              K: pmmParamsBG.k,
              i: pmmParamsBG.i,
              B0: pmmParamsBG.b0,
              Q0: pmmParamsBG.q0,
              R: pmmParamsBG.R,
              mtFeeRate: new BigNumber(0),
              // TODO 没有考虑手续费的影响
              lpFeeRate: new BigNumber(0),
            });
            midPrice = pmm.GetMidPrice(pmmState);
            if (midPrice.isNaN()) {
              midPrice = DEFAULT_MID_PRICE;
            }
          }
          mappingMidPrice.set(address, {
            midPrice: midPrice
              ? midPrice.dp(baseDecimals, BigNumber.ROUND_FLOOR)
              : undefined,
            pmmParams,
            pmmParamsBG,
          });
        }
      }
    } catch (error) {
      console.error('v2 error useBatchGetMidPrice', error);
    }
  }
  return mappingMidPrice;
}
