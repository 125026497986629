import { gql } from '@apollo/client';

export const fetchPoolList = gql`
  query FetchPoolList($first: Int, $where: Pair_filter) {
    pairs(
      first: $first
      where: $where
      orderBy: createdAtTimestamp
      orderDirection: desc
    ) {
      id
      type
      creator
      lpFeeRate
      i
      k
      baseReserve
      quoteReserve
      createdAtTimestamp
      lastTradePrice
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
    }
  }
`;

export const fetchMiningPoolList = gql`
  query FetchMiningPoolList($first: Int, $where: Pair_filter) {
    pairs(first: $first, where: $where) {
      id
      type
      baseToken {
        id
        symbol
        name
        decimals
      }
      quoteToken {
        id
        symbol
        name
        decimals
      }
      baseLpToken {
        id
        symbol
        name
        decimals
      }
      quoteLpToken {
        id
        symbol
        name
        decimals
      }
    }
  }
`;

/**
 * 查询合约方自主创建的挖矿池子，以及奖励数据
 */
export const fetchMinePools = gql`
  query FetchMinePools(
    $first: Int
    $skip: Int
    $where: MinePool_filter
    $orderBy: MinePool_orderBy
  ) {
    minePools(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      orderDirection: desc
    ) {
      id
      creator
      pool
      isLpToken
      platform
      stakeToken
      rewardDetail {
        id
        token
        rewardPerBlock
        startBlock
        endBlock
      }
    }
  }
`;

/**
 * 查询合约方自主创建的挖矿池子，以及奖励数据
 * 聚合查询 stakeToken_in 和 pool_in 的结果
 */
export const fetchAggregateMinePools = gql`
  fragment mingPoolsDetail on MinePool {
    id
    creator
    pool
    isLpToken
    platform
    stakeToken
    rewardDetail {
      id
      token
      rewardPerBlock
      startBlock
      endBlock
    }
  }
  query FetchAggregateMinePools(
    $first: Int
    $skip: Int
    $stakeTokenInWhere: MinePool_filter
    $poolInWhere: MinePool_filter
    $orderBy: MinePool_orderBy
  ) {
    stakeTokenIn: minePools(
      first: $first
      skip: $skip
      where: $stakeTokenInWhere
      orderBy: $orderBy
      orderDirection: desc
    ) {
      ...mingPoolsDetail
    }
    poolIn: minePools(
      first: $first
      skip: $skip
      where: $poolInWhere
      orderBy: $orderBy
      orderDirection: desc
    ) {
      ...mingPoolsDetail
    }
  }
`;

/**
 * 查询用户在合约方自主创建挖矿池子的存入数据
 */
export const fetchUserStakes = gql`
  query FetchUserStakes(
    $first: Int
    $skip: Int
    $where: UserStake_filter
    $orderBy: UserStake_orderBy
  ) {
    userStakes(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      orderDirection: desc
    ) {
      id
      user
      pool
      balance
    }
  }
`;
