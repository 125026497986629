import { BSCToken, SDKToken } from '../type';
import { DexKey } from './dexConfig';

export enum ThegraphKeyMap {
  bsc = 'bsc',
  eth = 'ethereum-mainnet',
  ethereum = 'ethereum',
  rinkeby = 'rinkeby',
  heco = 'heco',
  polygon = 'polygon',
  arbitrum = 'arbitrum',
  arbRinkeby = 'arb-rinkeby',
  okchain = 'okchain',
  boba = 'boba',
  bobaRinkeby = 'boba-rinkeby',
  optimism = 'optimism',
  optimismKovan = 'optimism-kovan',
  moonriver = 'moonriver',
  aurora = 'aurora',
  avalanche = 'avalanche',
  gor = 'gor',
  kcs = 'kcs',
}

export const ThegraphKeyChainIdMap = {
  [ThegraphKeyMap.bsc]: 56,
  [ThegraphKeyMap.eth]: 1,
  [ThegraphKeyMap.ethereum]: 1,
  [ThegraphKeyMap.rinkeby]: 4,
  [ThegraphKeyMap.gor]: 5,
  [ThegraphKeyMap.heco]: 128,
  [ThegraphKeyMap.polygon]: 137,
  [ThegraphKeyMap.arbitrum]: 42161,
  [ThegraphKeyMap.arbRinkeby]: 421611,
  [ThegraphKeyMap.okchain]: 66,
  [ThegraphKeyMap.boba]: 288,
  // [ThegraphKeyMap.bobaRinkeby]: 288,
  [ThegraphKeyMap.optimism]: 10,
  // [ThegraphKeyMap.optimismKovan]: 10,
  [ThegraphKeyMap.moonriver]: 1285,
  [ThegraphKeyMap.aurora]: 1313161554,
  [ThegraphKeyMap.avalanche]: 43114,
  [ThegraphKeyMap.kcs]: 321,
};

/**
 *
 * @see https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
 */
interface AddChainParameter {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

// Ethereum 链或这是测试链钱包都会默认支持，只需要切换，不需要其他参数
interface AddEthereumChainParameter {
  // Ethereum | Rinkeby | Goerli | optimistic kovan | boba rinkeby | 97 |
  chainId: '0x1' | '0x4' | '0x5' | '0x45' | '0x1c' | '0x61';
}

/**
 * 链配置
 * @see https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-421611.json
 */
export interface Chain {
  /** 是否启用对这条链的支持 */
  enable: boolean;
  name?: string;
  chainId: number;
  /**
   * @deprecated
   */
  chain?: string;
  /** for price-api https://www.notion.so/dodotopia/7e47296da3e54a158e2af775f15d22ef network */
  platformId: string;
  /** network 简称 */
  network?:
    | 'ETH'
    | 'BSC'
    | 'bsc-testnet'
    | 'Heco'
    | 'Polygon'
    | 'Arbitrum'
    | 'arb-rinkeby'
    | 'Rinkeby'
    | 'OKChain'
    | 'Optimism'
    | 'Goerli';
  networkId?: number;
  /** online-config 对应的 url */
  configUrl: string;
  /** online-nft-config 对应的 url */
  nftConfigUrl?: string;

  /* subgraph where.chain 对应值 */
  thegraphKey: ThegraphKeyMap;

  /// Used for fetching circulation.
  cirAddress?: string;

  fromTokenInit?: SDKToken | BSCToken;
  toTokenInit?: SDKToken | BSCToken;
  /** etherscan.io or bscscan.com website url domain */
  scanUrlDomain: string;

  /** 出块时间：单位是 秒; layer2 使用 主网 ETH 出块时间，主要用于计算挖矿相关数据 */
  blockTime: number;

  /**
   * --------- features ---------
   * @see https://www.notion.so/dodotopia/feature-410be668941d47cdbc67b3e730daed6b
   */
  /** 是否支持 bsc 交易挖矿 */
  bscTradeMiningEnable?: boolean;
  /** 是否支持 vDODO */
  vDODOEnable?: boolean;
  /** 是否支持预挖矿 */
  preminingEnable?: boolean;
  /** 是否支持 dsp 池子 */
  dspPoolEnable?: boolean;
  /** 询价目标 */
  providerCandidates: Array<string>;
  /** gasprice 标准 */
  gaspriceStandard: {
    good: 40000000000 | 20000000000;
    great: 30000000000 | 15000000000;
  };
  /** 提交上链结果后对应的区块链浏览器，说明文案的 key */
  viewOnScanKey: string;
  /** gas对应的预估上链时间
   * @deprecated
   */
  gasSelectSpeeds?: {
    rapid: string;
    fast: string;
    medium: string;
  };
  /** 是否启用 nft 功能 */
  nftEnable?: boolean;

  /** limit-order 合约地址 */
  limitOrderContract?: string;
  /** limit-order bot 地址 */
  limitOrderBotAddress?: string;
  /** RFQ 是否启用 */
  rfqEnable?: boolean;
  /** RFQ 合约地址 */
  RFQContract?: string;

  /** dex 平台配置 key，从 dexConfig.ts 获取详情 */
  dexKeys?: DexKey[];

  /**
   * 添加链或切换链到 metamask 的配置
   * @see https://docs.metamask.io/guide/rpc-api.html#usage-with-wallet-switchethereumchain
   */
  addChainParameters: AddChainParameter | AddEthereumChainParameter;

  /** ledger app 名称 */
  ledgerAppName?: string;

  /** 当前链是否为测试链 */
  isTestNet?: boolean;
  /** 测试链对应的正式链 */
  testWhichChain?: number;

  /** 是否启用 v2 路由，具有收费功能 */
  routeV2Enable?: boolean;
}

/**
 * 合约相关的配置
 */
export interface ContractConfigDataI {
  WETH: {
    symbol: string;
    address: string;
    decimals: number;
    name: string;
    source: string;
  };
  DODO_TOKEN: string;

  ERC20_HELPER: string;
  /**
   * contract name: DODOCalleeHelper
   */
  CALLEE_HELPER: string;

  DODO_APPROVE: string;
  DODO_PROXY: string;
  DODO_DSP_PROXY: string;
  DODO_DPP_PROXY: string;
  DODO_V1_PAIR_PROXY?: string;

  ERC20V3_FACTORY: string;

  /**
   * CrowdPooling
   */
  /**
   * contract name: DODOV2Proxy02
   */
  DODO_CP_PROXY_V2: string;
  /**
   * contract name: CrowdPoolingFactory
   */
  CP_FACTORY_V2: string;
  /**
   * contract name: FeeRateDIP3Impl
   */
  FEE_RATE_IMPL: string;

  /**
   * mineV3
   */
  DODO_MINEV3_PROXY: string;

  /**
   * dodoStarter
   */
  DODO_STARTER_PROXY?: string;

  DVM_FACTORY: string;
  DSP_FACTORY: string;

  MULTI_CALL: string;
  /**
   * contract name: DODOV1PmmHelper
   */
  ROUTE_V1_DATA_FETCH: string;
  /**
   * contract name: DODOV2RouteHelper
   */
  ROUTE_V2_DATA_FETCH: string;

  vDODO?: string;
  vDODO_DODO_TEAM?: string;

  // DODONFT
  ROUTE_NFT_DATA_FETCH?: string;
  DODO_NFT_PROXY?: string;
  BUYOUT_MODEL?: string;
  DODO_NFT_REGISTER?: string;
  DODO_NFT_ERC721?: string;
  DODO_NFT_ERC1155?: string;
  DODO_NFTPOOL_PROXY?: string;
  DODO_NFT_APPROVE?: string;
  DODO_DROPS_PROXY?: string;
}
