import { PaletteMode } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/configure-store/reducers';

export function useColorModeFromRedux() {
  const colorMode = useSelector((state: RootState) => state.settings.colorMode);
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const themeVariant = React.useMemo(() => {
    let mode = colorMode;
    if (colorMode === 'system') {
      mode = 'dark';
    }
    return mode as PaletteMode;
  }, [colorMode]);
  return themeVariant;
}
