import {
  Typography,
  Alert as AlertOrigin,
  useTheme,
  AlertColor,
  Collapse,
} from '@mui/material';
import { Box, alpha, BoxProps } from '@mui/system';
import React, { useMemo } from 'react';
import { useDevices, useBodyMovin } from '../hooks';
import Countdown from './Countdown';
import { ReactComponent as CloseIcon } from '../images/dialog-close.svg';
import { ReactComponent as LinkIcon } from '../images/open_link_24dp.svg';
import notifSuccessBodymovinJson from '../images/bodymovin/notif-success.json';
import notifWarningBodymovinJson from '../images/bodymovin/notif-warning.json';
import notifErrorBodymovinJson from '../images/bodymovin/notif-error.json';

export enum AlertVariant {
  success = 1,
  error,
  warn,
}

export function Alert({
  on,
  onClose,
  timeout,
  type,
  msg,
  hint,
  link,
  sx,
}: {
  on: boolean;
  onClose: () => void;
  timeout?: number;
  type: AlertVariant;
  msg: string | React.ReactNode;
  hint?: string | JSX.Element;
  link?: {
    text: string;
    onClick: () => void;
  };
  sx?: BoxProps['sx'];
}) {
  const theme = useTheme();
  const { isMobile } = useDevices();

  const typeSeverityMap: { [key in AlertVariant]: AlertColor } = {
    [AlertVariant.success]: 'success',
    [AlertVariant.error]: 'error',
    [AlertVariant.warn]: 'warning',
  };
  const bodymovinJsonMap = {
    [AlertVariant.success]: notifSuccessBodymovinJson,
    [AlertVariant.warn]: notifWarningBodymovinJson,
    [AlertVariant.error]: notifErrorBodymovinJson,
  };
  const { ref, destroy } = useBodyMovin({
    name: 'notification-animation',
    BodymovinJson: bodymovinJsonMap[type],
  });

  const hintElement = useMemo(() => {
    if (typeof hint === 'string') {
      return (
        <Typography
          variant="body2"
          sx={{
            mt: 4,
            color: 'text.secondary',
            wordBreak: 'break-all',
          }}
        >
          {hint}
        </Typography>
      );
    }

    if (React.isValidElement(hint)) {
      return hint;
    }

    return null;
  }, [hint]);

  return (
    <Collapse in={on}>
      <AlertOrigin
        onClose={() => {
          destroy();
          onClose();
        }}
        severity={typeSeverityMap[type]}
        action={
          <Box
            sx={{
              p: 6,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: 24,
              height: 24,
              borderRadius: 12,
              borderStyle: 'solid',
              borderWidth: timeout ? '2.4px' : 0,
              borderColor: theme.palette.custom.background.disabled,
              backgroundColor: timeout
                ? 'transparent'
                : theme.palette.custom.background.disabled,
              cursor: 'pointer',
              '& > svg.close-icon': {
                width: 10,
                height: 10,
                '& path:last-child': {
                  stroke: theme.palette.custom.text.weak,
                  strokeWidth: 3,
                },
              },
            }}
            onClick={onClose}
          >
            {timeout ? (
              <Countdown
                size={24}
                strokeWidth="2.4px"
                time={`${timeout}ms`}
                color={theme.palette.custom.text.weak}
              />
            ) : (
              ''
            )}
            <CloseIcon className="close-icon" />
          </Box>
        }
        sx={{
          position: 'relative',
          py: 22,
          pl: 66,
          pr: 44,
          borderRadius: 8,
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.custom.border.default
              : theme.palette.background.paper,
          alignItems: 'center',
          boxShadow: `0px 1px 8px ${alpha('#000', 0.1)}`,
          '&.MuiPaper-root': {
            padding: 20,
            pr: 115,
            pb: 50,
            width: isMobile ? '90vw' : 343,
          },
          '& .MuiAlert-action': {
            mr: 0,
            p: 0,
            position: 'absolute',
            top: 13,
            right: 13,
          },
          '& .MuiAlert-icon': {
            display: 'none',
          },
          '& .MuiAlert-message': {
            p: 0,
          },
          ...sx,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            wordBreak: 'break-all',
            color: 'text.primary',
          }}
        >
          {msg}
        </Typography>
        {link ? (
          <Box
            onClick={link.onClick}
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              color: 'text.secondary',
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
              '& > svg': {
                ml: 6,
                width: 16,
                height: 16,
                '& path:last-child': {
                  fill: theme.palette.text.secondary,
                },
              },
            }}
          >
            {link.text}
            <LinkIcon />
          </Box>
        ) : (
          ''
        )}
        {hintElement}
        <Box
          ref={ref}
          sx={{
            height: 72,
            // IOS 端 metamask 不设置宽度图片会居中显示
            width: 98,
            position: 'absolute',
            bottom: 0,
            right: 12,
          }}
        />
      </AlertOrigin>
    </Collapse>
  );
}

Alert.Variant = AlertVariant;
export default Alert;
