import { ContractConfigDataI } from '../type';

export const MATIC_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WMATIC',
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    decimals: 18,
    name: 'Wrapped MATIC',
    source: 'dodo',
  },
  MULTI_CALL: '0xc9eD9B18e447e600238fe50e944B9062B664DEa4',
  DODO_TOKEN: '0xe4bf2864ebec7b7fdf6eeca9bacae7cdfdaffe78',

  ERC20_HELPER: '0xfd24312Ec7871A6D1a31e454D5AbB16c6c25a9b3',
  ERC20V3_FACTORY: '0x5258Db198f6E39889bfCA6016786AF562Ab8bE91',

  DODO_APPROVE: '0x6D310348d5c12009854DFCf72e0DF9027e8cb4f4',

  DODO_PROXY: '0xa222e6a71D1A1Dd5F279805fbe38d5329C1d0e70',
  DODO_DSP_PROXY: '0xA3dfF6ae0F73f0970E31Da63B3736F7D3CEF683e',
  DODO_DPP_PROXY: '0xF6f1A1Ef2f5b56bb289993F75C12Eb41e4abC2f7',
  DODO_V1_PAIR_PROXY: '0x58E27C46c162F2a781d750BbE1119E1b2DfE82e0',
  DODO_CP_PROXY_V2: '0x6bD3747577d164159dc34b85C3c960aDDC8138fa',
  DODO_MINEV3_PROXY: '0x47a65e74dd6b6B5E3243dBb01EDEd9D55ba234Ad',
  DODO_STARTER_PROXY: '0xCBB0d6f3f6364EF368bC1c0b8f4F265e918dfB56',

  DVM_FACTORY: '0x79887f65f83bdf15Bcc8736b5e5BcDB48fb8fE13',
  DSP_FACTORY: '0x43C49f8DD240e1545F147211Ec9f917376Ac1e87',
  CP_FACTORY_V2: '0x29E7085Fda4A8492FBb16E840488B514149d51f6',

  ROUTE_V2_DATA_FETCH: '0x1870854754d1E76C58B0a56a153E12Ff868eE3C6', // "0xF077f9fB6a48d8722247C544c0C66AE0D7505d66",
  // ROUTE_V2_DATA_FETCH: "0x494C11BaC7699C1c780E2fFeeFc443B6B62bc35C",//cuttingHelper
  ROUTE_V1_DATA_FETCH: '0x18DFdE99F578A0735410797e949E8D3e2AFCB9D2',
  CALLEE_HELPER: '0x261F6cF4dF0e5c1432739cDAFD9299150FEd3dFc',
  FEE_RATE_IMPL: '0x6292e8f7647b3b9dDf5795b1Fb77D0187e30E0F9', // 0xD19d61DfE9e1485413291Cf5FBFC704c91AD54F5

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0x19309bB932df8C07DbCa8dF93ad4FCc419271e16',
  DODO_NFT_PROXY: '0xCb0A88A465Da7aB09C09B418F27bFf159A0FE09b',
  BUYOUT_MODEL: '0x5cE3aA21432F77945328c4847eC0a0C164336045',
  DODO_NFT_REGISTER: '0x840135913a2527C3481DB29e323E05F301D33210',
  DODO_NFT_ERC721: '0xA1FfE96Ee3e72a9Ec7523E5a289695843D3fd0f4',
  DODO_NFT_ERC1155: '0xFEb1fBcdAAF4475c1AFfeED66395D274e520fD57',

  DODO_NFTPOOL_PROXY: '0x533AF8ad419fB3082df9C80BE2ad903912a817FB',
  DODO_NFT_APPROVE: '0x91E1c84BA8786B1FaE2570202F0126C0b88F6Ec7',
};

export const MATIC_SWAPINFO = [
  {
    name: 'QuickSwap',
    factoryAddress: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
    initHash:
      '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f',
  },
  {
    name: 'SushiSwap',
    factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    initHash:
      '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  },
];
