/* eslint-disable import/no-mutable-exports */
import { IDLE_FREE_TIME } from '@dodoex/utils';
import { TokenList as UniswapTokenList } from '@uniswap/token-lists';
import BigNumber from 'bignumber.js';
import { fromJS, Map, OrderedMap } from 'immutable';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, AnyAction, CombinedState, Store } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  setBalanceLoadings,
  setCustomTokens,
  setEthBalance,
  setFullTokens,
  setTokens,
} from './configure-store/actions';
import { State } from './configure-store/reducer';
import {
  loadTokenListTokens,
  ThirdTokenListKeys,
} from './hooks/useManageTokenList';
import { PERSISTENT_TOKEN_LIST_KEY } from './localstorage';
import { Token, TokenResult, Tokens } from './types';
import { useFirstLoadTokenListBalance } from './hooks/useFirstLoadTokenListBalance';
import { useTimedRefreshTokensBalance } from './hooks/useTimedRefreshTokensBalance';
import { getEthBalance } from './configure-store';

export type StoreType = Store<CombinedState<{ dodoToken: State }>, AnyAction>;
type FetchTokensByAddress = (
  addresses: string[],
  chainId?: number,
  account?: string | undefined,
  spender?: string,
  forced?: boolean | undefined,
) => Promise<TokenResult[]>;

type QueryThirdPartyTokenList = (params: {
  chainId: number;
  fromNames: ThirdTokenListKeys[];
}) => Promise<{
  [key in ThirdTokenListKeys]?: UniswapTokenList;
}>;

export let store: StoreType;
export let sentry: any;
export let mixpanel: any;
export let fetchTokensByAddress: FetchTokensByAddress | undefined;
export let queryThirdPartyTokenList: QueryThirdPartyTokenList | undefined;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppThunkAction<Ret = void> = ThunkAction<
  Ret,
  RootState,
  unknown,
  Action<string>
>;
export type AppThunkDispatch = ThunkDispatch<
  RootState,
  unknown,
  Action<string>
>;

/**
 * 所有参数都是可选的，如果不传，则不会执行对应逻辑
 * 如果和 wallet 没关联，只需要前 5 个参数
 */
export interface InitTokenParams {
  projectStore: Store;
  projectSentry?: any;
  projectMixpanel?: any;
  /* dodo 维护当前链的 tokens */
  tokens?: Tokens;
  /* dodo 维护所有链的 tokens */
  fullTokens?: Token[];
  /** 为 ture 则加载 token list，同时还必须传入 chainId */
  loadTokenList?: boolean;
  chainId?: number;
  account?: string;
  /** 传入才会去链上查 token 信息，用于校验或获取 余额及授权状态 */
  fetchTokensByAddress?: FetchTokensByAddress;
  /** basic token 比较特殊，需要单独获取 */
  loadEthBalance?: (account: string) => Promise<BigNumber>;
  /** 如果有传入，则根据此来判断刷新间隔 */
  blockNumber?: BigNumber;
  /** 因为 CMC 跨域错误，所以通过中转获取。如果需要用到 tokenlist, 就需要传这个方法 */
  queryThirdPartyTokenList?: QueryThirdPartyTokenList;
}
export const useInitToken = ({
  projectStore,
  projectSentry,
  projectMixpanel,
  loadTokenList,
  chainId,
  account,
  tokens,
  fullTokens,
  fetchTokensByAddress: fetchTokensByAddressProps,
  loadEthBalance,
  blockNumber,
  queryThirdPartyTokenList: queryThirdPartyTokenListProps,
}: InitTokenParams) => {
  const dispatch = useDispatch<AppThunkDispatch>();

  queryThirdPartyTokenList = queryThirdPartyTokenListProps;

  if (!store) {
    store = projectStore;
  }
  if (!sentry) {
    sentry = projectSentry;
  }
  if (!mixpanel) {
    mixpanel = projectMixpanel;
  }

  // 初始化，避免切换钱包账户后，留着上一个账户的数据
  useEffect(() => {
    dispatch(setBalanceLoadings(Map()));
  }, [account, chainId]);

  if (fetchTokensByAddressProps) {
    fetchTokensByAddress = (
      addresses,
      currentChainId,
      currentAccount,
      spender,
      forced,
    ) => {
      return fetchTokensByAddressProps(
        addresses,
        currentChainId,
        currentAccount || account,
        spender,
        forced,
      );
    };
  }
  useFirstLoadTokenListBalance({
    account,
    chainId,
    tokens,
  });
  useTimedRefreshTokensBalance({
    account,
    chainId,
    blockNumber,
  });
  // eth 特殊处理
  useEffect(() => {
    const computed = async () => {
      try {
        if (!account || !loadEthBalance) return;
        const ethBalance = await loadEthBalance(account);
        if (projectMixpanel) {
          const accountBalances = projectStore
            .getState()
            .dodoToken.get('accountBalances');
          const prevEthBalance = accountBalances
            .get(account)
            ?.get('ethBalance');
          if (prevEthBalance?.toString() !== ethBalance.toString()) {
            projectMixpanel.people.set({ ETH: ethBalance.toNumber() });
          }
        }
        const oldEthBalance = getEthBalance(account);
        // 有更新才修改，避免触发不必要的更新
        if (!oldEthBalance || !oldEthBalance.isEqualTo(ethBalance)) {
          store.dispatch(setEthBalance(account, ethBalance));
        }
      } catch (e) {
        console.error(e);
      }
    };
    computed();
  }, [account, chainId, blockNumber]);

  useEffect(() => {
    const customsStr =
      window.localStorage.getItem(`${PERSISTENT_TOKEN_LIST_KEY}.${chainId}`) ??
      '[]';
    const customs = JSON.parse(customsStr);
    let tokensMap: OrderedMap<string, Token> = OrderedMap();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customs.forEach((token: any) => {
      tokensMap = tokensMap.set(token.address, fromJS(token) as Token);
    });
    dispatch(setCustomTokens(tokensMap));
  }, [chainId, dispatch]);

  useEffect(() => {
    if (loadTokenList && !chainId) {
      console.error(
        '[useInitToken] When loadTokenList is true, chainId is required.',
      );
      return;
    }
    if (!loadTokenList || !chainId) return;

    // Load tokenList on init
    // 查询第三方 TokenList 列表，可以在空闲时间查询
    function cb(deadline: IdleDeadline) {
      //  一帧还有的空闲时间 > IDLE_FREE_TIME
      if (chainId && deadline.timeRemaining() > IDLE_FREE_TIME) {
        dispatch(loadTokenListTokens(chainId));
        return;
      }
      requestIdleCallback(cb);
    }
    requestIdleCallback(cb);
  }, [loadTokenList, chainId, dispatch]);

  useEffect(() => {
    if (tokens?.size) {
      dispatch(setTokens(tokens));
    }
  }, [tokens, dispatch]);
  useEffect(() => {
    if (fullTokens) {
      dispatch(setFullTokens(fullTokens));
    }
  }, [fullTokens, dispatch]);
};
