import ArbitrumBCLogo from '../../components/images/networks-logo/arbitrum-bc.svg';
import ArbitrumLogo from '../../components/images/networks-logo/arbitrum.svg';
import AuroraBCLogo from '../../components/images/networks-logo/aurora-bc.svg';
import AuroraLogo from '../../components/images/networks-logo/aurora.svg';
import AvalancheBCLogo from '../../components/images/networks-logo/avalanche-bc.svg';
import AvalancheLogo from '../../components/images/networks-logo/avalanche.svg';
import BobaBCLogo from '../../components/images/networks-logo/boba-bc.svg';
import BobaLogo from '../../components/images/networks-logo/boba.svg';
import BSCBCLogo from '../../components/images/networks-logo/bsc-bc.svg';
import BSCLogo from '../../components/images/networks-logo/bsc.svg';
import ETHBCLogo from '../../components/images/networks-logo/eth-bc.svg';
import ETHLogo from '../../components/images/networks-logo/eth.png';
import HecoBCLogo from '../../components/images/networks-logo/heco-bc.svg';
import HecoLogo from '../../components/images/networks-logo/heco.svg';
import KCSLogo from '../../components/images/networks-logo/kcs.png';
// import KCSBCLogo from '../../components/images/networks-logo/kcs-bc.svg';
import MaticBCLogo from '../../components/images/networks-logo/matic-bc.svg';
import MaticLogo from '../../components/images/networks-logo/matic.svg';
import MoonriverBCLogo from '../../components/images/networks-logo/moonriver-bc.svg';
import MoonriverLogo from '../../components/images/networks-logo/moonriver.png';
import OKChainBCLogo from '../../components/images/networks-logo/okchain-bc.svg';
import OKChainLogo from '../../components/images/networks-logo/okchain.svg';
import OptimismBCLogo from '../../components/images/networks-logo/optimism-bc.svg';
import OptimismLogo from '../../components/images/networks-logo/optimism.svg';
import PolygonLogo from '../../components/images/networks-logo/polygon.svg';
import { Chain, ChainType } from './types';

export const ETH: Chain = {
  type: ChainType.ETH,
  name: 'ETH',
  showName: 'Ethereum',
  chainIds: [1, 4, 5],
  logo: ETHLogo,
  background: {
    backgroundImage: `url(${ETHBCLogo})`,
  },
  matchChainList: [
    {
      chainId: 4,
      showName: 'Rinkeby',
    },
    {
      chainId: 5,
      showName: 'Goerli',
    },
  ],
  color: '#708EFF',
  sort: 1,
};

export const BSC: Chain = {
  type: ChainType.BSC,
  name: 'BSC',
  showName: 'BNBChain',
  chainIds: [56, 86, 97],
  logo: BSCLogo,
  background: {
    backgroundImage: `url(${BSCBCLogo})`,
    backgroundPosition: 'right 16% top 0',
  },
  color: '#FFD975',
  sort: 4,
};

export const Heco: Chain = {
  type: ChainType.Heco,
  name: 'Heco',
  showName: 'Heco',
  chainIds: [128],
  logo: HecoLogo,
  background: {
    backgroundImage: `url(${HecoBCLogo})`,
  },
  color: '#C1EB8F',
  sort: 11,
};

export const Polygon: Chain = {
  type: ChainType.Polygon,
  name: 'Polygon',
  showName: 'Polygon',
  chainIds: [137],
  currencyLogo: MaticLogo,
  logo: PolygonLogo,
  background: {
    backgroundImage: `url(${MaticBCLogo})`,
    backgroundPosition: 'right 14% top 0',
  },
  color: '#BC9CFF',
  sort: 3,
};

export const Arbitrum: Chain = {
  type: ChainType.Arbitrum,
  name: 'Arbitrum',
  showName: 'Arbitrum',
  chainIds: [42161, 421611],
  logo: ArbitrumLogo,
  background: {
    backgroundImage: `url(${ArbitrumBCLogo})`,
  },
  color: '#708EFF',
  sort: 2,
  matchChainList: [
    {
      chainId: 421611,
      showName: 'Arbitrum Rinkeby',
    },
  ],
};

// TODO: 测试链，方便测试期间从前端添加一条新链
export const ArbRinkeby: Chain = {
  type: ChainType.ArbRinkeby,
  name: 'Arbitrum Rinkeby',
  showName: 'Arbitrum Rinkeby',
  chainIds: [421611],
  logo: ArbitrumLogo,
  background: {
    backgroundImage: `url(${ArbitrumBCLogo})`,
  },
  color: '#708EFF',
  sort: 6,
};

export const Moonriver: Chain = {
  type: ChainType.Moonriver,
  name: 'Moonriver',
  showName: 'Moonriver',
  chainIds: [1285],
  logo: MoonriverLogo,
  background: {
    backgroundImage: `url(${MoonriverBCLogo})`,
  },
  color: '#FFD975',
  sort: 9,
};

export const OKChain: Chain = {
  type: ChainType.OKChain,
  name: 'OKChain',
  showName: 'OKC',
  chainIds: [66],
  logo: OKChainLogo,
  background: {
    backgroundImage: `url(${OKChainBCLogo})`,
    backgroundPosition: 'right 16% top 0',
  },
  color: '#708EFF',
  sort: 8,
};

export const Boba: Chain = {
  type: ChainType.Boba,
  name: 'BOBA',
  showName: 'BOBA',
  chainIds: [288],
  logo: BobaLogo,
  background: {
    backgroundImage: `url(${BobaBCLogo})`,
  },
  color: '#C1EB8F',
  sort: 10,
};

export const Optimism: Chain = {
  type: ChainType.Optimism,
  name: 'Optimism',
  showName: 'Optimism',
  chainIds: [10],
  logo: OptimismLogo,
  background: {
    backgroundImage: `url(${OptimismBCLogo})`,
  },
  color: '#FF9692',
  sort: 5,
};

export const Aurora: Chain = {
  type: ChainType.Aurora,
  name: 'Aurora',
  showName: 'Aurora',
  chainIds: [1313161554],
  logo: AuroraLogo,
  background: {
    backgroundImage: `url(${AuroraBCLogo})`,
  },
  color: '#C1EB8F',
  sort: 7,
};

export const Avalanche: Chain = {
  type: ChainType.Avalanche,
  name: 'Avalanche',
  showName: 'Avalanche',
  chainIds: [43114],
  logo: AvalancheLogo,
  background: {
    backgroundImage: `url(${AvalancheBCLogo})`,
  },
  color: '#FF9692',
  sort: 6,
};

export const KCS: Chain = {
  type: ChainType.kcs,
  name: 'KCC Mainnet',
  showName: 'KCC',
  chainIds: [321],
  logo: KCSLogo,
  background: {
    backgroundImage: `url(${null})`,
  },
  color: '#FF9692',
  sort: 13,
};
