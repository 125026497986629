import { usePrevious } from '@dodoex/utils';
import {
  getCurrentAccount,
  getCurrentChainId,
  Submission,
} from '@dodoex/wallet';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { newCrowdPoolingV2SDK } from '../../sdk';
import { CrowdpoolingDetail } from './types';

const { State, useSubmission, OpCode } = Submission;

interface Props {
  cp?: CrowdpoolingDetail;
  callback?: () => void;
}

export const useHandleSettle = ({ cp, callback }: Props) => {
  const chainId = useSelector(getCurrentChainId);
  const sdk = useMemo(() => newCrowdPoolingV2SDK(chainId), [chainId]);
  const account = useSelector(getCurrentAccount);
  const [loading, setLoading] = useState<boolean>(false);
  const { execute, requests } = useSubmission();
  const currentSettleCPCount = useMemo<number>(() => {
    let countRunning = 0;
    const reqArr = requests.toArray().reverse();
    for (const [req, state] of reqArr) {
      if (
        req.metadata &&
        req.metadata['settle-cp'] &&
        state === State.Running
      ) {
        countRunning++;
      }
    }
    return countRunning;
  }, [requests]);
  const prevSettleCPCount = usePrevious<number>(currentSettleCPCount);
  useEffect(() => {
    if (
      prevSettleCPCount &&
      prevSettleCPCount > 0 &&
      prevSettleCPCount > currentSettleCPCount
    ) {
      if (callback) callback();
    }
  }, [callback, currentSettleCPCount, prevSettleCPCount]);

  const handleSettle = useCallback(async () => {
    if (!cp || !account) return;
    setLoading(true);
    try {
      const params = await sdk.SettleCPPool(cp.id);
      if (params) {
        await execute(
          'cp.detail.settle.title',
          {
            opcode: OpCode.TX,
            value: 0,
            ...params,
          },
          undefined,
          true,
          {
            'settle-cp': '1',
          },
        );
      }
    } finally {
      if (callback) callback();
      setLoading(false);
    }
  }, [cp, account, sdk, execute, callback]);

  return { handleSettle, loading };
};
