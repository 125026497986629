import moment from 'moment';
import { useEffect, useState } from 'react';
import { useRootQuery } from '../apollo';
import { fetchGetAnnouncement } from './data/queries';
import { FetchGetAnnouncement } from './data/__generated__/FetchGetAnnouncement';

export enum AnnouncementType {
  Notification = 1,
  Alert,
}

interface AnnouncementList {
  lastPublishTime: string;
  list: {
    id: number;
    url: string;
    title: string;
    lastPublishTime: string;
    type: AnnouncementType;
    background: string;
    icon: string;
  }[];
}

const defaultResult: AnnouncementList = {
  list: [],
  lastPublishTime: '',
};

export default function useGetAnnouncement({
  lang,
  lastPublishTime,
}: {
  lang: string;
  lastPublishTime?: string;
}) {
  const [announcementList, setAnnouncementList] = useState(defaultResult);
  const { data } = useRootQuery<FetchGetAnnouncement>(
    fetchGetAnnouncement,
    {
      variables: {
        lang,
      },
    },
    undefined,
    undefined,
    true,
  );

  useEffect(() => {
    if (data?.announcement_getAnnouncement) {
      const { announcement_getAnnouncement } = data;
      if (lastPublishTime && announcement_getAnnouncement.lastPublishTime) {
        if (
          new Date(lastPublishTime).getTime() -
            new Date(announcement_getAnnouncement.lastPublishTime).getTime() >=
          0
        ) {
          setAnnouncementList(defaultResult);
          return;
        }
      }
      setAnnouncementList({
        lastPublishTime: announcement_getAnnouncement.lastPublishTime || '',
        list:
          announcement_getAnnouncement.list?.map((item) => ({
            id: item?.id || 0,
            url: item?.url || '',
            title: item?.title || '',
            lastPublishTime: item?.lastPublishTime
              ? moment(item?.lastPublishTime).format('MM/DD')
              : '',
            type: item?.type as AnnouncementType,
            background: item?.metadata?.background || '',
            icon: item?.metadata?.icon || '',
          })) || [],
      });
    }
  }, [data, lastPublishTime]);

  return announcementList;
}
