/* eslint-disable no-param-reassign */
import {
  formatShortNumber,
  formatUnknownTokenSymbol,
  getChain,
  isAddr,
} from '@dodoex/utils';
import {
  getCachedContract,
  getCurrentChainId,
  getNetworkNameWithChainId,
  runAllV2,
} from '@dodoex/wallet';
import BigNumber from 'bignumber.js';
import { flatten, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRootQuery } from '..';
import { GetConfig } from '../../route/data/dataAdapter';
import vdodoTokenABI from '../ABIs/vdodoTokenABI';
import {
  fetchAllCPList,
  fetchBidPosition,
  fetchCPCountData,
  fetchCPList,
  searchCP,
} from './data/queries';
import { FetchAllCPList } from './data/__generated__/FetchAllCPList';
import {
  FetchBidPosition,
  FetchBidPositionVariables,
  FetchBidPosition_bidPositions,
} from './data/__generated__/FetchBidPosition';
import { FetchCPCountData } from './data/__generated__/FetchCPCountData';
import {
  FetchCPList,
  FetchCPListVariables,
  FetchCPList_crowdPoolings,
} from './data/__generated__/FetchCPList';
import {
  FetchIOPCPList,
  FetchIOPCPListVariables,
} from './data/__generated__/FetchIOPCPList';
import { SearchCP, SearchCPVariables } from './data/__generated__/SearchCP';
import { CP_OP_RANK, CP_STATUS, Crowdpooling } from './types';
import { getCPHardcapPrice, getRealTimePrice } from './useCPContractStatus';
import { getStatusWithTimes } from './utils';
import { useIOPCrowdpoolings } from './useIOPCrowdpoolings';

const StatusIndex = {
  [CP_STATUS.PROCESSING]: 0,
  [CP_STATUS.WAITING]: 1,
  [CP_STATUS.SETTLING]: 2,
  [CP_STATUS.ENDED]: 3,
};

export const sortCPList = (cpList: Crowdpooling[]) => {
  return cpList
    .sort((a, b) => (b.bidStartTime < a.bidStartTime ? -1 : 1)) // 时间倒序
    .sort((a, b) => (StatusIndex[a.status] > StatusIndex[b.status] ? 1 : -1)); // 状态排序
};

const formatCP = ({
  crowdpoolings,
  bidPositions,
  iopCPList,
}: {
  crowdpoolings: FetchCPList_crowdPoolings[];
  bidPositions?: FetchBidPosition_bidPositions[];
  iopCPList?: any;
}) => {
  const cpWhiteList = iopCPList || [];
  const formatedCpList = crowdpoolings.map((cp) => {
    const bidPosition =
      bidPositions && bidPositions.find((bp) => bp.cp.id === cp.id);
    const freezeDuration = Number(cp.freezeDuration) * 1000;
    const bidStartTime = Number(cp.bidStartTime) * 1000;
    const bidEndTime = Number(cp.bidEndTime) * 1000;
    const calmEndTime = Number(cp.calmEndTime) * 1000;
    const cpInWhiteList = cpWhiteList?.find(
      (iopCp: any) => iopCp?.address === cp.id,
    );
    const k = new BigNumber(cp.k).div(1e18);
    const isEscalation = !k.eq(0);
    const initPrice = new BigNumber(cp.i).div(
      new BigNumber(10).pow(
        new BigNumber(18)
          .minus(cp.baseToken.decimals)
          .plus(cp.quoteToken.decimals),
      ),
    );
    const formatedCP: Crowdpooling = {
      ...cp,
      initPrice,
      i: initPrice,
      price: initPrice,
      baseToken: {
        ...cp.baseToken,
        address: cp.baseToken.id,
        symbol: formatUnknownTokenSymbol(cp.baseToken),
        showDecimals: cp.baseToken.decimals > 6 ? 6 : 4,
      },
      k,
      isEscalation,
      salesBase: new BigNumber(cp.poolQuoteCap).div(initPrice),
      quoteToken: {
        ...cp.quoteToken,
        address: cp.quoteToken.id,
        symbol: formatUnknownTokenSymbol(cp.quoteToken),
        showDecimals: cp.quoteToken.decimals > 6 ? 6 : 4,
      },
      status: getStatusWithTimes(
        bidStartTime,
        bidEndTime,
        calmEndTime,
        cp.settled,
      ),
      progress: Number(
        new BigNumber(cp.poolQuote)
          .div(cp.poolQuoteCap)
          .multipliedBy(100)
          .toFixed(2),
      ),
      bidPosition,
      bidStartTime,
      bidEndTime,
      calmEndTime,
      freezeDuration,
      weight: cpInWhiteList?.weight || 0,
      opRank: cpInWhiteList?.opRank || CP_OP_RANK.Wild,
      personalPercentage: Number(
        new BigNumber(bidPosition?.investedQuote ?? 0)
          .div(cp.poolQuoteCap)
          .multipliedBy(100)
          .toFixed(2),
      ),
    };
    if (formatedCP.isEscalation) {
      formatedCP.price = getRealTimePrice(formatedCP);
      formatedCP.hardcapPrice = getCPHardcapPrice(formatedCP);
      formatedCP.salesBase = new BigNumber(cp.poolQuoteCap)
        .div(formatedCP.hardcapPrice)
        .dp(0);
    }
    return formatedCP;
  });
  return sortCPList(formatedCpList);
};

// C端众筹列表
export const useFetchIOPCPList = () => {
  const [iopCPList, setIopCPList] = useState<any>();
  const chainId = useSelector(getCurrentChainId);
  let networkName = getNetworkNameWithChainId(chainId).toLowerCase();
  if (networkName === 'bsc-mainnet') networkName = 'bsc';

  const { iopCrowdpoolings, loading, refetch, error } = useIOPCrowdpoolings({
    networkName,
    pollInterval: 15000,
  });

  const refetchIOPCPList = useCallback(async () => {
    const vdodoContractAddress = (GetConfig(chainId) as { vDODO: string })
      .vDODO;
    if (vdodoContractAddress && iopCrowdpoolings) {
      const votedAccounts = uniq(
        flatten(
          iopCrowdpoolings.map((cp: any) =>
            cp?.votes
              ?.map((vote: any) => vote?.creator?.address)
              .filter((o: any) => !!o),
          ),
        ),
      );
      try {
        const contract = await getCachedContract(
          vdodoContractAddress,
          vdodoTokenABI,
        );
        const thunk = votedAccounts.map((account) => {
          return {
            callData: {
              data: contract.methods.balanceOf(account).encodeABI(),
              to: vdodoContractAddress,
            },
            processor: (raw: any) => {
              return {
                account,
                balance: new BigNumber(raw).div(1e18),
              };
            },
          };
        });

        const [result] = await runAllV2<
          { account: unknown; balance: BigNumber }[]
        >(chainId, thunk);
        setIopCPList(
          iopCrowdpoolings.map((cp: any) => {
            let cpWeight = 0;
            const votes = cp?.votes?.map((vote: any) => {
              const weight = result
                .find((r) => r.account === vote?.creator?.address)
                ?.balance.toNumber();
              if (weight) cpWeight += weight;
              return {
                ...vote,
                weight,
              };
            });
            return {
              ...cp,
              votes,
              weight: cpWeight,
            };
          }),
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, [iopCrowdpoolings, chainId]);

  useEffect(() => {
    refetchIOPCPList();
  }, [refetchIOPCPList]);

  return {
    iopCPList,
    refetchIOPCPList: refetch,
    loading,
    error,
  };
};

const useFetchBidPositions = ({
  user,
  cpList,
}: {
  user?: string;
  cpList?: string[];
}) => {
  const where: FetchBidPositionVariables['where'] = {
    user: user?.toLowerCase() ?? '',
  };
  if (cpList) where.cp_in = cpList;
  const { loading, refetch, data, error } = useRootQuery<
    FetchBidPosition,
    FetchBidPositionVariables
  >(fetchBidPosition, {
    variables: { where },
    skip: !user || cpList?.length === 0,
  });
  return {
    loading,
    refetch,
    data,
    error,
  };
};

// B端众筹列表
export const useMyCPList = ({
  user,
}: FetchCPListVariables & { user?: string }) => {
  const [cpList, setCpList] = useState<Crowdpooling[]>([]);
  const { loading, data, refetch, error } = useRootQuery<
    FetchCPList,
    FetchCPListVariables
  >(fetchCPList, {
    variables: {
      first: 1000,
      where: { creator: user?.toLocaleLowerCase() },
    },
    skip: !user,
  });

  useEffect(() => {
    if (data) setCpList(formatCP({ crowdpoolings: data.crowdPoolings }));
  }, [data]);

  return {
    loading,
    cpList,
    refetch,
    error,
  };
};

export function useParticipatedCPList({
  user,
  iopCPList,
}: {
  user?: string;
  iopCPList: any;
}) {
  const [cpList, setCpList] = useState<Crowdpooling[]>([]);
  const [participatedList, setParticipatedList] = useState<string[]>();
  const {
    loading: bidPositionLoading,
    refetch: bidPositionRefetch,
    data: bidPositionData,
    error: bidPositionError,
  } = useFetchBidPositions({
    user,
  });

  const [refetchTimes, setRefetchTimes] = useState<number>(0);

  useEffect(() => {
    if (bidPositionData)
      setParticipatedList(bidPositionData.bidPositions.map((bp) => bp.cp.id));
  }, [bidPositionData]);

  const {
    loading: cpListLoading,
    data: cpListData,
    refetch: cpListRefetch,
    error: cpListError,
  } = useRootQuery<FetchCPList, FetchCPListVariables>(fetchCPList, {
    variables: {
      first: 1000,
      where: { id_in: participatedList || [] },
    },
    skip: !participatedList || participatedList.length === 0,
  });

  const error = useMemo(
    () => bidPositionError || cpListError,
    [bidPositionError, cpListError],
  );

  useEffect(() => {
    if (
      cpListData &&
      cpListData.crowdPoolings &&
      cpListData.crowdPoolings.length > 0
    ) {
      setCpList(
        formatCP({
          crowdpoolings: cpListData.crowdPoolings,
          bidPositions: bidPositionData?.bidPositions,
          iopCPList,
        }),
      );
    }
  }, [cpListData, bidPositionData, iopCPList, refetchTimes]);

  useEffect(() => {
    const timer = setInterval(() => {
      cpListRefetch();
      bidPositionRefetch();
      setRefetchTimes((state) => state + 1);
    }, 15000);
    return () => {
      timer && window.clearTimeout(timer);
    };
  }, [cpListRefetch, bidPositionRefetch]);

  return {
    loading: cpListLoading || bidPositionLoading,
    cpList,
    error,
    refetch: () => {
      cpListRefetch();
      bidPositionRefetch();
    },
  };
}

export const useAllCPList = ({ top }: { top: number }) => {
  const { loading, refetch, data, error } = useRootQuery<FetchAllCPList>(
    fetchAllCPList,
    {
      variables: {
        where: {
          top,
          chain: undefined,
        },
      },
    },
  );

  const list = useMemo(() => data && data.crowd_pooling_top_list, [data]);

  return {
    loading,
    list,
    refetch,
    error,
  };
};

export const useCPCountData = () => {
  const { loading, data, refetch } =
    useRootQuery<FetchCPCountData>(fetchCPCountData);

  const countData = useMemo(() => {
    if (!data || !data.crowd_pooling_count_data)
      return {
        pools: '-',
        fund_rise: '-',
        users: '-',
      };
    const { crowd_pooling_count_data } = data;
    return {
      pools: crowd_pooling_count_data.pools
        ? formatShortNumber(new BigNumber(crowd_pooling_count_data.pools))
        : '-',
      fund_rise: crowd_pooling_count_data.fund_rise
        ? formatShortNumber(new BigNumber(crowd_pooling_count_data.fund_rise))
        : '-',
      users: crowd_pooling_count_data.users
        ? formatShortNumber(new BigNumber(crowd_pooling_count_data.users))
        : '-',
    };
  }, [data]);

  return {
    countData,
    loading,
    refetch,
  };
};

export function useHotCPList({
  user,
  iopCPList,
}: {
  user?: string;
  iopCPList: any[];
}) {
  const [cpList, setCpList] = useState<Crowdpooling[]>([]);
  const [refetchTimes, setRefetchTimes] = useState<number>(0);
  const iopCPIdList = useMemo(
    () =>
      (iopCPList ?? [])
        .filter((cp) => Number(cp.weight) > 5000)
        .map((cp) => cp.address),
    [iopCPList],
  );
  const {
    loading: bidPositionLoading,
    refetch: bidPositionRefetch,
    data: bidPositionData,
  } = useFetchBidPositions({
    user,
    cpList: iopCPIdList,
  });

  const {
    loading: cpListLoading,
    data: cpListData,
    refetch: cpListRefetch,
  } = useRootQuery<FetchCPList, FetchCPListVariables>(fetchCPList, {
    variables: {
      first: 5,
      where: {
        id_in: iopCPIdList,
        bidEndTime_gt: Math.ceil(Date.now() / 1000),
      },
    },
    skip: !iopCPIdList || iopCPIdList.length === 0,
  });

  useEffect(() => {
    if (cpListData) {
      setCpList(
        formatCP({
          crowdpoolings: cpListData.crowdPoolings,
          bidPositions: bidPositionData?.bidPositions,
          iopCPList,
        }).sort((a, b) => Number(b.weight) - Number(a.weight)),
      );
    }
  }, [cpListData, bidPositionData, iopCPList, refetchTimes]);

  useEffect(() => {
    let timer: number;
    const ownRefetch = () => {
      timer = window.setTimeout(() => {
        cpListRefetch();
        bidPositionRefetch();
        ownRefetch();
        setRefetchTimes((state) => state + 1);
      }, 15000);
    };
    ownRefetch();
    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, []);

  return {
    loading: cpListLoading || bidPositionLoading,
    cpList,
    refetch: () => {
      cpListRefetch();
      bidPositionRefetch();
    },
  };
}

export function useFollowedCPList({
  user,
  followed,
  iopCPList,
}: {
  user?: string;
  followed: string[];
  iopCPList: any;
}) {
  const [cpList, setCpList] = useState<Crowdpooling[]>([]);
  const {
    loading: bidPositionLoading,
    refetch: bidPositionRefetch,
    data: bidPositionData,
    error: bidPositionError,
  } = useFetchBidPositions({
    user,
    cpList: followed,
  });
  const [refetchTimes, setRefetchTimes] = useState<number>(0);

  const {
    loading: cpListLoading,
    data: cpListData,
    refetch: cpListRefetch,
    error: cpListError,
  } = useRootQuery<FetchCPList, FetchCPListVariables>(fetchCPList, {
    variables: {
      first: 1000,
      where: {
        id_in: followed,
      },
    },
    skip: !followed || followed.length === 0,
  });

  const error = useMemo(
    () => bidPositionError || cpListError,
    [bidPositionError, cpListError],
  );

  useEffect(() => {
    if (cpListData) {
      setCpList(
        formatCP({
          crowdpoolings: cpListData.crowdPoolings,
          bidPositions: bidPositionData?.bidPositions,
          iopCPList,
        }),
      );
    }
  }, [cpListData, bidPositionData, iopCPList, refetchTimes]);

  useEffect(() => {
    let timer: number;
    const ownRefetch = () => {
      timer = window.setTimeout(() => {
        cpListRefetch();
        bidPositionRefetch();
        ownRefetch();
        setRefetchTimes((state) => state + 1);
      }, 15000);
    };
    ownRefetch();
    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, []);

  return {
    loading:
      followed.length === 0 ? cpListLoading || bidPositionLoading : false,
    cpList,
    error,
    refetch: () => {
      cpListRefetch();
      bidPositionRefetch();
    },
  };
}

interface SearchCPProps {
  filter: string;
  user?: string;
  iopCPList: any;
}

export const useSearchCP = ({ filter, user, iopCPList }: SearchCPProps) => {
  const [cpList, setCpList] = useState<Crowdpooling[]>([]);
  const chainId = useSelector(getCurrentChainId);
  const { thegraphKey } = getChain(chainId);
  const {
    loading: cpListLoading,
    data: cpListData,
    refetch: cpListRefetch,
  } = useRootQuery<SearchCP, SearchCPVariables>(searchCP, {
    variables: {
      cpIdwhere: {
        id: filter,
        chain: thegraphKey,
        refreshNow: true,
      },
      baseIdWhere: {
        baseToken: filter,
        chain: thegraphKey,
        refreshNow: true,
      },
      quoteIdWhere: {
        quoteToken: filter,
        chain: thegraphKey,
        refreshNow: true,
      },
    },
    skip: !filter || !isAddr(filter),
  });
  const {
    loading: bidPositionLoading,
    refetch: bidPositionRefetch,
    data: bidPositionData,
  } = useFetchBidPositions({
    user,
  });
  const [refetchTimes, setRefetchTimes] = useState<number>(0);
  useEffect(() => {
    if (cpListData) {
      setCpList(
        formatCP({
          crowdpoolings: cpListData.cpIdFiltered
            .concat(cpListData.baseTokenIdFiltered)
            .concat(cpListData.quoteTokenIdFiltered),
          bidPositions: bidPositionData?.bidPositions,
          iopCPList,
        }),
      );
    }
  }, [cpListData, bidPositionData, iopCPList, refetchTimes]);

  useEffect(() => {
    let timer: number;
    const ownRefetch = () => {
      timer = window.setTimeout(() => {
        if (filter && isAddr(filter)) {
          cpListRefetch();
        }
        bidPositionRefetch();
        ownRefetch();
        setRefetchTimes((state) => state + 1);
      }, 15000);
    };
    ownRefetch();
    return () => {
      if (timer) window.clearTimeout(timer);
    };
  }, []);

  return {
    loading: cpListLoading || bidPositionLoading,
    cpList,
    refetch: () => {
      cpListRefetch();
      bidPositionRefetch();
    },
  };
};
