import { ContractConfigDataI } from '../type';

export const BOBA_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
    decimals: 18,
    name: 'Wrapped Ethereum',
    source: 'dodo',
  },
  MULTI_CALL: '0xD56281EF996B6B29874C77D2e1464216E8043127',
  DODO_TOKEN: '0x572c5B5BF34f75FB62c39b9BFE9A75bb0bb47984',

  ERC20_HELPER: '0x9FCFD9ff955D36856F8EE480471B05488C0eB5cA',
  ERC20V3_FACTORY: '0x2DdAD36517d5795120bd38F1d87E434C25f60Eca',

  DODO_APPROVE: '0x8F8Dd7DB1bDA5eD3da8C9daf3bfa471c12d58486',

  DODO_PROXY: '0x55793C2c8A796cCE00EF2D1a86CCA2E0399BF285',
  DODO_DSP_PROXY: '0x25B2f945Fec30F34b05d416C7c0b5c6c51A3ADdC',
  DODO_DPP_PROXY: '0xCDFD45f965De9932367833Ca7187e4c9C43A2380',
  DODO_CP_PROXY_V2: '0x7C062B9C584fA6eC2504270790D38240A2c5fE72',
  DODO_MINEV3_PROXY: '0xb0C7cB15b52b0093130487209e96CFeC0Ba4Ff66',
  DODO_STARTER_PROXY: '0x21b9349736316939149337dB96b46627b87a2863',

  DVM_FACTORY: '0x2F2f9460500F27db68AAfBfa0472cEDDb168a5a6',
  DSP_FACTORY: '0xfF133A6D335b50bDAa6612D19E1352B049A8aE6a',
  CP_FACTORY_V2: '0x392B2AE9AB6161591582c4724cA9a89C0cC1caB6',

  ROUTE_V2_DATA_FETCH: '0x668E7f4d8051511279d3BD6d6854e7D39cc94873',
  ROUTE_V1_DATA_FETCH: '0x4EfF1D851366b8cc51d553a87e2d12dA8Da46F2a',
  CALLEE_HELPER: '0xF7c5311B618E6dFBBc34210c92D2C9675D7EdDCA',
  FEE_RATE_IMPL: '0x22909B38e5Ff7dC6ae2DB42D1022AD55f201Ec86',
};

export const BOBA_SWAPINFO = [
  {
    name: 'ooLongSwap',
    factoryAddress: '0x7DDaF116889D655D1c486bEB95017a8211265d29',
    initHash:
      '0x1db9efb13a1398e31bb71895c392fa1217130f78dc65080174491adcec5da9b9',
  },
];
