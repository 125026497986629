import { getChain } from '@dodoex/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInjectProvider } from '../injects/wallet/injectProvider';
import { WalletType } from '../injects/wallet';
import { setChainMismatchNotice } from '../actions';
import { getChainConfig } from '../chainConfigs';
import { getChainMismatchNotice, getCurrentChainId } from '../selectors';
import { getNetworkNameWithChainId } from '../utils/wallet';
import { registerNetworkWithMetamask } from '../wallet';
import {
  autoConnectToWallet,
  detectOpenBlockIframeWallet,
  detectOpenBlockWallet,
  web3Modal,
} from '../web3';

export function useSwitchNetwork({
  network,
  dispatch,
  chainId,
  replaceSearch,
}: {
  network: string;
  dispatch: any;
  chainId: number;
  replaceSearch: (search: string) => void;
}) {
  const [switchNetwork, setSwitchNetwork] = useState<
    (() => Promise<boolean>) | null
  >(null);
  useEffect(() => {
    const getSwitchNetwork = async () => {
      const { addChainParameters } = getChain(chainId);
      if (web3Modal.cachedProvider === WalletType.LedgerUSB) return null;
      if (!addChainParameters) return null;
      if (web3Modal.cachedProvider === WalletType.openBlock) {
        await detectOpenBlockWallet();
      }
      if (web3Modal.cachedProvider === WalletType.openBlockIframe) {
        await detectOpenBlockIframeWallet();
      }
      const provider = getInjectProvider(
        web3Modal.cachedProvider as WalletType,
      );
      if (provider && provider.isMetaMask) {
        return () => async () => {
          replaceSearch(
            `?network=${getNetworkNameWithChainId(chainId).toLowerCase()}`,
          );
          const { result } = await registerNetworkWithMetamask({
            addChainParameters,
            provider,
          });
          if (!result) {
            // 切换失败
            return false;
          }
          autoConnectToWallet();
          dispatch(setChainMismatchNotice(false));
          return true;
        };
      }
      if (
        window.ethereum &&
        window.ethereum.isBlocto &&
        ['ETH', 'BSC'].includes(network)
      ) {
        return () => async () => {
          replaceSearch(
            `?network=${getNetworkNameWithChainId(chainId).toLowerCase()}`,
          );
          const result = await registerNetworkWithMetamask({
            addChainParameters,
          });
          if (!result) {
            return false;
          }
          dispatch(setChainMismatchNotice(false));
          return true;
        };
      }
      return null;
    };
    getSwitchNetwork().then(setSwitchNetwork);
  }, [chainId, dispatch, network, replaceSearch]);

  return { switchNetwork };
}

let search = new URLSearchParams(window.location.search);

export const useSwitchChainConfirm = ({
  replaceSearch,
}: {
  replaceSearch: (search: string) => void;
}) => {
  const chainId = useSelector(getCurrentChainId);
  const on = useSelector(getChainMismatchNotice);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(setChainMismatchNotice(false));
    replaceSearch(
      `?network=${getNetworkNameWithChainId(chainId).toLowerCase()}`,
    );
  }, [dispatch, replaceSearch, chainId]);

  const { network } = getChainConfig(chainId);
  const unknown = !!search.get('unknown');
  const { switchNetwork } = useSwitchNetwork({
    network,
    dispatch,
    chainId,
    replaceSearch,
  });

  useEffect(() => {
    // 因为 close 方法会替换 search。如果直接在 WithNetworkMismatchDialog 赋值，unknown 的情况下。关闭弹窗的前一刻会变成非 unknown
    search = new URLSearchParams(window.location.search);
  }, [on]);

  return {
    on,
    onClose,
    unknown,
    switchNetwork,
    network,
  };
};
