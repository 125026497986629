import { generateProxyUrl, IMG_PROXY_URL } from '@dodoex/utils';
import { useMemo } from 'react';
import { useRootQuery } from '../apollo';
import { fetchDODOAppVersionNew } from './data/queries';
import { FetchDODOAppVersionNew } from './data/__generated__/FetchDODOAppVersionNew';

export enum RefreshType {
  force = 'force',
  none = 'none',
}
export const useNewAppVersion = () => {
  const { data } = useRootQuery<FetchDODOAppVersionNew>(
    fetchDODOAppVersionNew,
    undefined,
    undefined,
    undefined,
    true,
  );
  const appVersionInfo = useMemo(() => {
    if (!data?.dodo_app_version_new) return null;
    const { dodo_app_version_new } = data;
    const serverUrl = dodo_app_version_new.urls || [];
    const urls = serverUrl
      .map((item) => item?.url)
      .filter((item) => !!item) as string[];
    const { id, title, description, version, refreshType } =
      dodo_app_version_new;
    let icon = dodo_app_version_new.icon || '';
    icon = icon.startsWith(IMG_PROXY_URL)
      ? generateProxyUrl({
          url: icon,
          height: '96',
          width: '96',
        })
      : icon;
    return {
      id: String(id),
      title: title as string,
      description: description || '',
      version: version as string,
      refreshType: refreshType as RefreshType,
      urls,
      icon,
    };
  }, [data]);

  return appVersionInfo;
};
