import { ContractConfigDataI } from '../type';

/**
 * @link https://www.notion.so/dodotopia/231d1dd8b4084642a4942da1f9b3a34f?v=d9790d583fb442a88326c557c3f0ab92
 */
export const KCS_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WKCS',
    address: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
    decimals: 18,
    name: 'Wrapped KCS',
    source: 'dodo',
  },
  MULTI_CALL: '0x6433407a29706Bbdd43b36cd402a53A174f066a1',
  DODO_TOKEN: '',

  ERC20_HELPER: '0xAC716E87b0853C0712674e8E3a8435a489F276b4',
  ERC20V3_FACTORY: '0x5a0C840a7089aa222c4458b3BE0947fe5a5006DE',

  DODO_APPROVE: '0xA9148dC69f71b8f3dF71193a48f2915980dB8C19',

  DODO_PROXY: '0xf0512872fEc0173d1d99c2dd8CDCb770054b675b',
  DODO_DSP_PROXY: '0xB8C29329FD2d295C73Aa6AcFBD3cAff7f93FA28D',
  DODO_DPP_PROXY: '0x5d7DEb28e663070a5eF7d6871c6C10E67954b9c7',
  DODO_CP_PROXY_V2: '0x18586f053329cb477e171377bd5ba1f26feb2589',
  DODO_MINEV3_PROXY: '0x4c52f61212F9e36922ab78AAb250F1a2f000D93C',
  DODO_STARTER_PROXY: '',

  DVM_FACTORY: '0x31AC053c31a77055b2ae2d3899091C0A9c19cE3a',
  DSP_FACTORY: '0xbce44767af0a53A108b3B7ba4F740E03D228Ec0A',
  CP_FACTORY_V2: '0xAE1E7e4c5743321d2a8ceeD179264998a56CF1d9',

  ROUTE_V2_DATA_FETCH: '0x99d69565EdA438E3ebAA0627A407968ABD0f755b',
  ROUTE_V1_DATA_FETCH: '0x41bb458c5FEEB114a977E59D5E153A2112e4e293',
  CALLEE_HELPER: '0xe33421899CbF22E762407f02c34622519518986a',

  FEE_RATE_IMPL: '0xe6b09c6cb287bb2017d2c156ab6c4247e931dfda',
};

export const KCS_SWAPINFO = [];
