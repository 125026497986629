import { Box, BoxProps } from '@mui/material';
import { merge } from 'lodash';
import { forwardRef } from 'react';
import { useDevices } from '../hooks';

export const HoverOpacity = forwardRef(
  (
    {
      weak,
      color,
      useHover,
      sx,
      ...attrs
    }: BoxProps & {
      /** 当前为主文字颜色，hover 增加透明度 */
      weak?: boolean;
      /** 直接传入颜色，则用 opacity 控制 */
      color?: string;
      useHover?: boolean; // 是否使用hover
    },
    ref: any,
  ) => {
    const { isMobile } = useDevices();
    let defaultColor = weak ? 'text.primary' : 'text.secondary';
    let hoverColor = weak ? 'text.secondary' : 'text.primary';
    if (color) {
      defaultColor = color;
      hoverColor = color;
    }
    const hoverCss =
      isMobile && !useHover
        ? {}
        : {
            '&:hover': {
              color: hoverColor,
              opacity: color && weak ? 0.5 : 1,
            },
          };
    return (
      <Box
        ref={ref}
        sx={merge(
          {
            color: defaultColor,
            opacity: color && !weak ? 0.5 : 1,
            // '&:hover': {
            //   color: hoverColor,
            //   opacity: color && weak ? 0.5 : 1,
            // },
            ...hoverCss,
          },
          sx,
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attrs}
      />
    );
  },
);
