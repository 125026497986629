/* eslint-disable react/jsx-props-no-spreading */
import { MouseEventHandler } from 'react';
import { TabProps, TabsProps } from '@mui/material';
import { Tabs, Tab } from './Tabs';
import { TabButtons, TabButton } from './TabButtons';
import { TabButtonsWeak, TabButtonWeak } from './TabButtonsWeak';
import { TabSwitchButtons, TabSwitchButton } from './TabSwitchButton';

enum TabVariant {
  primary = 'primary',
  button = 'button',
  buttonWeak = 'buttonWeak',
  switch = 'switch',
}

interface TabItem {
  label: TabProps['label'];
  value: string | number;
  onClick?: MouseEventHandler<HTMLDivElement>;
}
interface TabsGroupProps extends TabsProps {
  tabList: TabItem[];
  tabVariant?: TabVariant;
}

export const TabGroup = ({
  tabVariant = TabVariant.primary,
  tabList,
  ...attrs
}: TabsGroupProps) => {
  const TabsMap = {
    [TabVariant.primary]: Tabs,
    [TabVariant.button]: TabButtons,
    [TabVariant.buttonWeak]: TabButtonsWeak,
    [TabVariant.switch]: TabSwitchButtons,
  };
  const TabMap = {
    [TabVariant.primary]: Tab,
    [TabVariant.button]: TabButton,
    [TabVariant.buttonWeak]: TabButtonWeak,
    [TabVariant.switch]: TabSwitchButton,
  };
  const TabsResult = TabsMap[tabVariant];
  const TabResult = TabMap[tabVariant];
  return (
    <TabsResult {...attrs}>
      {tabList.map((item) => (
        <TabResult
          key={item.value}
          value={item.value}
          label={item.label}
          onClick={item.onClick}
        />
      ))}
    </TabsResult>
  );
};

TabGroup.TabVariant = TabVariant;
