import { BigNumber } from 'bignumber.js';

/* eslint-disable no-param-reassign */
export const increaseArray = (len: number) => {
  return Object.keys(new Array(len + 1).join(','));
};

interface ReceiveFromCPInterface {
  investedQuote: string | number;
  price: string | number;
  poolQuote: string | number;
  poolQuoteCap: string | number;
}
export const getExpectedReceiveFromCP = ({
  investedQuote,
  price,
  poolQuote,
  poolQuoteCap,
}: ReceiveFromCPInterface) => {
  const investedQuoteAmount = new BigNumber(investedQuote);
  if (investedQuoteAmount.lte(0)) {
    return [new BigNumber('0'), new BigNumber('0')];
  }

  const exactProgress = new BigNumber(poolQuote).div(poolQuoteCap);
  const willReceiveBaseTokenAmount = new BigNumber(investedQuoteAmount).div(
    price,
  );
  if (exactProgress.lte(1)) {
    return [willReceiveBaseTokenAmount.dp(6), new BigNumber('0')];
  }

  const realInvestedQuote = new BigNumber(investedQuoteAmount)
    .div(poolQuote)
    .multipliedBy(poolQuoteCap);
  const realWillReceiveBaseTokenAmount = realInvestedQuote.div(price);
  const willRepayQuoteTokenAmount = willReceiveBaseTokenAmount
    .minus(realWillReceiveBaseTokenAmount)
    .multipliedBy(price);
  return [
    realWillReceiveBaseTokenAmount.dp(6),
    willRepayQuoteTokenAmount.dp(6),
  ];
};
export enum MatchLevel {
  fully = 1,
  prefix,
  other,
  none = 0,
}
export function getTextMatchLevel(
  text: string,
  search: string,
  ignoreCase?: boolean,
  carryLevel = 0,
) {
  if (ignoreCase) {
    text = text.toLocaleLowerCase();
    search = search.toLocaleLowerCase();
  }
  if (text === search) {
    return MatchLevel.fully + carryLevel;
  }
  const textIndex = text.indexOf(search);
  if (textIndex === 0) {
    return MatchLevel.prefix + carryLevel;
  }
  if (textIndex > 0) {
    return MatchLevel.other + carryLevel;
  }
  return MatchLevel.none;
}

export function simpleSort(a: any, b: any) {
  return a.sort - b.sort;
}

/**
 * 监听用户是否对页面上有交互
 * @param callback 回调
 * @returns 移除监听
 */
export const interactListener = (callback: () => void) => {
  const load = () => {
    document.removeEventListener('mouseover', load);
    document.removeEventListener('touchstart', load);
    callback();
  };

  const listener = () => {
    document.addEventListener('mouseover', load, {
      once: true,
    });
    document.addEventListener('touchstart', load, {
      once: true,
    });
  };

  const removeListener = () => {
    document.removeEventListener('mouseover', load);
    document.removeEventListener('touchstart', load);
  };

  return [listener, removeListener];
};

// 有交互之后才触发，优化测评网站的评分
export const lazyLoad = (callback: () => void) => {
  const [listener] = interactListener(callback);
  listener();
};

/**
 * 将字符串拆分成固定长度的字符串数组
 * @param str
 * @param size
 * @returns
 */
export function breakDownStr(str: string | null, size = 255) {
  const result: string[] = [];
  if (!str) {
    return result;
  }
  for (let index = 0; index < str.length; ) {
    result.push(str.substring(index, (index += size)));
  }
  return result;
}
