import Web3 from 'web3';
import BN from 'bignumber.js';

export const ETH_ADDR_OLD = '0x000000000000000000000000000000000000000e';
export const ETH_ADDR = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const WETH_ADDR = '0x5eca15b12d959dfcf9c71c59f8b467eb8c6efd0b';
export const ETH_ADDR_CASE_SENSITIVE =
  '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const USDC_TOKEN_ADDRESS = '0x69C8a7fc6E05d7aa36114B3e35F62DEcA8E11F6E';

/**
 * @see https://github.com/OpenZeppelin/openzeppelin-test-helpers/blob/master/src/constants.js
 */
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ZERO_BYTES32 =
  '0x0000000000000000000000000000000000000000000000000000000000000000';
export const MAX_UINT256 = new BN('2').pow(new BN('256')).minus(new BN('1'));
export const MAX_INT256 = new BN('2').pow(new BN('255')).minus(new BN('1'));
export const MIN_INT256 = new BN('2')
  .pow(new BN('255'))
  .multipliedBy(new BN('-1'));
export const BIG_ALLOWANCE = new BN(2).pow(256).minus(1); // (2**256) - 1

export const USDC_TOKEN = {
  symbol: 'USDC',
  address: USDC_TOKEN_ADDRESS,
  name: 'USD Coin',
  decimals: 6,
  source: 'dodo',
  showDecimals: 4,
  logoUrl: undefined,
  opRank: undefined,
};

export const ETH_ADDRS = [ETH_ADDR_OLD, ETH_ADDR, ETH_ADDR_CASE_SENSITIVE];

export const isSameAddress = (
  tokenAddress1: string,
  tokenAddress2: string,
): boolean => {
  if (tokenAddress1.length === 0 || tokenAddress2.length === 0) {
    return false;
  }
  if (tokenAddress2.length === tokenAddress1.length) {
    return tokenAddress1.toLowerCase() === tokenAddress2.toLowerCase();
  }
  const trimAddress1 = tokenAddress1
    .substring(2, tokenAddress1.length)
    .toLowerCase();
  const trimAddress2 = tokenAddress2
    .substring(2, tokenAddress2.length)
    .toLowerCase();
  if (trimAddress1.length > trimAddress2.length) {
    return trimAddress1.endsWith(trimAddress2);
  }
  return trimAddress2.endsWith(trimAddress1);
};

export function isAddr(s: string): boolean {
  return !!s.match(/^(0x)?[a-fA-F0-9]{40}$/);
}

/**
 * truncate pool address: 0xeBa959390016dd81419A189e5ef6F3B6720e5A90 => 0xeBa9...5A90
 * @param address pool address
 */
export function truncatePoolAddress(address: string): string {
  if (address.length <= 10) {
    return address;
  }
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length,
  )}`;
}

export const formatAddress = (address: string): string => {
  try {
    return Web3.utils.toChecksumAddress(address);
  } catch (error) {
    console.error('toChecksumAddress error', error);
    return '';
  }
};
