/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
import { getCurrentAccount } from '@dodoex/wallet';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DODO_BUSINESS_BACKEND_API_URL } from '@dodoex/utils';
import { SchemaName, useRootQuery } from '..';
import { User_orderBy } from '../../__generated__/graphql-global-types';
import { fetchVDODOUser, FetchVDODOUsers } from './data/queries';
import {
  FetchVDODOUser,
  FetchVDODOUserVariables,
  FetchVDODOUser_user,
} from './data/__generated__/FetchVDODOUser';
import {
  fetchVDODOUsers,
  fetchVDODOUsersVariables,
} from './data/__generated__/fetchVDODOUsers';

export interface VDODOUser extends FetchVDODOUser_user {
  redeemAmount: BigNumber;
}

export function useUserInfo({ userAddress }: { userAddress?: string }) {
  const [userInfo, setUserInfo] = useState<VDODOUser | undefined>();
  const { loading, data, error, refetch } = useRootQuery<
    FetchVDODOUser,
    FetchVDODOUserVariables
  >(
    fetchVDODOUser,
    {
      variables: {
        id: userAddress?.toLowerCase() ?? '',
      },
      skip: !userAddress,
    },
    SchemaName.vdodo,
  );

  useEffect(() => {
    if (data && data.user) {
      const formatedUser = {
        ...data.user,
        redeemAmount: new BigNumber(data.user.redeemRecieveAmount)
          .plus(data.user.redeemFeeAmount)
          .plus(data.user.redeemBurnAmount),
      };
      setUserInfo(formatedUser);
    }
  }, [data]);

  useEffect(() => {
    let timer: any;
    const ownRefetch = () =>
      (timer = window.setTimeout(() => {
        refetch();
        ownRefetch();
      }, 15000));
    ownRefetch();
    return () => {
      timer && window.clearInterval(timer);
    };
  }, []);

  return { loading, userInfo, error, refetch };
}

export function useFriendsInfo({
  accountAddress,
}: {
  accountAddress?: string | null;
}) {
  const [friendsInfo, setFriendsInfo] = useState<VDODOUser[]>([]);
  const [totalSP, setTotalSP] = useState<BigNumber>(new BigNumber(0));
  const [totalCredit, setTotalCredit] = useState<BigNumber>(new BigNumber(0));
  const { loading, data, error, refetch } = useRootQuery<
    fetchVDODOUsers,
    fetchVDODOUsersVariables
  >(
    FetchVDODOUsers,
    {
      variables: {
        where: {
          superior: accountAddress?.toLowerCase(),
          creditOfSuperior_gt: 0,
        },
        orderBy: User_orderBy.creditOfSuperior,
        first: 1000,
      },
      skip: !accountAddress,
    },
    SchemaName.vdodo,
  );

  useEffect(() => {
    if (data && data.users) {
      let totalSP = new BigNumber(0);
      let totalCredit = new BigNumber(0);
      const formatedUser = data.users.map((user) => {
        totalSP = totalSP.plus(user.superiorSP);
        totalCredit = totalCredit.plus(user.creditOfSuperior);
        return {
          ...user,
          redeemAmount: new BigNumber(user.redeemRecieveAmount)
            .plus(user.redeemFeeAmount)
            .plus(user.redeemBurnAmount),
        };
      });
      setTotalSP(totalSP);
      setTotalCredit(totalCredit);
      setFriendsInfo(formatedUser);
    }
  }, [data]);

  return {
    loading,
    friendsInfo,
    totalSP,
    totalCredit,
    error,
    refetch,
  };
}

const OWN_ADDRESS = '0x95c4f5b83aa70810d4f142d58e5f7242bd891cb0'; // 公司地址
export function useUsersInfo() {
  const [usersInfo, setUsersInfo] = useState<VDODOUser[]>([]);
  const { loading, data, error, refetch } = useRootQuery<
    fetchVDODOUsers,
    fetchVDODOUsersVariables
  >(
    FetchVDODOUsers,
    {
      variables: {
        first: 5,
        where: {
          id_not: OWN_ADDRESS,
        },
        orderBy: User_orderBy.creditFromInvited,
      },
    },
    SchemaName.vdodo,
  );

  useEffect(() => {
    if (data && data.users) {
      const formatedUser = data.users.map((user) => {
        return {
          ...user,
          redeemAmount: new BigNumber(user.redeemRecieveAmount)
            .plus(user.redeemFeeAmount)
            .plus(user.redeemBurnAmount),
        };
      });
      setUsersInfo(formatedUser);
    }
  }, [data]);

  useEffect(() => {
    let timer: any;
    const ownRefetch = () =>
      (timer = window.setTimeout(() => {
        refetch();
        ownRefetch();
      }, 15000));
    ownRefetch();
    return () => {
      timer && window.clearInterval(timer);
    };
  }, []);

  return {
    loading,
    usersInfo,
    error,
    refetch,
  };
}

const TRADING_FEE_URL = `${DODO_BUSINESS_BACKEND_API_URL}/api/v1/businessdata/vdodo/bonus`;
export function useUserTradingFeeInfo() {
  const [loading, setLoading] = useState<boolean>(true);
  const accountAddress = useSelector(getCurrentAccount);
  const [userTradingFee, setUserTradingFee] = useState<BigNumber>(
    new BigNumber(NaN),
  );

  useEffect(() => {
    if (!accountAddress) return;
    axios
      .get(TRADING_FEE_URL, { params: { address: accountAddress } })
      .then((res) => {
        if (res.data) {
          setUserTradingFee(new BigNumber(res.data.data));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [accountAddress]);

  return {
    loading,
    userTradingFee,
  };
}
