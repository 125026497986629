import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { marketInit } from '@dodoex/core';
// import { getPublicSavedSettings } from './actions/settings';
import createRootReducer from './reducers';
import { initialState } from './reducers/settings';
import { initialTokenState } from './reducers/token';
import { initialSystemState } from './reducers/system';

// https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

export const history = createBrowserHistory();

const middlewares: [Middleware<unknown, any, any>] = [thunkMiddleware];

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  // other store enhancers if any
);

// const savedSettings = getPublicSavedSettings();
const preloadedState = {
  settings: {
    ...initialState,
    // ...savedSettings,
  },
  token: initialTokenState,
  market: marketInit,
  system: initialSystemState,
};

export const store = createStore(createRootReducer(), preloadedState, enhancer);
