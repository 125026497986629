import { OrderedMap } from 'immutable';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import Entry, { Notif, NotifVariant } from './Entry';
import { Toast } from './Toast';

export { NotifVariant } from './Entry';
export * from './Alert';
export { default as Countdown } from './Countdown';
export * from './Bubble';

export type NotifCtx = {
  show: (notif: Notif, timeout?: number) => string;
  isShow: (id: string) => boolean;
  close: (id: string) => void;
};

const NotifContext = React.createContext<NotifCtx>({
  show: () => {
    return '';
  },
  isShow: () => {
    return false;
  },
  close: () => {
    // Nothing
  },
});

export const Area = styled.div`
  /* react-bootstrap modal z-index=1060 */
  /* react-bootstrap tooltip z-index=1070 */
  /* Dialog z-index=1065 */

  @media (max-width: 767px) {
    width: auto;
  }
`;

// eslint-disable-next-line no-underscore-dangle
let _notifs: NotifCtx | undefined;

export const WithNotifArea = React.memo(
  ({ children }: { children: React.ReactNode }): React.ReactElement => {
    const [notifs, setNotifs] = useState<OrderedMap<string, Notif>>(
      OrderedMap(),
    );
    const flattened = useMemo(() => Array.from(notifs), [notifs]);

    const ctxVal = useMemo(
      () => ({
        show: (notif: Notif) => {
          const id = uuidv4();
          setNotifs((n) => n.set(id, notif));
          return id;
        },
        isShow: (id: string) => !!notifs.get(id),
        close: (id: string) => {
          setNotifs((n) => n.delete(id));
        },
      }),
      [notifs, setNotifs],
    );

    useEffect(() => {
      // For consistent API of toastSuccess / toastFailed
      _notifs = ctxVal;
    }, [ctxVal]);

    return (
      <NotifContext.Provider value={ctxVal}>
        {children}
        <Area>
          {flattened.map(([uuid, val]) => {
            if (val.isToast) {
              return (
                <Toast
                  key={uuid}
                  msg={val.msg}
                  timeout={val.timeout}
                  onClose={() => {
                    setNotifs((prevNotifs) => prevNotifs.remove(uuid));
                    if (val.onClose) {
                      val.onClose();
                    }
                  }}
                />
              );
            }
            return (
              <Entry
                key={uuid}
                type={val.type}
                msg={val.msg}
                timeout={val.progress || val.timeout}
                link={val.link}
                hint={val.hint}
                onClose={() => {
                  setNotifs((prevNotifs) => prevNotifs.remove(uuid));
                  if (val.onClose) {
                    val.onClose();
                  }
                }}
              />
            );
          })}
        </Area>
      </NotifContext.Provider>
    );
  },
);

WithNotifArea.displayName = 'WithNotifArea';

export function toast(
  msg: string,
  duration: number,
  type?: NotifVariant,
  hint?: string | JSX.Element,
  link?: Notif['link'],
  onClose?: () => void,
) {
  if (!msg) {
    return {
      isShow: () => false,
      close: () => {
        // empty
      },
    };
  }
  if (!_notifs) throw new Error('Notif section not initialized');

  const id = _notifs.show({
    type,
    msg,
    timeout: duration > 0 ? duration : undefined,
    hint,
    link,
    onClose,
  });

  return {
    isShow: () => _notifs?.isShow(id),
    close: () => _notifs?.close(id),
  };
}

export const toastSuccess = (
  msg: string,
  duration = 3000,
  hint?: string | JSX.Element,
): void => {
  toast(msg, duration, NotifVariant.success, hint);
};

export const toastFailed = (msg: string, duration = 0, hint?: string): void => {
  toast(msg, duration, NotifVariant.error, hint);
};

export const toastWarn = (
  msg: string,
  duration = 3000,
  hint?: string | JSX.Element,
): void => {
  toast(msg, duration, NotifVariant.warn, hint);
};

export const toastMsg = (msg: string, duration = 3000) => {
  if (!msg) {
    return;
  }
  if (!_notifs) throw new Error('Notif section not initialized');

  _notifs.show({
    msg,
    timeout: duration > 0 ? duration : undefined,
    isToast: true,
  });
};

export function useNotifs(): NotifCtx {
  return useContext(NotifContext);
}
