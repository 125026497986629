/* eslint-disable @typescript-eslint/no-explicit-any */
export enum LogType {
  graphql = 'graphql',
  fiat = 'fiat',
}
export const clogKey = '__log';

// 使用之前设置一下：sessionStorage.setItem('__log', 'graphql');
export function clog(type: LogType, message: any, ...optionalParams: any[]) {
  const searchType = sessionStorage.getItem(clogKey);
  if (searchType) {
    const searchTypeArr = searchType.split(',') as LogType[];
    if (searchTypeArr.includes(type)) {
      // eslint-disable-next-line no-console
      console.log(`[clog-${type}]`, message, ...optionalParams);
    }
  }
}

clog.type = LogType;
