export const LastChainIdKey = 'dodo-wallet:lastChainId';
export const LastEndpointKey = 'dodo-wallet:lastEndpoint';
export const PERSISTENT_TX_HISTORY = 'dodo:wallet:txhist';
export const TOKEN_BLACK_LIST = 'dodo:token:blackList';
export const IS_LEDGER_LIVE_SEARCH = '0.ledger-live-search';

export const SETTINGS_ACCOUNT_RISK_CONFIRM =
  '0.dodo:settings:account:risk-confirm';

export const QUIT_CONNECT = '0.dodo:wallet:quit-connect';

export const setLastChainId = (chainId: number) => {
  window.localStorage.setItem(LastChainIdKey, String(chainId));
};

export const getLastChainId = () => {
  const lastChainId = window.localStorage.getItem(LastChainIdKey);
  return lastChainId ? Number(lastChainId) : 1;
};

export const setLastEndpoint = (chainId: number, endpoint: string) => {
  window.localStorage.setItem(`${chainId}:${LastEndpointKey}`, endpoint);
};

export const getLastEndpoint = (chainId?: number) => {
  if (!chainId) return 'global';
  return (
    window.localStorage.getItem(`${chainId}:${LastEndpointKey}`) ?? 'global'
  );
};
