import { gql } from '@apollo/client';

export const fetchRequestSplitConfig = gql`
  query FetchRequestSplitConfig {
    request_split_config_getRequestSplitConfig {
      name
      value
    }
  }
`;
