import Web3ProviderEngine from 'web3-provider-engine';
import AppEth from '@ledgerhq/hw-app-eth';
import CacheSubprovider from 'web3-provider-engine/subproviders/cache.js';
import RpcSubprovider from 'web3-provider-engine/subproviders/rpc';
import HookedWalletSubprovider from 'web3-provider-engine/subproviders/hooked-wallet';
import stripHexPrefix from 'strip-hex-prefix';
import { TypedDataUtils, SignTypedDataVersion } from '@metamask/eth-sig-util';
import { rlp, bufferToHex, addHexPrefix, intToHex } from 'ethereumjs-util';
import { EIP712Message } from '@ledgerhq/hw-app-eth/lib/modules/EIP712';
import { FeeMarketEIP1559TxData, TransactionFactory } from '@ethereumjs/tx';
import BigNumber from 'bignumber.js';
import { getTransport } from './connect';
import { TransactionConfigExt } from '../wallet';
import { getLedgerUSBConnectInfo } from '../db/ledgerUSBConnectInfo';
import { fetchGasPrice } from '../core';
import {
  getCommonConfiguration,
  getEIP1559Compatibility,
  TRANSACTION_ENVELOPE_TYPES,
} from '../transactions';

export interface ILedgerProviderOptions {
  chainId: number;
  rpcUrl: string;
  path: string;
  account: string;
  accountFetchingConfigs?: any;
  baseDerivationPath?: any;
  pollingInterval?: any;
  requestTimeoutMs?: any;
}

function convertWEIToHexWEI(value: number) {
  const bgValue = new BigNumber(String(value), 10);
  return `0x${bgValue.toString(16)}`;
}

function createLedgerSubprovider(options: ILedgerProviderOptions) {
  const { path, account, chainId } = options;

  function getAccounts() {
    return new Promise((resolve) => {
      resolve({
        [path]: account,
      });
    });
  }

  async function signPersonalMessage(msgData: any) {
    if (!path) throw new Error(`address unknown '${msgData.from}'`);
    const transport = await getTransport();
    try {
      const eth = new AppEth(transport);
      const result = await eth.signPersonalMessage(
        path,
        stripHexPrefix(msgData.data),
      );
      // @ts-ignore
      const v = parseInt(result.v, 10) - 27;
      let vHex = v.toString(16);
      if (vHex.length < 2) {
        vHex = `0${v}`;
      }
      return `0x${result.r}${result.s}${vHex}`;
    } finally {
      transport.close();
    }
  }

  async function signTransaction(txData: TransactionConfigExt) {
    if (!path) throw new Error(`address unknown '${txData.from}'`);

    const transport = await getTransport();
    try {
      const eth = new AppEth(transport);
      const supportsEIP1559 = await getEIP1559Compatibility(chainId);
      const common = await getCommonConfiguration(chainId);
      const eip1599TxData = txData as unknown as FeeMarketEIP1559TxData;
      eip1599TxData.type = TRANSACTION_ENVELOPE_TYPES.LEGACY;
      if (supportsEIP1559) {
        const gasPrice = await fetchGasPrice(chainId);
        if (gasPrice?.maxFeePerGas && gasPrice?.maxPriorityFeePerGas) {
          eip1599TxData.maxFeePerGas = convertWEIToHexWEI(
            gasPrice.maxFeePerGas,
          );
          eip1599TxData.maxPriorityFeePerGas = convertWEIToHexWEI(
            gasPrice.maxPriorityFeePerGas,
          );
          eip1599TxData.type = TRANSACTION_ENVELOPE_TYPES.FEE_MARKET;
        }
      }
      // https://github.com/ethereumjs/ethereumjs-monorepo/tree/master/packages/tx#signing-with-a-hardware-or-external-wallet
      // https://github.com/MetaMask/eth-ledger-bridge-keyring/blob/dec4772fd942be5da3d63ea89879c8d1968f2285/index.js#L247
      const unsignedEthTx = TransactionFactory.fromTxData(eip1599TxData, {
        common,
      });
      // Note also that `getMessageToSign` will return valid RLP for all transaction types, whereas the
      // `serialize` method will not for any transaction type except legacy. This is because `serialize` includes
      // empty r, s and v values in the encoded rlp. This is why we use `getMessageToSign` here instead of `serialize`.
      const messageToSign = unsignedEthTx.getMessageToSign(false);

      const rawTxHex = Buffer.isBuffer(messageToSign)
        ? messageToSign.toString('hex')
        : rlp.encode(messageToSign).toString('hex');
      const result = await eth.signTransaction(path, rawTxHex);
      // Because tx will be immutable, first get a plain javascript object that
      // represents the transaction. Using txData here as it aligns with the
      // nomenclature of ethereumjs/tx.
      const unsignedEthTxJSON = unsignedEthTx.toJSON();
      // The fromTxData utility expects a type to support transactions with a type other than 0
      unsignedEthTxJSON.type = eip1599TxData.type;
      // The fromTxData utility expects v,r and s to be hex prefixed
      unsignedEthTxJSON.v = addHexPrefix(result.v);
      unsignedEthTxJSON.r = addHexPrefix(result.r);
      unsignedEthTxJSON.s = addHexPrefix(result.s);
      // Adopt the 'common' option from the original transaction and set the
      // returned object to be frozen if the original is frozen.
      const newOrMutatedTx = TransactionFactory.fromTxData(unsignedEthTxJSON, {
        common,
        freeze: Object.isFrozen(unsignedEthTx),
      });
      const valid = newOrMutatedTx.verifySignature();
      if (!valid) {
        throw new Error('Ledger: The transaction signature is not valid');
      }
      return bufferToHex(newOrMutatedTx.serialize());
    } finally {
      transport.close();
    }
  }

  const subprovider = new HookedWalletSubprovider({
    getAccounts: (callback: (err: any, res: any) => void) => {
      getAccounts()
        .then((res: any) => callback(null, Object.values(res)))
        .catch((err) => {
          console.error(err);
          callback(err, null);
        });
    },
    signPersonalMessage: (
      txData: TransactionConfigExt,
      callback: (err: any, res: any) => void,
    ) => {
      signPersonalMessage(txData)
        .then((res) => callback(null, res))
        .catch((err) => callback(err, null));
    },
    signTransaction: (
      txData: TransactionConfigExt,
      callback: (err: any, res: any) => void,
    ) => {
      signTransaction(txData)
        .then((res) => callback(null, res))
        .catch((err) => callback(err, null));
    },
  });

  return subprovider;
}

class LedgerWebProvider extends Web3ProviderEngine {
  constructor(opts: ILedgerProviderOptions) {
    super({
      pollingInterval: opts.pollingInterval,
    });
    const ledger = createLedgerSubprovider(opts);
    this.addProvider(ledger);
    this.addProvider(new CacheSubprovider());
    this.addProvider(
      new RpcSubprovider({
        rpcUrl: opts.rpcUrl,
      }),
    );

    this.start();
  }
}

export async function signEIP712Message(params: EIP712Message) {
  const transport = await getTransport();
  try {
    const providerPackageOptions = await getLedgerUSBConnectInfo();
    const { path } = providerPackageOptions;
    if (!path) throw new Error(`address unknown '${params}'`);
    const eth = new AppEth(transport);
    const { domain, types, primaryType, message } = params;
    const domainSeparatorHex = TypedDataUtils.hashStruct(
      'EIP712Domain',
      domain,
      types,
      SignTypedDataVersion.V4,
    ).toString('hex');
    const hashStructMessageHex = TypedDataUtils.hashStruct(
      primaryType,
      message,
      types,
      SignTypedDataVersion.V4,
    ).toString('hex');
    const payload = await eth.signEIP712HashedMessage(
      path,
      domainSeparatorHex,
      hashStructMessageHex,
    );
    // @ts-ignore
    const vNum = parseInt(payload.v, 10);
    let v = vNum.toString(16);
    if (v.length < 2) {
      v = `0${v}`;
    }
    const signature = `0x${payload.r}${payload.s}${v}`;
    return signature;
    // return eth.signEIP712Message(path, params);
  } finally {
    transport.close();
  }
}

export default LedgerWebProvider;
