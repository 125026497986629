/**
 * Let's pretend that typescript has ADT
 */

import { BigNumber } from 'bignumber.js';

/**
 * All supported opcodes, as tagged union discriminants
 */
export enum OpCode {
  Approval = 'APPROVAL',
  TX = 'TX',
  /** 结构化签名，返回结构为一个签名对象 */
  TypedSign = 'TypedSign',
}

export enum SwapType {
  Normal = 'Normal',
  Privacy = 'Privacy',
  LimitOrder = 'LimitOrder',
  RFQ = 'RFQ',
}

export type TokenData = {
  address: string;
};

export type ApprovalStep = {
  opcode: OpCode.Approval;

  /**
   * The address of the token
   */
  token: TokenData;

  /**
   * The approved contract address
   */
  contract: string;

  /**
   * The amount to set the allowance to, in Wei, in base-10. If omitted, a (very) big constants is used
   * TODO(meow): bigint shows a 87% support rate. Can we use it here?
   */
  amt?: BigNumber;
};

export type TXStep = {
  opcode: OpCode.TX;

  value: number | string;
  to: string;
  data: string;

  // Gas is handled by the tx submodule
  swapType?: SwapType;
  gasLimit?: number;
  gasPrice?: number;
  ddlSecRel?: number;
  maxFeePerGas?: number;
  maxPriorityFeePerGas?: number;
};

export type TypedSignStep = {
  opcode: OpCode.TypedSign;
  swapType?: SwapType;
  /** 用户地址 */
  signer?: string;
  /** 需要签名的结构数据 */
  typedData: Record<string, unknown>;
};

export type Step = ApprovalStep | TXStep | TypedSignStep;
export type Steps = Step[];
