/* eslint-disable react/jsx-props-no-spreading */
import { styled, alpha } from '@mui/material';
import SliderOrigin, { SliderProps, SliderThumb } from '@mui/material/Slider';

const boxShadow = `0 2px 4px ${alpha('#1A1A1B', 0.2)}`;

const SliderOver = styled(SliderOrigin)(({ theme }) => ({
  color: 'primary.main',
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    boxShadow,
    borderRadius: 8,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow,
      },
    },
    '& .thumb-bar': {
      height: 9,
      width: 2,
      backgroundColor: theme.palette.background.default,
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    fontWeight: 'normal',
    top: -6,
    backgroundColor: 'unset',
    color: theme.palette.text.primary,
    '&:before': {
      display: 'none',
    },
    '& *': {
      background: 'transparent',
      color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.custom.border.default,
    height: 4,
    opacity: 1,
  },
  '& .MuiSlider-mark': {
    opacity: 1,
    backgroundColor: theme.palette.custom.border.default,
    height: 12,
    width: 2,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: 'currentColor',
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: '12px',
    lineHeight: '17px',
  },
}));

function ThumbComponent(props: React.HTMLAttributes<unknown>) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="thumb-bar" />
      <span className="thumb-bar" />
      <span className="thumb-bar" />
    </SliderThumb>
  );
}

export function Slider(props: SliderProps) {
  return <SliderOver components={{ Thumb: ThumbComponent }} {...props} />;
}
