import { gql } from '@apollo/client';

export const fetchPoolWithLpList = gql`
  query FetchPoolWithLpList(
    $first: Int
    $where: Pair_filter
    $orderBy: Pair_orderBy
  ) {
    pairs(
      first: $first
      where: $where
      orderBy: $orderBy
      orderDirection: desc
    ) {
      id
      type
      creator
      lpFeeRate
      i
      k
      baseReserve
      quoteReserve
      createdAtTimestamp
      lastTradePrice
      volumeUSD
      traderCount
      feeBase
      feeQuote
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      baseLpToken {
        id
        symbol
        name
        totalSupply
        decimals
      }
      quoteLpToken {
        id
        symbol
        name
        totalSupply
        decimals
      }
    }
  }
`;

export const fetchPoolVolumeAndTrader = gql`
  query FetchPoolVolumeAndTrader(
    $first: Int
    $where: Pair_filter
    $blockHeight: Int!
  ) {
    pairs(first: $first, where: $where, block: { number: $blockHeight }) {
      id
      volumeUSD
      traderCount
      lastTradePrice
      feeBase
      feeQuote
    }
  }
`;

export const queryBlocks = gql`
  query FetchBlocks($first: Int, $where: Block_filter) {
    blocks(
      first: $first
      orderBy: timestamp
      where: $where
      orderDirection: desc
    ) {
      id
      number
      timestamp
    }
  }
`;

export const fetchLiquidityPositions = gql`
  query FetchLiquidityPositions(
    $id: ID!
    $first: Int
    $skip: Int
    $where: LiquidityPosition_filter
    $miningWhere: LiquidityPosition_filter
    $orderBy: LiquidityPosition_orderBy
    $orderDirection: OrderDirection
  ) {
    balance: liquidityPositions(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      liquidityTokenBalance
    }
    mining: liquidityPositions(
      first: $first
      skip: $skip
      where: $miningWhere
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      liquidityTokenInMining
    }
    pair(id: $id) {
      baseReserve
      quoteReserve
      lastTradePrice
      baseLpToken {
        id
        totalSupply
        decimals
      }
      quoteLpToken {
        id
        totalSupply
        decimals
      }
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
    }
  }
`;

export const fetchSwaps = gql`
  query FetchSwaps(
    $first: Int
    $skip: Int
    $where: Swap_filter
    $orderBy: Swap_orderBy
    $orderDirection: OrderDirection
  ) {
    swaps(
      first: $first
      skip: $skip
      where: $where
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      timestamp
      from
      baseVolume
      quoteVolume
      feeBase
      feeQuote
      fromToken {
        id
        symbol
        name
        decimals
      }
      toToken {
        id
        symbol
        name
        decimals
      }
      amountIn
      amountOut
    }
  }
`;

export const fetchPool = gql`
  query FetchPool(
    $id: ID!
    $where: Pair_filter
    $cpWhere: CrowdPooling_filter
  ) {
    pair(id: $id, where: $where) {
      id
      type
      creator
      traderCount
      volumeBaseToken
      volumeQuoteToken
      volumeUSD
      feeBase
      feeQuote
      lpFeeRate
      i
      k
      baseReserve
      quoteReserve
      createdAtTimestamp
      lastTradePrice
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      baseLpToken {
        id
        symbol
        name
        totalSupply
        decimals
      }
      quoteLpToken {
        id
        symbol
        name
        totalSupply
        decimals
      }
    }
    crowdPoolings(
      first: 1
      where: $cpWhere
      orderBy: bidStartTime
      orderDirection: desc
    ) {
      id
      creator
      liquidator
      createTime
    }
  }
`;

export const fetchManageDppConfig = gql`
  query FetchManageDppConfig($where: ManagechainFilter) {
    manage_dpp_config(where: $where) {
      pools {
        address
      }
      banners {
        img_url
        title
        text
        pool_list
      }
      docs {
        title
        type
        text
        url
      }
      banner_max
    }
  }
`;

export const fetchDashboardPairsCountData = gql`
  query FetchDashboardPairsCountData($where: Dashboardtype_filter) {
    dashboard_pairs_count_data(where: $where) {
      totalVolume
      totalFee
      totalTvl
    }
  }
`;

export const fetcDashboardPairList = gql`
  query FetcDashboardPairList($where: Dashboardtype_list_filter) {
    dashboard_pairs_list(where: $where) {
      list {
        pairAddress
        poolType
        baseReserve
        quoteReserve
        totalFee
        baseAddress
        quoteAddress
        baseSymbol
        quoteSymbol
        baseTvl
        quoteTvl
        baseTvlRate
        quoteTvlRate
      }
      count
    }
  }
`;

export const fetchPoolPairList = gql`
  query FetchPoolPairList(
    $first: Int
    $baseWhere: Pair_filter
    $quoteWhere: Pair_filter
    $orderBy: Pair_orderBy
    $orderDirection: OrderDirection
  ) {
    basePairs: pairs(
      first: $first
      where: $baseWhere
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      type
      creator
      lpFeeRate
      i
      k
      baseReserve
      quoteReserve
      createdAtTimestamp
      lastTradePrice
      volumeUSD
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
    }
    quotePairs: pairs(
      first: $first
      where: $quoteWhere
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      id
      type
      creator
      lpFeeRate
      i
      k
      baseReserve
      quoteReserve
      createdAtTimestamp
      lastTradePrice
      volumeUSD
      baseToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
      quoteToken {
        id
        symbol
        name
        decimals
        usdPrice
      }
    }
  }
`;

export const fetchUserProfilePrivatePools = gql`
  query FetchUserProfilePrivatePools(
    $where: Userprofileprivate_pools_list_filter
  ) {
    userprofile_privatePools(where: $where) {
      chain
      user
      currentPage
      pageSize
      totalCount
      list {
        pairAddress
        totalFee
        totalVolume
        baseAddress
        baseSymbol
        quoteAddress
        quoteSymbol
        baseReserve
        quoteReserve
      }
    }
  }
`;
