import { gql } from '@apollo/client';

export const FetchVDODOUsers = gql`
  query fetchVDODOUsers(
    $first: Int
    $where: User_filter
    $orderBy: User_orderBy
  ) {
    users(
      first: $first
      where: $where
      orderBy: $orderBy
      orderDirection: desc
    ) {
      id
      stakingPower
      superiorSP
      superior
      credit
      creditFromInvited
      mintAmount
      redeemRecieveAmount
      redeemFeeAmount
      redeemBurnAmount
      creditOfSuperior
    }
  }
`;

export const fetchVDODOUser = gql`
  query FetchVDODOUser($id: ID!, $where: User_filter) {
    user(id: $id, where: $where) {
      id
      stakingPower
      superiorSP
      superior
      credit
      creditFromInvited
      mintAmount
      redeemRecieveAmount
      redeemFeeAmount
      redeemBurnAmount
      creditOfSuperior
    }
  }
`;

export const fetchVDODOUserOperationHistories = gql`
  query FetchVDODOUserOperationHistories($where: UserOperationHistory_filter) {
    userOperationHistories(
      where: $where
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      type
      amount
      timestamp
      superiorSpChange
      superiorCreditChange
      user {
        id
        superior
        superiorSP
      }
    }
  }
`;

export const fetchVDODOInfo = gql`
  query FetchVDODOInfo($id: ID!) {
    vDODO(id: $id) {
      id
      decimals
      totalUsers
      dodo
      mintAmount
      redeemAmount
      feeAmount
      dodoBalance
      totalBlockReward
      totalBlockDistribution
      totalDonate
      totalStakingPower
      dodoPerBlock
    }
  }
`;

export const fetchFeeInfo = gql`
  query FetchFeeInfo($where: VdodoChainsInput) {
    vdodo_getServiceCharges(where: $where) {
      dip3_fees_total
      dip3_fees_24h
      pool_fees_total
      pool_fees_24h
    }
  }
`;
