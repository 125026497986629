import { useQuery } from '@apollo/client';
import { getServiceDomain } from '@dodoex/utils';
import type { Token } from '@dodoex/token';
import { Map, OrderedMap } from 'immutable';
import { useEffect, useState } from 'react';
import { fetchErc20ListV2 } from './data/queries';
import {
  FetchErc20ListV2,
  FetchErc20ListV2Variables,
} from './data/__generated__/FetchErc20ListV2';

export enum Erc20FuncLabel {
  popularToken = 'popularToken',
}

/**
 * 查询 DODO 官方 tokenList
 * @param param0
 * @returns erc20List 全量的 tokenList
 * @returns filterErc20List 通过 chainId 筛选后的 tokenList
 * @notice 如果传入 chainId 则 erc20List = filterErc20List
 */
export const useGetErc20List = ({
  skip,
  chainId,
}: {
  skip?: boolean;
  chainId?: number;
} = {}) => {
  const { data, called } = useQuery<
    FetchErc20ListV2,
    FetchErc20ListV2Variables
  >(fetchErc20ListV2, {
    variables: {
      where: {
        page: 1,
        pageSize: 5000,
        // https://app.asana.com/0/0/1203174503953071/f
        // 只支持 chainId 查询，不传入则查询所有
        chainId,
      },
    },
    skip,
  });

  const [erc20List, setErc20List] = useState<Token[]>([]);
  const [filterErc20List, setFilterErc20List] = useState<
    OrderedMap<string, Token>
  >(OrderedMap());

  useEffect(() => {
    if (!called || !data || !data.erc20_listV2) return;
    const fullTokensMap: Token[] = [];
    let tokensMap: OrderedMap<string, Token> = OrderedMap();
    data.erc20_listV2.forEach((erc20) => {
      if (!erc20 || !erc20.address) return;
      // fullTokens
      const tokenRes = Map({
        address: erc20.address,
        decimals: erc20.decimals,
        name: erc20.name,
        source: null,
        symbol: erc20.symbol,
        opRank: null,
        logoUrl: (erc20.logoImg || '').replace('dodoex.io', getServiceDomain()),
        logo: (erc20.logoImg || '').replace('dodoex.io', getServiceDomain()),
        tokenlists: erc20.tokenlists,
        slippage: erc20.slippage,
        isPopular: !!erc20.funcLabels?.find(
          (item) => item?.key === Erc20FuncLabel.popularToken,
        ),
        chainId: erc20.chainId,
      }) as Token;
      // tokensFilter
      if (chainId) {
        const isCurrMainnetToken = erc20.chainId === chainId;
        const { hostname } = window.location;
        const isTestLink =
          hostname.includes('vercel.app') || hostname.includes('localhost');
        // 预发布环境等价于正式环境
        const hostName = hostname.includes('staging.dodoex.io')
          ? 'app.dodoex.io'
          : hostname;
        const erc20Domain = erc20.domains || [];
        const domainFilter =
          isTestLink ||
          erc20Domain.some(
            (domain) => domain && domain.name && hostName.includes(domain.name),
          );
        if (isCurrMainnetToken && domainFilter) {
          tokensMap = tokensMap.set(erc20.address, tokenRes);
        }
      }
      fullTokensMap.push(tokenRes);
    });
    setErc20List(fullTokensMap);
    setFilterErc20List(tokensMap);
  }, [data, called, chainId]);

  return {
    erc20List,
    filterErc20List,
  };
};
