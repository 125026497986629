import { dbGet } from './db';

export function getLocalItem(key: string) {
  try {
    return window.localStorage.getItem(key);
  } catch (error) {
    console.error('[get getLocalItem error]', error);
  }
  return null;
}

export function setLocalItem(key: string, value: string) {
  try {
    window.localStorage.setItem(key, value);
  } catch (error) {
    console.error('[get setLocalItem error]', error);
  }
  return null;
}

/**
 * rpc endpoint 列表
 */
export const RPC_ENDPOINT_LIST = 'rpc-endpoint-list';
export function getRpcEndpointFromLocalStorage(
  chainId: number,
  defaultEndpointUrl: string,
) {
  const rpcEndpointList = dbGet({
    path: RPC_ENDPOINT_LIST,
    defaultValue: {},
    dbName: 'sys',
  }) as Record<string, string>;
  if (Object.prototype.hasOwnProperty.call(rpcEndpointList, chainId)) {
    const rpcEndpoint = rpcEndpointList[chainId];
    if (rpcEndpoint) {
      return rpcEndpoint;
    }
  }

  return defaultEndpointUrl;
}
