/* eslint-disable eqeqeq */
import {
  arbConfig,
  arbRinkebyConfig,
  auroraConfig,
  avaxConfig,
  bobaConfig,
  bscConfig,
  ethConfig,
  gorConfig,
  hecoConfig,
  kcsConfig,
  maticConfig,
  moonriverConfig,
  okConfig,
  optimismConfig,
  rinkebyConfig,
} from '@dodoex/utils';

const { ARB_SWAPINFO } = arbConfig;
const { AURORA_SWAPINFO } = auroraConfig;
const { AVAX_SWAPINFO } = avaxConfig;
const { BOBA_SWAPINFO } = bobaConfig;
const { BSC_SWAPINFO } = bscConfig;
const { ETH_SWAPINFO } = ethConfig;
const { HECO_SWAPINFO } = hecoConfig;
const { MATIC_SWAPINFO } = maticConfig;
const { MOONRIVER_SWAPINFO } = moonriverConfig;
const { OK_SWAPINFO } = okConfig;
const { KCS_SWAPINFO } = kcsConfig;
const { ARBTEST_SWAPINFO } = arbRinkebyConfig;
const { GOR_SWAPINFO } = gorConfig;
const { OPTIMISM_SWAPINFO } = optimismConfig;
const { RINKEBY_SWAPINFO } = rinkebyConfig;

export { GetConfig } from '@dodoex/utils';

export function GetGeneralUniInfo(networkId: number): {
  name: string;
  factoryAddress: string;
  initHash: string;
  fee?: number;
}[] {
  if (networkId == 1) {
    return ETH_SWAPINFO;
  }
  if (networkId === 4) {
    return RINKEBY_SWAPINFO;
  }
  if (networkId == 5) {
    return GOR_SWAPINFO;
  }
  if (networkId === 10) {
    return OPTIMISM_SWAPINFO;
  }
  if (networkId == 56 || networkId == 86) {
    return BSC_SWAPINFO;
  }
  if (networkId == 66) {
    return OK_SWAPINFO;
  }
  if (networkId == 128) {
    return HECO_SWAPINFO;
  }
  if (networkId == 137) {
    return MATIC_SWAPINFO;
  }
  if (networkId == 288) {
    return BOBA_SWAPINFO;
  }
  if (networkId === 321) {
    return KCS_SWAPINFO;
  }
  if (networkId == 1285) {
    return MOONRIVER_SWAPINFO;
  }
  if (networkId == 42161) {
    return ARB_SWAPINFO;
  }
  if (networkId == 421611) {
    return ARBTEST_SWAPINFO;
  }
  if (networkId == 43114) {
    return AVAX_SWAPINFO;
  }
  if (networkId == 1313161554) {
    return AURORA_SWAPINFO;
  }
  return [];
}
