import { ApolloClient } from '@apollo/client';
import { TokenList as UniswapTokenList } from '@uniswap/token-lists';
import { ThirdTokenListKeys } from '@dodoex/token';
import { fetchThirdPartyTokenList } from './data/queries';
import {
  FetchThirdPartyTokenList,
  FetchThirdPartyTokenListVariables,
} from './data/__generated__/FetchThirdPartyTokenList';

const thirdTokenListInfoMap: {
  [key in ThirdTokenListKeys]: UniswapTokenList;
} = {
  // url: 'https://www.gemini.com/uniswap/manifest.json',
  [ThirdTokenListKeys.gemini]: {
    name: 'Gemini Token List',
    logoURI: 'https://gemini.com/static/images/loader.png',
    timestamp: '2022-06-22T14:15:22+0000',
    version: {
      major: 0,
      minor: 2,
      patch: 0,
    },
    tokens: [],
  },
  // https://static.optimism.io/optimism.tokenlist.json
  [ThirdTokenListKeys.optimism]: {
    name: 'Superchain Token List',
    logoURI: 'https://ethereum-optimism.github.io/optimism.svg',
    timestamp: '2023-04-14T15:43:37.999Z',
    version: {
      major: 7,
      minor: 3,
      patch: 33,
    },
    tokens: [],
  },
  // https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json
  [ThirdTokenListKeys.compound]: {
    name: 'Compound',
    logoURI:
      'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/compound-interface.svg',
    timestamp: '2021-05-27T20:37:00.000+00:00',
    version: {
      major: 2,
      minor: 0,
      patch: 0,
    },
    tokens: [],
  },
  // https://tokens.coingecko.com/uniswap/all.json
  [ThirdTokenListKeys.coingecko]: {
    name: 'CoinGecko',
    logoURI:
      'https://www.coingecko.com/assets/thumbnail-007177f3eca19695592f0b8b0eabbdae282b54154e1be912285c9034ea6cbaf2.png',
    timestamp: '2023-04-17T05:05:58.188+00:00',
    version: {
      major: 1302,
      minor: 1,
      patch: 0,
    },
    tokens: [],
  },
  // https://api.coinmarketcap.com/data-api/v3/uniswap/all.json
  [ThirdTokenListKeys.coinmarketcap]: {
    name: 'Coinmarketcap',
    logoURI: 'ipfs://QmQAGtNJ2rSGpnP6dh6PPKNSmZL8RTZXmgFwgTdy5Nz5mx',
    timestamp: '2023-04-16T18:08:00.222635Z',
    version: {
      major: 334,
      minor: 1,
      patch: 0,
    },
    tokens: [],
  },
};

export async function queryThirdPartyTokenList(
  client: ApolloClient<object>,
  {
    chainId,
    fromNames,
  }: {
    chainId: number;
    fromNames: ThirdTokenListKeys[];
  },
) {
  const { data } = await client.query<
    FetchThirdPartyTokenList,
    FetchThirdPartyTokenListVariables
  >({
    query: fetchThirdPartyTokenList,
    variables: {
      where: {
        chainId,
        fromNames,
      },
    },
  });

  const thirdPartyTokenListMap: {
    [key in ThirdTokenListKeys]?: UniswapTokenList;
  } = {};
  data?.third_party_token_list?.forEach((item) => {
    const key = item?.from as ThirdTokenListKeys;
    if (!key) return;
    const tokenInfo = {
      chainId,
      address: item?.address ?? '',
      decimals: item?.decimals as number,
      from: item?.from as ThirdTokenListKeys,
      logoURI: item?.logoURI ?? '',
      name: item?.name ?? '',
      symbol: item?.symbol ?? '',
    };
    if (!thirdPartyTokenListMap[key]) {
      thirdPartyTokenListMap[key] = thirdTokenListInfoMap[key];
    }
    (thirdPartyTokenListMap[key] as UniswapTokenList).tokens.push(tokenInfo);
  });

  return thirdPartyTokenListMap;
}
