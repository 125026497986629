import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLanguage } from 'src/configure-store/selectors/settings';
/**
 * 是否支持某个字体
 * @param f 字体名称
 * @returns boolean
 *
 * 兼容性支持：Chrome、
 */
export function isSupportFontFamily(f: string) {
  if (typeof f !== 'string') {
    return false;
  }
  const h = 'Arial';
  if (f.toLowerCase() === h.toLowerCase()) {
    return true;
  }
  const e = 'a';
  const d = 100;
  const a = 100;
  const i = 100;
  const c = document.createElement('canvas');
  const b = c.getContext('2d');
  c.width = a;
  c.height = i;
  if (!b) {
    return false;
  }
  b.textAlign = 'center';
  b.fillStyle = 'black';
  b.textBaseline = 'middle';
  function g(j: number | string) {
    if (b) {
      b.clearRect(0, 0, a, i);
      b.font = `${d}px ${j}, ${h}`;
      b.fillText(e, a / 2, i / 2);
      const k = b.getImageData(0, 0, a, i).data;
      return [].slice.call(k).filter((l) => {
        return l !== 0;
      });
    }
    return [];
  }
  return g(h).join('') !== g(f).join('');
}

export const useFontFamily = () => {
  const lang = useSelector(getLanguage);

  useEffect(() => {
    if (lang !== 'jaJP' || isSupportFontFamily('Meiryo')) {
      return;
    }

    const style = document.createElement('style');
    style.innerText = `
    @font-face {
      font-family: 'Meiryo';
      font-style: normal;
      font-weight: 400;
      src: local('Meiryo'), local('Meiryo-Regular'), url(${`${window.location.origin}/meiryo.ttc`}) format('truetype');
    }
    `;
    document.head.append(style);
  }, [lang]);
};
