/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { useQueryTokenByAllToken } from '../../hooks/useQueryTokenByAllToken';
import { TokenLogoByToken, TokenLogoByTokenProps } from './TokenLogoByToken';

export interface TokenLogoProps extends TokenLogoByTokenProps {
  address?: string;
  includeAllChain?: boolean;
  chainId?: number;
}

export function TokenLogo({
  address,
  token,
  includeAllChain,
  chainId,
  notCheckToken: notCheckTokenProps,
  ...attrs
}: TokenLogoProps) {
  const getToken = useQueryTokenByAllToken({
    includeAllChain,
    chainId,
  });
  const tokenRes = useMemo(() => {
    return token || (address ? getToken(address) : undefined);
  }, [getToken, token, address]);

  const notCheckToken = useMemo(() => {
    if (notCheckTokenProps !== undefined) return notCheckTokenProps;
    // 有传 chainId 的情况，不需要去检查。因为 checkToken 是请求当前链的数据
    if (chainId) return true;
    return undefined;
  }, [chainId, notCheckTokenProps]);

  return (
    <TokenLogoByToken
      {...attrs}
      notCheckToken={notCheckToken}
      token={tokenRes}
      address={address}
    />
  );
}
