import { generateLinkTo, getServiceDomain } from '.';
import { kStaticServerEndPoint } from './request';

export const isLocalhost =
  window.location.hostname === 'localhost' ||
  /^[0-9.]+[0-9]$/.test(window.location.hostname);
export const isTestLink =
  window.location.hostname.includes('vercel.app') &&
  window.location.hostname.includes('git');
export const APP_URL = isTestLink
  ? `https://${window.location.hostname.replace('dashboard', 'lite')}`
  : 'https://app.dodoex.io';
export const DASHBOARD_URL = isTestLink
  ? `https://${window.location.hostname.replace('lite', 'dashboard')}`
  : 'https://info.dodoex.io';
export const DEX_URL = 'https://legacy.dodoex.io';
export const MainDomain = 'https://dodoex.io/';

const CDN_IPFS_GATEWAY = `https://ipfs.${getServiceDomain()}/ipfs`;
// const IPFS_WRITE_GATEWAY = `https://upload-ipfs.${getServiceDomain()}/`;

export const IMG_PROXY_URL = `https://image-proxy.${getServiceDomain()}`;

export const cdnUrl = (url: string) => {
  const shouldUsingCDN = true;
  const hasCDN = url.indexOf('cdn-');
  if (hasCDN > 0) {
    if (shouldUsingCDN) {
      return url;
    }
    return url.replace('cdn-', '');
  }
  if (shouldUsingCDN) {
    return url.replace('https://', 'https://cdn-');
  }
  return url;
};

export const getStaticUrl = () => {
  return cdnUrl(kStaticServerEndPoint);
};

export const open = (url: string, chainId?: number, query?: string) => {
  let path = url;
  if (chainId) {
    path = generateLinkTo(url, chainId, query);
  }
  const isMobile = document.documentElement.clientWidth < 768;
  if (isMobile && path) {
    window.location.href = path;
    return window;
  }
  return window.open(path);
};

export const appendUrlSearchParam = (
  url = '',
  key = '',
  value = '',
): string => {
  if (!url || !key || !value) return url;

  try {
    const instance = new URL(url);
    instance.searchParams.append(key, value);
    return instance.href;
  } catch (e) {
    return url;
  }
};

export const parseTextTemplateWithVariables = (
  template: string,
  variables: { [key: string]: string | number | undefined },
) => {
  Object.keys(variables).forEach((key) => {
    const reg = new RegExp(`{{${key}}}`, 'g');
    // eslint-disable-next-line no-param-reassign
    template = template.replace(reg, String(variables[key] ?? '--'));
  });
  return template;
};

export function normalizeUri(uri?: string) {
  if (!uri) {
    return '';
  }
  if (uri.startsWith('http:') || uri.startsWith('https:')) {
    return uri;
  }
  if (uri.startsWith('ipfs:')) {
    const pathname = uri.replace('ipfs://', '');
    return `${CDN_IPFS_GATEWAY}/${pathname}`;
  }
  return '';
}

export function validUri(uri: string) {
  if (!uri) return true;
  return (
    uri.startsWith('http:') ||
    uri.startsWith('https:') ||
    uri.startsWith('ipfs:') ||
    uri.endsWith('.eth')
  );
}

export function pingUrl(url: string, delay?: number) {
  return new Promise<void>((resolve, reject) => {
    const ele = document.createElement('link');
    ele.href = url;
    ele.setAttribute('rel', 'preload');
    ele.setAttribute('as', 'script');
    document.body.append(ele);
    let time: NodeJS.Timeout | null = null;
    ele.onload = () => {
      if (time) {
        clearInterval(time);
      }
      resolve();
      document.body.removeChild(ele);
    };
    ele.onerror = () => {
      if (time) {
        clearInterval(time);
      }
      reject();
      document.body.removeChild(ele);
    };
    if (delay) {
      time = setTimeout(() => {
        document.body.removeChild(ele);
        reject();
      }, delay);
    }
  });
}
