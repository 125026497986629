/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ButtonBase,
  ButtonBaseProps,
  SimplePaletteColorOptions,
  BoxProps,
  Theme,
  useTheme,
  Box,
} from '@mui/material';
import { CSSSelectorObject, SystemCssProperties, alpha } from '@mui/system';
import { merge } from 'lodash';
import { makeStyles } from '@mui/styles';
import { ReactComponent as LadingIcon } from './icons/loading.svg';

enum Variant {
  contained = 'contained',
  outlined = 'outlined',
  dashed = 'dashed',
  second = 'second',
  tag = 'tag',
  tagText = 'tagText',
}

enum Size {
  small = 'small',
  middle = 'middle',
  big = 'big',
}

interface StyleProps {
  sx?: BoxProps['sx'];
  fullWidth?: boolean;
  variant?: Variant;
  danger?: boolean;
  /**
   * 默认 middle, 高 48
   */
  size?: Size;
}

// @ts-ignore
export interface Props extends StyleProps, React.HTMLProps<HTMLButtonElement> {
  disabled?: boolean;
  children?: React.ReactNode | string | number;
  to?: string;
  component?: React.ElementType;
  isLoading?: boolean;
}

const buttonStyles = (
  { fullWidth, variant, sx, danger, size }: StyleProps,
  theme: Theme,
) => {
  let result: SystemCssProperties<Theme> | CSSSelectorObject<Theme> = {
    px: 16,
    py: 10,
    // 应用字体
    typography: 'body1',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    fontFamily: 'inherit',
  };
  switch (size) {
    case Size.small:
      result.height = 36;
      result.borderRadius = 8;
      result.typography = 'body2';
      break;
    case Size.big:
      result.height = 60;
      result.borderRadius = 16;
      break;

    default:
      result.height = 48;
      result.borderRadius = 8;
      break;
  }
  if (fullWidth) {
    result.display = 'flex';
    result.width = '100%';
  }
  switch (variant) {
    case Variant.outlined:
      result = {
        ...result,
        border: `solid 1px ${
          danger ? theme.palette.error.main : theme.palette.primary.main
        }`,
        color: danger ? 'error.main' : 'primary.main',
        '&[disabled]': {
          color: 'text.disabled',
          borderColor: 'custom.border.disabled',
        },
        '&:hover': {
          background: danger
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(theme.palette.primary.main, 0.1),
        },
      };
      break;
    case Variant.dashed:
      result = {
        ...result,
        // 当边框为虚线时，如果 height 为 偶数，上边框会有点虚
        height: result.height - 1,
        border: `dashed 1px ${
          danger
            ? theme.palette.error.main
            : alpha(theme.palette.custom.button.tag, 0.3)
        }`,
        color: danger ? 'error.main' : 'primary.main',
        '&[disabled]': {
          backgroundColor: 'custom.background.disabled',
          color: 'text.disabled',
        },
        '&:hover': {
          background: danger
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(alpha(theme.palette.custom.button.tag, 0.3), 0.1),
        },
      };
      break;
    case Variant.second:
      result = {
        ...result,
        backgroundColor: danger
          ? 'error.main'
          : alpha(theme.palette.custom.button.tag, 0.1),
        border: 'none',
        color: danger ? 'error.contrastText' : 'text.main',
        '&[disabled]': {
          backgroundColor: 'custom.background.disabled',
          color: 'text.disabled',
        },
        '&:hover': {
          background: danger
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(alpha(theme.palette.custom.button.tag, 0.1), 0.2),
        },
      };
      break;
    case Variant.tag:
      result = {
        ...result,
        px: 10,
        py: 3,
        height: 'auto',
        borderRadius: 4,
        typography: 'body2',
        backgroundColor: danger
          ? 'error.main'
          : alpha(theme.palette.custom.button.tag, 0.1),
        border: 'none',
        color: danger ? 'error.contrastText' : 'primary.main',
        '&[disabled]': {
          backgroundColor: 'custom.background.disabled',
          color: 'text.disabled',
        },
        '&:hover': {
          background: danger
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(alpha(theme.palette.custom.button.tag, 0.1), 0.2),
        },
      };
      break;
    case Variant.tagText:
      result = {
        ...result,
        px: 16,
        py: 4,
        height: 'auto',
        borderRadius: 4,
        typography: 'h6',
        backgroundColor: danger
          ? 'error.main'
          : alpha(theme.palette.custom.button.tag, 0.1),
        border: 'none',
        color: danger ? 'error.contrastText' : 'text.primary',
        '&[disabled]': {
          backgroundColor: 'custom.background.disabled',
          color: 'text.disabled',
        },
        '&:hover': {
          background: danger
            ? alpha(theme.palette.error.main, 0.1)
            : alpha(alpha(theme.palette.custom.button.tag, 0.1), 0.2),
        },
      };
      delete result.fontWeight;
      break;
    default:
      result = {
        ...result,
        backgroundColor: danger ? 'error.main' : 'custom.button.main',
        '&:hover': {
          background: `linear-gradient(0deg, rgba(26, 26, 27, 0.1), rgba(26, 26, 27, 0.1)), ${
            danger
              ? theme.palette.error.main
              : // @ts-ignore
                sx?.backgroundColor || theme.palette.custom.button.main
          }`,
        },
        color: danger ? 'error.contrastText' : 'custom.button.mainContrast',
        '&[disabled]': {
          backgroundColor: 'custom.background.disabled',
          color: 'text.disabled',
        },
      };
      break;
  }
  return merge(result, sx);
};

export function Button({
  onClick,
  disabled,
  children,
  component,
  to,
  href,
  target,
  isLoading,
  ...styleAttrs
}: Props) {
  const theme = useTheme();
  const attrs = {
    disableTouchRipple: true,
    disableRipple: true,
    disabled,
    sx: buttonStyles(styleAttrs, theme),
    onClick,
    component,
    to,
    href,
    target,
  } as ButtonBaseProps;

  const loadingStyles = makeStyles({
    '@keyframes loadingRotate': {
      from: { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(359deg)' },
    },
    loading: {
      animation: '$loadingRotate 1.1s infinite linear',
    },
  });

  const loadingStylesClasses = loadingStyles();

  return (
    <ButtonBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attrs}
      disabled={isLoading ? true : disabled}
      className={styleAttrs.variant || Variant.contained}
    >
      <>
        {isLoading && (
          <Box
            component={LadingIcon}
            className={loadingStylesClasses.loading}
            sx={{
              '& path': {
                fill: theme.palette.text.disabled,
              },
            }}
          />
        )}

        {children}
      </>
    </ButtonBase>
  );
}

Button.Variant = Variant;
Button.Size = Size;

export default Button;
