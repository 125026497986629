import { AnyAction } from 'redux';

export type State = {
  // 用于exchangeIntroModal弹窗关闭后，再显示九宫格tooltip
  exchangeIntroModalShow: boolean;
};

export const initialSystemState: State = {
  exchangeIntroModalShow: false,
};

export default (
  state: State = initialSystemState,
  action: AnyAction,
): State => {
  switch (action.type) {
    case 'SET_EXCHANGE_INTRO_MODAL_SHOW':
      return {
        ...state,
        exchangeIntroModalShow: action.payload,
      };
    default:
      return state;
  }
};
