export const isAndroid =
  window.navigator?.userAgent && /(Android)/i.test(navigator.userAgent);

export const isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);

export const isPWA =
  typeof window.matchMedia === 'function' &&
  ['standalone', 'fullscreen', 'minimal-ui'].some(
    (displayMode) =>
      window.matchMedia(`(display-mode: ${displayMode})`).matches,
  );
