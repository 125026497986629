import { display as uAuthDisplay } from '@uauth/web3modal';
/* eslint-disable no-plusplus */
/**
 * 在选择钱包时，根据当前页的钱包顺序依次判断显示
 */
import { APP_URL } from '@dodoex/utils';
import { Wallet, WalletType } from './types';
import { ReactComponent as CoinbaseLogo } from '../../components/images/logos/coinbase.svg';
import { ReactComponent as BinanceChainLogo } from '../../components/images/logos/binance-chain.svg';
import { ReactComponent as WalletConnectLogo } from '../../components/images/logos/walletconnect.svg';
import { ReactComponent as PortisLogo } from '../../components/images/logos/portis.svg';
import { ReactComponent as GnosisSafeLogo } from '../../components/images/logos/gnosis-safe.svg';
import { ReactComponent as LedgerLogo } from '../../components/images/logos/ledger.svg';
import { ReactComponent as OpenBlockLogo } from '../../components/images/logos/openBlock.svg';
import { ReactComponent as NaboxLogo } from '../../components/images/logos/nabox.svg';
import { ReactComponent as OnekeyLogo } from '../../components/images/logos/onekey.svg';
import { ReactComponent as BitkeepLogo } from '../../components/images/logos/bitkeep.svg';
import { ReactComponent as OKXWalletLogo } from '../../components/images/logos/okx-wallet.svg';
import { ReactComponent as KuCoinLogo } from '../../components/images/logos/kuCoin.svg';
import { ReactComponent as FrontierLogo } from '../../components/images/logos/frontier.svg';
import { ReactComponent as UnstoppabledomainsLogo } from '../../components/images/logos/unstoppabledomains.svg';
import { walletConfig } from '../../core';
import { ChainType } from '../chain';
import { isSupportWebHid } from '../../ledger/connect';
import { getInjectProvider } from './injectProvider';
import { getIsMobile } from '../../utils/web';

let sort = 1;

export const Coinbase: Wallet = {
  type: WalletType.WalletLink,
  typeName: 'Coinbase',
  showName: 'Coinbase Wallet',
  logo: <CoinbaseLogo />,
  // 没有浏览器插件的情况下，才使用 walletlink
  checked: () =>
    !window.ethereum ||
    (!window.ethereum.isWalletLink &&
      !window.ethereum.isToshi &&
      document &&
      getIsMobile()),
  sort: sort++,
  supportMobile: true,
};

export const BSC: Wallet = {
  type: WalletType.BinanceChain,
  typeName: 'BinanceChain',
  showName: 'Binance Chain Wallet',
  logo: <BinanceChainLogo />,
  chainTypes: [ChainType.ETH, ChainType.BSC],
  link: 'https://www.bnbchain.world/en/binance-wallet',
  checked: () => window.BinanceChain,
  sort: sort++,
  supportMobile: true,
};

export const Frontier: Wallet = {
  type: WalletType.frontier,
  typeName: 'Frontier',
  showName: 'Frontier',
  logo: <FrontierLogo />,
  link: 'https://www.frontier.xyz/download',
  checked: () => getInjectProvider(WalletType.frontier),
  sort: sort++,
};

export const KuCoin: Wallet = {
  type: WalletType.kuCoinWallet,
  typeName: 'KuCoin Wallet',
  showName: 'KuCoin Wallet',
  logo: <KuCoinLogo />,
  checked: () => getInjectProvider(WalletType.kuCoinWallet),
  link: 'https://kuwallet.com/',
  sort: sort++,
  supportMobile: true,
  isMobileInjected: true,
};

export const Ledger: Wallet = {
  type: WalletType.LedgerUSB,
  typeName: 'Ledger',
  showName: 'Ledger',
  logo: <LedgerLogo />,
  chainTypes: [ChainType.ETH, ChainType.BSC, ChainType.Polygon],
  // chainTypes: [ChainType.ETH, ChainType.BSC],
  checked: isSupportWebHid,
  sort: sort++,
};

export const OpenBlock: Wallet = {
  type: WalletType.openBlock,
  typeName: 'OpenBlock',
  showName: 'OpenBlock',
  logo: <OpenBlockLogo />,
  chainTypes: [
    ChainType.ETH,
    ChainType.BSC,
    ChainType.Polygon,
    ChainType.Heco,
    ChainType.Arbitrum,
    ChainType.OKChain,
    ChainType.Optimism,
    ChainType.Avalanche,
  ],
  checked: () => true,
  sort: sort++,
};

export const OneKey: Wallet = {
  type: WalletType.OneKey,
  typeName: 'OneKey',
  showName: 'OneKey',
  logo: <OnekeyLogo />,
  checked: () =>
    !!window.onekey ||
    !!window.ethereum?.isOneKey ||
    getInjectProvider(WalletType.OneKey),
  link: 'https://onekey.so',
  sort: sort++,
  supportMobile: true,
  isMobileInjected: true,
};

export const Bitkeep: Wallet = {
  type: WalletType.Bitkeep,
  typeName: 'Bitkeep',
  showName: 'Bitkeep',
  logo: <BitkeepLogo />,
  checked: () => getInjectProvider(WalletType.Bitkeep),
  link: 'https://bitkeep.com/download?type=2',
  sort: sort++,
  supportMobile: true,
  isMobileInjected: true,
  mobileDeepLink: `https://bkcode.vip?action=dapp&url=${APP_URL}`,
};

export const Nabox: Wallet = {
  type: WalletType.Nabox,
  typeName: 'Nabox',
  showName: 'Nabox',
  logo: <NaboxLogo />,
  checked: () => getInjectProvider(WalletType.Nabox),
  link: 'https://nabox.io/',
  sort: sort++,
  supportMobile: true,
  isMobileInjected: true,
};

export const OKXWallet: Wallet = {
  type: WalletType.OKX,
  typeName: 'OKX Wallet',
  showName: 'OKX Wallet',
  logo: <OKXWalletLogo />,
  checked: () => getInjectProvider(WalletType.OKX),
  link: 'https://www.okx.com/',
  sort: sort++,
  isMobileInjected: true,
};

export const WalletConnect: Wallet = {
  type: WalletType.WalletConnect,
  typeName: 'WalletConnect',
  showName: 'WalletConnect',
  logo: <WalletConnectLogo />,
  checked: () => true,
  sort: sort++,
  supportMobile: true,
};

export const Portis: Wallet = {
  type: WalletType.Portis,
  typeName: 'Portis',
  showName: 'Portis',
  logo: <PortisLogo />,
  chainTypes: [ChainType.ETH],
  checked: () => !!walletConfig.PORTIS_ID,
  sort: sort++,
};

export const Gnosis: Wallet = {
  type: WalletType.Gnosis,
  typeName: 'Gnosis',
  showName: 'Gnosis',
  logo: <GnosisSafeLogo />,
  checked: () => true,
  chainTypes: [ChainType.ETH],
  isSafe: true,
  sort: sort++,
};

export const UAuth: Wallet = {
  type: WalletType.uAuth,
  typeName: uAuthDisplay.name,
  showName: uAuthDisplay.name,
  logo: <UnstoppabledomainsLogo />,
  checked: () => true,
  sort: sort++,
};
