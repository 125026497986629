import { ContractConfigDataI } from '../type';

export const BSC_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    name: 'Wrapped BNB',
    source: 'dodo',
  },
  MULTI_CALL: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  DODO_TOKEN: '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',

  ERC20_HELPER: '0x566651Ad34f6306872FaC5fB95bbF4C9beE8D8F2',
  ERC20V3_FACTORY: '0x5eD22a48Fee263207f5cAAE1A1f1a27011e7Dc92',

  DODO_APPROVE: '0xa128Ba44B2738A558A1fdC06d6303d52D3Cef8c1',

  DODO_PROXY: '0x8F8Dd7DB1bDA5eD3da8C9daf3bfa471c12d58486',
  DODO_DSP_PROXY: '0x2442A8B5cdf1E659F3F949A7E454Caa554D4E65a',
  DODO_DPP_PROXY: '0x624FC8368fE11BE00D8B2F3fE0B9D0053BEc21b9',
  DODO_V1_PAIR_PROXY: '0x4Fc222194862081866FC636893DDeCB3E4341A10',
  DODO_CP_PROXY_V2: '0x6990254C0Dcae91e2FD93C493D33570cDaBC3dC6',
  DODO_MINEV3_PROXY: '0x8f3fB1f9148dC473453163bF31de1d830Eb5c590',
  DODO_STARTER_PROXY: '0x81521b0E899112B36fE7218954A81b7Fe8FfE8D1',

  DVM_FACTORY: '0x790B4A80Fb1094589A3c0eFC8740aA9b0C1733fB',
  DSP_FACTORY: '0x0fb9815938Ad069Bf90E14FE6C596c514BEDe767',
  CP_FACTORY_V2: '0x224fEce8104771478a3A4CE6D92ab1538d3659ee',

  // ROUTE_V2_DATA_FETCH: "0xf2cd74A4206E6A4281c709c0274e2d0e286Cd617",//origin
  ROUTE_V2_DATA_FETCH: '0x35b8F92AcFE368afb90d37555f6d075Fc569a6BA', // "0xd9d39391959e281284512b71bf79E0c88e9c4468",cutting
  ROUTE_V1_DATA_FETCH: '0x2BBD66fC4898242BDBD2583BBe1d76E8b8f71445',
  CALLEE_HELPER: '0x2673E5333620bb22BD1bFB3af9Fc7012008E3b4B',
  FEE_RATE_IMPL: '0x04bfc975eb76ac870c1bce812992ab3767d809b6', // 0x06C19e1D97AadD6f30a8A2be4c5DeFa30976322b
  vDODO: '0x4D6A41C682874E5dd1BBD58184eE8FF145C89202',
  vDODO_DODO_TEAM: '0xcaa42F09AF66A8BAE3A7445a7f63DAD97c11638b',
  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0x2d8349E957A69E4cC7B4ef225A4B6a85Be57FBF3',
  DODO_NFT_PROXY: '0x0D224A4a6Da3Cfb360B9879965b1f78865798C71',
  BUYOUT_MODEL: '0xfEA1AcAD77F64bf6E933635eD078e63d22484197',
  DODO_NFT_REGISTER: '0x2c0Dd764A0bE8A7e747Cb0765E268d576edce31a',
  DODO_NFT_ERC721: '0x5eD972C0a7f8D0C4dEDf6a37848A297B67489888',
  DODO_NFT_ERC1155: '0xdC9224cbc4B1826Bb74fF383FF1CE94DE1F56119',

  DODO_NFTPOOL_PROXY: '0x509D3775F684fA4d19C9C99B961202e309B5B965',
  DODO_NFT_APPROVE: '0x8f697865Fb43236683a174feE87fD84dB64C7A6c',
  // DODO_DROPS_PROXY: "0xC05A30468d039381AaBaB6dcaC31078DB2C3323B"
};

export const BSC_SWAPINFO = [
  {
    // price comparison
    name: 'Pancake V2',
    factoryAddress: '0xca143ce32fe78f1f7019d7d551a6402fc5350c73',
    initHash:
      '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5',
    fee: 25,
  },
  {
    name: 'Pancake V1',
    factoryAddress: '0xBCfCcbde45cE874adCB698cC183deBcF17952812',
    initHash:
      '0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66',
  },
  {
    name: 'MDEX',
    factoryAddress: '0x3cd1c46068daea5ebb0d3f55f6915b10648062b8',
    initHash:
      '0x0d994d996174b05cfc7bed897dc1b20b4c458fc8d64fe98bc78b3c64a6b4d093',
  },
  {
    name: 'Bakery',
    factoryAddress: '0x01bF7C66c6BD861915CdaaE475042d3c4BaE16A7',
    initHash:
      '0xe2e87433120e32c4738a7d8f3271f3d872cbe16241d67537139158d90bac61d3',
  },
  {
    name: 'BiSwap',
    factoryAddress: '0x858E3312ed3A876947EA49d572A7C42DE08af7EE',
    initHash:
      '0xfea293c909d87cd4153593f077b76bb7e94340200f4ee84211ae8e4f9bd7ffdf',
  },
  {
    name: 'ApeSwap',
    factoryAddress: '0x0841BD0B734E4F5853f0dD8d7Ea041c241fb0Da6',
    initHash:
      '0xf4ccce374816856d11f00e4069e7cada164065686fbef53c6167a63ec2fd8c5b',
  },
  {
    name: 'BabySwap',
    factoryAddress: '0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da',
    initHash:
      '0x48c8bec5512d397a5d512fbb7d83d515e7b6d91e9838730bd1aa1b16575da7f5',
    fee: 20,
  },
];
