import { AnyAction } from 'redux';

export type SlippageWithTokens = {
  slippage: string;
  tokens: string[];
};

export type TokenState = {
  slippageWithTokens: SlippageWithTokens[];
};

export const initialTokenState: TokenState = {
  slippageWithTokens: [],
};

export default (
  state: TokenState = initialTokenState,
  action: AnyAction,
): TokenState => {
  switch (action.type) {
    case 'SET_TOKEN_LIST_WITH_SLIPPAGE':
      return {
        ...state,
        slippageWithTokens: action.payload,
      };
    default:
      return state;
  }
};
