import { LpTokenPlatformID } from '@dodoex/utils';
import BigNumber from 'bignumber.js';
import { Market } from '../../setting/getConfig/types';

export interface RewardTokenWithBlockI {
  /** 奖励代币地址：若未配置则抛出异常 */
  rewardToken: string;
  /** 奖励开始释放区块高度：若未配置则默认为 null, 视为已结束 */
  startBlock: BigNumber | null;
  /** 奖励结束释放区块高度：若未配置则默认为 null, 视为已结束 */
  endBlock: BigNumber | null;
  /** 每区块释放奖励数量 ：若未配置则默认为0 */
  rewardPerBlock: BigNumber;

  /** v2 特有：从 online-configs 中获取到 */
  rewardTokenSymbol?: string;
}

export interface TabMiningI
  extends Pick<Market, 'type' | 'version' | 'vdodoDODOTokenAddress'> {
  /** 挖矿活动名称 */
  name?: string;
  /**
   * 存入代币地址：
   *
   * v2 dvm 池子合约地址、
   *
   * v2 classical 池子合约地址、
   *
   * v2 single 类型存入代币地址；
   *
   * v3 lptoken 合约地址（也是 dvm dsp 资金池地址）、
   *
   * v3 single 类型存入代币地址、
   *
   * v3 vdodo 合约地址、
   *
   * v3 classical 池子合约地址
   */
  stakeToken: string;
  /**
   * 挖矿合约地址
   * v3 classical baseLpToken 对应的挖矿合约对称
   */
  miningContractAddress: string;
  /**
   * v3 classical quoteLpToken 对应的挖矿合约地址
   */
  quoteLpTokenMiningContractAddress?: string;
  /**
   * v2 版本只有一个奖励代币；v3 可能有多个;
   * v2 classical 类型 rewardPerBlock 值需要 lpToken 合约地址，暂时置为 0，后续计算
   * v3 classical baseLpToken 对应的挖矿合约奖励代币列表
   */
  rewardTokenInfoList: RewardTokenWithBlockI[];
  /**
   * v3 classical quoteLpToken 对应的挖矿合约奖励代币列表
   */
  quoteLpTokenRewardTokenInfoList?: RewardTokenWithBlockI[];
  /** 挖矿活动来源：
   *
   * 官方 online-configs 配置,
   *
   * 非官方 项目方自主创建挖矿活动，可以选择 DODO 官方资金池或其他平台资金池
   */
  source: 'official' | 'unofficial';
  /**
   * 挖矿活动质押代币来源：
   *
   * v2 single, v3 single 任意 erc20 代币，
   *
   * v2 dvm 和 v2 classical 为 DODO 资金池做市得到的 lptoken,
   *
   * v3 vdodo 为 DODO 代币换得 vDODO 代币，
   *
   * v3 lptoken 有可能为 DODO 资金池做市得到的 lptoken, 也可能在其他平台做市换得的 lptoken, 需要特殊标识，
   *
   * v3 classical 为 DODO 资金池任意一边做事得到的 baseLpToken 或 quoteLpToken
   */
  lpTokenPlatformID: LpTokenPlatformID;
}

export enum MiningStatusE {
  upcoming,
  active,
  ended,
}
export interface MiningWithStatusI extends TabMiningI {
  status: MiningStatusE;
  /**
   * v3 classical baseLpToken 挖矿状态
   */
  baseLpTokenStatus: MiningStatusE;
  /**
   * v3 classical quoteLpToken 挖矿状态
   */
  quoteLpTokenStatus: MiningStatusE;
  /**
   * 挖矿活动的开始区块高度（多个奖励代币中最早开始释放的奖励代币的的开始区块高度）
   * v3 classical baseLpToken 挖矿合约奖励代币列表中最早开始释放的奖励代币的开始区块高度
   * v3 classical baseLpToken 和 quoteLpToken 开始区块高度不同，分开显示倒计时
   */
  startBlockHeight?: BigNumber;
  /**
   * v3 classical quoteLpToken 挖矿合约奖励代币列表中最早开始释放的奖励代币的开始区块高度
   */
  quoteLpTokenStartBlockHeight?: BigNumber;
  /** 当前快高 */
  currentBlockHeight: BigNumber;
}

export interface MiningStakeTokenI {
  address: string;
  decimals?: number;
  symbol?: string;
  name?: string;
}

/**
 * 有 lptoken 地址的挖矿数据结构
 */
export interface MiningWithLpTokenI {
  // v3 lptoken(其他平台):
  /** v2 single, v2 dvm, v3 single, v3 vdodo, v3 lptoken(DODO 平台): 质押代币 lptoken */
  lpToken?: MiningStakeTokenI;
  /**
   * v2 single, v2 dvm, v3 single, v3 vdodo, v3 lptoken(DODO 平台): 用户在挖矿合约中所质押 lptoken 的份额 ；
   *
   * v3 lptoken(其他平台)：只有 lpTokenStakeBalance 这个数据，其他数据需要在从合约上面查询
   */
  lpTokenStakeBalance?: BigNumber;
  /** v2 classical, v3 classical, v2 dvm, v3 lptoken(DODO 平台): 原代币 baseToken */
  baseToken?: MiningStakeTokenI;
  /** v2 classical, v3 classical, v2 dvm, v3 lptoken(DODO 平台): 原代币 quoteToken */
  quoteToken?: MiningStakeTokenI;
  /** v2 classical, v3 classical：baseToken 对应的 lptoken */
  baseLpToken?: MiningStakeTokenI;
  /** v2 classical, v3 classical：用户在挖矿合约中质押的 baseLpToken 的余额 */
  baseLpTokenStakeBalance?: BigNumber;
  /** v2 classical, v3 classical：quoteToken 对应的 lptoken */
  quoteLpToken?: MiningStakeTokenI;
  /** v2 classical, v3 classical：用户在挖矿合约中质押的 quoteLpToken 的余额 */
  quoteLpTokenStakeBalance?: BigNumber;
}
