export const kCandidates = [
  'Uniswap',
  'Balancer',
  'Sushi',
  '0xProtocol',
  'Kyber',
  'Bancor',
  'OneBit',
  'DODO',
];
export const kBSCCandidates = [
  'Pancake',
  '1inch',
  '0xProtocol',
  'Wootrade',
  'Acryptos',
  'ApeSwap',
  'Bakery Swap',
  'Burger Swap',
  'JulSwap',
  'Street Swap',
  'Pancake V2',
  'MDEX',
];
export const kHecoCandidates = [
  'MDEX',
  'DODOV1',
  'DODOV2',
  'BXH',
  'Pippi',
  'MDIS',
];

export const kMaticCandidates = [
  'Sushi',
  'Curve',
  'QuickSwap',
  'DODOV2',
  'DODOV1',
  'mStable',
  'Dfyn',
  'Cometh',
  '0xProtocol',
  'ParaSwap',
];

export const kOkchainCandidates = ['CherrySwap', 'DODOV2', 'DODOV1'];

export const kArbitrumCandidates = [];

export const kArbRinkebyCandidates = ['DODOV2'];

export const kGorCandidates = ['DODOV2'];

export const kAuroraCandidates = [];

export const kOptimismCandidates = ['DODOV1', 'DODOV2', '0xProtocol', '1inch'];

export const kBobaCandidates = ['DODOV1', 'DODOV2'];

export const kMoonriverCandidates = ['DODOV1', 'DODOV2'];

export const kKCSCandidates = ['DODOV1', 'DODOV2'];

/**
 * 禁用路由
 */
export const kDisabledRoutingCandidates = ['ParaSwap', 'DODO'];
