import { cdnUrl, getChain, request } from '@dodoex/utils';
import Immutable, { Map } from 'immutable';
import { setEnv } from './env';
import { LegacyMarket, Market, RowMarket } from './types';

export * from './env';
export {
  default as marketReducers,
  initialState as marketInit,
} from './market';
export type {
  LegacyMarket,
  Market,
  RowMarket,
  State as marketState,
} from './types';
export { useRpcEndpointConfig } from './useRpcEndpointConfig';

export const getConfig = (chainId: number) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return async (dispatch: any) => {
    const chain = getChain(chainId);
    const configUrl = cdnUrl(chain.configUrl);
    let data = {
      endpoints: {
        // global: 'https://eth-rinkeby.dodoex.io',
      },
      ethProxyAddress: '',
      nativeToken: '',
      mine: [],
      markets: [],
      tokens: [],
      miningDODOTokenAddress: '',
      miningTokenSymbol: '',
      miningContractAddress: '',
      miningDODOMarketAddress: '',
      preminingContractAddress: '',
      miningDODOReaderAddress: '',
      crowdpooling: [],
      mtFeeRate: {
        '0xaFF366ACCd2d8958240F331d74AB39735A0baBee': 0,
      },
      mineOrg: {},
    };
    try {
      const res = await request.get(configUrl);
      data = res.data;
    } catch (error) {
      console.error('load config failed', error);
    }

    setEnv('MINING_CONTRACT_ADDRESS', data.miningContractAddress);
    setEnv('MINING_DODO_ADDRESS', data.miningDODOTokenAddress);
    setEnv('PREMINING_CONTRACT_ADDRESS', data.preminingContractAddress);
    const mineList = data.mine;
    let markets: Immutable.List<RowMarket> = Immutable.fromJS(
      mineList || [],
    ) as Immutable.List<RowMarket>;
    if (markets.size) {
      markets = markets.map((m) => {
        const isV3 = m.get('version') === '3';
        let newM = isV3
          ? m.set('pool', m.get('pool')?.toLowerCase())
          : m
              .set('address', m.get('address')?.toLowerCase())
              .set('version', '2');
        newM = newM.set(
          'type',
          (m.get('type') || 'classical').toLowerCase() as Market['type'],
        );
        if (newM.get('type') === 'vdodo') {
          newM = newM.set(
            'vdodoDODOTokenAddress',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (m.get('miningDODOTokenAddress') as string) ||
              data.miningDODOTokenAddress,
          );
        }
        if (!isV3) {
          newM = newM
            .set(
              'mining',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (m.get('miningContractAddress') as string) ||
                m.get('mining') ||
                data.miningContractAddress,
            )
            .set(
              'rewardTokenAddress',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (m.get('miningDODOTokenAddress') as string) ||
                data.miningDODOTokenAddress,
            )
            .set(
              'rewardTokenSymbol',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (m.get('miningTokenSymbol') as string) ||
                data.miningTokenSymbol ||
                'DODO',
            )
            .set('rewardNum', 1);
        }
        return newM;
      });
    }
    let legacyMarkets: Immutable.List<LegacyMarket> = Immutable.fromJS(
      data.markets || [],
    ) as Immutable.List<LegacyMarket>;
    if (legacyMarkets.size) {
      legacyMarkets = legacyMarkets.map((m) => {
        const newM = m.set('address', m.get('address')?.toLowerCase());
        return newM;
      });
    }

    const mtFeeRate: Record<string, number> = {};
    if (data.mtFeeRate) {
      for (const key of Object.keys(data.mtFeeRate)) {
        mtFeeRate[key.toLowerCase()] = (
          data.mtFeeRate as Record<string, number>
        )[key];
      }
    }
    dispatch({
      type: 'SET_MT_FEE_RATE',
      payload: Map(mtFeeRate),
    });

    const mineOrgMap: Record<
      string,
      {
        linkTextKey: string;
        linkUrl: string;
      }
    > = {};
    if (data.mineOrg) {
      for (const key of Object.keys(data.mineOrg)) {
        mineOrgMap[key.toLowerCase()] = (
          data.mineOrg as Record<
            string,
            {
              linkTextKey: string;
              linkUrl: string;
            }
          >
        )[key];
      }
    }

    return dispatch({
      type: 'SET_MARKETS',
      payload: {
        markets,
        legacyMarkets,
        mineOrgMap: Map(mineOrgMap),
      },
    });
  };
};
