import { useState, useMemo, useEffect } from 'react';
import { getInjectedProvider, getInjectedProviderName } from 'web3modal';
import { dbGet, dbSet, isAndroid, isIOS } from '@dodoex/utils';
import { useSelector } from 'react-redux';
import { chainList as chainListOrigin, getChain } from '../injects/chain';
import { ChainType } from '../injects/chain/types';
import {
  injectWalletList,
  InjectDefaultLogo,
  WalletType,
  walletList as OtherWalletList,
  supportMobileWalletList,
  followInjectWalletList,
  useIsOpenBlockIframe,
  Wallet,
} from '../injects/wallet';
import { connectToWallet, ProviderPackageOptions, web3Modal } from '../web3';
import { logSwitchWallet } from '../utils/mixpanel';
import { SETTINGS_ACCOUNT_RISK_CONFIRM } from '../localstorage';
import { insertLedgerUSBInfo } from '../db/ledgerUSBConnectInfo';
import { getCurrentAccount } from '..';
import { Brave } from '../injects/wallet/injectedConstant';
import { WalletConnect, OpenBlock, KuCoin } from '../injects/wallet/constant';
import { getIsMobile } from '../utils/web';

export interface WalletItem {
  title: string;
  icon: React.ReactElement;
  currentType: string;
  onClick: (
    key?: ChainType,
    providerPackageOptions?: ProviderPackageOptions,
  ) => void;
  disabled?: boolean;
  chainTypes?: ChainType[];
  link?: string;
}
const metamaskName = 'MetaMask';
const metamaskMobileDeepLink = 'https://metamask.app.link/dapp/app.dodoex.io';
const isSafari = /Apple/.test(navigator.vendor);

export const metamaskWalletItem = {
  title: metamaskName,
  icon: <InjectDefaultLogo />,
  currentType: 'injected',
  disabled: true,
  link: 'https://metamask.io',
  onClick: () => {
    // empty
  },
} as WalletItem;

function getInjectedWalletItem(
  injectedWalletName: string | null,
  showOtherInjectWallet?: boolean,
) {
  if (injectedWalletName) {
    const injected = getInjectedProvider();
    let title = injectedWalletName;
    let icon =
      !injected?.logo || injected.name === metamaskName ? (
        <InjectDefaultLogo />
      ) : (
        <img src={injected.logo} alt="logo" />
      );
    // 使用自定义的 injected 替换 web3modal 这一套
    injectWalletList.some((wallet) => {
      if (wallet.checked()) {
        if (
          getIsMobile() ||
          !(wallet.isFollowInject && showOtherInjectWallet)
        ) {
          title = wallet.showName;
          icon = wallet.logo;
        }
        return true;
      }
      return false;
    });
    return {
      title,
      icon,
      currentType: 'injected',
      onClick: (networkKey?: ChainType, providerPackageOptions?: any) => {
        connectToWallet(
          WalletType.injected,
          networkKey,
          providerPackageOptions,
        );
        logSwitchWallet();
      },
    } as WalletItem;
  }
  return metamaskWalletItem;
}

function getWalletLink(wallet: Wallet) {
  let { link } = wallet;
  if (isAndroid) {
    link = wallet.mobileAndroidDeepLink || wallet.mobileDeepLink || wallet.link;
  } else if (isIOS) {
    link = wallet.mobileIOSDeepLink || wallet.mobileDeepLink || wallet.link;
  }
  return link;
}

export function useWalletListByNetwork(
  network: ChainType,
  {
    showOtherInjectWallet,
  }: {
    // https://app.asana.com/0/0/1202434255688299/1202462920004201/f
    showOtherInjectWallet?: boolean;
  } = {},
) {
  const [walletList, setWalletList] = useState<WalletItem[]>([]);
  const injectedWalletName = getInjectedProviderName();
  const account = useSelector(getCurrentAccount);

  useEffect(() => {
    let list: WalletItem[] = [];
    const injectedWalletItem = getInjectedWalletItem(
      injectedWalletName,
      showOtherInjectWallet,
    );
    const isMobile = getIsMobile();
    if (isMobile && injectedWalletItem.title === metamaskName) {
      injectedWalletItem.link = metamaskMobileDeepLink;
    }
    list.push(injectedWalletItem);
    const safeList: WalletItem[] = [];
    if (isMobile) {
      if (!injectedWalletName) {
        // 移动端未连接单独排序
        supportMobileWalletList.forEach((wallet) => {
          list.push({
            title: wallet.showName,
            icon: wallet.logo,
            currentType: wallet.type,
            chainTypes: wallet.chainTypes,
            disabled: !wallet.checked(),
            link: getWalletLink(wallet),
            onClick: (networkKey, providerPackageOptions) => {
              connectToWallet(wallet.type, networkKey, providerPackageOptions);
              logSwitchWallet();
            },
          });
        });
      } else {
        list.push({
          title: WalletConnect.showName,
          icon: WalletConnect.logo,
          currentType: WalletConnect.type,
          chainTypes: WalletConnect.chainTypes,
          link: WalletConnect.link,
          onClick: (networkKey, providerPackageOptions) => {
            connectToWallet(
              WalletConnect.type,
              networkKey,
              providerPackageOptions,
            );
            logSwitchWallet();
          },
        });
      }
    } else {
      OtherWalletList.forEach((wallet) => {
        const checked = wallet.checked();
        // 产品要求将 showOtherInjectWallet 的列表显示在 KuCoin 后面
        const needAddInjected =
          (showOtherInjectWallet || !injectedWalletName) &&
          wallet.showName === KuCoin.showName;
        const item: WalletItem = {
          title: wallet.showName,
          icon: wallet.logo,
          currentType: wallet.type,
          chainTypes: wallet.chainTypes,
          disabled: !checked,
          link: wallet.link,
          onClick: (networkKey, providerPackageOptions) => {
            connectToWallet(wallet.type, networkKey, providerPackageOptions);
            logSwitchWallet();
          },
        };
        if (wallet.isSafe) {
          safeList.push(item);
        } else {
          list.push(item);
          if (needAddInjected) {
            followInjectWalletList
              .filter((w) => w.showName !== injectedWalletItem.title)
              .forEach((w) => {
                list.push({
                  title: w.showName,
                  icon: w.logo,
                  currentType: w.type,
                  chainTypes: w.chainTypes,
                  // brave 肯定没装插件，所以特殊处理
                  disabled:
                    w.showName === Brave.showName || !!injectedWalletName,
                  link: w.link,
                  onClick: (networkKey, providerPackageOptions) => {
                    connectToWallet(w.type, networkKey, providerPackageOptions);
                    logSwitchWallet();
                  },
                });
              });
          }
        }
      });
    }
    if (isMobile ? !injectedWalletName : isSafari) {
      list = list.sort((a) =>
        a.currentType === WalletType.WalletConnect ? -1 : 0,
      );
    }
    setWalletList(list);
    web3Modal.isSafeApp().then((isSafe) => {
      if (isSafe) {
        setWalletList([...list, ...safeList]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, showOtherInjectWallet]);

  const showWalletList = useMemo(() => {
    if (!walletList.length) return [];
    return walletList.filter(
      (item) => !item.chainTypes || item.chainTypes.includes(network),
    );
  }, [walletList, network]);

  return {
    walletList: showWalletList,
  };
}

export const useConnectWallet = ({
  chains,
  chainId,
  showOtherInjectWallet,
  matchTestChain,
}: {
  chains: ChainType[];
  chainId: number;
  showOtherInjectWallet?: boolean;
  /** 匹配测试链，将 showName 和 chainIds 第一位展示成匹配的测试链。如果为 false 或不传， 仍然会显示为主网信息 */
  matchTestChain?: boolean;
}) => {
  const chain = getChain(chainId, matchTestChain);
  const isOpenBlockIframe = useIsOpenBlockIframe();
  const chainList = useMemo(() => {
    let list = chainListOrigin;
    if (chains?.length) {
      list = list.filter((item) => chains.includes(item.type));
    }
    if (isOpenBlockIframe && OpenBlock.chainTypes) {
      list = list.filter((item) => OpenBlock.chainTypes?.includes(item.type));
    }
    return list;
  }, [chains, isOpenBlockIframe]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeChain = useMemo(() => chain.type, [chainId]);

  const [selectedChain, setSelectedChain] = useState(activeChain);
  const selectedChainId = useMemo(() => {
    let res = chainId;
    chainList.some((c) => {
      if (c.type === selectedChain) {
        [res] = c.chainIds;
        return true;
      }
      return false;
    });
    return res;
  }, [selectedChain, chainList, chainId]);
  const { walletList } = useWalletListByNetwork(selectedChain, {
    showOtherInjectWallet,
  });
  const [userReadAndChecked, setUserReadAndChecked] = useState<boolean>(
    dbGet({
      path: SETTINGS_ACCOUNT_RISK_CONFIRM,
      defaultValue: false,
    }),
  );

  const handleChangeUserReadAndChecked = (val: boolean) => {
    dbSet({
      path: SETTINGS_ACCOUNT_RISK_CONFIRM,
      value: val,
    });
    setUserReadAndChecked(val);
  };

  const handleConnectLedgerUSB = (path: string, account: string) => {
    walletList.some((wallet) => {
      if (wallet.currentType === WalletType.LedgerUSB) {
        const value = {
          path,
          account,
        };
        wallet.onClick(
          selectedChain !== activeChain ? selectedChain : undefined,
          value,
        );
        insertLedgerUSBInfo(value);
        return true;
      }
      return false;
    });
  };

  return {
    activeChain,
    chainList,
    selectedChain,
    selectedChainId,
    setSelectedChain,
    walletList,
    userReadAndChecked,
    handleChangeUserReadAndChecked,
    handleConnectLedgerUSB,
  };
};
