import { gql } from '@apollo/client';

export const fetchBridgeChainsAndTokens = gql`
  query FetchBridgeChainsAndTokens($where: Cross_chain_tokentokenlistFilter) {
    cross_chain_token_list(where: $where) {
      chains
      tokens {
        id
        name
        symbol
        address
        decimals
        logoImg
        chain
        attributeLabels {
          key
        }
      }
      products {
        id
        name
      }
    }
  }
`;

export const fetchBridgeRoutes = gql`
  query FetchBridgeRoutes($data: Cross_chain_swaprouteData) {
    cross_chain_swap_routesV2(data: $data) {
      routes {
        toAmount
        fromAmountUSD
        toAmountUSD
        feeUSD
        fee
        executionDuration
        product
        step {
          tool
          toolDetails {
            key
            name
            logoURI
          }
          type
          approvalAddress
          includedSteps {
            id
            tool
            toolDetails {
              key
              name
              logoURI
            }
            type
            estimate {
              fromToken {
                chainId
                address
                decimals
                symbol
              }
              toToken {
                chainId
                address
                decimals
                symbol
              }
              fromAmount
              toAmount
            }
          }
        }
        encodeParams
        productParams
      }
      ruoteErrorCodes {
        errCode
        product
      }
    }
  }
`;

export const fetchTransactionEncodeDataAndGasPrice = gql`
  query FetchTransactionEncodeDataAndGasPrice(
    $data: Cross_chain_swaptransactionEncodeData
    $where: Gas_feedergasPrices
  ) {
    cross_chain_swap_transactionEncode(data: $data) {
      data
      to
      value
      from
      chainId
    }
    gas_feeder_getGasPrices(where: $where) {
      confidence
      maxFeePerGas
      maxPriorityFeePerGas
      gasPrice
    }
  }
`;

export const updateBridgeOrderCreate = gql`
  query UpdateBridgeOrderCreate($data: Cross_chain_swaporderCreateData) {
    cross_chain_swap_orderCreate(data: $data) {
      id
      fromChainId
      fromAmount
      fromTokenAddress
      toChainId
      toAmount
      toTokenAddress
      user
      toAddress
      slippage
      fromHash
      toHash
      product
      extend {
        route
        toToken
        fromToken
        lifiBridge
      }
      status
      subStatus
      massage
      createdAt
      updatedAt
    }
  }
`;

export const fetchBridgeOrderList = gql`
  query FetchBridgeOrderList($where: Cross_chain_swaporderListData) {
    cross_chain_swap_orderList(where: $where) {
      list {
        id
        fromChainId
        fromAmount
        fromTokenAddress
        toChainId
        toAmount
        toTokenAddress
        user
        toAddress
        slippage
        fromHash
        toHash
        product
        extend {
          route
          toToken
          fromToken
          lifiBridge
        }
        status
        subStatus
        massage
        createdAt
        updatedAt
        endTime
      }
      total
      currentPage
      limit
    }
  }
`;

export const fetchBridgeOrderDetail = gql`
  query FetchBridgeOrderDetail($where: Cross_chain_swaporderDetailData) {
    cross_chain_swap_orderDetail(where: $where) {
      id
      fromChainId
      fromAmount
      fromTokenAddress
      toChainId
      toAmount
      toTokenAddress
      user
      toAddress
      slippage
      fromHash
      toHash
      product
      extend {
        route
        toToken
        fromToken
        lifiBridge
      }
      status
      subStatus
      massage
      createdAt
      updatedAt
      endTime
    }
  }
`;

export const fetchBridgeOrderNewStatusList = gql`
  query FetchBridgeOrderNewStatusList(
    $where: Cross_chain_swaporderNewStatusData
  ) {
    cross_chain_swap_orderNewStatus(where: $where) {
      id
      fromChainId
      fromAmount
      fromTokenAddress
      toChainId
      toAmount
      toTokenAddress
      user
      toAddress
      fromHash
      toHash
      product
      status
      subStatus
      massage
      createdAt
      updatedAt
      crossChainOrderStatus {
        id
        orderId
        beforeStatus
        status
        beforeSubStatus
        subStatus
        message
        isNew
        createdAt
        updatedAt
      }
    }
  }
`;
