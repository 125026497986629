import { gql } from '@apollo/client';

export const fetchCPList = gql`
  query FetchCPList($first: Int, $where: CrowdPooling_filter) {
    crowdPoolings(
      first: $first
      where: $where
      orderBy: bidStartTime
      orderDirection: desc
    ) {
      id
      creator
      bidStartTime
      bidEndTime
      freezeDuration
      i
      k
      poolQuoteCap
      poolQuote
      investorsCount
      totalBase
      createTime
      settled
      totalShares
      calmEndTime
      dvm {
        id
      }
      baseToken {
        id
        symbol
        name
        decimals
      }
      quoteToken {
        id
        symbol
        name
        decimals
      }
      version
      feeRate
      isOvercapStop
      tokenCliffRate
      tokenClaimDuration
      tokenVestingDuration
    }
  }
`;

export const fetchAllCPList = gql`
  query FetchAllCPList($where: Crowd_poolinglist_filter) {
    crowd_pooling_top_list(where: $where) {
      address
      chain
      total_base
      base_token
      quote_token
      base_symbol
      quote_symbol
      pool_quote_cap
      pool_quote
      base_price
      quote_price
      quote_base_rate
    }
  }
`;

export const fetchIOPCPList = gql`
  query FetchIOPCPList(
    $where: Crowd_poolingcrowdpoolingListFilter
    $voteWhere: Crowd_poolingcrowdpoolingVoteListFilter
  ) {
    crowd_pooling_list(where: $where) {
      id
      address
      chain {
        id
        name
      }
      votes {
        id
        accountId
        crowdpoolingId
      }
    }
    crowd_pooling_voteList(where: $voteWhere) {
      id
      address
      crowdpooling {
        id
        address
        chain {
          id
          name
        }
      }
      account {
        id
        address
      }
    }
  }
`;

export const fetchVoteList = gql`
  query FetchVoteList($where: Crowd_poolingcrowdpoolingVoteListFilter) {
    crowd_pooling_voteList(where: $where) {
      id
      address
      crowdpooling {
        id
        address
      }
      account {
        id
        address
      }
    }
  }
`;

export const postVote = gql`
  query PostVote($data: Crowd_poolingcrowdpoolingVoteData) {
    crowd_pooling_vote(data: $data) {
      jwt
    }
  }
`;

export const postUnvote = gql`
  query PostUnvote($data: Crowd_poolingcrowdpoolingUnvoteData) {
    crowd_pooling_unvote(data: $data) {
      jwt
    }
  }
`;

export const fetchCPCountData = gql`
  query FetchCPCountData {
    crowd_pooling_count_data {
      pools
      fund_rise
      users
    }
  }
`;

export const searchCP = gql`
  query SearchCP(
    $cpIdwhere: CrowdPooling_filter
    $baseIdWhere: CrowdPooling_filter
    $quoteIdWhere: CrowdPooling_filter
  ) {
    cpIdFiltered: crowdPoolings(
      where: $cpIdwhere
      orderBy: bidStartTime
      orderDirection: desc
    ) {
      id
      creator
      bidStartTime
      bidEndTime
      freezeDuration
      i
      k
      poolQuoteCap
      poolQuote
      investorsCount
      totalBase
      createTime
      settled
      totalShares
      calmEndTime
      dvm {
        id
      }
      baseToken {
        id
        symbol
        name
        decimals
      }
      quoteToken {
        id
        symbol
        name
        decimals
      }
      version
      feeRate
      isOvercapStop
      tokenCliffRate
      tokenClaimDuration
      tokenVestingDuration
    }
    baseTokenIdFiltered: crowdPoolings(
      first: 1000
      where: $baseIdWhere
      orderBy: bidStartTime
      orderDirection: desc
    ) {
      id
      creator
      bidStartTime
      bidEndTime
      freezeDuration
      i
      k
      poolQuoteCap
      poolQuote
      investorsCount
      totalBase
      createTime
      settled
      totalShares
      calmEndTime
      dvm {
        id
      }
      baseToken {
        id
        symbol
        name
        decimals
      }
      quoteToken {
        id
        symbol
        name
        decimals
      }
      version
      feeRate
      isOvercapStop
      tokenCliffRate
      tokenClaimDuration
      tokenVestingDuration
    }
    quoteTokenIdFiltered: crowdPoolings(
      first: 1000
      where: $quoteIdWhere
      orderBy: bidStartTime
      orderDirection: desc
    ) {
      id
      creator
      bidStartTime
      bidEndTime
      freezeDuration
      i
      k
      poolQuoteCap
      poolQuote
      investorsCount
      totalBase
      createTime
      settled
      totalShares
      calmEndTime
      dvm {
        id
      }
      baseToken {
        id
        symbol
        name
        decimals
      }
      quoteToken {
        id
        symbol
        name
        decimals
      }
      version
      feeRate
      isOvercapStop
      tokenCliffRate
      tokenClaimDuration
      tokenVestingDuration
    }
  }
`;

export const fetchCP = gql`
  query FetchCP(
    $cp: ID!
    $bidPositionsFirst: Int
    $bidPositionWhere: BidPosition_filter
    $where: CrowdPooling_filter
  ) {
    crowdPooling(id: $cp, where: $where) {
      id
      creator
      baseToken {
        id
        name
        symbol
        decimals
      }
      quoteToken {
        id
        name
        symbol
        decimals
      }
      bidStartTime
      bidEndTime
      calmEndTime
      freezeDuration
      vestingDuration
      settled
      i
      k
      poolQuoteCap
      poolQuote
      investorsCount
      totalBase
      createTime
      totalShares
      dvm {
        id
      }
      version
      feeRate
      isOvercapStop
      tokenCliffRate
      tokenClaimDuration
      tokenVestingDuration
    }
    bidPositions(
      first: $bidPositionsFirst
      where: $bidPositionWhere
      orderBy: shares
      orderDirection: desc
    ) {
      user {
        id
      }
      cp {
        id
      }
      shares
      investedQuote
    }
  }
`;

export const fetchBidPosition = gql`
  query FetchBidPosition($where: BidPosition_filter) {
    bidPositions(where: $where, orderBy: lastTxTime, orderDirection: desc) {
      user {
        id
      }
      cp {
        id
      }
      shares
      investedQuote
    }
  }
`;

export const fetchSwapList = gql`
  query FetchSwapList($first: Int, $where: BidHistory_filter) {
    bidHistories(
      first: $first
      where: $where
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      user {
        id
      }
      timestamp
      action
      quote
      hash
      share
      fee
      cp {
        i
        baseToken {
          id
          symbol
          decimals
        }
        quoteToken {
          id
          symbol
          decimals
        }
      }
    }
  }
`;

export const fetchCPDayData = gql`
  query FetchCPDayData($first: Int, $where: CrowdPoolingDayData_filter) {
    crowdPoolingDayDatas(
      first: $first
      where: $where
      orderBy: date
      orderDirection: asc
    ) {
      date
      investedQuote
      investCount
      newcome
      investors
      poolQuote
    }
  }
`;

export const fetchCPHourData = gql`
  query FetchCPHourData($first: Int, $where: CrowdPoolingHourData_filter) {
    crowdPoolingHourDatas(
      first: $first
      where: $where
      orderBy: hour
      orderDirection: asc
    ) {
      hour
      investedQuote
      investCount
      newcome
      investors
      poolQuote
    }
  }
`;
