/* eslint-disable react/jsx-props-no-spreading */
import {
  styled,
  Tooltip as TooltipOrigin,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from '@mui/material';
import { merge } from 'lodash';

type StyledTooltipProps = TooltipProps & {
  /** 某些弹出框不需要预设的边距 */
  noPadding?: boolean;
  maxWidth?: string | number;
};

const TooltipWrapper = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, noPadding, maxWidth, ...props }: StyledTooltipProps) => {
    return <TooltipOrigin {...props} classes={{ popper: className }} />;
  },
)(({ theme, noPadding, maxWidth }) => {
  const bgColor = theme.palette.custom.background.paperContrast;
  const borderColor = theme.palette.custom.border.default;
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      padding: noPadding ? '0px' : '11px 9px',
      maxWidth: maxWidth || 240,
      backgroundColor: bgColor,
      fontSize: 12,
      lineHeight: '16.39px',
      fontWeight: 500,
      borderRadius: 8,
      color: theme.palette.text.secondary,
      borderColor,
      borderWidth: 1,
      borderStyle: 'solid',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: bgColor,
      // 箭头变大
      fontSize: 16,
      '&:before': {
        borderColor,
        borderWidth: 1,
        borderStyle: 'solid',
      },
    },
  };
});

export function Tooltip(props: StyledTooltipProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const { sx = {} } = props || {};
  return (
    <TooltipWrapper
      placement="top"
      arrow
      {...props}
      sx={merge(
        {
          '& .MuiTooltip-tooltip': {
            backgroundColor: isLight
              ? 'rgba(246, 246, 246, 1)'
              : theme.palette.custom.background.paperContrast,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: isLight
              ? 'rgba(69, 72, 81, 0.1)'
              : 'rgba(69, 72, 81, 1)',
            '& .MuiTooltip-arrow:before': {
              backgroundColor: isLight
                ? 'rgba(246, 246, 246, 1)'
                : theme.palette.custom.background.paperContrast,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: isLight
                ? 'rgba(69, 72, 81, 0.1)'
                : 'rgba(69, 72, 81, 1)',
            },
          },
        },
        sx,
      )}
    />
  );
}
