/* eslint-disable no-param-reassign */
import { TokenInterface } from '../type';
import { getChain } from './config';

// https://docs.metamask.io/guide/ethereum-provider.html#chain-ids
export const getNetworkName = (networkId: number): string => {
  switch (networkId) {
    case 1:
      return 'Mainnet';
    case 3:
      return 'Ropsten';
    case 4:
      return 'Rinkeby';
    case 5:
      return 'gor';
    case 10:
      return 'Optimism';
    case 288:
      return 'BOBA-Mainnet';
    case 56:
      return 'BSC-Mainnet';
    case 66:
      return 'OKChain';
    case 86:
      return 'BSC-Mainnet';
    case 97:
      return 'BSC-Testnet';
    case 128:
      return 'Heco';
    case 137:
      return 'Polygon';
    case 321:
      return 'kcs';
    case 1285:
      return 'Moonriver';
    case 42161:
      return 'Arbitrum';
    case 421611:
      return 'arb-rinkeby';
    case 1313161554:
      return 'Aurora';
    case 43114:
      return 'Avalanche';
    default:
      return 'Unknown';
  }
};

export const EtherTokenWithChainId: Record<
  number,
  TokenInterface & { wrappedTokenSymbol: string; wrappedTokenAddress: string }
> = {
  1: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  },
  4: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xB26c0d8Be2960c70641A95A9195BE1f59Ac83aC0',
  },
  5: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
  },
  10: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0x4200000000000000000000000000000000000006',
  },
  288: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'ETH',
    decimals: 18,
    showDecimals: 6,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
  },
  56: {
    symbol: 'BNB',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'BNB',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WBNB',
    wrappedTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
  97: {
    symbol: 'tBNB',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'tBNB',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WBNB',
    wrappedTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
  66: {
    symbol: 'OKT',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'OKT',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WOKT',
    wrappedTokenAddress: '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',
  },
  86: {
    symbol: 'BNB',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'BNB',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WBNB',
    wrappedTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  },
  128: {
    symbol: 'HT',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'HT',
    decimals: 18,
    showDecimals: 6,
    wrappedTokenSymbol: 'WHT',
    wrappedTokenAddress: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
  },
  137: {
    symbol: 'MATIC',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'MATIC',
    decimals: 18,
    showDecimals: 6,
    wrappedTokenSymbol: 'WMATIC',
    wrappedTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  },
  321: {
    symbol: 'KCS',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'KuCoin Token',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WKCS',
    wrappedTokenAddress: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
  },
  1285: {
    symbol: 'MOVR',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'MOVR',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WMOVR',
    wrappedTokenAddress: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
  },
  42161: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  },
  421611: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
  },
  1313161554: {
    symbol: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Ether',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WETH',
    wrappedTokenAddress: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
  },
  43114: {
    symbol: 'AVAX',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    name: 'Avalanche',
    decimals: 18,
    showDecimals: 4,
    wrappedTokenSymbol: 'WAVAX',
    wrappedTokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  },
};

export const getEtherTokenSymbolMatch = (symbolOrigin: string) => {
  const symbol = symbolOrigin.toLocaleUpperCase();
  let result = [symbol];
  Object.values(EtherTokenWithChainId).some((item) => {
    if (symbol === item.symbol || symbol === item.wrappedTokenSymbol) {
      result = [item.symbol, item.wrappedTokenSymbol];
      return true;
    }
    return false;
  });
  return result;
};

export const getWrappedTokenSymbol = (symbolOrigin: string) => {
  const symbol = symbolOrigin.toLocaleUpperCase();
  let result = symbol;
  Object.values(EtherTokenWithChainId).some((item) => {
    if (symbol === item.symbol) {
      result = item.wrappedTokenSymbol;
      return true;
    }
    return false;
  });
  return result;
};

/**
 * 获取 平台币对应的 wrapped 代币
 * 如果 addressOrigin 为 0x...ee 且不传入 chainId，因为大部分链上平台币地址都为 0x...ee, 则无论在再哪条链调用该方法都会返回 ETH 链的 WETH，buggy
 * 传入 chainId
 * @param addressOrigin
 * @returns
 */
export const getWrappedTokenAddress = (
  addressOrigin: string,
  chainId: number,
) => {
  const address = addressOrigin.toLowerCase();
  const nativeToken = EtherTokenWithChainId[chainId];
  if (nativeToken && nativeToken.address.toLowerCase() === address) {
    return nativeToken.wrappedTokenAddress;
  }
  return addressOrigin;
};

export const getEtherTokenSymbolBlurMatch = (symbolOrigin: string) => {
  const symbol = symbolOrigin.toLocaleUpperCase();
  let result = [symbol];
  const symbolReg = new RegExp(`^${symbol.toLocaleUpperCase()}`);
  Object.values(EtherTokenWithChainId).some((item) => {
    if (
      symbolReg.test(item.symbol) ||
      symbolReg.test(item.wrappedTokenSymbol)
    ) {
      result = [item.symbol, item.wrappedTokenSymbol];
      return true;
    }
    return false;
  });
  return result;
};

enum SymbolMatchType {
  NOMATCH = 1,
  BASE,
  QUOTE,
}
export const getBlurMatchSymbol = ({
  blurSymbol,
  relevanceSymbols,
  baseSymbol,
  quoteSymbol,
}: {
  blurSymbol: string;
  // 主网代币关联的代币组
  relevanceSymbols: string[];
  baseSymbol: string;
  quoteSymbol?: string;
}) => {
  const symbolReg = new RegExp(`^${blurSymbol.toLocaleUpperCase()}`);
  const matchSymbolInfo:
    | { [key in SymbolMatchType]: string }
    | Record<never, never> = {};
  if (symbolReg.test(baseSymbol.toLocaleUpperCase())) {
    (matchSymbolInfo as { [key in SymbolMatchType]: string })[
      SymbolMatchType.BASE
    ] = baseSymbol.toLocaleUpperCase();
  }
  if (quoteSymbol && symbolReg.test(quoteSymbol.toLocaleUpperCase())) {
    (matchSymbolInfo as { [key in SymbolMatchType]: string })[
      SymbolMatchType.QUOTE
    ] = quoteSymbol.toLocaleUpperCase();
  }
  if (relevanceSymbols.includes(baseSymbol.toLocaleUpperCase())) {
    let matchBase = '';
    relevanceSymbols.some((symbol) => {
      if (symbolReg.test(symbol)) {
        matchBase = symbol;
        return true;
      }
      return false;
    });
    (matchSymbolInfo as { [key in SymbolMatchType]: string })[
      SymbolMatchType.BASE
    ] = matchBase;
  }
  if (quoteSymbol && relevanceSymbols.includes(quoteSymbol)) {
    let matchBase = '';
    relevanceSymbols.some((symbol) => {
      if (symbolReg.test(symbol)) {
        matchBase = symbol;
        return true;
      }
      return false;
    });
    (matchSymbolInfo as { [key in SymbolMatchType]: string })[
      SymbolMatchType.QUOTE
    ] = matchBase;
  }
  return matchSymbolInfo as { [key in SymbolMatchType]: string };
};

/**
 * 模糊匹配代币
 * 最多输入两个代币，中间非字母非数字隔开
 * 这里不止要匹配搜索词，还要匹配主网对应的币种。如 ETH，还要匹配 WETH
 * @returns false | 如果匹配到关联的主币，则返回关联的代币名称。如 WETH
 */
export const searchSymbol = ({
  search,
  baseSymbol,
  quoteSymbol,
}: {
  search: string;
  baseSymbol: string;
  quoteSymbol?: string;
}) => {
  const filterSymbolArray = search.split(/[^a-zA-Z0-9]+/);
  const filterSymbolArrayLen = filterSymbolArray.length;
  if (filterSymbolArrayLen && filterSymbolArrayLen > 2) {
    return [baseSymbol, quoteSymbol];
  }
  const aSearch = filterSymbolArray[0];
  let bSearch = '';
  const aMatchSymbols: string[] = getEtherTokenSymbolBlurMatch(aSearch);
  let bMatchSymbols: string[] = [];
  if (filterSymbolArrayLen === 2) {
    // eslint-disable-next-line prefer-destructuring
    bSearch = filterSymbolArray[1];
    bMatchSymbols = getEtherTokenSymbolBlurMatch(bSearch);
  }
  if (aSearch) {
    const aMatchSymbolInfo = getBlurMatchSymbol({
      blurSymbol: aSearch,
      relevanceSymbols: aMatchSymbols,
      baseSymbol,
      quoteSymbol,
    });
    const aMatchSymbolInfoKeys = Object.keys(aMatchSymbolInfo);
    if (!aMatchSymbolInfoKeys.length) return false;
    if (bSearch) {
      const bMatchSymbolInfo = getBlurMatchSymbol({
        blurSymbol: bSearch,
        relevanceSymbols: bMatchSymbols,
        baseSymbol,
        quoteSymbol,
      });
      const bMatchSymbolInfoKeys = Object.keys(bMatchSymbolInfo);
      if (!bMatchSymbolInfoKeys.length) return false;
      if (
        aMatchSymbolInfo[SymbolMatchType.BASE] &&
        bMatchSymbolInfo[SymbolMatchType.QUOTE]
      ) {
        baseSymbol = aMatchSymbolInfo[SymbolMatchType.BASE];
        quoteSymbol = bMatchSymbolInfo[SymbolMatchType.QUOTE];
      } else if (
        aMatchSymbolInfo[SymbolMatchType.QUOTE] &&
        bMatchSymbolInfo[SymbolMatchType.BASE]
      ) {
        baseSymbol = bMatchSymbolInfo[SymbolMatchType.BASE];
        quoteSymbol = aMatchSymbolInfo[SymbolMatchType.QUOTE];
      } else {
        return false;
      }
    } else if (aMatchSymbolInfo[SymbolMatchType.BASE]) {
      baseSymbol = aMatchSymbolInfo[SymbolMatchType.BASE];
    } else if (aMatchSymbolInfo[SymbolMatchType.QUOTE]) {
      quoteSymbol = aMatchSymbolInfo[SymbolMatchType.QUOTE];
    }
  }
  return [baseSymbol, quoteSymbol];
};

export function getEtherscanPage(
  chainId: number,
  id?: string,
  prefix = 'address',
) {
  const { scanUrlDomain } = getChain(chainId);
  return `https://${scanUrlDomain}${id ? `/${prefix}/${id}` : ''}`;
}

/**
 * open etherscan.io or bscscan.com
 * @param path url path
 * @param chainId chain ID
 */
export async function openEtherscanPage(
  path: string | undefined,
  chainId: number,
  customUrl?: string,
): Promise<void> {
  const { scanUrlDomain } = getChain(chainId);

  window.open(
    `https://${customUrl ?? scanUrlDomain}${path ? `/${path}` : ''}`,
    '_blank',
    'noopener,noreferrer',
  );
}

export const generateLinkTo = (
  path: string,
  chainId: number,
  query?: string,
) => {
  const pathSuffix = `network=${getNetworkName(chainId).toLowerCase()}${
    query || ''
  }`;
  if (path.indexOf('?') > -1) {
    return `${path}&${pathSuffix}`;
  }
  return `${path}?${pathSuffix}`;
};
