import { gql } from '@apollo/client';

export const fetchRFQTakerInquiry = gql`
  query QueryRFQTakerInquiry($where: Limit_and_rfqtakerQueryRfqOffer) {
    limit_and_rfq_rfqTakerInquiryDirect(where: $where) {
      id
      makerAmount
      expiration
      makerTokenFeeAmount
      slot
    }
  }
`;

export const updateRFQOrder = gql`
  query UpdateRFQOrder($where: Limit_and_rfqtakerConfirmRfqOffer) {
    limit_and_rfq_rfqTakerConfirmDirect(where: $where) {
      createdAt
      expiration
      hash
      id
      inquiryExpiration
      lastPackTime
      maker
      makerAmount
      makerSignature
      makerToken
      makerTokenFeeAmount
      network
      progress
      reBuildTimes
      slot
      taker
      takerAmount
      takerFillAmount
      takerSignature
      takerToken
      updatedAt
    }
  }
`;

export const fetchRFQOrderList = gql`
  query Limit_and_rfq_rfqTakerOrderListWithPage(
    $where: Limit_and_rfqtakerGetOrderListWithPage
  ) {
    limit_and_rfq_rfqTakerOrderListWithPage(where: $where) {
      list {
        createdAt
        expiration
        hash
        id
        inquiryExpiration
        maker
        makerAmount
        makerSignature
        makerToken
        makerTokenFeeAmount
        makerTokenSymbol
        makerTokenDecimal
        network
        progress
        reBuildTimes
        slot
        taker
        takerAmount
        takerFillAmount
        takerSignature
        takerToken
        takerTokenSymbol
        takerTokenDecimal
        updatedAt
        failureReason
      }
      total
      totalPage
      currentPage
      limit
    }
  }
`;

export const fetchRFQTokenAddressList = gql`
  query QueryRFQTokenAddressList {
    limit_and_rfq_rfqSupportTokenList
  }
`;

export const fetchGaslessTokenMinAndMaxAmount = gql`
  query QueryGaslessTokenMinAndMaxAmount(
    $where: Limit_and_rfquserRqFeeLimitQuery
  ) {
    limit_and_rfq_rfqTradeAmountLimit(where: $where) {
      min
      max
    }
  }
`;
