/* eslint-disable default-param-last */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  TypedDocumentNode,
  useApolloClient,
  useLazyQuery,
  useQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import {
  useAutoUpdateRef,
  getChain,
  ThegraphKeyMap,
  clog,
} from '@dodoex/utils';
import { getCurrentChainId } from '@dodoex/wallet';
import { isEqual, merge, cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { captureException } from '../../utils';
import { _messageClient } from './useInitMessageClient';

export enum SchemaName {
  dodoex = 'dodoex',
  vdodo = 'vdodo',
  token = 'token',
  nft = 'nft',
  mine = 'mine',
  eip721 = 'eip721',
  eip1155 = 'eip1155',
}

function mergeOptions<TData = any, TVariables = OperationVariables>(
  options: QueryHookOptions<
    TData,
    keyof TVariables extends 'where'
      ? Omit<TVariables, 'where'> & {
          // @ts-ignore
          where: Omit<TVariables['where'], 'chain'>;
        }
      : TVariables
  > = {},
  thegraphKey: ThegraphKeyMap,
  schemaName?: SchemaName,
  realtime = true,
  notChain?: boolean,
) {
  const where: any = {};
  if (schemaName) {
    where.schemaName = schemaName;
  }
  // @ts-ignore
  if (
    !notChain &&
    (!options.variables ||
      // @ts-ignore
      !options.variables.where ||
      // @ts-ignore
      !options.variables.where.chain_in)
  ) {
    where.chain = thegraphKey;
  }
  if (realtime) {
    where.refreshNow = true;
  }
  return {
    fetchPolicy: 'network-only' as WatchQueryFetchPolicy,
    ...options,
    variables: {
      first: 1000,
      ...(options.variables || {}),
      where: {
        ...where,
        // @ts-ignore
        ...((options.variables && options.variables.where) || {}),
      },
    } as unknown as TVariables,
  };
}

function useSubscribeMessage<TData = any, TVariables = OperationVariables>({
  result,
  variables,
  query,
  skip,
  delay = 0,
}: {
  result: QueryResult<TData, TVariables>;
  variables: OperationVariables;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  skip?: boolean;
  delay?: number;
}) {
  interface MessageResult {
    data?: TData;
  }
  const [messageResult, setMessageResult] = useState<MessageResult>({});
  const id = useRef(
    new Date().getTime() + Math.floor(Math.random() * (5000 - 4000 + 1)) + 4000,
  );
  /**
   * 要注意这里采用订阅的方式，是不能取消的。而常规的 graphql 是可以取消的。
   * 刚进页面可能还没获取到 account，常规的 graphql 会因为下一次的查询而取消上一次的请求，所以不会有问题。订阅不会取消，就会导致超出预期的查询
   * 所以现在是等待 graphql 返回之后，再去订阅
   */
  useEffect(() => {
    let time: NodeJS.Timeout;
    let unsubscribe: (() => void | undefined) | undefined;
    if (result.called && result.data && !skip) {
      if (query.loc?.source.body && _messageClient) {
        time = setTimeout(() => {
          const operationName = query.definitions?.length
            ? // @ts-ignore
              query.definitions[0].name.value
            : undefined;
          const subscribeParams = {
            operationName,
            // @ts-ignore
            query: query.loc.source.body,
            variables,
          };
          clog(
            clog.type.graphql,
            'subscribe',
            id.current,
            operationName,
            variables,
          );
          unsubscribe = _messageClient?.subscribe(subscribeParams, {
            next: (data) => {
              clog(clog.type.graphql, 'subscribe:next', id.current, data);
              setMessageResult(data as MessageResult);
            },
            /**
             * An error that has occured. Calling this function "closes" the sink.
             * Besides the errors being `Error` and `readonly any[]`, it
             * can also be a `CloseEvent`, but to avoid bundling DOM typings because
             * the client can run in Node env too, you should assert the close event
             * type during implementation.
             */
            error: (error: Error | CloseEvent) => {
              clog(
                clog.type.graphql,
                'subscribe:error',
                id.current,
                subscribeParams,
                error,
              );
              if (error instanceof CloseEvent && !error.code) {
                return;
              }
              let errorInfo = '';
              try {
                errorInfo = JSON.stringify(error);
              } catch (e) {
                // empty
              }
              let errorRes: Error;
              if (error instanceof CloseEvent) {
                const errorApi = `[CloseEvent]: type: ${error.type}, code: ${error.code}, reason: ${error.reason}, wasClean: ${error.wasClean}`;
                console.error(errorApi);
                if (
                  !error.code ||
                  // 4403: 在 useInitMessageClient 已经处理，无需再次记录
                  error.code === 4403 ||
                  // 后端会重试，这里没有消息记录为什么报错，没必要记录
                  (error.code === 1006 && !error.reason)
                ) {
                  return;
                }
                errorRes = new Error(errorApi);
              } else {
                errorRes = error as Error;
              }
              captureException(
                `messageClient error: ${operationName}`,
                errorRes,
                {
                  error: errorInfo,
                },
                {
                  notNotice: true,
                },
              );
            },
            complete: () => {
              // empty
            },
            completeStatus: () => {
              // empty
            },
          });
        }, delay);
      }
    }
    return () => {
      clog(clog.type.graphql, 'unsubscribe', id.current);
      clearTimeout(time);
      unsubscribe && unsubscribe();
    };
  }, [result.called, !result.data, JSON.stringify(variables), skip, delay]);

  return messageResult;
}

export function useRootQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  // eslint-disable-next-line default-param-last
  options: QueryHookOptions<
    TData,
    keyof TVariables extends 'where'
      ? Omit<TVariables, 'where'> & {
          // @ts-ignore
          where: Omit<TVariables['where'], 'chain'>;
        }
      : TVariables
  > = {},
  schemaName?: SchemaName,
  realtime?: boolean,
  notChain?: boolean,
  /** 默认用订阅返回数据，refetch 只有在请求错误时有效。如果这个参数 为 true， refetch 就在任何情况都有效 */
  needRefetch?: boolean,
) {
  const chainId = useSelector(getCurrentChainId);
  const { thegraphKey } = getChain(chainId);
  const optionsRes = useMemo(() => {
    const res = mergeOptions<TData, TVariables>(
      options,
      thegraphKey,
      schemaName,
      realtime,
      notChain,
    );

    return res as QueryHookOptions<TData, TVariables>;
  }, [options, thegraphKey, schemaName, realtime, notChain]);

  const result = useQuery<TData, TVariables>(query, optionsRes);
  const previousOptions = useAutoUpdateRef(options);
  // 仅在 error 需要重试的时候才返回具体方法。其他情况走订阅返回
  const refetch = useCallback(
    (variables?: Partial<TVariables> | undefined) => {
      if (result.error || needRefetch) {
        const config = mergeOptions(options, thegraphKey, schemaName, true);
        const resultVariables = {
          ...config.variables,
          ...(variables || {}),
        };
        return result.refetch(resultVariables);
      }

      return Promise.resolve(result);
    },
    [
      needRefetch,
      result.refetch,
      isEqual(options, previousOptions.current),
      schemaName,
      thegraphKey,
      result.error,
    ],
  );
  const messageResult = useSubscribeMessage<TData, TVariables>({
    result,
    // @ts-ignore
    variables: optionsRes.variables as TVariables,
    query,
    delay: 10000,
  });

  return {
    ...result,
    ...messageResult,
    refetch,
  };
}

export function useRootLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<
    TData,
    keyof TVariables extends 'where'
      ? Omit<TVariables, 'where'> & {
          // @ts-ignore
          where: Omit<TVariables['where'], 'chain'>;
        }
      : TVariables
  > = {},
  schemaName?: SchemaName,
  realtime?: boolean,
) {
  const chainId = useSelector(getCurrentChainId);
  const { thegraphKey } = getChain(chainId);
  const lazyQuery = useLazyQuery<TData, TVariables>(
    query,
    mergeOptions<TData, TVariables>(
      options,
      thegraphKey,
      schemaName,
      realtime,
    ) as QueryHookOptions<TData, TVariables>,
  );
  const result = lazyQuery.map((item: any, index) => {
    if (index) return item;
    return (fetchOptions: any) => {
      if (fetchOptions) {
        return item(
          mergeOptions(fetchOptions, thegraphKey, schemaName, realtime),
        );
      }
      return item(fetchOptions);
    };
  });
  return result as QueryTuple<TData, TVariables>;
}

export function useRootPageQuery<
  TData = any,
  TVariables = OperationVariables,
  TItem = any,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<
    TData,
    keyof TVariables extends 'where'
      ? Omit<TVariables, 'where'> & {
          // @ts-ignore
          where: Omit<TVariables['where'], 'chain'>;
        }
      : TVariables
  > = {},
  {
    limit,
    convertData,
    getTotalCount,
    pageKey = 'page',
    limitKey = 'limit',
    schemaName,
    realtime,
    skip,
    notChain,
  }: {
    limit: number;
      convertData: (data: TData) => Array<TItem>;
    getTotalCount: (data: TData) => number;
    schemaName?: SchemaName;
    realtime?: boolean;
    pageKey?: string;
    limitKey?: string;
    skip?: boolean;
    notChain?: boolean;
  },
) {
  const chainId = useSelector(getCurrentChainId);
  const { thegraphKey } = getChain(chainId);
  const page = useRef(1);
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [realData, setRealData] = useState<Array<TItem>>([]);

  const optionsRes = useMemo(() => {
    return mergeOptions(options, thegraphKey, schemaName, realtime, notChain);
  }, [options, thegraphKey, schemaName, realtime]) as QueryHookOptions<
    TData,
    TVariables
  >;

  const [fetchQuery, fetchResult] = useLazyQuery<TData, TVariables>(
    query,
    optionsRes,
  );

  const totalCount = useMemo(() => {
    if (!fetchResult.data) return 0;
    return getTotalCount(fetchResult.data);
  }, [fetchResult.data]);
  const hasMore = useMemo(() => {
    return loadMoreLoading || totalCount > page.current * limit;
  }, [totalCount, page.current, loadMoreLoading]);

  const refreshVariables = useMemo(() => {
    return merge(optionsRes?.variables, {
      where: {
        [pageKey]: 1,
        [limitKey]: realData?.length || limit,
      },
    });
  }, [optionsRes, thegraphKey, realData?.length]);
  const refreshSkip = useMemo(() => {
    return skip || loadMoreLoading;
  }, [skip, loadMoreLoading]);

  const messageResult = useSubscribeMessage({
    result: fetchResult,
    variables: refreshVariables,
    query,
    skip: refreshSkip,
  });

  const refetch = useCallback(() => {
    const operationName = query.definitions?.length
      ? // @ts-ignore
        query.definitions[0].name.value
      : undefined;
    fetchQuery({
      variables: refreshVariables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data: TData) => {
        if (data) {
          clog(
            clog.type.graphql,
            'useRootPageQuery:refetch:onCompleted',
            operationName,
            refreshVariables,
            data,
          );
          setRealData(convertData(data));
        }
        setLoadMoreLoading(false);
        setLoading(false);
      },
    });
  }, [
    refreshVariables,
    setRealData,
    setLoadMoreLoading,
    setLoading,
    fetchQuery,
  ]);

  useEffect(() => {
    if (messageResult.data && !(messageResult as any).errors?.length) {
      setRealData(convertData(messageResult.data) || []);
      setLoading(false);
    }
  }, [messageResult]);

  useEffect(() => {
    if (!skip) {
      page.current = 1;
      const operationName = query.definitions?.length
        ? // @ts-ignore
          query.definitions[0].name.value
        : undefined;
      const variables = {
        where: {
          [pageKey]: page.current,
          [limitKey]: limit,
        },
      };
      clog(
        clog.type.graphql,
        'useRootPageQuery:first-load',
        operationName,
        variables,
      );
      fetchQuery(
        merge(optionsRes, {
          variables,
          onCompleted: (data: TData) => {
            if (data) {
              clog(
                clog.type.graphql,
                'useRootPageQuery:first-load:back',
                operationName,
                variables,
                data,
              );
              setRealData(convertData(data));
              setLoading(false);
            }
          },
        }),
      ).then(({ data }) => {
        if (data) {
          clog(
            clog.type.graphql,
            'useRootPageQuery:first-load:back',
            operationName,
            variables,
            data,
          );
          setRealData(convertData(data));
          setLoading(false);
        }
      });
    }
  }, [skip, optionsRes]);

  useEffect(() => {
    if (fetchResult.error) {
      setLoading(false);
    }
  }, [!!fetchResult.error]);

  const loadMore = useCallback(() => {
    if (skip) return;
    setLoadMoreLoading(true);
    page.current += 1;
    const operationName = query.definitions?.length
      ? // @ts-ignore
        query.definitions[0].name.value
      : undefined;
    const variables = {
      where: {
        [pageKey]: page.current,
        [limitKey]: limit,
      },
    };
    clog(
      clog.type.graphql,
      'useRootPageQuery:loadMore',
      operationName,
      variables,
    );
    const optionsResObj = cloneDeep(optionsRes);
    fetchQuery(
      merge(optionsResObj, {
        variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data: TData) => {
          if (data) {
            clog(
              clog.type.graphql,
              'useRootPageQuery:loadMore:onCompleted',
              operationName,
              variables,
              data,
            );
            setRealData((prev) => [...(prev || []), ...convertData(data)]);
          }
          setLoadMoreLoading(false);
        },
        onError: (error: any) => {
          clog(
            clog.type.graphql,
            'useRootPageQuery:loadMore:error',
            operationName,
            variables,
            error,
          );
          captureException(
            `useRootPageQuery error: ${operationName}`,
            error,
            {
              options: optionsResObj,
              page: page.current,
              limit,
            },
            {
              notNotice: true,
            },
          );
        },
      }),
    );
  }, [
    page,
    limit,
    pageKey,
    limitKey,
    skip,
    optionsRes,
    setRealData,
    fetchResult,
    fetchQuery,
  ]);

  return {
    hasMore,
    totalCount,
    loading,
    loadMoreLoading,
    data: realData,
    error: fetchResult.error,

    loadMore,
    refetch,
  };
}
