import { gql } from '@apollo/client';

export const fetchIDOList = gql`
  query QueryIDOList($where: AuctionlistFilter) {
    auction_auctionList(where: $where) {
      id
      name
      idoContractAddress
      chain
      coverImg
      abbreviationImg
      whitelistApplyStartTime
      whitelistApplyEndTime
      auctionStartTime
      auctionDuration
      claimStartTime
      claimDuration
      baseToken
      quoteToken
      sort
      quoteTokenSymbol
      quoteTokenDecimals
      baseTokenSymbol
      baseTokenDecimals
      auctionType {
        key
        name
      }
      baseTokenTotalAmount
      quoteTokenRaiseFundAmount
      baseTokenSoldTokenAmount
      baseTokenClaimableTokenAmount
      quoteTokenCurrentPrice
      quoteTokenMinPrice
      quoteTokenMaxPrice
      participantCount
      baseTokenTotalClaimAmount
      baseTokenClaimedTokenAmount
    }
  }
`;

export const fetchIDODetail = gql`
  query QueryIDODetail($where: AuctiondetailFilter) {
    auction_auctionDetail(where: $where) {
      id
      name
      idoContractAddress
      chain
      coverImg
      abbreviationImg
      whitelistApplyStartTime
      whitelistApplyEndTime
      auctionStartTime
      auctionDuration
      claimStartTime
      claimDuration
      whitelistUrl
      whitelistPublicityUrl
      baseToken
      quoteToken
      describe
      status
      sort
      quoteTokenSymbol
      quoteTokenDecimals
      baseTokenSymbol
      baseTokenDecimals
      auctionType {
        key
        name
      }
      baseTokenTotalAmount
      baseTokenSoldTokenAmount
      quoteTokenCurrentPrice
      quoteTokenMinPrice
      quoteTokenMaxPrice
      participantCount
    }
  }
`;

export const fetchActiveIDOList = gql`
  query QueryActiveIDOList($where: AuctionactiveFilter) {
    auction_auctionActive(where: $where) {
      id
      idoContractAddress
      baseTokenTotalAmount
      baseTokenSoldTokenAmount
      quoteTokenCurrentPrice
      quoteTokenMinPrice
      quoteTokenMaxPrice
      participantCount
    }
  }
`;

export const fetchIDOOperationList = gql`
  query QueryIDOOperationList($where: AuctionoperationRecordFilter) {
    auction_auctionOperationRecord(where: $where) {
      list {
        type
        starter
        amount
        hash
        timestamp
        symbol
        decimals
      }
      count
      page
      pageSize
    }
  }
`;
