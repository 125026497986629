import { Step as StepSpec } from './spec';

export enum MetadataFlag {
  createNft = 1,
  addNftToVault,
  removeNftByVault,
  fragment,
  createVault,
  // 创建挖矿
  submissionCreateMetaKey,
  addLiquidity,
  removeLiquidity,
  createPool, // 废弃此条，变为 createDSPPool 和 createDVMPool
  createDPPPool, // 做市商池
  editPool,
  createToken,
  createCP, // 创建众筹
  createDSPPool, // 锚定池
  createDVMPool, // 标准池 和 单币池
}

export type Metadata = Record<string, any>;

export type DeleteSyncTxType = (tx: string) => void;

export interface RequestOptions {
  /** 需要等待后台数据同步，前端才能看到 */
  needSync?: boolean;
  /** 附加信息给判断交易记录对应关系使用 */
  extraData?: any;
}

export enum State {
  Running,
  Success,
  Failed,
  Warning, // 加速或取消的tx 警告处理
}

export enum StateText {
  Running = 'pending',
  Success = 'success',
  Failed = 'failed',
  /** 加速或取消的tx 警告处理 */
  Warning = 'reset',
}

export type Request = {
  brief: string;
  spec: StepSpec;
  subtitle?: string;
  tx: string;
  nonce?: number;
  metadata?: Metadata;
  options?: RequestOptions;
};
