import { dbGet, dbSet } from '@dodoex/utils';
import { EndpointConfig, selectEndpoint, setEndpoints } from '@dodoex/wallet';
import { Map } from 'immutable';
import { CUSTOM_NETWORK_LIST } from '../../static/localstorage';

export const getCustomEndpoint = (chainId: number) => {
  return dbGet({
    path: `${chainId}.${CUSTOM_NETWORK_LIST}`,
    defaultValue: {},
  });
};

export const checkPointValid = async (url: string, chainId: number) => {
  if (url.trim() === 'test') return false;
  try {
    const { result } = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jsonrpc: '2.0',
        method: 'net_version',
        params: [],
        id: chainId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());

    return Number(result) === chainId;
  } catch (error) {
    return false;
  }
};

export const addCustomEndpoint = async ({
  chainId,
  endpointConfig,
  endPointName,
  endPointAddress,
  dispatch,
}: {
  chainId: number;
  endpointConfig: EndpointConfig;
  endPointName: string;
  endPointAddress: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any;
}) => {
  const network = getCustomEndpoint(chainId);
  const networkName = `custom.${endPointName}`;
  network[networkName] = endPointAddress;

  dbSet({
    path: `${chainId}.${CUSTOM_NETWORK_LIST}`,
    value: network,
  });

  dispatch(selectEndpoint(networkName));
  dispatch(setEndpoints(Map({ ...endpointConfig.toJSON(), ...network })));
};

export const removeCustomEndpoint = async ({
  chainId,
  endpointConfig,
  deleteEndPointName,
  dispatch,
}: {
  chainId: number;
  endpointConfig: EndpointConfig;
  deleteEndPointName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any;
}) => {
  const network = getCustomEndpoint(chainId);
  delete network[deleteEndPointName];

  dbSet({
    path: `${chainId}.${CUSTOM_NETWORK_LIST}`,
    value: network,
  });

  const target = endpointConfig.toJSON();
  delete target[deleteEndPointName];
  const allNetworkNames = Object.keys(target);

  if (allNetworkNames.length) {
    dispatch(selectEndpoint(allNetworkNames[0]));
  }

  dispatch(setEndpoints(Map(target)));
};
