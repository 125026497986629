import SafeServiceClient from '@safe-global/safe-service-client';
import Web3Adapter from '@safe-global/safe-web3-lib';
import { getWeb3 } from '../../../web3';

// https://docs.safe.global/learn/safe-core/safe-core-api/available-services
// https://github.com/safe-global/safe-core-sdk/tree/main/packages/safe-service-client#gettransaction
// https://github.com/safe-global/safe-react-apps/issues/62
const serverChainIdObject: {
  [key: number]: string;
} = {
  1: 'https://safe-transaction-mainnet.safe.global/',
  5: 'https://safe-transaction-goerli.safe.global/',
  42161: 'https://safe-transaction-arbitrum.safe.global/',
  43114: 'https://safe-transaction-avalanche.safe.global/',
  1313161554: 'https://safe-transaction-aurora.safe.global/',
  56: 'https://safe-transaction-bsc.safe.global/',
  10: 'https://safe-transaction-optimism.safe.global/',
  137: 'https://safe-transaction-polygon.safe.global/',
};
let safeServiceCache:
  | {
      safeService: SafeServiceClient;
      chainId: number;
    }
  | undefined;

async function getSafeServiceClient(chainId: number) {
  if (safeServiceCache?.chainId === chainId)
    return safeServiceCache.safeService;
  const web3: any = await getWeb3();
  const ethAdapter = new Web3Adapter({
    web3,
  });
  const txServiceUrl = serverChainIdObject[chainId];
  if (!txServiceUrl) return undefined;
  const safeService = new SafeServiceClient({
    txServiceUrl,
    ethAdapter,
  });
  safeServiceCache = {
    safeService,
    chainId,
  };
  return safeServiceCache.safeService;
}
export async function getIsSafe(chainId: number, account?: string) {
  if (!account) return false;
  const safeService = await getSafeServiceClient(chainId);
  if (!safeService) return false;
  try {
    const safeInfo = await safeService.getSafeInfo(account);
    return !!safeInfo;
  } catch (error) {
    return false;
  }
}

/**
 * web3.eth.sendTransaction 有时吐 safeTxHash, 有时 吐 account, 所以判断一下。如果吐的是 account, 就返回最新一条
 */
export async function getSafeTxHash(
  chainId: number,
  account: string,
  safeTxHash: string,
) {
  const safeService = await getSafeServiceClient(chainId);
  if (!safeService) {
    console.error(`chainId: ${chainId} is not supported safe`);
    return safeTxHash;
  }
  if (safeTxHash !== account) return safeTxHash;
  const multisigTransactions = await safeService.getMultisigTransactions(
    safeTxHash,
  );
  const [item] = multisigTransactions.results;
  return item.safeTxHash;
}

export async function getSafeTxStatus(
  chainId: number,
  safeTxHash: string,
  account: string,
) {
  const safeService = await getSafeServiceClient(chainId);
  if (!safeService) {
    throw new Error(`chainId: ${chainId} is not supported safe`);
  }
  try {
    const { results: multisigTransactions } =
      await safeService.getMultisigTransactions(account);
    const transaction = multisigTransactions.find(
      (item) =>
        item.safeTxHash.toLocaleLowerCase() === safeTxHash.toLocaleLowerCase(),
    );
    // const transaction = await safeService.getTransaction(safeTxHash);
    if (!transaction) {
      return null;
    }
    // reject
    const isReject = multisigTransactions.some((item) => {
      if (
        item.nonce === transaction.nonce &&
        item.safeTxHash.toLocaleLowerCase() !==
          safeTxHash.toLocaleLowerCase() &&
        item.isExecuted &&
        item.isSuccessful
      ) {
        return true;
      }
      return false;
    });
    if (isReject) {
      return 'rejected';
    }
    if (transaction.isExecuted === null || transaction.isSuccessful === null) {
      return null;
    }
    const res = {
      status: (transaction.isExecuted && transaction.isSuccessful) || false,
      transactionHash: transaction.transactionHash,
      blockNumber: transaction.blockNumber || null,
    };
    return res;
  } catch (error) {
    console.error(error);
    throw new Error(`safeTxHash: ${safeTxHash} is not valid.`);
  }
}

export function getSafeHashDetailUrl(
  safeTxHash: string,
  chainId: number,
  account: string,
) {
  const networkMap: {
    [key: number]: string;
  } = {
    1: 'eth',
    5: 'gor',
    137: 'matic',
    56: 'bnb',
    42161: 'arb1',
    10: 'oeth',
    43114: 'avax',
    1313161554: 'aurora',
  };
  const network = networkMap[chainId];
  if (!network) {
    throw new Error(`Not Supported network: ${chainId}`);
  }
  return `https://app.safe.global/transactions/tx?id=multisig_${account}_${safeTxHash}&safe=${network}%3A${account}`;
}
