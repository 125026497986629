import { gql } from '@apollo/client';

export const fetchUserprofileReward = gql`
  query FetchUserprofileReward($where: UserprofileReward_filter) {
    userprofile_reward(where: $where) {
      name_key
      token_address
      contract_address
      token_symbol
      locking
      version
      merkle {
        index
        amout
        proof
      }
    }
  }
`;

export const fetchUserprofileAsset = gql`
  query FetchUserprofileAsset($where: UserprofileAsset_filter) {
    userprofile_asset(where: $where) {
      id
      totalValue
      valueInMining
      valueInVDODO
      valueInCrowdPooling
      valueInLiquidity
      liquidityPools {
        pool
        poolType
        baseBalance
        quoteBalance
        baseInMine
        quoteInMine
        baseSymbol
        quoteSymbol
        valueUSD
        quoteToken
        baseToken
      }
      crowdPools {
        pool
        baseSymbol
        quoteSymbol
        quoteToken
        baseToken
        investedQuoteBalance
        valueUSD
      }
      miningPools {
        lpToken
        quoteBalance
        baseBalance
        quoteSymbol
        baseSymbol
        quoteToken
        baseToken
        valueUSD
        miningPool
        pair
      }
      VDODO {
        balance
        valueUSD
      }
    }
  }
`;

export const fetchAchievementMe = gql`
  query FetchAchievementMe($where: AchievementMe_filter) {
    achievement_me(where: $where) {
      total_number_of_transactions {
        name
        achievement_id
        description
        condition {
          type
          num
          amount
        }
        process {
          type
          target_num
          target_amount
          completed_at
          amount
          num
        }
        defaultlogo
        activelogo
      }
      user_invite {
        name
        achievement_id
        description
        condition {
          type
          num
          amount
        }
        process {
          type
          target_num
          target_amount
          completed_at
          amount
          num
        }
        defaultlogo
        activelogo
      }
      total_volume {
        name
        achievement_id
        description
        reward
        condition {
          type
          num
          amount
        }
        process {
          type
          target_num
          target_amount
          completed_at
          amount
          num
        }
        defaultlogo
        activelogo
      }
      constellation_series {
        name
        achievement_id
        description
        condition {
          type
          num
          amount
        }
        process {
          type
          target_num
          target_amount
          completed_at
          amount
          num
        }
        defaultlogo
        activelogo
      }
    }
  }
`;

export const fetchAchievementDetail = gql`
  query FetchAchievementDetail($where: AchievementDetail_filter) {
    achievement_detail(where: $where) {
      user_count
      completed_count
      finish_rate
    }
  }
`;
