export enum ChartKLineChartScope {
  ALL = 'ALL',
  DODO = 'DODO',
}
export interface ChartDataType {
  time: number;
  volume: number;
  open: number;
  close: number;
  high: number;
  low: number;
}
export enum TimeScaleType {
  '15M' = 900,
  '1H' = 3600,
  '4H' = 14400,
  '24H' = 86400,
  '1W' = 604800,
}
