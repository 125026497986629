import {
  generateLinkTo,
  DEX_URL,
  DASHBOARD_URL,
  INIT_LANGUAGE,
} from '@dodoex/utils';
import { getCurrentChainId } from '@dodoex/wallet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { ReactComponent as MenuGOVIcon } from '../images/platform-nav/gov.svg';
import { ReactComponent as MenuDexIcon } from '../images/platform-nav/menu-dex.svg';
import { ReactComponent as MenuNftIcon } from '../images/platform-nav/menu-nft.svg';
import { ReactComponent as MenuWorkshopIcon } from '../images/platform-nav/menu-workshop.svg';
import { ReactComponent as DashboardIcon } from '../images/platform-nav/dashboard-icon.svg';

export enum languageBtnTextMap {
  enUS = 'English',
  zhCN = '简体中文',
  // zhHK = '繁體中文',
  ruRU = 'Pусский',
  jaJP = '日本語',
  viVN = 'Tiếng Việt',
  msMY = 'Bahasa Melayu',
  // frFR = 'Français',
  koKR = '한국인',
  // esES = 'Español',
  // idID = 'Bahasa Indonesia',
}

export type languageBtnTextMapKey = keyof typeof languageBtnTextMap;

// 用于区分项目种类
export enum ProjectTag {
  DEX = 'dex',
  DEV = 'dev',
  NFT = 'nft',
  GOV = 'gov',
  DASHBOARD = 'dashboard',
}
export const usePlatformMenu = ({
  language,
}: {
  language?: languageBtnTextMapKey;
}) => {
  const chainId = useSelector(getCurrentChainId);
  const { t } = useTranslation();
  const platformList = useMemo(
    () => [
      {
        icon: MenuDexIcon,
        name: t('nav.product-menu.dex'),
        desc: t('nav.product-menu.dex.desc'),
        link: generateLinkTo('/', chainId),
        tag: ProjectTag.DEX,
      },
      {
        icon: MenuWorkshopIcon,
        name: t('nav.product-menu.workshop'),
        desc: t('nav.product-menu.workshop.desc'),
        link: generateLinkTo('/dev/erc20', chainId),
        tag: ProjectTag.DEV,
      },
      {
        icon: MenuNftIcon,
        name: t('nav.product-menu.nft'),
        desc: t('nav.product-menu.nft.desc'),
        link: generateLinkTo(`${DEX_URL}/fragment-list`, chainId),
        tag: ProjectTag.NFT,
      },
      {
        icon: MenuGOVIcon,
        name: t('nav.product-menu.gov'),
        desc: t('nav.product-menu.gov.desc'),
        link: generateLinkTo('/gov/dao', chainId),
        tag: ProjectTag.GOV,
      },
      {
        icon: DashboardIcon,
        name: t('nav.product-menu.dashboard'),
        desc: t('nav.product-menu.dashboard.desc'),
        link: `${DASHBOARD_URL}${
          language ? `?${INIT_LANGUAGE}=${language}` : ''
        }`,
        tag: ProjectTag.DASHBOARD,
      },
    ],
    [chainId, language, t],
  );
  return platformList;
};
