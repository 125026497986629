import { ContractConfigDataI } from '../type';

export const HECO_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WHT',
    address: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
    decimals: 18,
    name: 'Wrapped HT',
    source: 'dodo',
  },
  MULTI_CALL: '0x76a328B3440B9276a31523e731B34082df7a6f1A',
  DODO_TOKEN: '0x7d5DF05F987f9B9dD2a13deF1793f943Ad2A5e93',

  ERC20_HELPER: '0x270C2abC692Dd476B860CCbDfD6D8146BF61f8D8',
  ERC20V3_FACTORY: '0x08928cC8bfE3Fb166ed16864953829205f214689',

  DODO_APPROVE: '0x68b6c06Ac8Aa359868393724d25D871921E97293',

  DODO_PROXY: '0xAc7cC7d2374492De2D1ce21e2FEcA26EB0d113e7',
  DODO_DSP_PROXY: '0x9951CdEc21F42ab69D02daAAFEF4C3fc810B36FF',
  DODO_DPP_PROXY: '0xa10b13b55da91F0505FEf91fB91C8a4900F43488',
  DODO_CP_PROXY_V2: '0x1DDcd87C07dD1CB3945E7D9f258Ecf1E4B693094',
  DODO_MINEV3_PROXY: '0x50832DEdCF8b390160D892654530Fb96042d674B',
  DODO_STARTER_PROXY: '0xeFA6617FeAC570B50c195A36B8a46De115362c6C',

  DVM_FACTORY: '0xd8c77CF0F01222b07B8343A681C46eFA3faEa985',
  DSP_FACTORY: '0xaEc55245AB0cDcc03Bc5f104dAdCd0a33Ef50714',
  CP_FACTORY_V2: '0x25406177d125c8AB4101954Cc02945BE49D67515',

  ROUTE_V2_DATA_FETCH: '0x6ff889d124d639fb99fDfEdB7c0fcb888AE2e561',
  ROUTE_V1_DATA_FETCH: '0xFB973C79C665C0AC69E74C67be90D4C7A6f23c59',
  CALLEE_HELPER: '0x684D9d0768761fF0659B9f55D98F57648302ed14',

  FEE_RATE_IMPL: '0x1061a329aDBA6cb91514D7A23bb2CBE980e45A8E',
  vDODO: '',
  vDODO_DODO_TEAM: '',

  // DODONFT
  ROUTE_NFT_DATA_FETCH: '0x5AE86Ec9d43Fb90729b2d75f99DCD73736885492',
  DODO_NFT_PROXY: '0x181d1F15281E5475c517Fb840A6d31d32BbF65f3',
  BUYOUT_MODEL: '0xF0A4D44455168896ad0f305Ff6b744Cbd79704b9',
  DODO_NFT_REGISTER: '0x77777FF74856716fd3FF89aD59fcABcCc1bE0522',
  DODO_NFT_ERC721: '0xc5D46183ce36679186517C04EC9b35B8EE944f72',
  DODO_NFT_ERC1155: '0x036C7f862B80Bb2a9D2Bd0597f9f48D72a9B82F6',

  DODO_NFTPOOL_PROXY: '0xe5A12788A968671abD2ACce8FFD62c95314D3d90',
  DODO_NFT_APPROVE: '0x0d0463bCfAb32892F9dAf56f88d9671F913baB77',
};

export const HECO_SWAPINFO = [
  {
    name: 'MDEX',
    factoryAddress: '0xb0b670fc1F7724119963018DB0BfA86aDb22d941',
    initHash:
      '0x2ad889f82040abccb2649ea6a874796c1601fb67f91a747a80e08860c73ddf24',
  },
  {
    name: 'BXH',
    factoryAddress: '0xe0367ec2bd4Ba22B1593E4fEFcB91D29DE6C512a',
    initHash:
      '0xc109ad0262416bd34b17cc00feda5fa51c2a97264a7b53e4421343f2d9255531',
  },
  {
    name: 'Pippi',
    factoryAddress: '0x979efE7cA072b72d6388f415d042951dDF13036e',
    initHash:
      '0xd805d4c8a7fb3567167020352386905de5d4bd188fe2284675e3ed584653df75',
  },
  {
    name: 'Mdis',
    factoryAddress: '0x2f3f03b6a1b1d73b01390e234ae813bc06d5b8e8',
    initHash:
      '0xc5a24d8158ef97c42050dd7ff53c64db08633f3aa6250cc47b90e63eaeddb4ff',
  },
];
