import { TokenInterface } from '@dodoex/utils';
import { BigNumber } from 'bignumber.js';
import { FetchBidPosition_bidPositions } from './data/__generated__/FetchBidPosition';
import { FetchCP_bidPositions } from './data/__generated__/FetchCP';
import { FetchCPList_crowdPoolings } from './data/__generated__/FetchCPList';
import { FetchSwapList_bidHistories } from './data/__generated__/FetchSwapList';

export const CP_STATUS = {
  WAITING: 'waiting',
  PROCESSING: 'processing',
  ENDED: 'ended',
  SETTLING: 'settling',
  CALMING: 'calming',
};

export const CP_OP_RANK = {
  Verified: 'Verified',
  Star: 'Star',
  Wild: 'Wild',
};

type valueof<T> = T[keyof T];
export type CPStatusType = valueof<typeof CP_STATUS>;

export interface Crowdpooling
  extends Omit<
    FetchCPList_crowdPoolings,
    'baseToken' | 'quoteToken' | 'settled'
  > {
  status: CPStatusType;
  progress: number;
  bidPosition?: FetchBidPosition_bidPositions;
  personalPercentage: number;
  price: BigNumber;
  baseToken: TokenInterface;
  quoteToken: TokenInterface;
  opRank?: string;
  isEscalation: boolean;
  initPrice: BigNumber;
  salesBase: BigNumber;
  weight?: number;
  hardcapPrice?: BigNumber;
  settled?: boolean;
}

export interface CrowdpoolingDetail extends Crowdpooling {
  utilProtectionTime?: number;
  protectionDays?: string | number;
  salesRatio: BigNumber;
  sessionSupplyAmount: BigNumber;
  targetTakerTokenAmount: BigNumber;
  bidPositions?: FetchCP_bidPositions[];
  myShares: BigNumber;
  claimableBaseToken: BigNumber;
  claimedBaseToken: BigNumber;
  claimableQuoteToken: BigNumber;
  isOverflowLimit: boolean | null;
  isClaimedQuoteToken: boolean;
  settledTime?: number;
}

export interface BidHistory extends FetchSwapList_bidHistories {
  price: BigNumber;
}
