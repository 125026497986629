import { getRpcEndpointFromLocalStorage } from '@dodoex/utils';
import { Chain, CustomChain } from '@ethereumjs/common';

export const ValidChainIds = [
  1, 4, 5, 10, 56, 66, 86, 128, 137, 288, 321, 1285, 42161, 421611, 1313161554,
  43114,
];

export interface BasicToken {
  symbol: string;
  address: string;
  name: string;
  decimals: number;
  showDecimals: number;
  wrappedTokenSymbol: string;
  wrappedTokenAddress: string;
}
export interface ChainConfig {
  defaultEndpointUrl: string;
  blockTime: number;
  basicToken: BasicToken;
  /**
   * 用于前端显示切换链提示弹窗，需要可读性好
   */
  network: string;
  scanName: string;
  watchTxTime?: number;
  EIPS?: {
    1559?: boolean;
  };
  /** https://github.com/ethereumjs/ethereumjs-monorepo/tree/master/packages/tx */
  common?: {
    chain?: Chain;
    customChain?: CustomChain;
  };
}
export interface CustomChainConfig {}
export type CustomChainConfigType = Record<number, CustomChainConfig>;
let CustomChainConfig: CustomChainConfigType = {};

export const initCustomChainConfig = (config: CustomChainConfigType) => {
  CustomChainConfig = config;
};

export const getChainConfig = (
  chainId: number,
): ChainConfig & CustomChainConfig => {
  switch (chainId) {
    case 1:
      return {
        network: 'ETH',
        // https://github.com/ethereum-lists/chains/blob/master/_data/chains/eip155-1.json
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          1,
          'https://cloudflare-eth.com',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ether',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
        },
        scanName: 'Etherscan',
        watchTxTime: 3000,
        common: {
          chain: Chain.Mainnet,
        },
        EIPS: {
          1559: true,
        },
        ...CustomChainConfig[1],
      };
    case 4:
      return {
        network: 'Rinkeby',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          4,
          'https://rinkeby.infura.io/v3/17e1665e3b834b979413ccdb5e6fb267',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ether',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xB26c0d8Be2960c70641A95A9195BE1f59Ac83aC0',
        },
        scanName: 'Etherscan',
        watchTxTime: 200,
        common: {
          chain: Chain.Rinkeby,
        },
        ...CustomChainConfig[4],
      };
    case 5:
      return {
        network: 'Goerli',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          5,
          'https://goerli.infura.io/v3/7358883bfd5b44dd9d03c50d373e8b6f',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ether',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
        },
        scanName: 'Etherscan',
        watchTxTime: 200,
        common: {
          chain: Chain.Goerli,
        },
        ...CustomChainConfig[chainId],
      };
    case 10:
      return {
        network: 'Optimism',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          10,
          'https://mainnet.optimism.io',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ether',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0x4200000000000000000000000000000000000006',
        },
        scanName: 'Optimistic Explorer',
        watchTxTime: 200,
        common: {
          customChain: CustomChain.OptimisticEthereum,
        },
        ...CustomChainConfig[chainId],
      };
    case 288:
      return {
        network: 'Boba',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          288,
          'https://mainnet.boba.network',
        ),
        blockTime: 10000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'ETH',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
        },
        watchTxTime: 200,
        scanName: 'BOBA Explorer',
        ...CustomChainConfig[chainId],
      };
    case 56:
    case 86:
      return {
        network: 'BSC',
        // https://docs.bnbchain.org/docs/rpc#mainnet-chainid-0x38-56-in-decimal
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          56,
          'https://bsc-dataseed.binance.org',
        ),
        blockTime: 3000,
        basicToken: {
          symbol: 'BNB',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'BNB',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WBNB',
          wrappedTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        },
        watchTxTime: 200,
        scanName: 'Bscscan',
        EIPS: {
          1559: false,
        },
        ...CustomChainConfig[chainId],
      };
    case 97:
      return {
        network: 'BSC',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          97,
          'https://data-seed-prebsc-1-s1.binance.org:8545',
        ),
        blockTime: 3000,
        basicToken: {
          symbol: 'tBNB',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'tBNB',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WBNB',
          wrappedTokenAddress: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        },
        watchTxTime: 200,
        scanName: 'Bscscan',
      };
    case 66:
      return {
        network: 'OKC',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          66,
          'https://exchainrpc.okex.org',
        ),
        blockTime: 3000,
        basicToken: {
          symbol: 'OKT',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'OKT',
          decimals: 18,
          showDecimals: 4,
          wrappedTokenSymbol: 'WOKT',
          wrappedTokenAddress: '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',
        },
        watchTxTime: 200,
        scanName: 'OKchain Scan',
        ...CustomChainConfig[chainId],
      };
    case 128:
      return {
        network: 'Heco',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          128,
          'https://http-mainnet.hecochain.com',
        ),
        blockTime: 3000,
        basicToken: {
          symbol: 'HT',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'HT',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WHT',
          wrappedTokenAddress: '0x5545153ccfca01fbd7dd11c0b23ba694d9509a6f',
        },
        watchTxTime: 200,
        scanName: 'Hecoinfo',
        ...CustomChainConfig[chainId],
      };
    case 137:
      return {
        network: 'Polygon',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          137,
          'https://polygon-rpc.com',
        ),
        blockTime: 2200,
        basicToken: {
          symbol: 'MATIC',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'MATIC',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WMATIC',
          wrappedTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        watchTxTime: 200,
        scanName: 'Polygon',
        common: {
          customChain: CustomChain.PolygonMainnet,
        },
        EIPS: {
          1559: true,
        },
        ...CustomChainConfig[chainId],
      };
    case 321:
      return {
        network: 'KCC',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          321,
          'https://rpc-mainnet.kcc.network',
        ),
        blockTime: 3000,
        basicToken: {
          symbol: 'KCS',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'KuCoin Token',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WKCS',
          wrappedTokenAddress: '0x4446fc4eb47f2f6586f9faab68b3498f86c07521',
        },
        watchTxTime: 200,
        scanName: 'KCC Explorer',
        ...CustomChainConfig[chainId],
      };
    case 1285:
      return {
        network: 'Moonriver',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          1285,
          'https://rpc.api.moonriver.moonbeam.network',
        ),
        blockTime: 12500,
        basicToken: {
          symbol: 'MOVR',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'MOVR',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WMOVR',
          wrappedTokenAddress: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
        },
        watchTxTime: 200,
        scanName: 'Moonriver Explore',
        ...CustomChainConfig[chainId],
      };
    case 42161:
      return {
        network: 'Arbitrum',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          42161,
          'https://arb1.arbitrum.io/rpc',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ethereum',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        },
        scanName: 'Arbitrum',
        watchTxTime: 200,
        ...CustomChainConfig[chainId],
      };
    case 421611:
      return {
        network: 'arb-rinkeby',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          421611,
          'https://rinkeby.arbitrum.io/rpc',
        ),
        blockTime: 13000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ether',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xEBbc3452Cc911591e4F18f3b36727Df45d6bd1f9',
        },
        scanName: 'arb-rinkeby',
        watchTxTime: 200,
        common: {
          customChain: CustomChain.ArbitrumRinkebyTestnet,
        },
        ...CustomChainConfig[chainId],
      };
    case 1313161554:
      return {
        network: 'Aurora',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          1313161554,
          'https://mainnet.aurora.dev',
        ),
        blockTime: 1000,
        basicToken: {
          symbol: 'ETH',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Ethereum',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WETH',
          wrappedTokenAddress: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
        },
        scanName: 'Aurora',
        watchTxTime: 200,
        ...CustomChainConfig[chainId],
      };
    case 43114:
      return {
        network: 'Avalanche',
        defaultEndpointUrl: getRpcEndpointFromLocalStorage(
          43114,
          'https://api.avax.network/ext/bc/C/rpc',
        ),
        blockTime: 2000,
        basicToken: {
          symbol: 'AVAX',
          address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
          name: 'Avalanche',
          decimals: 18,
          showDecimals: 6,
          wrappedTokenSymbol: 'WAVAX',
          wrappedTokenAddress: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        },
        scanName: 'Avalanche',
        watchTxTime: 200,
        ...CustomChainConfig[chainId],
      };
    default:
      return {
        network: '',
        defaultEndpointUrl: '',
        blockTime: 13000,
        basicToken: {
          symbol: '',
          address: '',
          name: '',
          decimals: 0,
          showDecimals: 0,
          wrappedTokenSymbol: '',
          wrappedTokenAddress: '',
        },
        scanName: '',
      };
  }
};
