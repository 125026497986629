import BigNumber from 'bignumber.js';
import classicalPoolABI from '../../ABIs/classicalPoolABI';
import dppPoolABI from '../../ABIs/dppPoolABI';
import dvmPoolABI from '../../ABIs/DVM';
import { ComputeLpParams } from '../computeLp';
import { PoolPriceContract, PoolPriceContractObject } from './type';

export const defaultValue: ComputeLpParams = {
  baseReserve: new BigNumber(0),
  quoteReserve: new BigNumber(0),
  totalBase: new BigNumber(0),
  totalQuote: new BigNumber(0),
  userBase: new BigNumber(0),
  userQuote: new BigNumber(0),
  userMiningBase: new BigNumber(0),
  userMiningQuote: new BigNumber(0),
  totalMiningBase: new BigNumber(0),
  totalMiningQuote: new BigNumber(0),
};

export const lpTokenAndDspContractObject: PoolPriceContract = {
  ABI: dvmPoolABI,
  isV3Mining: true,
  baseReserveParams: 'baseReserve',
  quoteReserveParams: 'quoteReserve',
  method: {
    totalBase: 'totalSupply',
    userBase: 'balanceOf',
    reserve: 'getVaultReserve',
    userMiningBase: 'balanceOf',
    totalMiningBase: 'totalSupply',
  },
};

export const poolPriceContractObject: PoolPriceContractObject = {
  DVM: {
    ABI: dvmPoolABI,
    baseReserveParams: 'baseReserve',
    quoteReserveParams: 'quoteReserve',
    method: {
      totalBase: 'totalSupply',
      userBase: 'balanceOf',
      reserve: 'getVaultReserve',
      userMiningBase: 'getUserLpBalance',
      totalMiningBase: 'balanceOf',
    },
  },
  LPTOKEN: lpTokenAndDspContractObject,
  DSP: lpTokenAndDspContractObject,
  CLASSICAL: {
    ABI: classicalPoolABI,
    baseReserveParams: 'baseTarget',
    quoteReserveParams: 'quoteTarget',
    method: {
      totalBase: 'getTotalBaseCapital',
      totalQuote: 'getTotalQuoteCapital',
      userBase: 'getBaseCapitalBalanceOf',
      userQuote: 'getQuoteCapitalBalanceOf',
      reserve: 'getExpectedTarget',
      userMiningBase: 'getUserLpBalance',
      userMiningQuote: 'getUserLpBalance',
      totalMiningBase: 'balanceOf',
      totalMiningQuote: 'balanceOf',
    },
  },
  DPP: {
    ABI: dppPoolABI,
    baseReserveParams: 'baseReserve',
    quoteReserveParams: 'quoteReserve',
    method: {
      reserve: 'getVaultReserve',
    },
  },
  VIRTUAL: undefined,
};
