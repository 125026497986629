import { gql } from '@apollo/client';

export const combineAnnouncementAndVersion = gql`
  query CombineAnnouncementAndVersion(
    $lang: String
    $where: Dodo_app_versionnewFilter
  ) {
    announcement_getAnnouncement(lang: $lang) {
      list {
        id
        url
        title
        lastPublishTime
        type
        metadata {
          background
          icon
        }
      }
      lastPublishTime
    }
    dodo_app_version_new(where: $where) {
      id
      title
      description
      version
      urls {
        name
        url
      }
      refreshType
      icon
    }
  }
`;

export const combinePendingListAndStatus = gql`
  query CombinePendingListAndStatus(
    $pendingOrderWhere: Limit_and_rfqgetPendingLimitOrderParam
    $bridgeOrderWhere: Cross_chain_swaporderNewStatusData
  ) {
    limit_and_rfq_getPendingOrderChainList(where: $pendingOrderWhere)
    limit_and_rfq_getOrderStatusBroadcasts(where: $pendingOrderWhere) {
      id
      orderId
      user
      chainId
      makerToken
      takerToken
      makerTokenSymbol
      takerTokenSymbol
      reason
      createdAt
      updatedAt
    }
    cross_chain_swap_orderNewStatus(where: $bridgeOrderWhere) {
      id
      fromChainId
      fromAmount
      fromTokenAddress
      toChainId
      toAmount
      toTokenAddress
      user
      toAddress
      fromHash
      toHash
      product
      status
      subStatus
      massage
      createdAt
      updatedAt
      crossChainOrderStatus {
        id
        orderId
        beforeStatus
        status
        beforeSubStatus
        subStatus
        message
        isNew
        createdAt
        updatedAt
      }
    }
  }
`;
