/** 空闲时间基准为 10ms */
export const IDLE_FREE_TIME = 10;
/**
 * 幕后任务协作调度 API
 * 用 requestIdleCallback() 来在浏览器空闲时运行高耗时、低优先级的任务
 * @see https://developer.mozilla.org/zh-CN/docs/Web/API/Background_Tasks_API#%E5%9B%9E%E9%80%80%E5%88%B0_settimeout
 */
window.requestIdleCallback =
  window.requestIdleCallback ||
  ((handler) => {
    const startTime = Date.now();

    return setTimeout(() => {
      handler({
        didTimeout: false,
        timeRemaining() {
          return Math.max(0, 50.0 - (Date.now() - startTime));
        },
      });
    }, 1);
  });

window.cancelIdleCallback =
  window.cancelIdleCallback ||
  ((id) => {
    clearTimeout(id);
  });
