import { gql } from '@apollo/client';

/**
 * query:dashboard_pairs_detail
 */
export const fetchPairsStat = gql`
  query FetchPairsStat($where: Dashboardpair_detail_filter) {
    dashboard_pairs_detail(where: $where) {
      fee
      volume
      totalFee
      totalVolume
      tvl
      turnover
      liquidity
      baseReserve
      quoteReserve
      baseVolume
      quoteVolume
      basePrice
      quotePrice
      price
      baseFee
      quoteFee
      pair
      poolType
      baseVolumeCumulative
      quoteVolumeCumulative
      baseAddress
      baseSymbol
      quoteAddress
      quoteSymbol
      network
      pairAddress
      txes
      txesNear24h
      txUsers
      txUserNear24h
    }
  }
`;

export const fetchPairsStatList = gql`
  query FetchPairsStatList($where: Dashboardtype_list_filter) {
    dashboard_pairs_list(where: $where) {
      list {
        fee
        volume
        totalFee
        totalVolume
        tvl
        turnover
        liquidity
        baseReserve
        quoteReserve
        baseVolume
        quoteVolume
        basePrice
        quotePrice
        price
        baseFee
        quoteFee
        pair
        poolType
        baseVolumeCumulative
        quoteVolumeCumulative
        baseAddress
        baseSymbol
        quoteAddress
        quoteSymbol
        network
        pairAddress
        txes
        txesNear24h
        txUsers
        txUserNear24h
      }
      count
    }
  }
`;

export const fetchPairsStatDateData = gql`
  query FetchPairsStatDateData(
    $dayWhere: Dashboardday_filter
    $hourWhere: Dashboardhour_filter
  ) {
    dashboard_pairs_day_data(where: $dayWhere) {
      timestamp
      date
      volume
      fee
      tvl
      addresses
      txes
    }
    dashboard_pairs_hour_data(where: $hourWhere) {
      timestamp
      date
      volume
      fee
      tvl
      addresses
      txes
    }
  }
`;
