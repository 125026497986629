import { gql } from '@apollo/client';

export const fetchDashboardDailyData = gql`
  query FetchDashboardDailyData($where: Dashboardchain_daily_data_filter) {
    dashboard_chain_day_data(where: $where) {
      volume_near24h {
        bsc
        aurora
        boba
        ethereum
        polygon
        arbitrum
      }
      txCount_near24h {
        bsc
        aurora
        boba
        ethereum
        polygon
        arbitrum
      }
      traders_near24h {
        bsc
        aurora
        boba
        ethereum
        arbitrum
        polygon
      }
      tvl {
        bsc
        aurora
        boba
        ethereum
        polygon
        arbitrum
      }
      pools {
        bsc
        aurora
        boba
        ethereum
        polygon
        arbitrum
      }
      pairs {
        bsc
        aurora
        boba
        ethereum
        polygon
        arbitrum
      }
      list {
        timestamp
        date_str
        txCount {
          bsc
          aurora
          boba
          ethereum
          polygon
          arbitrum
        }
        traders {
          bsc
          aurora
          boba
          ethereum
          polygon
          arbitrum
        }
        volume {
          bsc
          aurora
          boba
          ethereum
          polygon
          arbitrum
        }
        tvl {
          bsc
          aurora
          boba
          ethereum
          polygon
          arbitrum
        }
      }
    }
  }
`;

export const fetchDashboardSymbolList = gql`
  query FetchDashboardSymbolList($where: Dashboardsymbol_detail_filter) {
    dashboard_pairs_pair_symbol_list(where: $where) {
      pairAddress
      network
      poolType
      pair
      liquidity
      tvl
      volume
      totalFee
      fee
      turnover
      baseAddress
      baseSymbol
      quoteAddress
      quoteSymbol
      date
    }
  }
`;

export const fetchDashboardInfoData = gql`
  query FetchDashboardInfoData($where: Dashboardtype_filter) {
    dashboard_pairs_count_data(where: $where) {
      totalVolume
      txes
      txesUsers
    }
  }
`;

export const fetchDashboardPairs = gql`
  query FetchDashboardPairs($where: Dashboardrate24h_filter) {
    dashboard_pairs_rate_24(where: $where) {
      pages
      pairs
    }
  }
`;
