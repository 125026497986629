import bodymovin from 'lottie-web';
import { useEffect, useCallback, useState, SetStateAction } from 'react';

export function useBodyMovin({
  BodymovinJson,
  loop = false,
  name,
}: {
  BodymovinJson: any;
  loop?: boolean;
  name: string;
}) {
  const [animationIconEle, setAnimationIconEle] = useState<HTMLElement>();
  const ref = useCallback(
    (node: SetStateAction<HTMLElement | undefined> | null) => {
      if (node !== null) {
        setAnimationIconEle(node);
      }
    },
    [],
  );
  useEffect(() => {
    if (animationIconEle) {
      try {
        bodymovin.loadAnimation({
          loop,
          name,
          container: animationIconEle,
          renderer: 'svg',
          autoplay: true,
          animationData: BodymovinJson,
        });
      } catch (error) {
        console.error('[bodymovin loadAnimation]', error);
      }
    }
  }, [animationIconEle]);
  return {
    ref,
    destroy: () => bodymovin.destroy(name),
  };
}
