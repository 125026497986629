import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { set_once } from '@dodoex/mixpanel/dist/wallet';
import { AnyAction, CombinedState, Store } from 'redux';
import { pingUrl } from '@dodoex/utils';
import { setChainId, setIsChinaMainland } from './actions';
import { CustomChainConfigType, initCustomChainConfig } from './chainConfigs';
import {
  useBlockNumberTimer,
  useGasPriceTimer,
  useBaseFeeTimer,
} from './hooks/useTimers';
import reducer, { State } from './reducer';
import { getCurrentChainId } from './selectors';
import { getChainIdWithNetworkName } from './utils';
import {
  autoConnectToWallet,
  initWeb3Modal,
  initIsSupportAllChain,
} from './web3';
import {
  getSafeTxHash,
  getSafeTxStatus,
} from './injects/wallet/safeWalletConnect';

export const WalletReducer = { dodoWallet: reducer };
export type WalletState = { dodoWallet: State };

export interface WalletConfig {
  PORTIS_ID?: string;
  ETH_NETWORK: string;
}

export type StoreType = Store<CombinedState<{ dodoWallet: State }>, AnyAction>;

type FetchGasPrice = (
  chainId: number,
  confidence?: number,
) => Promise<{
  gasPrice: number;
  maxFeePerGas?: number;
  maxPriorityFeePerGas?: number;
}>;

export let walletConfig: WalletConfig;
export let store: StoreType;
export let mixpanel: any;
export let sentry: any;
export let fetchGasPrice: FetchGasPrice;
export let accessToken: string | undefined;
type AlertEndpointStatus = (isSuccess: boolean) => void;
export let alertEndpointStatus: AlertEndpointStatus = (isSuccess: boolean) => {
  // empty
};

export interface InitWalletParams {
  projectWalletConfig: WalletConfig;
  customChainConfig?: CustomChainConfigType;
  projectStore: Store;
  projectMixpanel?: any;
  projectSentry?: any;
  isSupportAllChain?: boolean; // 是否支持所有链 (目前用于开发者平台，不需要区分链，只需要钱包签名)
}

export const initWallet = ({
  projectWalletConfig,
  customChainConfig,
  projectStore,
  projectMixpanel,
  projectSentry,
  isSupportAllChain,
}: InitWalletParams) => {
  walletConfig = projectWalletConfig;
  if (customChainConfig) initCustomChainConfig(customChainConfig);
  if (isSupportAllChain) initIsSupportAllChain(isSupportAllChain);
  store = projectStore;
  mixpanel = projectMixpanel;
  sentry = projectSentry;
  const chainId = getCurrentChainId();
  if (!chainId)
    store.dispatch(
      setChainId(getChainIdWithNetworkName(walletConfig.ETH_NETWORK)),
    );
  initWeb3Modal();
};

export const useDODOWallet = ({
  fetchGasPrice: fetchGasPriceProps,
  accessToken: accessTokenProps,
  alertEndpointStatus: alertEndpointStatusProps,
}: {
  fetchGasPrice?: FetchGasPrice;
  accessToken?: string;
  alertEndpointStatus?: AlertEndpointStatus;
} = {}) => {
  const dispatch = useDispatch();

  if (fetchGasPriceProps) {
    fetchGasPrice = fetchGasPriceProps;
  }

  if (alertEndpointStatusProps) {
    alertEndpointStatus = alertEndpointStatusProps;
  }

  useEffect(() => {
    accessToken = accessTokenProps;
  }, [accessTokenProps]);

  useEffect(() => {
    autoConnectToWallet();
  }, []);

  useBlockNumberTimer();
  useBaseFeeTimer();
  useGasPriceTimer();

  useEffect(() => {
    pingUrl('https://www.google.com')
      .then(() => {
        dispatch(setIsChinaMainland(false));
      })
      .catch(() => {
        dispatch(setIsChinaMainland(true));
      });
  }, []);

  useEffect(() => {
    if (mixpanel) set_once('Sign Up', new Date());
  }, []);
};
