import { gql } from '@apollo/client';

export const fetchGasFeederGetGasPrices = gql`
  query FetchGasFeederGetGasPrices($where: Gas_feedergasPrices) {
    gas_feeder_getGasPrices(where: $where) {
      confidence
      maxFeePerGas
      maxPriorityFeePerGas
      gasPrice
    }
  }
`;
