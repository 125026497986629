import { gql } from '@apollo/client';

export const updateCreateLimitOrder = gql`
  query UpdateCreateLimitOrder($where: Limit_and_rfquserCreateLimitOrder) {
    limit_and_rfq_limitOrderCreate(where: $where) {
      createdAt
      filledAmount
      id
      network
      order {
        makerToken
        takerToken
        makerAmount
        takerAmount
        maker
        taker
        expiration
        salt
      }
      expiration
      maker
      makerAmount
      makerToken
      salt
      taker
      takerAmount
      takerToken
      progress
      signature
      updatedAt
    }
  }
`;

export const updateCancelLimitOrder = gql`
  query UpdateCancelLimitOrder($where: Limit_and_rfquserCancelLimitOrder) {
    limit_and_rfq_limitOrderCancel(where: $where) {
      authorization
    }
  }
`;

export const fetchLimitOrderList = gql`
  query Limit_and_rfq_limitOrderListWithPage(
    $where: Limit_and_rfquserQueryLimitOrderListWithPage
  ) {
    limit_and_rfq_limitOrderListWithPage(where: $where) {
      list {
        createdAt
        filledAmount
        id
        network
        order {
          makerToken
          takerToken
          makerAmount
          takerAmount
          maker
          taker
          expiration
          salt
        }
        expiration
        maker
        makerAmount
        makerToken
        makerTokenSymbol
        makerTokenDecimal
        makerTokenLogoImg
        salt
        taker
        takerAmount
        takerToken
        takerTokenSymbol
        takerTokenDecimal
        takerTokenLogoImg
        progress
        updatedAt
        failureReason
        hash
      }
      total
      totalPage
      currentPage
      limit
    }
  }
`;

export const fetchPendingLimitOrderList = gql`
  query Limit_and_rfq_getPendingOrderChainList(
    $where: Limit_and_rfqgetPendingLimitOrderParam
  ) {
    limit_and_rfq_getPendingOrderChainList(where: $where)
  }
`;

export const fetchFailedLimitOrder = gql`
  query Limit_and_rfq_getOrderStatusBroadcasts(
    $where: Limit_and_rfqgetPendingLimitOrderParam
  ) {
    limit_and_rfq_getOrderStatusBroadcasts(where: $where) {
      id
      orderId
      user
      chainId
      makerToken
      takerToken
      makerTokenSymbol
      takerTokenSymbol
      reason
      createdAt
      updatedAt
    }
  }
`;

export const fetchLimitOrderTokenMinAmount = gql`
  query Limit_and_rfq_limitOrderAmountLimit(
    $where: Limit_and_rfqlimitOrderAmountLimitParam
  ) {
    limit_and_rfq_limitOrderAmountLimit(where: $where)
  }
`;
