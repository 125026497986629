import { identify } from '@dodoex/mixpanel/dist/wallet';
import BigNumber from 'bignumber.js';
import { mixpanel } from './core';
import { setLastChainId, setLastEndpoint } from './localstorage';
import { AccountType, EndpointConfig, GasPriceInfo, LatestBlockNumberStatus } from './reducer';
import { getCurrentChainId } from './selectors';
import { timePromise } from './utils';

export const setCurrentAccount = (account?: string) => {
  if (account && mixpanel) identify(account);
  const search = new URLSearchParams(window.location.search);
  if (search.get('_account')) {
    account = search.get('_account') as string;
  }
  // account = '0x3c9f68e0F63541168907253acd3149b70b2F45C2';
  return {
    type: 'SET_CURRENT_ACCOUNT',
    payload: { account },
  };
};

export const setChainId = (chainId: number) => {
  setLastChainId(chainId);
  return {
    type: 'SET_CHAIN_ID',
    payload: { chainId },
  };
};

export const setBaseFee = (baseFee: BigNumber) => {
  return {
    type: 'SET_CURRENT_BASE_FEE',
    payload: { baseFee },
  };
};

export const setGasPriceInfo = (gasPriceInfo: GasPriceInfo) => {
  return {
    type: 'SET_GAS_PRICE',
    payload: { gasPriceInfo },
  };
};

export function setEndpoints(endpoints: EndpointConfig) {
  return (dispatch: any) => {
    dispatch({
      type: 'SET_ENDPOINTS',
      payload: {
        endpoints,
      },
    });

    if (!endpoints) return;

    endpoints.map((endpoint, key) => {
      // Fire and forget
      timePromise(
        fetch(endpoint, {
          method: 'POST',
          body: JSON.stringify({
            jsonrpc: '2.0',
            method: 'eth_blockNumber',
            params: [],
            id: 1,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }),
        3000,
      )
        .then(async (resp) => {
          let rtt = -1;
          let blockHeight = -1;
          if (resp) {
            const [time, xhr] = resp;
            const ct = xhr.headers.get('content-type');
            if (ct && ct.includes('application/json')) {
              const payload = await xhr.json();
              if (payload.success !== false && payload.success !== 'false') {
                rtt = time;
                // 403 错误这里会报错
                try {
                  blockHeight = new BigNumber(payload.result).toNumber();
                } catch (error) {
                  console.error('error payload.result', error);
                }
              }
            }
          }

          dispatch({
            type: 'SET_ENDPOINT_RTT',
            payload: {
              endpoint: key,
              rtt,
              blockHeight,
            },
          });
        })
        .catch((e) => {
          console.error(e);
        });
    });
  };
}

export const selectEndpoint = (endpoint: string) => {
  const chainId = getCurrentChainId();
  setLastEndpoint(chainId, endpoint);
  return {
    type: 'SELECT_ENDPOINT',
    payload: { endpoint },
  };
};

export const setBlockNumber = (blockNumber: BigNumber) => {
  return {
    type: 'SET_BLOCK_NUMBER',
    payload: { blockNumber },
  };
};

export const setETHBlockNumber = (blockNumber: BigNumber) => {
  return {
    type: 'SET_ETH_BLOCK_NUMBER',
    payload: { blockNumber },
  };
};

export const setProxyAddress = (proxyAddress: string) => {
  return {
    type: 'SET_PROXY_ADDRESS',
    payload: { proxyAddress },
  };
};

export const setChainMismatchNotice = (shown: boolean) => {
  return {
    type: 'SET_CHAIN_MISMATCH_NOTICE',
    payload: { shown },
  };
};

export const setShowAccountModalAction = (show: boolean) => {
  return {
    type: 'SET_SHOW_ACCOUNT_MODAL',
    payload: { show },
  };
};

export const setBuyCryptoListAutoOpen = (show: boolean) => {
  return {
    type: 'SET_BUY_CRYPTO_LIST_AUTO_OPEN',
    payload: { show },
  };
};

export const setIsChinaMainland = (isChinaMainland: boolean) => {
  return {
    type: 'SET_IS_CHINA_MAINLAND',
    payload: { isChinaMainland },
  };
};

export const setCurrentAccountType = (accountType: AccountType) => {
  return {
    type: 'SET_ACCOUNT_TYPE',
    payload: {
      accountType,
    }
  }
};

export const setBlockNumberStatus = (
  latestBlockNumberStatus: LatestBlockNumberStatus,
) => {
  return {
    type: 'SET_BLOCK_NUMBER_STATUS',
    payload: {
      latestBlockNumberStatus,
    },
  };
};
