/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-properties */
import { ApolloClient, useQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import {
  formatReadableNumber,
  getChain,
  formatUnknownTokenSymbol,
  useRefetch,
} from '@dodoex/utils';
import { fetchPool } from './data/queries';
import { FetchPool, FetchPoolVariables } from './data/__generated__/FetchPool';
import { InnerPoolType } from '../liquidity/poolTypes';
import {
  batchGetMidPriceAndPmmParams,
  DEFAULT_MID_PRICE,
  PmmModelParams,
  PmmModelParamsBG,
} from '../pmm';
import { MyLiquidityInfo, Pool } from './types';
import { computeLpBatch } from '../liquidity/computeLpBatch';
import { computeMyLiquidityInfo } from '../liquidity/computeMyLiquidityInfo';
import { useRootQuery } from '../apollo/query';

type Props = {
  address?: string;
  chainId: number;
  /** 如果需要过滤出当前账户的流动性池子时传入 */
  account?: string | null;
  callback?: (v: Pool | null) => void;
  /** 是否需要跳过查询 midPrice 和 pmmParams */
  skipMidPriceQuery?: boolean;
};

const initialPool: Pool = {
  poolId: '',
  address: '',
  type: 'public',
  innerType: 'DVM',
  baseToken: null,
  quoteToken: null,
  feeRate: '-',
  addPortion: new BigNumber(1),
  price: '-',
  reversePrice: '-',
  baseAmount: '-',
  baseReserve: new BigNumber(1),
  quoteAmount: '-',
  quoteReserve: new BigNumber(0),
  creator: '-',
  i: 100,
  k: 0.1,
  isCurrentAccountHasLiquidity: false,
  traderCount: '-',
  midPrice: new BigNumber(DEFAULT_MID_PRICE),
  isCpPool: false,
};

export function usePoolDetail({
  address,
  callback,
  account,
  chainId,
  skipMidPriceQuery,
}: Props) {
  const userId = account?.toLowerCase();
  const [pool, setPool] = useState<Pool>({
    ...initialPool,
    address: address || '',
  });
  const [noResultModalVisible, setNoResultModalVisible] = useState(false);
  const { thegraphKey } = getChain(chainId);

  const queryOptions = useMemo(() => {
    const id = address?.toLocaleLowerCase() || '';
    return {
      variables: {
        id,
        // 此时的池子可能是众筹建池 settle 后间接创建出来的 dvm 池子，要保存众筹建池的地址信息
        cpWhere: {
          dvm: id,
          chain: thegraphKey,
          refreshNow: false,
        },
        where: {
          refreshNow: false,
          chain: thegraphKey,
        },
      },
      skip: !address,
    };
  }, [address, thegraphKey]);

  const {
    error,
    loading,
    refetch: refetchData,
    data,
  } = useRootQuery<FetchPool, FetchPoolVariables>(
    fetchPool,
    queryOptions,
    undefined,
    false,
    true,
  );
  const [updatePoolLoading, setUpdatePoolLoading] = useState(true);
  const { refetch, isRefetch, refetchTimes } = useRefetch(refetchData);

  useEffect(() => {
    if (error) {
      setUpdatePoolLoading(false);
    }
  }, [error]);
  useEffect(() => {
    async function updatePool() {
      if (!address || loading) return;
      if (data && !data.pair) {
        // 池子不存在
        callback && callback(initialPool);

        if (data.pair === null) {
          setNoResultModalVisible(true);
        }
        return;
      }
      setNoResultModalVisible(false);
      setUpdatePoolLoading(true);
      const { pair, crowdPoolings } = data || {};
      if (pair) {
        const {
          creator,
          volumeBaseToken,
          volumeQuoteToken,
          volumeUSD,
          feeBase,
          feeQuote,
          traderCount,
          lpFeeRate,
        } = pair;
        const innerType = pair.type.toUpperCase() as InnerPoolType;

        // i 是除以 10 ^ (18 - baseTokenDecimals + quoteTokenDecimals)
        let i = new BigNumber(pair.i).div(
          10 **
            (18 -
              Number(pair.baseToken.decimals) +
              Number(pair.quoteToken.decimals)),
        );

        let midPrice = new BigNumber(DEFAULT_MID_PRICE);
        let pmmParamsBG: PmmModelParamsBG | undefined;
        let pmmParams: PmmModelParams | undefined;
        if (!skipMidPriceQuery) {
          const midPriceAndPmmParams = await batchGetMidPriceAndPmmParams({
            chainId,
            poolList: [
              {
                address,
                innerType: pair.type.toUpperCase() as InnerPoolType,
                baseDecimals: Number(pair.baseToken.decimals),
                quoteDecimals: Number(pair.quoteToken.decimals),
              },
            ],
          });
          if (midPriceAndPmmParams.has(address)) {
            const found = midPriceAndPmmParams.get(address);
            if (found?.midPrice) {
              midPrice = found.midPrice;
            }
            if (found?.pmmParamsBG) {
              pmmParamsBG = found.pmmParamsBG;
            }
            if (found?.pmmParams) {
              pmmParams = found.pmmParams;
            }
          }
        }

        let baseReserve = new BigNumber(pair.baseReserve);
        let quoteReserve = new BigNumber(pair.quoteReserve);
        const baseShowDecimals = pair.baseToken.decimals > 6 ? 6 : 4;
        const quoteShowDecimals = pair.quoteToken.decimals > 6 ? 6 : 4;
        let myLiquidityInfo: MyLiquidityInfo | undefined;
        if (userId) {
          const computeLpMap = await computeLpBatch({
            chainId,
            account: userId,
            poolList: [
              {
                poolAddress: pair.id,
                poolType: innerType,
                baseLpTokenAddress: pair.baseLpToken?.id,
                quoteLpTokenAddress: pair.quoteLpToken?.id,
                baseLpDecimals: pair.baseLpToken?.decimals || 18,
                quoteLpDecimals: pair.quoteLpToken?.decimals || 18,
                baseTokenDecimals: pair.baseToken?.decimals || 18,
                quoteTokenDecimals: pair.quoteToken?.decimals || 18,
              },
            ],
          });
          const computeLp = computeLpMap.get(pair.id);
          if (computeLp) {
            myLiquidityInfo = computeMyLiquidityInfo({
              computeLp,
              address: pair.id,
              poolType: innerType,
              baseDecimals: pair.baseToken.decimals,
              baseShowDecimals,
              quoteDecimals: pair.quoteToken.decimals,
              quoteShowDecimals,
              // classical 池子直接使用 pmm 里面的 b 做为 baseReserve；而调用 computeLp 获取的是 target 的值，用来计算添加流动性的参数，不能作为展示
              baseReserve: pmmParamsBG?.b || baseReserve,
              quoteReserve: pmmParamsBG?.q || quoteReserve,
              baseLpDecimals: pair.baseLpToken?.decimals,
              quoteLpDecimals: pair.quoteLpToken?.decimals,
            });
            // 从合约中读取实时的 reserve
            baseReserve = myLiquidityInfo.classicalBaseTarget || baseReserve;
            quoteReserve = myLiquidityInfo.classicalQuoteTarget || quoteReserve;
          }
        }
        const isSellPool = innerType === 'DVM' && quoteReserve.eq(0);
        const isEmptyDspPool =
          innerType === 'DSP' && (quoteReserve.eq(0) || baseReserve.eq(0));

        // 从合约中读取到 pmmParams 中的 i 更精确
        if (pmmParamsBG && pmmParamsBG.i) {
          i = pmmParamsBG.i;
        }

        let data: Pool = {
          poolId: address,
          address,
          type:
            innerType === 'DPP'
              ? 'private'
              : innerType === 'DSP'
              ? 'dsp'
              : innerType === 'VIRTUAL'
              ? 'virtual'
              : 'public',
          innerType,
          baseToken: {
            name: pair.baseToken.name,
            symbol: formatUnknownTokenSymbol(pair.baseToken),
            address: pair.baseToken.id,
            decimals: Number(pair.baseToken.decimals),
            showDecimals: baseShowDecimals,
            usdPrice: pair.baseToken.usdPrice,
          },
          quoteToken: {
            name: pair.quoteToken.name,
            symbol: formatUnknownTokenSymbol(pair.quoteToken),
            address: pair.quoteToken.id,
            decimals: Number(pair.quoteToken.decimals),
            showDecimals: quoteShowDecimals,
            usdPrice: pair.quoteToken.usdPrice,
          },
          lastTradePrice: pair.lastTradePrice,
          volumeBaseToken,
          volumeQuoteToken,
          volumeUSD,
          baseLpFee: feeBase,
          quoteLpFee: feeQuote,
          traderCount,
          feeRate: lpFeeRate,
          isSellPool,
          addPortion: isEmptyDspPool
            ? i
            : isSellPool
            ? new BigNumber(1)
            : quoteReserve.div(baseReserve),
          baseReserve,
          quoteReserve,
          price: isEmptyDspPool
            ? formatReadableNumber({
                input: i,
                showDecimals: baseShowDecimals,
              })
            : baseReserve.eq(0)
            ? '-'
            : formatReadableNumber({
                input: quoteReserve.div(baseReserve),
                showDecimals: baseShowDecimals,
              }),
          reversePrice: isEmptyDspPool
            ? formatReadableNumber({
                input: new BigNumber(1).div(i),
                showDecimals: baseShowDecimals,
              })
            : isSellPool
            ? '-'
            : formatReadableNumber({
                input: baseReserve.div(quoteReserve),
                showDecimals: quoteShowDecimals,
              }),
          baseAmount: formatReadableNumber({
            input: baseReserve,
            showDecimals: baseShowDecimals,
          }),
          quoteAmount: formatReadableNumber({
            input: quoteReserve,
            showDecimals: quoteShowDecimals,
          }),
          creator,
          i: i.toNumber(),
          // k 是除以 10^18
          k: new BigNumber(pair.k).div(10 ** 18).toNumber(),
          createdAtTimestamp: Number(pair.createdAtTimestamp || 0),
          isCurrentAccountHasLiquidity: false,
          midPrice: new BigNumber(DEFAULT_MID_PRICE),
          isCpPool: false,
        };

        if (crowdPoolings && crowdPoolings.length > 0) {
          const [cp] = crowdPoolings;
          if (cp && cp.id) {
            data.isCpPool = true;
            data.cpAddress = cp.id;
            data.cpLiquidator = cp.liquidator;
            data.cpCreator = cp.creator;
            data.cpCreatedAtTimestamp = Number(cp.createTime || 0);
          }
        }

        data = {
          ...data,
          ...(myLiquidityInfo || {}),
          midPrice,
          pmmParams,
        };
        callback && callback(data);
        setPool(data);
        setUpdatePoolLoading(false);
      }
    }
    updatePool();
  }, [loading, refetchTimes]);

  return {
    data: pool,
    loading: updatePoolLoading && !isRefetch,
    refetch,
    noResultModalVisible,
    setNoResultModalVisible,
  };
}

export type LP = {
  /**
   * user address - lp Token address
   */
  id: string;
  /**
   * lp token balance
   */
  liquidityTokenBalance: string;
  /**
   * lp token balance in mining contract
   */
  liquidityTokenInMining?: string;
};

/**
 * FIN/USDT 池子计算有误差，需要特殊处理（https://app.asana.com/0/1199598807906421/1200091447980458/f）: 如果计算的值要大于 reserve 则要特殊处理
 *
 * 0x4f9DFeF24E6C5109a7869981f3fFDD865C8546A5 0x253956AEDC059947e700071bc6D74Bd8E34Fe2Ab 用户地址用于测试 2-5 号池子
 *
 * @param address 池子地址
 * @returns
 */
export function isSpecificPool(address: string) {
  const specificPoolIds = [
    '0x9D9793e1E18CDEe6cf63818315D55244f73EC006',
    '0x0D04146B2Fe5d267629a7eb341Fb4388DcdBD22f',
    '0x3D9d765b0fbAf594F90F07bc42889473e6613C7A',
    '0x3854BdcB1a7cBA4b1A5f9a7C8719e247Df4c42c6',
    '0xA46F5eC3219f956d14C6816Ef9cf6CaBf13bdD77',
    '0xB16f2Ff8E8499E31B257d2A02D25e8956Ae6aFe7',
  ];
  return (
    specificPoolIds.findIndex(
      (s) => s.toLowerCase() === address.toLowerCase(),
    ) !== -1
  );
}
