/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, Ref } from 'react';
import { Box, BoxProps, Skeleton, SkeletonProps } from '@mui/material';
import { merge } from 'lodash';
import { RefreshTextByFailed } from './RefreshTextByFailed';

interface LoadingSkeletonProps extends BoxProps {
  loading?: boolean;
  loadingSx?: BoxProps['sx'];
  loadingProps?: SkeletonProps;
  errorRefetch?: () => void;
  errorElement?: JSX.Element;
}

export const LoadingSkeleton = forwardRef(
  (
    {
      loading,
      loadingSx,
      loadingProps,
      errorRefetch,
      children,
      errorElement,
      ...attrs
    }: LoadingSkeletonProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <Box ref={ref} {...attrs}>
        {loading ? (
          <Skeleton
            variant="rectangular"
            height="fit-content"
            width="100%"
            {...loadingProps}
            sx={merge(
              {
                borderRadius: 4,
              },
              loadingSx,
            )}
          >
            <Box
              sx={{
                visibility: 'hidden',
              }}
            >
              1
            </Box>
          </Skeleton>
        ) : errorRefetch ? (
          errorElement ?? <RefreshTextByFailed refresh={errorRefetch} />
        ) : (
          children
        )}
      </Box>
    );
  },
);
