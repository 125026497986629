/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-nested-ternary */
import { EtherTokenWithChainId } from '@dodoex/utils';
import {
  getCurrentAccount,
  getCurrentChainId,
  Submission,
} from '@dodoex/wallet';
import { parseFixed } from '@ethersproject/bignumber';
import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import { newLiquiditySDK } from '../../sdk';
import { MyLiquidityInfo } from './poolTypes';
import { LiquidityItem } from './utils';
import { captureException } from '../../utils';

const { useSubmission, ExecutionResult, OpCode, MetadataFlag } = Submission;

export interface SubmitLiquidityParams {
  liquidity: LiquidityItem;
  baseAmount: string;
  quoteAmount: string;
  slippageProtection: number;
  myLqInfo: MyLiquidityInfo;
  SLIPPAGE_PROTECTION: number;
  isClickMax: boolean;
  txTitle: string;
  isAdd?: boolean;
  submittedBack?: () => void;
  successBack?: () => void;
  withdrawInfo: {
    withdrawFee: string;
    receiveBaseAmount: string;
    receiveQuoteAmount: string;
    receiveAmountBg: BigNumber;
  };
}

export const useSubmitLiquidity = () => {
  const account = useSelector(getCurrentAccount);
  const chainId = useSelector(getCurrentChainId);
  const submission = useSubmission();

  const handleSubmit = useCallback(
    async (params: SubmitLiquidityParams) => {
      const {
        isAdd,
        liquidity,
        baseAmount,
        quoteAmount,
        slippageProtection,
        myLqInfo,
        SLIPPAGE_PROTECTION,
        isClickMax,
        submittedBack,
        successBack,
        txTitle,
        withdrawInfo,
      } = params;
      const { pairId, isClassical, type, baseToken, quoteToken } = liquidity;
      const pairAAddress = baseToken.id.toLowerCase();
      const pairBAddress = quoteToken.id.toLowerCase();
      const EtherToken = EtherTokenWithChainId[chainId];
      const unwrappedTokenAddress = EtherToken.address.toLowerCase();
      // 选择 eth 直接指向 weth, 资金池中只能存 weth
      const pairAIsUnWrapped = unwrappedTokenAddress === pairAAddress;
      const pairBIsUnWrapped = unwrappedTokenAddress === pairBAddress;
      if (!account) {
        return;
      }
      const liquiditySdk = newLiquiditySDK(chainId);
      const isDsp = type === 'DSP';

      const address = pairId.toLocaleLowerCase();
      const baseInAmount = new BigNumber(baseAmount);
      const quoteInAmount = new BigNumber(quoteAmount);
      const baseInAmountIsNaN = baseInAmount.isNaN();
      const quoteInAmountIsNaN = quoteInAmount.isNaN();
      const baseTokenDecimals = +baseToken.decimals;
      const quoteTokenDecimals = +quoteToken.decimals;
      const baseTokenSymbol = baseToken.symbol;
      const quoteTokenSymbol = quoteToken.symbol;
      const slippageProtectionVal = slippageProtection;
      const baseMinAmount = !baseInAmountIsNaN
        ? parseFixed(
            baseInAmount
              .multipliedBy(1 - slippageProtectionVal)
              .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
              .toString(),
            baseTokenDecimals,
          ).toString()
        : '0';
      const quoteMinAmount = !quoteInAmountIsNaN
        ? parseFixed(
            quoteInAmount
              .multipliedBy(1 - slippageProtectionVal)
              .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
              .toString(),
            quoteTokenDecimals,
          ).toString()
        : '0';

      let result: {
        data: string;
        to: string;
        value?: string;
      };
      try {
        if (isAdd) {
          if (isClassical) {
            const classicalBaseMinAmount =
              !baseInAmountIsNaN &&
              myLqInfo.classicalBaseTarget &&
              myLqInfo.classicalBaseTarget.gt(0) &&
              myLqInfo.baseLpTokenTotalSupply
                ? parseFixed(
                    baseInAmount
                      .multipliedBy(myLqInfo.baseLpTokenTotalSupply)
                      .multipliedBy(1 - SLIPPAGE_PROTECTION)
                      .div(myLqInfo.classicalBaseTarget)
                      .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    baseTokenDecimals,
                  ).toString()
                : '0';
            const classicalQuoteMinAmount =
              !quoteInAmountIsNaN &&
              myLqInfo.classicalBaseTarget &&
              myLqInfo.quoteLpTokenTotalSupply &&
              myLqInfo.classicalQuoteTarget &&
              myLqInfo.classicalQuoteTarget.gt(0)
                ? parseFixed(
                    quoteInAmount
                      .multipliedBy(myLqInfo.quoteLpTokenTotalSupply)
                      .multipliedBy(1 - SLIPPAGE_PROTECTION)
                      .div(myLqInfo.classicalQuoteTarget)
                      .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    quoteTokenDecimals,
                  ).toString()
                : '0';
            const addClassicalParams = [
              address,
              !baseInAmountIsNaN
                ? parseFixed(
                    baseInAmount
                      .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    baseTokenDecimals,
                  ).toString()
                : '0',
              !quoteInAmountIsNaN
                ? parseFixed(
                    quoteInAmount
                      .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    quoteTokenDecimals,
                  ).toString()
                : '0',
              classicalBaseMinAmount,
              classicalQuoteMinAmount,
              pairAIsUnWrapped ? 1 : pairBIsUnWrapped ? 2 : 0,
              Math.ceil(Date.now() / 1000) + 10 * 60,
            ];
            // @ts-ignore
            result = await liquiditySdk.addClassicalLiquidity(
              // @ts-ignore
              ...addClassicalParams,
            );
          } else {
            const addParams = [
              address,
              !baseInAmountIsNaN
                ? parseFixed(
                    baseInAmount
                      .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    baseTokenDecimals,
                  ).toString()
                : '0',
              !quoteInAmountIsNaN
                ? parseFixed(
                    quoteInAmount
                      .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    quoteTokenDecimals,
                  ).toString()
                : '0',
              baseMinAmount,
              quoteMinAmount,
              pairAIsUnWrapped ? 1 : pairBIsUnWrapped ? 2 : 0,
              Math.ceil(Date.now() / 1000) + 10 * 60,
            ];
            if (isDsp) {
              // @ts-ignore
              result = await liquiditySdk.addDSPLiquidity(...addParams);
            } else {
              // @ts-ignore
              result = await liquiditySdk.addDVMLiquidity(...addParams);
            }
          }
        } else if (isClassical) {
          const tokenSide = !baseInAmountIsNaN ? 'base' : 'quote';
          if (tokenSide === 'base') {
            // 如果 baseInAmount === data.myLiquidityBaseBalance 表示要移除最大值，需要特殊处理
            const b = baseInAmount
              .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
              .toString();

            const receiveBaseMinAmount =
              withdrawInfo && withdrawInfo.receiveAmountBg
                ? parseFixed(
                    withdrawInfo.receiveAmountBg
                      .multipliedBy(1 - slippageProtectionVal)
                      .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    baseTokenDecimals,
                  ).toString()
                : baseMinAmount;
            if (
              isClickMax ||
              baseInAmount.gte(myLqInfo.myLiquidityBaseBalance as string)
            ) {
              // 移除全部
              const removeBaseParams = [address, receiveBaseMinAmount];
              result = await liquiditySdk.removeMaxClassicalBase(
                // @ts-ignore
                ...removeBaseParams,
              );
            } else {
              const removeBaseParams = [
                address,
                parseFixed(b, baseTokenDecimals).toString(),
                receiveBaseMinAmount,
              ];
              result = await liquiditySdk.removeClassicalBase(
                // @ts-ignore
                ...removeBaseParams,
              );
            }
          } else {
            const q = quoteInAmount
              .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
              .toString();

            const receiveQuoteMinAmount =
              withdrawInfo && withdrawInfo.receiveAmountBg
                ? parseFixed(
                    withdrawInfo.receiveAmountBg
                      .multipliedBy(1 - slippageProtectionVal)
                      .dp(quoteTokenDecimals, BigNumber.ROUND_FLOOR)
                      .toString(),
                    quoteTokenDecimals,
                  ).toString()
                : quoteMinAmount;
            if (
              isClickMax ||
              quoteInAmount.gte(myLqInfo.myLiquidityQuoteBalance as string)
            ) {
              const removeQuoteParams = [address, receiveQuoteMinAmount];
              result = await liquiditySdk.removeMaxClassicalQuote(
                // @ts-ignore
                ...removeQuoteParams,
              );
            } else {
              const removeQuoteParams = [
                address,
                parseFixed(q, quoteTokenDecimals).toString(),
                receiveQuoteMinAmount,
              ];
              result = await liquiditySdk.removeClassicalQuote(
                // @ts-ignore
                ...removeQuoteParams,
              );
            }
          }
        } else {
          /**
if (totalSupply == 0) {
  // case 1. initial supply
  require(baseBalance >= 10**3, “INSUFFICIENT_LIQUIDITY_MINED”);
  shares = baseBalance; // 以免出现balance很大但shares很小的情况
} else if (baseReserve > 0 && quoteReserve == 0) {
  // case 2. supply when quote reserve is 0
  shares = baseInput.mul(totalSupply).div(baseReserve);
} else if (baseReserve > 0 && quoteReserve > 0) {
  // case 3. normal case
  uint256 baseInputRatio = DecimalMath.divFloor(baseInput, baseReserve);
  uint256 quoteInputRatio = DecimalMath.divFloor(quoteInput, quoteReserve);
  uint256 mintRatio = quoteInputRatio < baseInputRatio ? quoteInputRatio : baseInputRatio;
  shares = DecimalMath.mulFloor(totalSupply, mintRatio);
}
         */
          let sharesAmount: BigNumber = new BigNumber(0);
          if (
            myLqInfo.baseLpTokenTotalSupply &&
            myLqInfo.baseLpTokenTotalSupply.lte(0) &&
            myLqInfo.myLiquidityBaseBalanceBN
          ) {
            sharesAmount = myLqInfo.myLiquidityBaseBalanceBN;
          } else if (
            myLqInfo.classicalBaseTarget &&
            myLqInfo.classicalQuoteTarget &&
            myLqInfo.myLiquidityBaseBalance &&
            myLqInfo.myLiquidityBaseBalanceBN &&
            myLqInfo.baseLpTokenTotalSupply &&
            myLqInfo.classicalBaseTarget.gt(0) &&
            myLqInfo.classicalQuoteTarget.eq(0)
          ) {
            let b = baseInAmount;
            if (baseInAmount.gte(myLqInfo.myLiquidityBaseBalance)) {
              b = myLqInfo.myLiquidityBaseBalanceBN;
            }
            sharesAmount = b
              .multipliedBy(myLqInfo.baseLpTokenTotalSupply)
              .div(myLqInfo.classicalBaseTarget);
          } else if (
            myLqInfo.classicalBaseTarget &&
            myLqInfo.classicalQuoteTarget &&
            myLqInfo.classicalBaseTarget.gt(0) &&
            myLqInfo.classicalQuoteTarget.gt(0)
          ) {
            let b = baseInAmount;
            if (
              myLqInfo.myLiquidityBaseBalance &&
              myLqInfo.myLiquidityBaseBalanceBN &&
              baseInAmount.gte(myLqInfo.myLiquidityBaseBalance)
            ) {
              b = myLqInfo.myLiquidityBaseBalanceBN;
            }
            const baseInputRatio = b.div(myLqInfo.classicalBaseTarget);
            let q = quoteInAmount;
            if (
              myLqInfo.myLiquidityQuoteBalance &&
              myLqInfo.myLiquidityQuoteBalanceBN &&
              quoteInAmount.gte(myLqInfo.myLiquidityQuoteBalance)
            ) {
              q = myLqInfo.myLiquidityQuoteBalanceBN;
            }
            const quoteInputRatio = q.div(myLqInfo.classicalQuoteTarget);
            let mintRatio = baseInputRatio;
            if (quoteInputRatio.lt(baseInputRatio)) {
              mintRatio = quoteInputRatio;
            }
            sharesAmount = mintRatio.multipliedBy(
              myLqInfo.baseLpTokenTotalSupply as BigNumber,
            );
          }
          const isUnWrap = pairAIsUnWrapped || pairBIsUnWrapped;
          const removeParams = [
            address,
            account,
            !sharesAmount.isNaN()
              ? parseFixed(
                  sharesAmount
                    .dp(baseTokenDecimals, BigNumber.ROUND_FLOOR)
                    .toString(),
                  baseTokenDecimals,
                ).toString()
              : '0',
            baseMinAmount,
            quoteMinAmount,
            isUnWrap,
            Math.ceil(Date.now() / 1000) + 10 * 60,
          ];
          if (isDsp) {
            // @ts-ignore
            result = await liquiditySdk.removeDSPLiquidity(...removeParams);
          } else {
            // @ts-ignore
            result = await liquiditySdk.removeDVMLiquidity(...removeParams);
          }
        }
      } catch (error) {
        console.error(
          'v2 addDVMLiquidity or removeDVMLiquidity or removeDSPLiquidity error',
          error,
        );
        captureException(
          'v2 addDVMLiquidity or removeDVMLiquidity or removeDSPLiquidity error',
          error as Error,
          params,
        );
        return;
      }

      if (result && result.data) {
        try {
          if (isAdd && pairAIsUnWrapped) {
            result.value = Web3.utils.toHex(
              Web3.utils.toWei(
                baseInAmountIsNaN ? '0' : baseInAmount.toString(),
                'ether',
              ),
            );
          }
          if (isAdd && pairBIsUnWrapped) {
            result.value = Web3.utils.toHex(
              Web3.utils.toWei(
                quoteInAmountIsNaN ? '0' : quoteInAmount.toString(),
                'ether',
              ),
            );
          }
          let subTitle: undefined | string;
          if (!baseInAmountIsNaN) {
            subTitle = `${baseInAmount.toString()}${baseTokenSymbol}`;
          }
          if (!quoteInAmountIsNaN) {
            if (baseInAmountIsNaN) {
              subTitle = `${quoteInAmount.toString()}${quoteTokenSymbol}`;
            } else {
              subTitle = `${subTitle} & ${quoteInAmount.toString()}${quoteTokenSymbol}`;
            }
          }
          let needSync = isAdd;
          if (needSync) {
            const smallBalance = 0.000005;
            if (
              myLqInfo.myLiquidityBaseBalanceBN?.gt(smallBalance) ||
              myLqInfo.myLiquidityQuoteBalanceBN?.gt(smallBalance)
            ) {
              needSync = false;
            }
          }
          const txResult = await submission.execute(
            txTitle,
            {
              opcode: OpCode.TX,
              ...result,
              value: result.value ?? 0,
            },
            subTitle,
            false,
            {
              [isAdd
                ? MetadataFlag.addLiquidity
                : MetadataFlag.removeLiquidity]: true,
            },
            submittedBack,
            {
              operationAddress: pairId,
            },
            undefined,
            undefined,
            {
              requestOptions: {
                needSync,
                extraData: {
                  pairId,
                },
              },
            },
          );
          if (txResult === ExecutionResult.Success && successBack) {
            successBack();
          }
        } catch (error) {
          console.error('v2 sendTransaction error', error);
        }
      }
    },
    [chainId, account, submission],
  );

  return {
    handleSubmit,
  };
};
