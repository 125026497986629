import { ContractConfigDataI } from '../type';

export const OPTIMISM_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WETH',
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    name: 'Wrapped Ether',
    source: 'dodo',
  },
  MULTI_CALL: '0xb98Ac2fEFc8b73aeAE33D02BB00c26E12afCa9Df',
  DODO_TOKEN: '',

  ERC20_HELPER: '0x42E456ea0dd7538ea103fBb1d0388D14C97bB5b2',
  ERC20V3_FACTORY: '0x5a274F1DeADf90FC844C9Ff94f79F9c1fF0E0aCC',

  DODO_APPROVE: '0xa492d6eABcdc3E204676f15B950bBdD448080364',

  DODO_PROXY: '0xfD9D2827AD469B72B69329dAA325ba7AfbDb3C98',
  DODO_DSP_PROXY: '0x61721e89a498dADa7aD579482BDC2aE60a9C5D54',
  DODO_DPP_PROXY: '0x51A70a33655FE1c5fae3286890DcE3bE332E85dD',
  DODO_CP_PROXY_V2: '0x072b3e5391B8bc868934562E510e6B2454163093',
  DODO_MINEV3_PROXY: '0xaEdbD08D92ECccaA9A93b1A8D66D1d356e470c78',
  // DODO_STARTER_PROXY: "",
  DVM_FACTORY: '0x2B800DC6270726F7E2266cE8cD5A3F8436fe0B40',
  DSP_FACTORY: '0x1f83858cD6d0ae7a08aB1FD977C06DABEcE6d711',
  CP_FACTORY_V2: '0x424976B3997514aFa7c5122D053b8973f225D9db',

  ROUTE_V2_DATA_FETCH: '0xC48A8e689a644de96F80786ACb69E6F76D057F25',
  ROUTE_V1_DATA_FETCH: '0x6281E0628eb2B37fE9943279EA39725D5f0E0dBe',
  CALLEE_HELPER: '0x0BD7426f008737FeeD575ED8e2aA1bd4Fc49112D',
  FEE_RATE_IMPL: '0x12780df58a224055574E33Cafe973c0631C8F8F3',
};

export const OPTIMISM_SWAPINFO = [];
