import { ContractConfigDataI } from '../type';

export const AVAX_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WAVAX',
    address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
    decimals: 18,
    name: 'Wrapped AVAX',
    source: 'dodo',
  },
  MULTI_CALL: '0x97f0153E7F5749640aDF3Ff9CFC518b79D6Fe53b',
  DODO_TOKEN: '',

  ERC20_HELPER: '0xC3528D128CC227fd60793007b5e3FdF7c2945282',
  ERC20V3_FACTORY: '0x00971e21955F1bb8021a5eF2546beC2Ff896EE42',

  DODO_APPROVE: '0xCFea63e3DE31De53D68780Dd65675F169439e470',

  DODO_PROXY: '0x2cD18557E14aF72DAA8090BcAA95b231ffC9ea26',
  DODO_DSP_PROXY: '0xeCEaDe494FD5F913Fd937C5CAc4577236395Dc32',
  DODO_DPP_PROXY: '0xe44F14BFDe673B7339734a28152cCd6b821753C9',
  DODO_CP_PROXY_V2: '0x973CAB76C35BB1da47e044A63546c69A8Ac1143c',
  DODO_MINEV3_PROXY: '0x5D6e6A0BFB2176AFCc4FB809822D8e009216b245',
  DODO_STARTER_PROXY: '0xb8132c7112aAf11fed09c7218AD2fcB42Da84773',

  DVM_FACTORY: '0xfF133A6D335b50bDAa6612D19E1352B049A8aE6a',
  DSP_FACTORY: '0x2b0d94Eb7A63B8a2909dE1CB3951ecF7Ae76D2fE',
  CP_FACTORY_V2: '0xc1ba6bD60c6790B751ec2D90288B6cbF87D4F032',

  ROUTE_V2_DATA_FETCH: '0x686984f0aa37585504f51DEbA0Eb45B0f0CB7e4a',
  ROUTE_V1_DATA_FETCH: '0x790B4A80Fb1094589A3c0eFC8740aA9b0C1733fB',
  CALLEE_HELPER: '0x4EfF1D851366b8cc51d553a87e2d12dA8Da46F2a',
  FEE_RATE_IMPL: '0xB2570F5381ee94dd75FbF303e6889F19199922AA',
};

export const AVAX_SWAPINFO = [
  {
    name: 'TraderJoe',
    factoryAddress: '0x9Ad6C38BE94206cA50bb0d90783181662f0Cfa10',
    initHash:
      '0x0bbca9af0511ad1a1da383135cf3a8d2ac620e549ef9f6ae3a4c33c2fed0af91',
  },

  {
    name: 'Pangolin',
    factoryAddress: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
    initHash:
      '0x40231f6b438bce0797c9ada29b718a87ea0a5cea3fe9a771abdd76bd41a3e545',
  },
  {
    name: 'Sushi',
    factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    initHash:
      '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  },
];
