export enum ChainType {
  ETH = 'mainnet',
  BSC = 'bsc-mainnet',
  Heco = 'heco',
  Polygon = 'polygon',
  Arbitrum = 'arbitrum',
  ArbRinkeby = 'arb-rinkeby',
  OKChain = 'okchain',
  Optimism = 'optimism',
  Boba = 'boba-mainnet',
  Moonriver = 'moonriver',
  Aurora = 'aurora',
  Avalanche = 'avalanche',
  kcs = 'kcs',
}

export type ChainKey = keyof typeof ChainType;

export interface Chain {
  type: ChainType;
  name: string; // 在连接钱包后，右上角显示名称
  showName: string; // 选项名
  /** 主网对应的 chainId 置于索引 0 */
  chainIds: number[];
  logo: string;
  currencyLogo?: string; // 个别 chain 的 货币 logo 和 logo 不一致
  background: {
    backgroundImage: string;
    // x offset percentage = (x offset value) / (container width - image width)
    backgroundPosition?: string;
  };
  color: string;
  matchChainList?: {
    chainId: number;
    showName: string;
  }[];
  sort?: number; // 排序，lite 版打包会错乱顺序
}
