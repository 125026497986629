import { ARB_CONFIG_DATA } from './contract-config/arb-config';
import { ARBTEST_CONFIG_DATA } from './contract-config/arb-rinkeby-config';
import { AURORA_CONFIG_DATA } from './contract-config/aurora-config';
import { AVAX_CONFIG_DATA } from './contract-config/avax-config';
import { BOBA_CONFIG_DATA } from './contract-config/boba-config';
import { BSC_CONFIG_DATA } from './contract-config/bsc-config';
import { ETH_CONFIG_DATA } from './contract-config/eth-config';
import { GOR_CONFIG_DATA } from './contract-config/gor-config';
import { HECO_CONFIG_DATA } from './contract-config/heco-config';
import { KCS_CONFIG_DATA } from './contract-config/kcs-config';
import { MATIC_CONFIG_DATA } from './contract-config/matic-config';
import { MOONRIVER_CONFIG_DATA } from './contract-config/moonriver-config';
import { OK_CONFIG_DATA } from './contract-config/ok-config';
import { OPTIMISM_CONFIG_DATA } from './contract-config/optimism-config';
import { RINKEBY_CONFIG_DATA } from './contract-config/rinkeby-config';
import { ContractConfigDataI } from './type';

export function GetConfig(networkId: number): ContractConfigDataI {
  let CONFIG = GOR_CONFIG_DATA;
  if (networkId === 1) {
    CONFIG = ETH_CONFIG_DATA;
  } else if (networkId === 4) {
    CONFIG = RINKEBY_CONFIG_DATA;
  } else if (networkId === 5) {
    CONFIG = GOR_CONFIG_DATA;
  } else if (networkId === 10) {
    CONFIG = OPTIMISM_CONFIG_DATA;
  } else if (networkId === 56 || networkId === 86) {
    CONFIG = BSC_CONFIG_DATA;
    // 如果要在这里加 97 ，需要把 wallet 中的 batchV2 下的 MULTI_CALL 去掉（之前为了测试用加的）
  } else if (networkId === 66) {
    CONFIG = OK_CONFIG_DATA;
  } else if (networkId === 128) {
    CONFIG = HECO_CONFIG_DATA;
  } else if (networkId === 137) {
    CONFIG = MATIC_CONFIG_DATA;
  } else if (networkId === 288) {
    CONFIG = BOBA_CONFIG_DATA;
  } else if (networkId === 321) {
    CONFIG = KCS_CONFIG_DATA;
  } else if (networkId === 1285) {
    CONFIG = MOONRIVER_CONFIG_DATA;
  } else if (networkId === 42161) {
    CONFIG = ARB_CONFIG_DATA;
  } else if (networkId === 421611) {
    CONFIG = ARBTEST_CONFIG_DATA;
  } else if (networkId === 43114) {
    CONFIG = AVAX_CONFIG_DATA;
  } else if (networkId === 1313161554) {
    CONFIG = AURORA_CONFIG_DATA;
  }
  return CONFIG;
}
