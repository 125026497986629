/* eslint-disable no-nested-ternary */
import {
  Snackbar,
  Slide,
  Typography,
  Box,
  alpha,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { memo, useMemo } from 'react';
import Countdown from './Countdown';
import { Button } from '../Button';
import { HoverOpacity } from '../Hover';
import { useDevices } from '../hooks';
import { ReactComponent as CloseIcon } from '../icons/suggested/error.svg';
import { ReactComponent as MessageIcon } from '../icons/suggested/message.svg';
import { ReactComponent as WarnIcon } from '../icons/suggested/warn.svg';

export enum BubbleType {
  msg = 1,
  warn,
}

export interface BubbleItem {
  id: string;
  title: string;
  timeout?: number;
  description?: string;
  icon?: string;
  type: BubbleType;
}

export const Bubble = memo(
  ({ item, onClose }: { item?: BubbleItem; onClose: () => void }) => {
    const { isMobile } = useDevices();
    const { t } = useTranslation();
    const theme = useTheme();

    // 避免重复触发动画效果，所以放在 useMemo 下
    const SnackbarBubble = useMemo(() => {
      if (!item) return null;
      let iconWidth = item.icon ? 74 : 28;
      if (item.timeout) {
        iconWidth = item.icon ? 24 : 0;
      } else if (isMobile) {
        iconWidth = 32;
      }
      if (!item.icon && item.type === BubbleType.warn) {
        iconWidth = 24;
      }
      const iconMr = isMobile ? 16 : 20;

      let maxContentWidth = 256;
      if (isMobile) {
        maxContentWidth =
          document.body.clientWidth - (item.timeout ? 104 : 175);
      }
      const computedBorderRadius = () => {
        if (item?.timeout) {
          const titleLen = item?.title.length;
          let descriptionLen = 0;
          if (item?.description) {
            descriptionLen = item.description.replace(
              /(\[[^\]]*\])\([^)]*\)/g,
              '$1',
            ).length;
          }
          if (
            !(titleLen && descriptionLen) &&
            titleLen * 16 <= maxContentWidth &&
            descriptionLen * 16 <= maxContentWidth &&
            !/\\n/.test(item.title || '') &&
            !/\\n/.test(item.description || '')
          ) {
            return 31;
          }
        }
        return 16;
      };
      // match link in description
      let descriptions: Array<string | React.ReactNode> = [];
      if (item.description) {
        const descriptionSplitList =
          item.description.split(/\[[^\]]*\]\([^)]*\)/g);
        const descriptionMatchList =
          item.description.match(/\[[^\]]*\]\([^)]*\)/g);
        if (descriptionMatchList && descriptionSplitList.length > 1) {
          descriptionSplitList.forEach((splitText, index) => {
            descriptions.push(splitText.replace(/\\n/g, '\n'));
            if (index < descriptionMatchList.length) {
              const match = descriptionMatchList[index];
              const matchText = match.replace(/\[([^\]]*)\]\([^)]*\)/, '$1');
              const matchUrl = match.replace(/\[[^\]]*\]\(([^)]*)\)/, '$1');
              descriptions.push(
                <HoverOpacity
                  weak
                  component="a"
                  key={matchUrl}
                  // @ts-ignore
                  href={matchUrl}
                  target="_blank"
                  color="primary.main"
                  sx={{
                    textDecoration: 'underline',
                  }}
                >
                  {matchText.replace(/\\n/g, '\n')}
                </HoverOpacity>,
              );
            }
          });
        } else {
          descriptions = descriptionSplitList;
        }
      }

      return (
        <Snackbar
          open={!!item}
          autoHideDuration={item?.timeout}
          onClose={(evt, reason) => {
            if (!isMobile && reason === 'clickaway') return;
            onClose();
          }}
          message={item?.title}
          anchorOrigin={{
            vertical: isMobile && !item.timeout ? 'bottom' : 'top',
            horizontal: 'center',
          }}
          sx={{
            // submission loading: 5000
            zIndex: 5100,
            [theme.breakpoints.up('tablet')]: {
              top: 40,
              // 左侧导航的宽度
              ml: 236,
            },
            [theme.breakpoints.down('tablet')]: {
              left: 16,
              right: 16,
              ...(item.timeout
                ? {
                    top: 76,
                  }
                : {
                    bottom: 100,
                  }),
            },
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          TransitionComponent={(props: any) => (
            <Slide
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              direction={isMobile && !item.timeout ? 'up' : 'down'}
            />
          )}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              px: {
                mobile: item?.timeout ? 20 : 10,
                tablet: item?.timeout ? 20 : 28,
              },
              backgroundColor: alpha(
                item.timeout
                  ? theme.palette.background.paper
                  : theme.palette.background.default,
                0.92,
              ),
              color: theme.palette.text.primary,
              border: 'solid 1px',
              borderColor: 'custom.border.default',
              borderRadius: computedBorderRadius(),
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.08)',
              width: item?.timeout || !isMobile ? 'auto' : '100%',
            }}
          >
            {item?.timeout && !item?.icon && item.type !== BubbleType.warn ? (
              ''
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: iconWidth,
                  height: iconWidth,
                  my: {
                    mobile: 0,
                    tablet: item?.timeout ? 18 : 28,
                  },
                  mr: iconMr,
                }}
              >
                {!item?.icon ? (
                  item.type === BubbleType.warn ? (
                    <Box
                      component={WarnIcon}
                      sx={{
                        color: 'warning.main',
                      }}
                    />
                  ) : (
                    <Box component={MessageIcon} />
                  )
                ) : (
                  <Box
                    component="img"
                    src={item?.icon}
                    sx={{
                      height: iconWidth,
                      maxWidth: iconWidth,
                      borderRadius: item?.timeout ? 0 : 8,
                    }}
                  />
                )}
              </Box>
            )}
            <Box
              sx={{
                flex: 1,
                py: {
                  mobile: 20,
                },
                [theme.breakpoints.up('tablet')]: item?.timeout
                  ? {
                      maxWidth: maxContentWidth,
                      py: 20,
                    }
                  : {
                      width: maxContentWidth,
                      py: 28,
                    },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {item?.title?.replace(/\\n/g, '\n')}
              </Typography>
              {item?.description ? (
                <Typography
                  variant="body2"
                  sx={{
                    mt: 8,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {descriptions.map((description) => description)}
                </Typography>
              ) : (
                ''
              )}
            </Box>
            {item?.timeout ? (
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: 20,
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                }}
              >
                <Countdown
                  size={24}
                  strokeWidth="2.4px"
                  time={`${item?.timeout}ms`}
                  color={theme.palette.text.disabled}
                  sx={{
                    top: 0,
                    left: 0,
                  }}
                />
                <Box
                  component={CloseIcon}
                  sx={{
                    position: 'relative',
                    top: 1,
                    width: 18,
                    height: 18,
                    color: 'text.disabled',
                    cursor: 'pointer',
                  }}
                  onClick={onClose}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: {
                    mobile: 8,
                    tablet: 28,
                  },
                }}
              >
                <Button
                  variant={Button.Variant.outlined}
                  size={isMobile ? Button.Size.small : Button.Size.middle}
                  sx={{
                    [theme.breakpoints.down('tablet')]: {
                      p: 8,
                      fontSize: 14,
                    },
                    [theme.breakpoints.up('tablet')]: {
                      p: theme.spacing(14, 16, 12),
                    },
                  }}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  {t('common.refresh')}
                </Button>
                {isMobile ? (
                  ''
                ) : (
                  <HoverOpacity
                    component={CloseIcon}
                    sx={{
                      ml: 20,
                      color: 'text.disabled',
                      cursor: 'pointer',
                    }}
                    onClick={onClose}
                  />
                )}
              </Box>
            )}
          </Box>
        </Snackbar>
      );
    }, [item, onClose, t, theme, isMobile]);

    return SnackbarBubble;
  },
);
