/* eslint-disable react/jsx-props-no-spreading */
import {
  Tabs as TabsOrigin,
  Tab as TabOrigin,
  TabProps,
  alpha,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { merge } from 'lodash';

// export const Tabs = ({ children }: { children: React.ReactNode }) => {
//   return <TabsOrigin>{children}</TabsOrigin>;
// };

export const TabSwitchButtons = styled(TabsOrigin)(({ theme }) => {
  const { palette } = theme;
  const { custom } = palette;
  return {
    minHeight: 'auto',
    backgroundColor: custom.input.background,
    borderRadius: 12,
    padding: 4,
    '& > div': {
      borderRadius: 8,
    },
    '& .MuiTabs-indicator': {
      opacity: 0,
    },
    '& .Mui-selected:first-child': {
      marginLeft: -1,
    },
    '& .Mui-selected:last-child': {
      marginRight: -1,
    },
  };
});

export function TabSwitchButton({ sx, ...props }: TabProps) {
  const theme = useTheme();
  const { spacing, palette } = theme;
  const { text, custom } = palette;
  const { button } = custom;
  return (
    <TabOrigin
      {...props}
      sx={merge(
        {
          padding: spacing(4, 7),
          extTransform: 'none',
          backgroundColor: 'transparent',
          borderRadius: 8,
          color: text.secondary,
          typography: 'body2',
          fontWeight: 600,
          textTransform: 'capitalize',
          '&.Mui-selected': {
            backgroundColor: button.main,
            color: button.mainContrast,
          },
          minHeight: 'auto',
          minWidth: 'auto',
          maxWidth: 'auto',
          height: 40,
          '&:not(.Mui-selected):hover': {
            color: alpha(theme.palette.text.secondary, 1),
          },
        },
        sx,
      )}
    />
  );
}
