import { gql } from '@apollo/client';

export const fetchUserSwapOrderHistories = gql`
  query FetchUserSwapOrderHistories($where: User_swapswapFilter) {
    user_swap_orderHistories(where: $where) {
      count
      list {
        chainId
        createdAt
        fromAmount
        fromTokenDecimals
        fromTokenPrice
        fromTokenSymbol
        fromTokenAddress
        fromTokenLogoImg
        hash
        status
        toAmount
        toTokenDecimals
        toTokenPrice
        toTokenSymbol
        toTokenAddress
        toTokenLogoImg
        nonce
        extra
        user
      }
    }
  }
`;
