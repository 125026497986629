import { getNormalFontWeight } from '@dodoex/components';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { merge } from 'lodash';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLanguage } from 'src/configure-store/selectors/settings';
import { getDesignTokens } from './config';
import { useColorModeFromRedux } from './useColorMode';
import { useFontFamily } from './useFontFamily';

export const useNormalFontWeight = () => {
  const lang = useSelector(getLanguage);
  return getNormalFontWeight(lang);
};

export function CustomStyles({
  children,
  mergeTheme,
}: {
  children: JSX.Element | JSX.Element[];
  mergeTheme?: Theme;
}) {
  const lang = useSelector(getLanguage);
  const themeVariant = useColorModeFromRedux();
  const normalFontWeight = useNormalFontWeight();

  useFontFamily();

  const theme = React.useMemo(() => {
    const defaultTheme = createTheme(
      getDesignTokens(themeVariant, normalFontWeight, lang),
    );
    if (mergeTheme) {
      return merge(defaultTheme, mergeTheme);
    }
    console.log('defaultTheme', defaultTheme);

    return defaultTheme;
  }, [themeVariant, normalFontWeight, lang, mergeTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
