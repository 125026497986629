import { isSameAddress } from '@dodoex/utils';
import { Token } from '../../types';
import dodoTokenImg from './assets/dodo-token.svg';
import finImg from './assets/FIN.svg';
import kogeImg from './assets/koge.svg';
import vDodoLogoImg from './assets/vDODO-logo.svg';
import wooImg from './assets/WOO.svg';

export const getTokenLogoUrl = (
  address: string,
  symbol?: string,
  token?: Token,
): string => {
  if (!address) return '';
  let url = '';
  if (token) {
    url = token.get('logoUrl') || '';
  }
  if (url) {
    return url;
  }

  const symbolRes = symbol || token?.get('symbol');
  if (symbolRes === 'DODO') {
    return dodoTokenImg;
  }
  if (isSameAddress(address, '0xc4436fBAE6eBa5d95bf7d53Ae515F8A707Bd402A')) {
    return vDodoLogoImg;
  }
  if (isSameAddress(address, '0x054f76beed60ab6dbeb23502178c52d6c5debe40')) {
    return finImg;
  }
  if (isSameAddress(address, '0x4691937a7508860f876c9c0a2a617e7d9e945d4b')) {
    return wooImg;
  }
  if (isSameAddress(address, '0xe6df05ce8c8301223373cf5b969afcb1498c5528')) {
    return kogeImg;
  }

  return '';
};
