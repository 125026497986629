import DODOImg from '../images/dex/dodo.svg';
import BabyImg from '../images/dex/BABY.svg';
import ApeSwapImg from '../images/dex/ApeSwap.svg';
import BakeryImg from '../images/dex/Bakery.svg';
import MDEXImg from '../images/dex/MDEX.svg';
import PancakeImg from '../images/dex/pancake.svg';
import QuickSwapImg from '../images/dex/QuickSwap.svg';
import SolarbeamImg from '../images/dex/solarbeam.svg';
import SushiswapImg from '../images/dex/sushiswap.svg';
import TrisolarisplanetsImg from '../images/dex/Trisolarisplanets.svg';
import Biswap from '../images/dex/Biswap.svg';
import UniswapImg from '../images/dex/uniswap.svg';
import WannaSwapImg from '../images/dex/wannaSwap.svg';
import CherryImg from '../images/dex/cherry.svg';
// import KSwapImg from '../images/dex/KSwap.svg';
import PangolinImg from '../images/dex/Pangolin.svg';
import ShibaswapImg from '../images/dex/shibaswap.svg';
import TraderJoeImg from '../images/dex/TraderJoe.svg';

/**
 * 第三方挖矿平台标识
 */
export enum LpTokenPlatformID {
  dodo,
  pancakeV2,
}

export interface DexConfigI {
  name: string;
  icon: string;
  /** 是否支持创建挖矿选中 */
  createMiningEnable?: boolean;
  platformID?: LpTokenPlatformID;
}

export const dexListObj: Record<string, DexConfigI> = {
  dodo: {
    name: 'DODO Dex',
    icon: DODOImg,
    createMiningEnable: true,
    platformID: LpTokenPlatformID.dodo,
  },
  uniswapV2: {
    name: 'Uniswap V2',
    icon: UniswapImg,
  },
  sushiSwap: {
    name: 'SushiSwap',
    icon: SushiswapImg,
  },
  shibaSwap: {
    name: 'ShibaSwap',
    icon: ShibaswapImg,
  },
  pancakeV2: {
    name: 'Pancake V2',
    icon: PancakeImg,
    createMiningEnable: true,
    platformID: LpTokenPlatformID.pancakeV2,
  },
  pancakeV1: {
    name: 'Pancake V1',
    icon: PancakeImg,
  },
  MDEX: {
    name: 'MDEX',
    icon: MDEXImg,
  },
  bakery: {
    name: 'Bakery',
    icon: BakeryImg,
  },
  biSwap: {
    name: 'BiSwap',
    icon: Biswap,
  },
  apeSwap: {
    name: 'ApeSwap',
    icon: ApeSwapImg,
  },
  babySwap: {
    name: 'BabySwap',
    icon: BabyImg,
  },
  trisolaris: {
    name: 'Trisolaris',
    icon: TrisolarisplanetsImg,
  },
  wannaSwap: {
    name: 'wannaSwap',
    icon: WannaSwapImg,
  },
  traderJoe: {
    name: 'TraderJoe',
    icon: TraderJoeImg,
  },
  pangolin: {
    name: 'Pangolin',
    icon: PangolinImg,
  },
  sushi: {
    name: 'Sushi',
    icon: DODOImg,
  },
  ooLongSwap: {
    name: 'ooLongSwap',
    icon: DODOImg,
  },
  quickSwap: {
    name: 'QuickSwap',
    icon: QuickSwapImg,
  },
  solarBeam: {
    name: 'Solarbeam',
    icon: SolarbeamImg,
  },
  cherrySwap: {
    name: 'CherrySwap',
    icon: CherryImg,
  },
};

export type DexKey = keyof typeof dexListObj;
