import { ApolloProvider } from '@apollo/client';
import { useAuth, useClient } from '@dodoex/core';
import { getCurrentChainId } from '@dodoex/wallet';
import mixpanel from 'mixpanel-browser';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { initMixpanel } from '@dodoex/mixpanel';
import {
  getIsDevEnvironment,
  isTestLink,
  MixpanelProxyURL,
  MixpanelProxyURLDev,
} from '@dodoex/utils';
import { store } from './configure-store';
import { CustomStyles } from './theme/CustomStyles';

const Index = React.lazy(() => import('./pages/index/index'));

const isDev = getIsDevEnvironment();
initMixpanel({
  isDev,
  url: isDev ? MixpanelProxyURLDev : MixpanelProxyURL,
  config: {
    // debug: isLocalhost || isTestLink,
    debug: isTestLink,
    // debug: false,
  },
});

mixpanel.init('3012936818f0ffd6b6e01ba5e4133f32');

function Init() {
  const distinct_id = mixpanel.get_distinct_id();
  const chainId = useSelector(getCurrentChainId);

  const { accessToken } = useAuth(chainId, distinct_id) || undefined;
  const client = useClient({
    isDev: false,
    accessToken,
  });
  return (
    <ApolloProvider client={client}>
      <CustomStyles>
        <BrowserRouter>
          <Routes>
            <Route index element={<Index />} />
          </Routes>
        </BrowserRouter>
      </CustomStyles>
    </ApolloProvider>
  );
}

function App() {
  return (
    <Suspense fallback={<div />}>
      <Provider store={store}>
        <Init />
      </Provider>
    </Suspense>
  );
}

const rootEl = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!); // createRoot(container!) if you use TypeScript
root.render(<App />);
