import { Box, Slide, Snackbar, alpha, useTheme } from '@mui/material';

function TransitionComponent(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slide {...props} direction="down" />
  );
}

export function Toast({
  msg,
  timeout = 1500,
  onClose,
}: {
  msg: string;
  timeout?: number;
  onClose?: () => void;
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  return (
    <Snackbar
      open
      autoHideDuration={timeout}
      disableWindowBlurListener
      resumeHideDuration={500}
      onClose={(evt, reason) => {
        if (reason === 'clickaway') return;
        if (onClose) {
          onClose();
        }
      }}
      key={msg}
      message={msg}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        top: 16,
        left: '50%',
        right: 'auto',
        transform: 'translate(-50%, 0)',
        // bottom: 8,
        width: 'max-content',
        margin: 'auto',
      }}
      TransitionComponent={TransitionComponent}
    >
      <Box
        sx={{
          px: 16,
          py: 12,
          minWidth: 100,
          maxWidth: {
            mobile: '80vw',
            tablet: 256,
          },
          typography: 'body2',
          backgroundColor: '#454851',
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: 8,
          color: alpha('#fff', 0.5),
        }}
      >
        {msg}
      </Box>
    </Snackbar>
  );
}
