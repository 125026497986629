/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import { useCallback, useEffect, useMemo, useState } from 'react';
import Ajv from 'ajv';
import { schema, TokenList as UniswapTokenList } from '@uniswap/token-lists';
import {
  dbGet,
  dbSet,
  get,
  normalizeUri,
  validUri,
  simpleSort,
  ThegraphKeyMap,
  getChain,
  MatchLevel,
  IDLE_FREE_TIME,
  SDKToken,
} from '@dodoex/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS, Map, OrderedMap } from 'immutable';
import { Token, Tokens, TokenList, TokenListFilterToken } from '../types';
import {
  getTokenLists,
  getImportTokens,
  getTokenListStatus,
} from '../configure-store/selectors';
import {
  setImportTokens,
  setTokenLists,
  setTokenListsStatus,
} from '../configure-store/actions';
import {
  MANAGE_TOKEN_LIST_TOKENS_IMPORT,
  TOKEN_LIST_MANAGE,
  TOKEN_LIST_MANAGE_VERSION,
} from '../localstorage';
import {
  AppThunkAction,
  AppThunkDispatch,
  queryThirdPartyTokenList,
} from '../init';
import { getFuzzySearchTokensSort } from '../searchTokens';

export enum ThirdTokenListKeys {
  gemini = 'gemini',
  optimism = 'optimism',
  compound = 'compound',
  coingecko = 'coingecko',
  coinmarketcap = 'coinmarketcap',
}

interface Source {
  url: string;
  enabled: boolean;
  isOfficial?: boolean;
  /** 方便后续新增 token list，不会影响当前用户的 token list 管理 */
  version?: number;
}

const cmcUrl = 'https://api.coinmarketcap.com/data-api/v3/uniswap/all.json';
/** 如果要新加 token list，就增加该版本号，并在新增的 token list 上配置新增的版本号 */
const currentTokenListsVersion = 2;
const defaultSources: Source[] = [
  {
    url: 'https://tokens.coingecko.com/uniswap/all.json',
    enabled: true,
  },
  {
    url: 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
    enabled: true,
  },
  {
    url: 'https://static.optimism.io/optimism.tokenlist.json',
    enabled: false,
  },
  {
    url: 'https://www.gemini.com/uniswap/manifest.json',
    enabled: false,
  },
  {
    url: cmcUrl,
    enabled: true,
    version: 2,
  },
  // 'https://raw.githubusercontent.com/opynfinance/opyn-tokenlist/master/opyn-v1.tokenlist.json',
];
const matchOtherChainSources: {
  [key in string]: {
    [sonKey in ThegraphKeyMap]?: Omit<Source, 'enabled'>;
  };
} = {
  'https://tokens.coingecko.com/uniswap/all.json': {
    [ThegraphKeyMap.arbitrum]: {
      url: 'https://tokens.coingecko.com/arbitrum-one/all.json',
      isOfficial: true,
    },
    [ThegraphKeyMap.aurora]: {
      url: 'https://tokens.coingecko.com/aurora/all.json',
      isOfficial: true,
    },
    [ThegraphKeyMap.bsc]: {
      url: 'https://tokens.coingecko.com/binance-smart-chain/all.json',
      isOfficial: true,
    },
  },
  [cmcUrl]: {
    [ThegraphKeyMap.bsc]: {
      url: 'https://tokens.pancakeswap.finance/cmc.json',
    },
  },
};
const thirdPartyTokenListUrlKeysMap: {
  [key in string]: ThirdTokenListKeys;
} = {
  'https://tokens.coingecko.com/uniswap/all.json': ThirdTokenListKeys.coingecko,
  'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json':
    ThirdTokenListKeys.compound,
  'https://static.optimism.io/optimism.tokenlist.json':
    ThirdTokenListKeys.optimism,
  'https://www.gemini.com/uniswap/manifest.json': ThirdTokenListKeys.gemini,
  [cmcUrl]: ThirdTokenListKeys.coinmarketcap,
};

const ajv = new Ajv({ allErrors: true });
const validator = ajv.compile(schema);

const importDefaultEnabled = true;

function helpConvertTokenList({
  tokens,
}: {
  tokens: UniswapTokenList['tokens'];
}) {
  return new Promise<OrderedMap<string, Token>>((resolve, reject) => {
    let tokensMap: OrderedMap<string, Token> = OrderedMap();

    // 500 个 token 为一组，在浏览器空闲期间分片处理
    const unitLength = 500;
    const unit = Math.ceil(tokens.length / unitLength);
    let uIndex = 0;
    function cb(deadline: IdleDeadline) {
      while (uIndex < unit && deadline.timeRemaining() > IDLE_FREE_TIME) {
        const startIndex = uIndex * unitLength;
        const curTokens = tokens.slice(startIndex, startIndex + unitLength);
        tokensMap = tokensMap.withMutations((map) => {
          curTokens.forEach((token) => {
            map.set(
              token.address,
              Map({
                address: token.address,
                decimals: token.decimals,
                name: token.name,
                source: null,
                symbol: token.symbol,
                opRank: null,
                logoUrl: normalizeUri(token.logoURI),
                tokenlists: undefined,
                slippage: null,
                isPopular: null,
              }) as Token,
            );
          });
        });

        uIndex += 1;
      }

      // 任务干完, 执行回调

      if (uIndex >= unit) {
        // 执行回调
        resolve(tokensMap);
        return;
      }

      // 任务没完成, 继续等空闲执行

      requestIdleCallback(cb);
    }
    requestIdleCallback(cb);
  });
}

/**
 * tokenlist 数组过大，产生长任务，长达 100ms，需要优化
 * @param chainId
 * @param tokenlist
 * @param param2
 * @returns
 */
async function convertTokenList(
  chainId: number,
  tokenlist: UniswapTokenList,
  { url, enabled, isOfficial }: Source,
) {
  const { logoURI } = tokenlist;

  const tokens = tokenlist.tokens.filter(
    (item) => item.chainId === chainId && item.address,
  );

  // tokens.forEach((token) => {
  //   // set 会不断创建新的对象，不断生成的中间对象造成内存浪费和性能低下
  //   // https://immutable-js.com/docs/v3.8.2/Map/#withMutations()
  //   tokensMap = tokensMap.set(
  //     token.address,
  //     Map({
  //       address: token.address,
  //       decimals: token.decimals,
  //       name: token.name,
  //       source: null,
  //       symbol: token.symbol,
  //       opRank: null,
  //       logoUrl: normalizeUri(token.logoURI),
  //       tokenlists: undefined,
  //       slippage: null,
  //       isPopular: null,
  //     }) as Token,
  //   );
  // });

  // Use withMutations to avoid the many intermediate maps that this process like above may create
  // tokensMap = tokensMap.withMutations((map) => {
  //   tokens.forEach((token) => {
  //     map.set(
  //       token.address,
  //       Map({
  //         address: token.address,
  //         decimals: token.decimals,
  //         name: token.name,
  //         source: null,
  //         symbol: token.symbol,
  //         opRank: null,
  //         logoUrl: normalizeUri(token.logoURI),
  //         tokenlists: undefined,
  //         slippage: null,
  //         isPopular: null,
  //       }) as Token,
  //     );
  //   });
  // });

  // withMutations 之后依然为长任务，60ms，继续优化
  const tokensMap = await helpConvertTokenList({ tokens });

  return Map({
    ...tokenlist,
    tokens: tokensMap,
    logoUrl: normalizeUri(logoURI),
    enabled,
    id: url,
    isOfficial,
  }) as TokenList;
}

function getMatchSource(chainId: number, source: Source) {
  const matchUrlObject = matchOtherChainSources[source.url];
  if (matchUrlObject) {
    const { thegraphKey } = getChain(chainId);
    const matchUrlChain = matchUrlObject[thegraphKey];
    if (matchUrlChain) {
      return {
        ...matchUrlChain,
        enabled: source.enabled,
      };
    }
  }
  return source;
}

async function getTokenList(
  chainId: number,
  source: Source,
  isValid?: boolean,
) {
  const { data } = await get({
    url: normalizeUri(source.url),
  });

  if (isValid && !validator(data)) {
    return false;
  }

  return convertTokenList(chainId, data, source);
}

export function tokenListsSort(tokenLists: TokenList[]) {
  return tokenLists.sort((a, b) => {
    let aIndex = 0;
    let bIndex = 0;
    if (a.get('enabled')) {
      aIndex--;
    }
    if (b.get('enabled')) {
      bIndex--;
    }
    if (aIndex !== bIndex) {
      return aIndex - bIndex;
    }
    return b.get('tokens').size - a.get('tokens').size;
  });
}

/**
 * 该代码由于 tokenLists 数组过大，运行会产生长任务，不应该大量调用，不能被 useQueryTokenByAllToken 调用，后者被 TokenLogo 大量调用
 * @returns
 */
export const useTokenListTokens = () => {
  const tokenLists = useSelector(getTokenLists);
  const importTokens = useSelector(getImportTokens);
  // const [activeTokens, setActiveTokens] = useState<Tokens>(OrderedMap());
  // const [disabledTokens, setDisabledTokens] = useState<Tokens>(OrderedMap());

  const { activeTokens } = useMemo(() => {
    let activeTokensMap = OrderedMap<string, Token>();

    // const disabledTokensMap = OrderedMap<string, Token>().asMutable();
    activeTokensMap = activeTokensMap.withMutations((map) => {
      tokenLists.forEach((list) => {
        const isEnabled = list.get('enabled');
        const tokens = list.get('tokens');

        tokens.forEach((token) => {
          if (isEnabled || importTokens.has(token.get('address'))) {
            map.set(token.get('address'), token);
          } else {
            // disabledTokensMap 目前没有用到，暂时注释
            // disabledTokensMap.set(token.get('address'), token);
          }
        });
      });
    });

    // setActiveTokens(activeTokensMap);
    // setDisabledTokens(disabledTokensMap);

    return {
      activeTokens: activeTokensMap,
    };
  }, [tokenLists, importTokens]);

  return {
    activeTokens,
    // disabledTokens,
  };
};

/**
 * 返回满足 search 过滤条件及 filterTokenAddress 隐藏的 tokens
 * @param search 需要过滤的字段
 * @param filterTokenAddress 过滤不显示的字段
 * @returns { enabledTokens, disabledTokens } OrderedMap
 */
export const useTokenListTokensFilter = ({
  chainId,
  account,
  search,
  filterTokenAddress = [],
  enabledNum,
  disabledNum,
}: {
  chainId: number;
  account?: string;
  search?: string;
  enabledNum?: number;
  disabledNum?: number;
  filterTokenAddress?: string[];
}) => {
  const tokenLists = useSelector(getTokenLists);
  const importTokens = useSelector(getImportTokens);
  const [enabledTokens, setEnabledTokens] = useState<
    OrderedMap<string, TokenListFilterToken>
  >(OrderedMap());
  const [disabledTokens, setDisabledTokens] = useState<
    OrderedMap<string, TokenListFilterToken>
  >(OrderedMap());

  useEffect(() => {
    if (!search || !account) {
      setEnabledTokens(OrderedMap());
      setDisabledTokens(OrderedMap());
      return;
    }
    const time = setTimeout(async () => {
      const searchTokens: SDKToken[] = [];
      const matchAddressTokenAddressSet = new Set<string>();
      tokenLists.forEach((list) => {
        const isEnabled = list.get('enabled');
        list.get('tokens').forEach((token) => {
          const address = token.get('address');
          searchTokens.push(token.toJS() as SDKToken);
          const active = isEnabled || importTokens.has(address);
          if (active) {
            matchAddressTokenAddressSet.add(address);
          }
        });
      });
      const searchSortMap = await getFuzzySearchTokensSort({
        tokens: searchTokens,
        search,
        options: {
          matchAddressSet: matchAddressTokenAddressSet,
        },
      });

      let enabledMapRes: OrderedMap<string, TokenListFilterToken> =
        OrderedMap();
      let disabledMapRes: OrderedMap<string, TokenListFilterToken> =
        OrderedMap();
      let enabledMatchPrefixCount = 0;
      let disabledMatchPrefixCount = 0;
      tokenLists.some((list) => {
        const name = list.get('name');
        const logoUrl = list.get('logoUrl');
        const isEnabled = list.get('enabled');
        const tokens = list.get('tokens');
        return tokens.some((token) => {
          const address = token.get('address');
          const active = isEnabled || importTokens.has(address);
          const sort = searchSortMap.get(address);
          const filterTokenAddressRes = filterTokenAddress.map((item) =>
            item.toLocaleLowerCase(),
          );
          token = token.set('source', {
            name,
            logoUrl,
            id: list.get('id'),
          });
          if (
            sort &&
            !filterTokenAddressRes.includes(address.toLocaleLowerCase())
          ) {
            const res = {
              name,
              logoUrl,
              token,
              sort,
            } as TokenListFilterToken;
            if (active) {
              enabledMapRes = enabledMapRes.set(address, res);
              if (sort === MatchLevel.fully) return true;
              if (enabledNum && sort === MatchLevel.prefix) {
                enabledMatchPrefixCount += 1;
                if (enabledMatchPrefixCount >= enabledNum) {
                  return true;
                }
              }
            } else {
              disabledMapRes = disabledMapRes.set(address, res);
              if (sort === MatchLevel.fully) return true;
              if (disabledNum && sort === MatchLevel.prefix) {
                disabledMatchPrefixCount += 1;
                if (disabledMatchPrefixCount >= disabledNum) {
                  return true;
                }
              }
            }
          }
          return false;
        });
      });

      disabledMapRes = disabledMapRes.filter(
        ({ token }) => !enabledMapRes.has(token.get('address')),
      );
      enabledMapRes = enabledMapRes.sort(simpleSort);
      disabledMapRes = disabledMapRes.sort(simpleSort);
      setEnabledTokens(enabledMapRes);
      setDisabledTokens(disabledMapRes);
    }, 300);
    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(time);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    tokenLists,
    chainId,
    importTokens,
    account,
    enabledNum,
    disabledNum,
  ]);

  return {
    enabledTokens,
    disabledTokens,
  };
};

export const useImportTokenListToken = ({ chainId }: { chainId?: number }) => {
  const dispatch = useDispatch();
  const importTokens = useSelector(getImportTokens);
  const handleImport = useCallback(
    (token: Token) => {
      const storageKey = `${chainId}.${MANAGE_TOKEN_LIST_TOKENS_IMPORT}`;
      const newImportTokens = importTokens.set(token.get('address'), token);
      dbSet({
        path: storageKey,
        value: newImportTokens.valueSeq().toJS(),
      });
      dispatch(setImportTokens(newImportTokens));
    },
    [chainId, dispatch, importTokens],
  );

  return {
    handleImport,
  };
};

/**
 * 查询 coingecko 等第三方 TokenList
 * @param chainId
 * @param queryCoinmarketcapTokenList
 * @returns
 */
export const loadTokenListTokens = (chainId: number): AppThunkAction => {
  const storageKey = TOKEN_LIST_MANAGE;
  return async (dispatch) => {
    dispatch(setTokenListsStatus('loading'));
    const sources = dbGet({
      path: storageKey,
      defaultValue: defaultSources,
    }) as Source[];
    const userVersion = dbGet({
      path: TOKEN_LIST_MANAGE_VERSION,
      defaultValue: 1,
    });
    // 版本迭代
    if (userVersion < currentTokenListsVersion) {
      dbSet({
        path: TOKEN_LIST_MANAGE_VERSION,
        value: currentTokenListsVersion,
      });
      const includesCurrentVersion = sources.some(
        (source) => source.version === currentTokenListsVersion,
      );
      if (!includesCurrentVersion) {
        defaultSources.forEach((item) => {
          if (item.version === currentTokenListsVersion) {
            sources.push(item);
          }
        });
        dbSet({
          path: storageKey,
          value: sources,
        });
      }
    }
    const promiseList = [] as Promise<false | TokenList>[];
    const thirdPartyKeySourceMap = {} as {
      [key in ThirdTokenListKeys]?: Source;
    };
    sources.forEach((source) => {
      const matchKey = thirdPartyTokenListUrlKeysMap[source.url];
      const sourceRes = getMatchSource(chainId, source);
      if (matchKey) {
        thirdPartyKeySourceMap[matchKey] = sourceRes;
      } else {
        promiseList.push(getTokenList(chainId, sourceRes));
      }
    });
    const res = await Promise.allSettled(promiseList);
    const fulfilledRes = res.filter(
      (item) => item.status === 'fulfilled',
    ) as PromiseFulfilledResult<TokenList>[];
    const tokenLists = fulfilledRes.map((item) => item.value);
    const thirdPartyKeys = Object.keys(
      thirdPartyKeySourceMap,
    ) as ThirdTokenListKeys[];
    if (thirdPartyKeys.length) {
      if (!queryThirdPartyTokenList) {
        dispatch(setTokenListsStatus('failed'));
        dispatch(setTokenLists(tokenListsSort(tokenLists)));
        console.error('queryThirdPartyTokenList is undefined');
      } else {
        try {
          const queryTokenListMap = await queryThirdPartyTokenList({
            chainId,
            fromNames: thirdPartyKeys,
          });
          await Promise.all(
            Object.entries(queryTokenListMap).map(async ([key, value]) => {
              const source = thirdPartyKeySourceMap[key as ThirdTokenListKeys];
              if (!source) return null;

              const tokenList = await convertTokenList(chainId, value, source);
              tokenLists.push(tokenList);
            }),
          );
          dispatch(setTokenLists(tokenListsSort(tokenLists)));
          dispatch(setTokenListsStatus('complete'));
        } catch (error) {
          dispatch(setTokenListsStatus('failed'));
          dispatch(setTokenLists(tokenListsSort(tokenLists)));
        }
      }
    } else {
      dispatch(setTokenLists(tokenListsSort(tokenLists)));
      dispatch(setTokenListsStatus('complete'));
    }

    // importTokens
    let importTokens = dbGet({
      path: `${chainId}.${MANAGE_TOKEN_LIST_TOKENS_IMPORT}`,
      defaultValue: [],
    });
    // 之前存错了，这里转换一下
    if (typeof importTokens === 'object') {
      const importTokensTemp = [] as SDKToken[];
      Object.values(importTokens).forEach((token) => {
        importTokensTemp.push(token as SDKToken);
      });
      importTokens = importTokensTemp;
    }

    let tokensMap: OrderedMap<string, Token> = OrderedMap() as Tokens;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    importTokens.forEach((token: any) => {
      tokensMap = tokensMap.set(token.address, fromJS(token) as Token);
    });
    dispatch(setImportTokens(tokensMap));
  };
};

export const useManageTokenList = ({
  search,
  chainId,
}: {
  search?: string;
  chainId: number;
}) => {
  const storageKey = TOKEN_LIST_MANAGE;
  const dispatch = useDispatch<AppThunkDispatch>();
  const tokenLists = useSelector(getTokenLists);
  const tokenListsStatus = useSelector(getTokenListStatus);
  const loading = useMemo(
    () => tokenListsStatus === 'loading',
    [tokenListsStatus],
  );
  const tokenListFailedRefresh = useMemo(() => {
    if (tokenListsStatus !== 'failed') return undefined;
    return () => dispatch(loadTokenListTokens(chainId));
  }, [tokenListsStatus, chainId, dispatch]);
  const [importSearchLoading, setImportSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchTokenList, setSearchTokenList] = useState<
    TokenList | undefined
  >();

  useEffect(() => {
    const getSearchTokenList = async () => {
      if (!search) {
        setSearchError(false);
        setSearchTokenList(undefined);
        return;
      }
      if (!validUri(search)) {
        setSearchTokenList(undefined);
        setSearchError(true);
        return;
      }
      let searchUrl = search;
      if (search.endsWith('.eth')) {
        searchUrl = `https://${search}.link`;
      }
      setImportSearchLoading(true);
      try {
        const data = await getTokenList(
          chainId,
          {
            url: searchUrl,
            enabled: importDefaultEnabled,
          },
          true,
        );
        if (!data) {
          console.error('invalid source.');
          setSearchError(true);
          setSearchTokenList(undefined);
          return;
        }
        setSearchError(false);
        setSearchTokenList(data);
      } catch (error) {
        console.error(error);
        setSearchError(true);
        setSearchTokenList(undefined);
      }
      setImportSearchLoading(false);
    };
    const time = setTimeout(() => {
      getSearchTokenList();
    }, 800);
    return () => {
      clearTimeout(time);
    };
  }, [search, chainId]);

  const importTokenList = () => {
    const sources = dbGet({
      path: storageKey,
      defaultValue: defaultSources,
    }) as Source[];
    dbSet({
      path: storageKey,
      value: [
        ...sources,
        {
          url: search,
          enabled: importDefaultEnabled,
        },
      ],
    });
    if (searchTokenList) {
      dispatch(setTokenLists(tokenListsSort([...tokenLists, searchTokenList])));
    }
  };

  const searchImported = useMemo(() => {
    if (!searchTokenList) return false;
    const searchId = searchTokenList.get('id');
    return tokenLists.some((item) => {
      const currentId = item.get('id');
      if (currentId === searchId) return true;
      const matchUrlObject = matchOtherChainSources[currentId];
      if (matchUrlObject) {
        return Object.values(matchUrlObject).some(
          (matchSource) => matchSource.url === searchId,
        );
      }
      return false;
    });
  }, [searchTokenList, tokenLists]);

  const toggleTokenList = (id: string) => {
    const sources = dbGet({
      path: storageKey,
      defaultValue: defaultSources,
    }) as Source[];

    dbSet({
      path: storageKey,
      value: sources.map((item) => {
        let { enabled } = item;
        if (item.url === id) {
          enabled = !enabled;
        }
        return {
          ...item,
          enabled,
        };
      }),
    });

    dispatch(
      setTokenLists(
        tokenLists.map((item) => {
          if (item.get('id') === id) {
            item = item.set('enabled', !item.get('enabled'));
          }
          return item;
        }),
      ),
    );
  };

  const deleteTokenList = (deleteId: string) => {
    const sources = dbGet({
      path: storageKey,
      defaultValue: defaultSources,
    }) as Source[];
    sources.some((source, index) => {
      const currentId = source.url;
      if (currentId === deleteId) {
        sources.splice(index, 1);
        return true;
      }
      const matchUrlObject = matchOtherChainSources[currentId];
      if (matchUrlObject) {
        return Object.values(matchUrlObject).some((matchSource) => {
          if (matchSource.url === deleteId) {
            sources.splice(index, 1);
            return true;
          }
          return false;
        });
      }
      return false;
    });

    dbSet({
      path: storageKey,
      value: sources,
    });

    dispatch(
      setTokenLists(tokenLists.filter((item) => item.get('id') !== deleteId)),
    );
  };

  return {
    loading,
    tokenListFailedRefresh,
    tokenLists,
    searchError,
    searchTokenList,
    importTokenList,
    importSearchLoading,
    searchImported,
    toggleTokenList,
    deleteTokenList,
  };
};
