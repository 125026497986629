import { gql } from '@apollo/client';

export const fetchDODOAppVersionNew = gql`
  query FetchDODOAppVersionNew($where: Dodo_app_versionnewFilter) {
    dodo_app_version_new(where: $where) {
      id
      title
      description
      version
      urls {
        name
        url
      }
      refreshType
      icon
    }
  }
`;
