import BigNumber from 'bignumber.js';

const Billion = 10 ** 9;
const Million = 10 ** 6;
const Kilo = 10 ** 3;

export const moneyFormat = (num: number | string, fixed = 2) => {
  const value = new BigNumber(num);

  if (value.toNumber() > Billion) {
    return `${(value.toNumber() / Billion).toFixed(fixed)}B`;
  }

  if (value.toNumber() > Million) {
    return `${(value.toNumber() / Million).toFixed(fixed)}M`;
  }

  if (value.toNumber() > Kilo) {
    return `${(value.toNumber() / Kilo).toFixed(fixed)}K`;
  }

  return value.toFormat(fixed);
};
