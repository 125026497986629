import { getChainConfig } from '@dodoex/wallet/build/chainConfigs';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { GetConfig } from '../route/data/dataAdapter';
import STARTER_PROXY_ABI from '../server/ABIs/dodoStarterProxy';

BigNumber.config({
  EXPONENTIAL_AT: [-80, 80],
  DECIMAL_PLACES: 80,
});

export class DODOStarter {
  public NETWORK_ID: number;

  public WEB3: any;

  public DODO_STARTER_PROXY?: string;

  public constructor(_NETWORK_ID: number, _WEB3?: any) {
    this.NETWORK_ID = _NETWORK_ID;
    const CONFIG = GetConfig(_NETWORK_ID);
    this.WEB3 = _WEB3 || getChainConfig(_NETWORK_ID).defaultEndpointUrl;
    this.DODO_STARTER_PROXY = CONFIG.DODO_STARTER_PROXY;
  }

  /**
   * @notice Bid
   * @param pool           募资合约
   * @param fundAmount     投入Fund数量（考虑Decimals）
   * @param flag           0 - ERC20， 1 - quoteInETH
   * @param deadline       截止时间戳 单位 秒
   */
  public async Bid(
    pool: string,
    fundAmount: string,
    flag: number,
    deadline: number,
    web3Provider: any = null,
  ) {
    const web3 = new Web3(web3Provider || this.WEB3);
    const proxyInstance = new web3.eth.Contract(
      STARTER_PROXY_ABI,
      this.DODO_STARTER_PROXY,
    );
    const data = await proxyInstance.methods
      .bid(pool, fundAmount, flag, deadline)
      .encodeABI();
    return { data, to: this.DODO_STARTER_PROXY };
  }
}
