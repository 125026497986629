/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Dexie, { DexieOptions, Table } from 'dexie';

export function captureIndexedDBError(db: Dexie) {
  const oldOpen = db.open;
  db.open = () => {
    const result = oldOpen.call(db);
    result.catch(async (e) => {
      if (
        // firefox 无痕模式会报这个错
        e.message.indexOf(
          'A mutation operation was attempted on a database that did not allow mutations',
        ) > -1
      ) {
        const fakeIndexedDB = (await import('fake-indexeddb')).default;
        const fakeIndexedDBFDBKeyRange = (
          await import('fake-indexeddb/lib/FDBKeyRange')
        ).default;
        (db as any)._deps.indexedDB = fakeIndexedDB;
        (db as any)._deps.IDBKeyRange = fakeIndexedDBFDBKeyRange;
        (db as any)._options.indexedDB = fakeIndexedDB;
        (db as any)._options.IDBKeyRange = fakeIndexedDBFDBKeyRange;
        oldOpen.call(db);
      }
    });
    return result;
  };
}

Dexie.addons.push(captureIndexedDBError);

export enum CacheTokensType {
  balance = 1,
  sortFirst,
}

export interface CacheTokens {
  address: string;
  account: string;
  chainId: number;
  type: CacheTokensType;
}

export class TokenDexie extends Dexie {
  tokens!: Table<CacheTokens, string>;

  constructor(options?: DexieOptions) {
    super('dodoex-token', options);
    // https://dexie.org/docs/Tutorial/Design#database-versioning
    // https://dexie.org/docs/Version/Version.stores()
    this.version(1).stores({
      // https://dexie.org/docs/Compound-Index
      tokens:
        '[account+chainId+address+type], [account+chainId+type], [account+chainId], *type',
    });
  }
}

export const tokenDb = new TokenDexie();
