import { makeStyles } from '@mui/styles';

export const useRotationStyles = makeStyles({
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  root: {
    animation: '$rotation 2s infinite linear',
  },
});
