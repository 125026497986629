import { gql } from '@apollo/client';

export const fetchKChartDataV2 = gql`
  query FetchKChartDataV2($where: ChartOhlcvInput) {
    chart_getOhlcvData(where: $where)
  }
`;

export const fetchChartCaution = gql`
  query FetchChartCaution($where: Manage_serviceCautionTokensInput) {
    manage_service_getCautionsV2(where: $where) {
      token
      alert
      reason
    }
  }
`;
