import { gql } from '@apollo/client';

export const fetchDIPWhitelist = gql`
  query FetchDIPWhitelist($where: Dip_whitelistlistFilter) {
    dip_whitelist_list(where: $where) {
      id
      snapshotId
      deadlineVotes
      sn
    }
  }
`;
