/* eslint-disable react/jsx-props-no-spreading */
import { styled, TooltipProps, tooltipClasses, Tooltip } from '@mui/material';

export const TooltipArea = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    border: 'none',
  },
}));
