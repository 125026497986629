import { getAppToken } from '@dodoex/auth-web-sdk';
import { getServiceDomainDev } from '@dodoex/utils';
import { useState, useEffect, useCallback } from 'react';

export const useAuth = (
  chainId: number,
  account: string,
  {
    isDev,
  }: {
    isDev?: boolean;
  } = {},
) => {
  const [accessToken, setAccessToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const refetch = useCallback(() => {
    getAppToken('appId1', chainId, account, {
      url: isDev
        ? `https://api.${getServiceDomainDev()}/frontend-auth`
        : undefined,
    })
      .then((res) => setAccessToken(res || undefined))
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [chainId, account, isDev]);

  useEffect(() => {
    refetch();
    // 每 50 分钟刷新 token
    setInterval(() => {
      refetch();
    }, 3000000);
  }, [refetch]);

  return {
    accessToken,
    loading,
    refetch,
  };
};
