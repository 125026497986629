/* eslint-disable no-plusplus */
import { APP_URL } from '@dodoex/utils';
import { ReactComponent as HuobiWalletLogo } from '../../components/images/logos/huobiWallet.svg';
import { ReactComponent as MathLogo } from '../../components/images/logos/math.svg';
import { ReactComponent as Coin98Logo } from '../../components/images/logos/Coin98.svg';
import { ReactComponent as TokenPocketLogo } from '../../components/images/logos/tokenPocket.svg';
import { ReactComponent as CoinbaseLogo } from '../../components/images/logos/coinbase.svg';
import { ReactComponent as GameStopLogo } from '../../components/images/logos/gameStop.svg';
import { ReactComponent as TrustLogo } from '../../components/images/logos/trust.svg';
import { ReactComponent as ImTokenLogo } from '../../components/images/logos/imtoken.svg';
import { ReactComponent as BraveLogo } from '../../components/images/logos/brave.svg';
import { Wallet, WalletType } from './types';

let sort = 1;

const formattedAPPUrl = APP_URL.replace('://', '%3A%2F%2F');

export const Coinbase: Wallet = {
  type: WalletType.injected,
  typeName: 'Coinbase',
  showName: 'Coinbase Wallet',
  logo: <CoinbaseLogo />,
  checked: () =>
    window.ethereum &&
    (window.ethereum.isWalletLink || window.ethereum.isToshi),
  sort: sort++,
  mobileAndroidDeepLink: `https://go.cb-w.com/dapp?cburl=${formattedAPPUrl}`,
  mobileIOSDeepLink: `cbwallet://dapp?url=${formattedAPPUrl}`,
};

export const TokenPocket: Wallet = {
  type: WalletType.injected,
  typeName: 'TokenPocket',
  showName: 'TokenPocket',
  logo: <TokenPocketLogo />,
  checked: () => window.ethereum && window.ethereum.isTokenPocket,
  link: 'https://www.tokenpocket.pro/',
  sort: sort++,
  supportMobile: true,
  mobileDeepLink: `tpdapp://open?params=${encodeURI(`{
    "url": "${APP_URL}"
  }`)}`,
};

export const Trust: Wallet = {
  type: WalletType.injected,
  typeName: 'TrustWallet',
  showName: 'TrustWallet',
  logo: <TrustLogo />,
  link: 'https://trustwallet.com/',
  checked: () => window.ethereum?.isTrust,
  sort: sort++,
  supportMobile: true,
  mobileDeepLink: `https://link.trustwallet.com/open_url?url=${APP_URL}`,
};

export const ImToken: Wallet = {
  type: WalletType.injected,
  typeName: 'ImToken',
  showName: 'ImToken',
  logo: <ImTokenLogo />,
  link: 'https://token.im/',
  checked: () => window.ethereum?.isImToken,
  sort: sort++,
  supportMobile: true,
  mobileDeepLink: `imtokenv2://navigate/DappView?url=${formattedAPPUrl}`,
};

export const Math: Wallet = {
  type: WalletType.injected,
  typeName: 'Math',
  showName: 'Math Wallet',
  logo: <MathLogo />,
  link: 'https://mathwallet.org',
  checked: () => window.ethereum && window.ethereum.isMathWallet,
  isFollowInject: true,
  sort: sort++,
  supportMobile: true,
  // mobileDeepLink: `mathwallet://mathwallet.org?action=link&value=${APP_URL}`,
};

export const GameStop: Wallet = {
  type: WalletType.injected,
  typeName: 'GameStop',
  showName: 'GameStop Wallet',
  logo: <GameStopLogo />,
  checked: () => window.ethereum?.isGamestop,
  sort: sort++,
  isFollowInject: true,
  link: 'https://wallet.gamestop.com/',
};

export const Brave: Wallet = {
  type: WalletType.injected,
  typeName: 'Brave',
  showName: 'Brave',
  logo: <BraveLogo />,
  checked: () => window.ethereum?.isBraveWallet,
  sort: sort++,
  link: 'https://wallet-docs.brave.com/',
  isFollowInject: true,
};

export const Huobi: Wallet = {
  type: WalletType.injected,
  typeName: 'Huobi Wallet',
  showName: 'Huobi Wallet',
  logo: <HuobiWalletLogo />,
  checked: () => window.ethereum && window.ethereum.isHbWallet,
  sort: sort++,
};

export const Coin98: Wallet = {
  type: WalletType.injected,
  typeName: 'Coin98',
  showName: 'Coin98',
  logo: <Coin98Logo />,
  checked: () => window.ethereum && window.ethereum.isCoin98,
  sort: sort++,
};
