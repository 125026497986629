import TransportWebUSB from '@ledgerhq/hw-transport-webhid';

export type { default as Transport } from '@ledgerhq/hw-transport-webhid';

export const getTransport = async () => {
  const transport = (await TransportWebUSB.create()) as TransportWebUSB;
  return transport;
};

export const isSupportWebHid = () => {
  // @ts-ignore
  return !!(window.navigator && window.navigator.hid);
};
