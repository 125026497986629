import BigNumber from 'bignumber.js';
import { Map, OrderedMap } from 'immutable';

export interface ImmutableMap<T> extends Map<string, T[keyof T]> {
  get<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): T[K];
  set<K extends Extract<keyof T, string>>(key: K, value: T[K]): this;
  delete<K extends Extract<keyof T, string>>(key: K): this;

  update<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    notSetValue: V,
    updater: (value: V) => V,
  ): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    updater: (value: V) => V,
  ): this;
}
export type TokenResult = {
  address: string;
  balance: BigNumber;
  allowance: BigNumber;
  decimals: number;
  symbol: string;
  name: string;
};

export type Token = ImmutableMap<{
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  showDecimals: number;
  logoUrl?: string;
  logo?: { url: string };
  tokenlists?: { name: string }[];
  opRank?: number;
  custom?: boolean;
  occupied?: boolean;
  slippage: number | null;
  isPopular?: boolean | null;
  chainId?: number;
  source?: SimpleSource;
}>;

export type Tokens = OrderedMap<string, Token>; // key: tokenAddress

export type TokenList = ImmutableMap<{
  // source
  id: string;
  logoUrl: string;
  name: string;
  tokens: Tokens;
  enabled: boolean;
  isOfficial?: boolean;
}>;

export const EmptyToken = Map({
  name: '',
  symbol: '',
  address: '',
  decimals: 0,
  showDecimals: 0,
}) as Token;

export interface TokenListFilterToken {
  name: string;
  logoUrl: string;
  token: Token;
  sort: number;
}

export interface SimpleSource {
  name: string;
  logoUrl: string;
  id: string;
}
