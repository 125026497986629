import { gql } from '@apollo/client';

export const fetchTokenList = gql`
  query FetchTokenList($first: Int, $where: Token_filter) {
    tokens(
      first: $first
      where: $where
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
      symbol
      name
      decimals
      creator
      totalSupply
      holderCount
      type
    }
  }
`;

export const fetchAllToken = gql`
  query FetchAllToken($where: DodoToken_filter) {
    dodoTokens(where: $where) {
      tokens
    }
  }
`;

export const fetchTokenIdList = gql`
  query FetchTokenIdList($first: Int, $where: Token_filter) {
    tokens(
      first: $first
      where: $where
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
    }
  }
`;

export const fetchErc20ListV2 = gql`
  query FetchErc20ListV2($where: Erc20listV2Filter) {
    erc20_listV2(where: $where) {
      address
      chainId
      decimals
      logoImg
      name
      slippage
      symbol
      domains {
        name
      }
      funcLabels {
        key
      }
      attributeLabels {
        key
      }
      tokenlists {
        name
      }
    }
  }
`;
