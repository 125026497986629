import type { ContractConfigDataI } from '@dodoex/utils';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line no-restricted-globals
const { endpoint } = self;

export function callableMethod() {
  endpoint.callable('singleSend');
  endpoint.callable('batchSend');
  endpoint.callable('getEndpointUrl');
  endpoint.callable('getConfig');
  endpoint.callable('getLatestBlockNumber');
}

export function singleSend(
  contractAddress: string,
  ABI: any,
  method: string,
  params: any[] = [],
) {
  return endpoint.call.singleSend(contractAddress, ABI, method, params);
}

export function batchSend(chainId: number, to: string, data: string) {
  return endpoint.call.batchSend(chainId, to, data);
}

export function getEndpointUrl(chainId: number) {
  return endpoint.call.getEndpointUrl(chainId);
}

export function getConfig(chainId: number) {
  return endpoint.call.getConfig(chainId) as ContractConfigDataI;
}

export function getLatestBlockNumber() {
  return endpoint.call.getLatestBlockNumber() as number;
}
