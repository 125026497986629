export enum GrayRelease {
  normal = '0',
  gray = '1',
}
export enum GrayConfigKey {
  RouteApi = 'route_api_split',
  GatewayMigrateApi = 'gateway_migrate',
}
export const grayKey = 'gray';

export function getIsGrayRelease(grayConfigKey: GrayConfigKey) {
  const grayRelease = localStorage.getItem(`${grayKey}:${grayConfigKey}`);

  const isGrayRelease =
    grayRelease === undefined ? undefined : grayRelease === GrayRelease.gray;

  return isGrayRelease;
}
