/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import Dexie, { DexieOptions, Table } from 'dexie';
import type { Request, State } from './Submission';

export function captureIndexedDBError(db: Dexie) {
  const oldOpen = db.open;
  db.open = () => {
    const result = oldOpen.call(db);
    result.catch(async (e) => {
      if (
        // firefox 无痕模式会报这个错
        e.message.indexOf(
          'A mutation operation was attempted on a database that did not allow mutations',
        ) > -1
      ) {
        const fakeIndexedDB = (await import('fake-indexeddb')).default;
        const fakeIndexedDBFDBKeyRange = (
          await import('fake-indexeddb/lib/FDBKeyRange')
        ).default;
        (db as any)._deps.indexedDB = fakeIndexedDB;
        (db as any)._deps.IDBKeyRange = fakeIndexedDBFDBKeyRange;
        (db as any)._options.indexedDB = fakeIndexedDB;
        (db as any)._options.IDBKeyRange = fakeIndexedDBFDBKeyRange;
        oldOpen.call(db);
      }
    });
    return result;
  };
}

Dexie.addons.push(captureIndexedDBError);

export type WalletTx = Request & {
  state: State;
  account: string;
  chainId: number;
  safeTxHash?: string;
};

/**
 * 询价记录
 */
export interface RouteRespForTokenPairList {
  id?: number;
  fromTokenAddress: string;
  toTokenAddress: string;
  fromTokenAmount: string;
  toTokenAmount: string;
}

// ens\spaceId 之类的域名缓存
export interface Domain {
  chainId: number;
  account: string;
  domain: string;
}

export enum AccountStatusType {
  LedgerUSBConnectInfo = 'ledger-usb-connect-info',
}
export interface AccountStatus {
  key: AccountStatusType;
  value: any;
  account?: string;
  chainId?: number;
}

export interface SubmitTxTracking {
  key?: string;
  hash: string;
  chainId: number;
  from: string;
  to?: string;
  nonce: number;
  extra?: any;
  failedTime: number;
}

export class WalletDexie extends Dexie {
  walletTxList!: Table<WalletTx, string>;

  routeRespForTokenPairList!: Table<RouteRespForTokenPairList, string>;

  domainList!: Table<Domain, string>;

  accountStatus!: Table<AccountStatus, string>;

  submitTxTracking!: Table<SubmitTxTracking, string>;

  constructor(options?: DexieOptions) {
    super('dodoex-wallet', options);
    // https://dexie.org/docs/Tutorial/Design#database-versioning
    // https://dexie.org/docs/Version/Version.stores()
    this.version(1).stores({
      // https://dexie.org/docs/Compound-Index
      walletTxList: '++id, &tx, *state, [account+chainId]',
    });
    this.version(2).stores({
      routeRespForTokenPairList: '++id, [fromTokenAddress+toTokenAddress]',
    });
    this.version(3).stores({
      domainList: '++id, *chainId, [account+chainId]',
    });
    this.version(4).stores({
      accountStatus: 'key, [account+chainId+key]',
    });
    this.version(5).stores({
      submitTxTracking: 'hash, *failedTime',
    });
  }
}

export const walletDb = new WalletDexie();

// window.addEventListener('unhandledrejection', (e) => {
//   if (e.reason.name === 'DatabaseClosedError') {
//   }
// });
