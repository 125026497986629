import BigNumber from 'bignumber.js';
import { Map, OrderedMap, Set } from 'immutable';
import { AnyAction } from 'redux';
import { getLastChainId, getLastEndpoint } from './localstorage';
import { getChainIdWithNetworkName } from './utils';

export enum ChainIdSource {
  query = 'query',
  stored = 'stored',
}

export interface GasPriceInfo {
  gasPrice: number;
  maxFeePerGas?: number;
  maxPriorityFeePerGas?: number;
}

export type EndpointConfig = Map<string, string>;

export enum AccountType {
  EOA = 1,
  contract,
  safe,
};
export interface LatestBlockNumberStatus {
  loading: boolean;
  failed: boolean;
}

export type State = ImmutableMap<{
  chainId: number;
  currentAccount?: string;
  chainIdSource: ChainIdSource;
  endpoints: EndpointConfig;
  endpointSelect: string;
  latestBlockNumber: BigNumber;
  latestETHBlockNumber: BigNumber;
  latestBlockNumberStatus: LatestBlockNumberStatus;
  updateTimestamp: number;
  proxyAddress?: string;
  endpointRTTs: Map<string, number>;
  endpointBlockHeights: Map<string, number>;
  currentBaseFee: BigNumber;

  chainMismatchNotice: boolean;
  showAccountModal: boolean;
  isChinaMainland: boolean;
  gasPriceInfo: GasPriceInfo;
  buyCryptoListAutoOpen: boolean;
  accountType: AccountType;
}>;

const search = new URLSearchParams(window.location.search);
const network = search.get('network');
const forcedChainId = network ? getChainIdWithNetworkName(network) : 0;
const defaultChainId = forcedChainId !== 0 ? forcedChainId : getLastChainId();

export const initialState: State = Map({
  chainId: defaultChainId || 1,
  chainIdSource:
    forcedChainId === 0 ? ChainIdSource.stored : ChainIdSource.query,
  endpoints: Map(),
  endpointSelect: getLastEndpoint(defaultChainId),
  latestBlockNumber: new BigNumber(0),
  latestETHBlockNumber: new BigNumber(0),
  latestBlockNumberStatus: {
    loading: false,
    failed: false,
  },
  updateTimestamp: 0,
  endpointRTTs: Map({}),
  endpointBlockHeights: Map({}),
  currentBaseFee: new BigNumber(0),
  chainMismatchNotice: false,
  showAccountModal: false,
  isChinaMainland: true,
  gasPriceInfo: {
    gasPrice: new BigNumber(NaN),
  },
  buyCryptoListAutoOpen: false,
  accountType: AccountType.EOA,
}) as State;

export default (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'SET_GAS_PRICE':
      return state.set('gasPriceInfo', action.payload.gasPriceInfo);
    case 'SET_PROXY_ADDRESS':
      return state.set('proxyAddress', action.payload.proxyAddress);
    case 'SET_BLOCK_NUMBER':
      state = state.set('latestBlockNumber', action.payload.blockNumber);
      state = state.set('updateTimestamp', Date.now());
      return state;
    case 'SET_ETH_BLOCK_NUMBER':
      state = state.set('latestETHBlockNumber', action.payload.blockNumber);
      return state;
    case 'SET_BLOCK_NUMBER_STATUS':
      state = state.set(
        'latestBlockNumberStatus',
        action.payload.latestBlockNumberStatus,
      );
      return state;
    case 'SET_CURRENT_BASE_FEE':
      state = state.set('currentBaseFee', action.payload.baseFee);
      return state;
    case 'SELECT_ENDPOINT':
      return state.set('endpointSelect', action.payload.endpoint);
    case 'SET_ENDPOINTS':
      state = state.set('endpoints', action.payload.endpoints);
      state = state.set('endpointRTTs', Map({}));
      return state;
    case 'SET_ENDPOINT_RTT':
      state = state.setIn(
        ['endpointBlockHeights', action.payload.endpoint],
        action.payload.blockHeight,
      );
      state = state.setIn(
        ['endpointRTTs', action.payload.endpoint],
        action.payload.rtt,
      );
      return state;
    case 'SET_CURRENT_ACCOUNT':
      return state.set('currentAccount', action.payload.account);
    case 'SET_CHAIN_ID':
      return state.set('chainId', action.payload.chainId);
    case 'SET_CHAIN_MISMATCH_NOTICE':
      return state.set('chainMismatchNotice', action.payload.shown);
    case 'SET_SHOW_ACCOUNT_MODAL':
      return state.set('showAccountModal', action.payload.show);
    case 'SET_BUY_CRYPTO_LIST_AUTO_OPEN':
      return state.set('buyCryptoListAutoOpen', action.payload.show);
    case 'SET_IS_CHINA_MAINLAND':
      return state.set('isChinaMainland', action.payload.isChinaMainland);
    case 'SET_ACCOUNT_TYPE':
      return state.set('accountType', action.payload.accountType);
    default:
      return state;
  }
};

export interface ImmutableMap<T> extends Map<string, T[keyof T]> {
  get<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): T[K];
  set<K extends Extract<keyof T, string>>(key: K, value: T[K]): this;
  delete<K extends Extract<keyof T, string>>(key: K): this;

  update<K extends Extract<keyof T, string>>(key: K, notSetValue?: T[K]): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    notSetValue: V,
    updater: (value: V) => V,
  ): this;
  update<K extends Extract<keyof T, string>, V extends T[K]>(
    key: K,
    updater: (value: V) => V,
  ): this;
}
