import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getCurrentChainId } from '@dodoex/wallet';
import { fetchChartCaution } from './data/queries';
import {
  FetchChartCaution,
  FetchChartCautionVariables,
  FetchChartCaution_manage_service_getCautionsV2,
} from './data/__generated__/FetchChartCaution';

export enum WarningType {
  Normal = 'normal',
  UnknownSource = 'unknown-source',
}
export const useChartCaution = ({ tokens }: { tokens: string[] }) => {
  const chainId = useSelector(getCurrentChainId);
  const { data, loading, refetch } = useQuery<
    FetchChartCaution,
    FetchChartCautionVariables
  >(fetchChartCaution, {
    variables: {
      where: {
        network: chainId,
        tokens,
      },
    },
  });

  const cautionInfos: FetchChartCaution_manage_service_getCautionsV2[] =
    useMemo(() => {
      if (!data || !data.manage_service_getCautionsV2) return [];
      return data.manage_service_getCautionsV2;
    }, [data]);

  const warningType = useMemo(() => {
    let hasUnknownSource = false;
    if (cautionInfos) {
      hasUnknownSource = cautionInfos.some(
        (info: FetchChartCaution_manage_service_getCautionsV2) =>
          info.alert && info.reason.indexOf(WarningType.UnknownSource) > -1,
      );
    }
    return hasUnknownSource ? WarningType.UnknownSource : WarningType.Normal;
  }, [cautionInfos]);

  return {
    loading,
    refetch,
    warningType,
  };
};
