import { useTranslation } from 'react-i18next';
import { useState, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { isAndroid } from '@dodoex/utils';
import { ReactComponent as WalletConnectLogo } from '../images/walletConnect.svg';
import { Dialog } from '../Dialog';
import { TabSwitchButtons, TabSwitchButton } from '../Tabs';
import { Button } from '../Button';
import { Display } from '../styles';
import QrcodeDisplay from './QrcodeDisplay';
import WalletList from './WalletList';
import { useDevices } from '../hooks';

interface WalletConnectModalProps {
  url: string | undefined;
  onClose: () => void;
}

enum Tab {
  select = 1,
  qr,
}

function Footer({ url }: { url?: string }) {
  const { t } = useTranslation();
  if (url && isAndroid) {
    return (
      <Box
        sx={{
          px: 20,
          pb: 20,
        }}
      >
        <Button
          fullWidth
          component="a"
          rel="noopener noreferrer"
          href={url}
          target="_blank"
        >
          {t('wallet.wallet-connect.android.btn.connect-wallet')}
        </Button>
      </Box>
    );
  }
  return null;
}

export function WalletConnectModal({ url, onClose }: WalletConnectModalProps) {
  const { t } = useTranslation();
  const { isMobile } = useDevices();
  const [activeTab, setActiveTab] = useState<Tab>(
    isMobile ? Tab.select : Tab.qr,
  );
  const tabList = useMemo(() => {
    return [
      {
        value: Tab.select,
        label: t(
          isMobile
            ? 'wallet.wallet-connect.tab.mobile'
            : 'wallet.wallet-connect.tab.pc',
        ),
      },
      {
        value: Tab.qr,
        label: t('wallet.wallet-connect.tab.qr'),
      },
    ];
  }, [t, isMobile]);

  return (
    <Dialog
      on={!!url}
      onClose={onClose}
      title={
        <Box
          sx={{
            typography: 'caption',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            component={WalletConnectLogo}
            sx={{
              mr: 4,
              width: 32,
              height: 32,
            }}
          />
          {t('wallet.wallet-connect.title')}
        </Box>
      }
      footer={activeTab === Tab.qr ? undefined : <Footer url={url} />}
      sx={{
        '& .dodo-dialog-body': {
          p: 0,
        },
      }}
    >
      <TabSwitchButtons
        value={activeTab}
        onChange={(_, val) => setActiveTab(val)}
        variant="fullWidth"
        sx={{
          mt: 20,
          mx: 20,
        }}
      >
        {tabList.map((tab) => (
          <TabSwitchButton
            key={tab.value}
            value={tab.value}
            label={tab.label}
          />
        ))}
      </TabSwitchButtons>
      <Display hidden={activeTab !== Tab.select}>
        {isAndroid ? (
          <Box
            sx={{
              mt: 20,
              p: 20,
              pb: 48,
              borderStyle: 'solid',
              borderWidth: '1px 0 0',
              borderColor: 'custom.border.default',
            }}
          >
            <Box
              sx={{
                pb: 16,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {t('wallet.wallet-connect.android.alert.title')}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: 6,
                  color: 'text.secondary',
                }}
              >
                {t('wallet.wallet-connect.android.alert.no-wallet')}
              </Typography>
            </Box>
          </Box>
        ) : (
          <WalletList url={url} />
        )}
      </Display>
      <Display hidden={activeTab !== Tab.qr}>
        <QrcodeDisplay url={url} />
      </Display>
    </Dialog>
  );
}
