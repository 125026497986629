import { gql } from '@apollo/client';

export const fetchTokenUsdPrice = gql`
  query FetchTokenUsdPrice($where: Token_filter) {
    tokens(where: $where) {
      id
      usdPrice
    }
  }
`;
