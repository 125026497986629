import { Tabs as TabsOrigin, Tab as TabOrigin, alpha } from '@mui/material';
import { styled } from '@mui/system';

export const Tabs = styled(TabsOrigin)(({ theme }) => {
  return {
    borderStyle: 'solid',
    borderWidth: '1px 0',
    borderColor: theme.palette.custom.border.default,
    minHeight: 'auto',
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '&&& .MuiTabs-fixed': {
      width: 'max-content',
      overflow: 'visible !important',
    },
    '& .MuiTabs-indicator': {
      width: '28px !important',
      marginLeft: 16,
    },
  };
});

export const Tab = styled(TabOrigin)(({ theme }) => {
  return {
    textTransform: 'none',
    fontSize: '1rem',
    lineHeight: '22px',
    alignItems: 'flex-start',
    padding: '20px 8px 22px 20px',
    minWidth: 'auto',
    '&:not(.Mui-selected):hover': {
      color: alpha(theme.palette.text.secondary, 1),
    },
  };
});
