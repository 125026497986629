import { Submission } from '@dodoex/wallet';
import { claimedMerkle, claimPending } from './walletMining';
import { _env } from '../../setting/getConfig/env';

const { useSubmission, ExecutionResult, OpCode } = Submission;

export const useClaimAirdrop = ({
  account,
  claimTitle,
  sucessBack,
}: {
  account?: string;
  claimTitle: string;
  sucessBack: ({ contractAddress }: { contractAddress?: string }) => void;
}) => {
  const submission = useSubmission();

  const handleClaimAirdrop = async (contractAddress?: string, merkle?: any) => {
    if (!account) return;
    try {
      let executeData = '';
      if (merkle) {
        const { index, amout, proof } = merkle;
        executeData = await claimedMerkle(
          index,
          account,
          amout,
          proof,
          contractAddress as string,
        );
      } else {
        executeData = await claimPending(contractAddress);
      }
      if (!executeData) {
        throw new Error('contract claim data is null');
      }
      const txResult = await submission.execute(claimTitle, {
        opcode: OpCode.TX,
        data: executeData,
        to: contractAddress || _env.PREMINING_CONTRACT_ADDRESS,
        value: 0,
      });
      if (txResult === ExecutionResult.Success) {
        sucessBack({
          contractAddress,
        });
      }
    } catch (error) {
      console.error('claim airdrop sendTransaction error', error);
    }
  };

  return {
    handleClaimAirdrop,
  };
};
