import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '.';
import { fetchDashboardDailyData } from './data/queries';
import {
  FetchDashboardDailyData,
  FetchDashboardDailyDataVariables,
  FetchDashboardDailyData_dashboard_chain_day_data_list_traders,
  FetchDashboardDailyData_dashboard_chain_day_data_list_tvl,
  FetchDashboardDailyData_dashboard_chain_day_data_list_txCount,
  FetchDashboardDailyData_dashboard_chain_day_data_list_volume,
} from './data/__generated__/FetchDashboardDailyData';
import { Point } from './types';

type Item = (
  | FetchDashboardDailyData_dashboard_chain_day_data_list_tvl
  | FetchDashboardDailyData_dashboard_chain_day_data_list_volume
  | FetchDashboardDailyData_dashboard_chain_day_data_list_txCount
  | FetchDashboardDailyData_dashboard_chain_day_data_list_traders
) & { date?: string | null };

const init = {
  BNBChain: 0,
  ETH: 0,
  Polygon: 0,
  Arbitrum: 0,
  Aurora: 0,
  Boba: 0,
  timestamp: '',
};

const format = (item: Item | null): Point => {
  if (!item) return init;
  return {
    ETH: item.ethereum ? Number(item.ethereum) : 0,
    BNBChain: item.bsc ? Number(item.bsc) : 0,
    Polygon: item.polygon ? Number(item.polygon) : 0,
    Arbitrum: item.arbitrum ? Number(item.arbitrum) : 0,
    Aurora: item.aurora ? Number(item.aurora) : 0,
    Boba: item.boba ? Number(item.boba) : 0,
    timestamp: item.date ? item.date.replace(/-/g, '/') : '',
  };
};

const adapter = (target: Item[]): Point[] => {
  return target.map(format);
};

export const useBusinessdata = () => {
  const { setUpdateTime } = useContext(DashboardContext);
  const [dataSource, setDataSource] = useState<{
    tvl: Point[];
    volume: Point[];
    txCount: Point[];
    traders: Point[];
    volume_near24h: Point;
    txCount_near24h: Point;
    traders_near24h: Point;
    tvl_amount: Point;
    pools_amount: Point;
    pairs_amount: Point;
  }>({
    tvl: [],
    volume: [],
    txCount: [],
    traders: [],
    volume_near24h: init,
    txCount_near24h: init,
    traders_near24h: init,
    tvl_amount: init,
    pools_amount: init,
    pairs_amount: init,
  });

  const { data, loading, refetch, error } = useQuery<
    FetchDashboardDailyData,
    FetchDashboardDailyDataVariables
  >(fetchDashboardDailyData, { variables: { where: { day: 365 } } });

  useEffect(() => {
    if (data && data.dashboard_chain_day_data) {
      const dataList = data.dashboard_chain_day_data.list;
      const tvl: Item[] = [];
      const volume: Item[] = [];
      const txCount: Item[] = [];
      const traders: Item[] = [];
      const updateTime = dataList
        ? String(dataList[dataList.length - 1]?.timestamp)
        : '';
      try {
        dataList?.forEach((item) => {
          if (item?.tvl) {
            tvl.push({ ...item.tvl, date: item.date_str });
          }

          if (item?.volume) {
            volume.push({ ...item.volume, date: item.date_str });
          }

          if (item?.txCount) {
            txCount.push({ ...item.txCount, date: item.date_str });
          }

          if (item?.traders) {
            traders.push({ ...item.traders, date: item.date_str });
          }
        });

        setDataSource({
          tvl: adapter(tvl),
          volume: adapter(volume),
          txCount: adapter(txCount).slice(-30),
          traders: adapter(traders).slice(-30),
          volume_near24h: format(data.dashboard_chain_day_data.volume_near24h),
          txCount_near24h: format(
            data.dashboard_chain_day_data.txCount_near24h,
          ),
          traders_near24h: format(
            data.dashboard_chain_day_data.traders_near24h,
          ),
          tvl_amount: format(data.dashboard_chain_day_data.tvl),
          pools_amount: format(data.dashboard_chain_day_data.pools),
          pairs_amount: format(data.dashboard_chain_day_data.pairs),
        });
      } catch (e) {
        console.error(e);
      }
      setUpdateTime(updateTime);
    }
  }, [data, setUpdateTime]);

  return {
    ...dataSource,
    loading,
    refetch,
    error,
  };
};
