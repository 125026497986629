import { gql } from '@apollo/client';

export const fetchGetAnnouncement = gql`
  query FetchGetAnnouncement($lang: String) {
    announcement_getAnnouncement(lang: $lang) {
      list {
        id
        url
        title
        lastPublishTime
        type
        metadata {
          background
          icon
        }
      }
      lastPublishTime
    }
  }
`;
