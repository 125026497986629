/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ChartKLineChartScope {
  ALL = "ALL",
  DODO = "DODO",
}

export enum Limit_and_rfqlimitCancelSignType {
  eip1271 = "eip1271",
  eip712 = "eip712",
}

export enum Limit_and_rfqlimitOrderQueryEnum {
  ALL = "ALL",
  FRESH = "FRESH",
  NOT_FRESH = "NOT_FRESH",
}

export enum LiquidityPosition_orderBy {
  chain = "chain",
  id = "id",
  lastTxTime = "lastTxTime",
  liquidityTokenBalance = "liquidityTokenBalance",
  liquidityTokenInMining = "liquidityTokenInMining",
  lpToken = "lpToken",
  pair = "pair",
  updatedAt = "updatedAt",
  user = "user",
}

export enum MinePool_orderBy {
  chain = "chain",
  creator = "creator",
  id = "id",
  isLpToken = "isLpToken",
  platform = "platform",
  pool = "pool",
  rewardDetail = "rewardDetail",
  stakeToken = "stakeToken",
  timestamp = "timestamp",
  updatedAt = "updatedAt",
}

export enum OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum Pair_orderBy {
  baseLpToken = "baseLpToken",
  baseReserve = "baseReserve",
  baseSymbol = "baseSymbol",
  baseToken = "baseToken",
  chain = "chain",
  createdAtBlockNumber = "createdAtBlockNumber",
  createdAtTimestamp = "createdAtTimestamp",
  creator = "creator",
  feeBase = "feeBase",
  feeQuote = "feeQuote",
  feeUSD = "feeUSD",
  i = "i",
  id = "id",
  isDepositBaseAllowed = "isDepositBaseAllowed",
  isDepositQuoteAllowed = "isDepositQuoteAllowed",
  isTradeAllowed = "isTradeAllowed",
  k = "k",
  lastTradePrice = "lastTradePrice",
  liquidityProviderCount = "liquidityProviderCount",
  lpFeeRate = "lpFeeRate",
  maintainer = "maintainer",
  mtFeeBase = "mtFeeBase",
  mtFeeQuote = "mtFeeQuote",
  mtFeeRate = "mtFeeRate",
  mtFeeRateModel = "mtFeeRateModel",
  mtFeeUSD = "mtFeeUSD",
  quoteLpToken = "quoteLpToken",
  quoteReserve = "quoteReserve",
  quoteSymbol = "quoteSymbol",
  quoteToken = "quoteToken",
  source = "source",
  traderCount = "traderCount",
  txCount = "txCount",
  type = "type",
  untrackedBaseVolume = "untrackedBaseVolume",
  untrackedQuoteVolume = "untrackedQuoteVolume",
  updatedAt = "updatedAt",
  volumeBaseToken = "volumeBaseToken",
  volumeQuoteToken = "volumeQuoteToken",
  volumeUSD = "volumeUSD",
}

export enum Swap_orderBy {
  amountIn = "amountIn",
  amountOut = "amountOut",
  baseVolume = "baseVolume",
  chain = "chain",
  feeBase = "feeBase",
  feeQuote = "feeQuote",
  from = "from",
  fromToken = "fromToken",
  hash = "hash",
  id = "id",
  logIndex = "logIndex",
  pair = "pair",
  quoteVolume = "quoteVolume",
  sender = "sender",
  timestamp = "timestamp",
  to = "to",
  toToken = "toToken",
  updatedAt = "updatedAt",
  volumeUSD = "volumeUSD",
}

export enum UserStake_orderBy {
  balance = "balance",
  chain = "chain",
  id = "id",
  pool = "pool",
  updatedAt = "updatedAt",
  user = "user",
}

export enum User_orderBy {
  chain = "chain",
  createdAt = "createdAt",
  credit = "credit",
  creditFromInvited = "creditFromInvited",
  creditOfSuperior = "creditOfSuperior",
  id = "id",
  liquidityPositions = "liquidityPositions",
  mintAmount = "mintAmount",
  redeemBurnAmount = "redeemBurnAmount",
  redeemFeeAmount = "redeemFeeAmount",
  redeemRecieveAmount = "redeemRecieveAmount",
  spFromInvited = "spFromInvited",
  stakingPower = "stakingPower",
  superior = "superior",
  superiorSP = "superiorSP",
  timestamp = "timestamp",
  tokens = "tokens",
  tradingRewardRecieved = "tradingRewardRecieved",
  txCount = "txCount",
  updatedAt = "updatedAt",
}

export interface Account_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  balances_?: Balance_filter | null;
  transfersOperator_?: Transfer_filter | null;
  transfersFrom_?: Transfer_filter | null;
  transfersTo_?: Transfer_filter | null;
  approvalsOwner_?: Approval_filter | null;
  approvalsSpender_?: Approval_filter | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Account_filter | null)[] | null;
  or?: (Account_filter | null)[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_gt?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_lte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface AchievementDetail_filter {
  achievement_id: string;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface AchievementMe_filter {
  user: string;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Approval_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  transaction?: string | null;
  transaction_not?: string | null;
  transaction_gt?: string | null;
  transaction_lt?: string | null;
  transaction_gte?: string | null;
  transaction_lte?: string | null;
  transaction_in?: string[] | null;
  transaction_not_in?: string[] | null;
  transaction_contains?: string | null;
  transaction_contains_nocase?: string | null;
  transaction_not_contains?: string | null;
  transaction_not_contains_nocase?: string | null;
  transaction_starts_with?: string | null;
  transaction_starts_with_nocase?: string | null;
  transaction_not_starts_with?: string | null;
  transaction_not_starts_with_nocase?: string | null;
  transaction_ends_with?: string | null;
  transaction_ends_with_nocase?: string | null;
  transaction_not_ends_with?: string | null;
  transaction_not_ends_with_nocase?: string | null;
  transaction_?: Transaction_filter | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  token?: string | null;
  token_not?: string | null;
  token_gt?: string | null;
  token_lt?: string | null;
  token_gte?: string | null;
  token_lte?: string | null;
  token_in?: string[] | null;
  token_not_in?: string[] | null;
  token_contains?: string | null;
  token_contains_nocase?: string | null;
  token_not_contains?: string | null;
  token_not_contains_nocase?: string | null;
  token_starts_with?: string | null;
  token_starts_with_nocase?: string | null;
  token_not_starts_with?: string | null;
  token_not_starts_with_nocase?: string | null;
  token_ends_with?: string | null;
  token_ends_with_nocase?: string | null;
  token_not_ends_with?: string | null;
  token_not_ends_with_nocase?: string | null;
  token_?: Token_filter | null;
  owner?: string | null;
  owner_not?: string | null;
  owner_gt?: string | null;
  owner_lt?: string | null;
  owner_gte?: string | null;
  owner_lte?: string | null;
  owner_in?: string[] | null;
  owner_not_in?: string[] | null;
  owner_contains?: string | null;
  owner_contains_nocase?: string | null;
  owner_not_contains?: string | null;
  owner_not_contains_nocase?: string | null;
  owner_starts_with?: string | null;
  owner_starts_with_nocase?: string | null;
  owner_not_starts_with?: string | null;
  owner_not_starts_with_nocase?: string | null;
  owner_ends_with?: string | null;
  owner_ends_with_nocase?: string | null;
  owner_not_ends_with?: string | null;
  owner_not_ends_with_nocase?: string | null;
  owner_?: Account_filter | null;
  spender?: string | null;
  spender_not?: string | null;
  spender_gt?: string | null;
  spender_lt?: string | null;
  spender_gte?: string | null;
  spender_lte?: string | null;
  spender_in?: string[] | null;
  spender_not_in?: string[] | null;
  spender_contains?: string | null;
  spender_contains_nocase?: string | null;
  spender_not_contains?: string | null;
  spender_not_contains_nocase?: string | null;
  spender_starts_with?: string | null;
  spender_starts_with_nocase?: string | null;
  spender_not_starts_with?: string | null;
  spender_not_starts_with_nocase?: string | null;
  spender_ends_with?: string | null;
  spender_ends_with_nocase?: string | null;
  spender_not_ends_with?: string | null;
  spender_not_ends_with_nocase?: string | null;
  spender_?: Account_filter | null;
  value?: any | null;
  value_not?: any | null;
  value_gt?: any | null;
  value_lt?: any | null;
  value_gte?: any | null;
  value_lte?: any | null;
  value_in?: any[] | null;
  value_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Approval_filter | null)[] | null;
  or?: (Approval_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface AuctionactiveFilter {
  ids?: string | null;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface AuctiondetailFilter {
  id?: string | null;
  userAddress?: string | null;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface AuctionlistFilter {
  userAddress?: string | null;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface AuctionoperationRecordFilter {
  id?: string | null;
  userAddress?: string | null;
  page?: number | null;
  pageSize?: number | null;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Balance_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  token?: string | null;
  token_not?: string | null;
  token_gt?: string | null;
  token_lt?: string | null;
  token_gte?: string | null;
  token_lte?: string | null;
  token_in?: string[] | null;
  token_not_in?: string[] | null;
  token_contains?: string | null;
  token_contains_nocase?: string | null;
  token_not_contains?: string | null;
  token_not_contains_nocase?: string | null;
  token_starts_with?: string | null;
  token_starts_with_nocase?: string | null;
  token_not_starts_with?: string | null;
  token_not_starts_with_nocase?: string | null;
  token_ends_with?: string | null;
  token_ends_with_nocase?: string | null;
  token_not_ends_with?: string | null;
  token_not_ends_with_nocase?: string | null;
  token_?: Token_filter | null;
  account?: string | null;
  account_not?: string | null;
  account_gt?: string | null;
  account_lt?: string | null;
  account_gte?: string | null;
  account_lte?: string | null;
  account_in?: string[] | null;
  account_not_in?: string[] | null;
  account_contains?: string | null;
  account_contains_nocase?: string | null;
  account_not_contains?: string | null;
  account_not_contains_nocase?: string | null;
  account_starts_with?: string | null;
  account_starts_with_nocase?: string | null;
  account_not_starts_with?: string | null;
  account_not_starts_with_nocase?: string | null;
  account_ends_with?: string | null;
  account_ends_with_nocase?: string | null;
  account_not_ends_with?: string | null;
  account_not_ends_with_nocase?: string | null;
  account_?: Account_filter | null;
  value?: any | null;
  value_not?: any | null;
  value_gt?: any | null;
  value_lt?: any | null;
  value_gte?: any | null;
  value_lte?: any | null;
  value_in?: any[] | null;
  value_not_in?: any[] | null;
  transfersFrom_?: Transfer_filter | null;
  transfersTo_?: Transfer_filter | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Balance_filter | null)[] | null;
  or?: (Balance_filter | null)[] | null;
  nft?: string | null;
  nft_not?: string | null;
  nft_gt?: string | null;
  nft_lt?: string | null;
  nft_gte?: string | null;
  nft_lte?: string | null;
  nft_in?: string[] | null;
  nft_not_in?: string[] | null;
  nft_contains?: string | null;
  nft_contains_nocase?: string | null;
  nft_not_contains?: string | null;
  nft_not_contains_nocase?: string | null;
  nft_starts_with?: string | null;
  nft_starts_with_nocase?: string | null;
  nft_not_starts_with?: string | null;
  nft_not_starts_with_nocase?: string | null;
  nft_ends_with?: string | null;
  nft_ends_with_nocase?: string | null;
  nft_not_ends_with?: string | null;
  nft_not_ends_with_nocase?: string | null;
  nft_?: Nft_filter | null;
  tokenID?: any | null;
  tokenID_not?: any | null;
  tokenID_gt?: any | null;
  tokenID_lt?: any | null;
  tokenID_gte?: any | null;
  tokenID_lte?: any | null;
  tokenID_in?: any[] | null;
  tokenID_not_in?: any[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_gt?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_lte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface BidHistory_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  user?: string | null;
  user_not?: string | null;
  user_gt?: string | null;
  user_lt?: string | null;
  user_gte?: string | null;
  user_lte?: string | null;
  user_in?: string[] | null;
  user_not_in?: string[] | null;
  user_contains?: string | null;
  user_not_contains?: string | null;
  user_starts_with?: string | null;
  user_not_starts_with?: string | null;
  user_ends_with?: string | null;
  user_not_ends_with?: string | null;
  cp?: string | null;
  cp_not?: string | null;
  cp_gt?: string | null;
  cp_lt?: string | null;
  cp_gte?: string | null;
  cp_lte?: string | null;
  cp_in?: string[] | null;
  cp_not_in?: string[] | null;
  cp_contains?: string | null;
  cp_not_contains?: string | null;
  cp_starts_with?: string | null;
  cp_not_starts_with?: string | null;
  cp_ends_with?: string | null;
  cp_not_ends_with?: string | null;
  action?: string | null;
  action_not?: string | null;
  action_gt?: string | null;
  action_lt?: string | null;
  action_gte?: string | null;
  action_lte?: string | null;
  action_in?: string[] | null;
  action_not_in?: string[] | null;
  action_contains?: string | null;
  action_not_contains?: string | null;
  action_starts_with?: string | null;
  action_not_starts_with?: string | null;
  action_ends_with?: string | null;
  action_not_ends_with?: string | null;
  hash?: string | null;
  hash_not?: string | null;
  hash_gt?: string | null;
  hash_lt?: string | null;
  hash_gte?: string | null;
  hash_lte?: string | null;
  hash_in?: string[] | null;
  hash_not_in?: string[] | null;
  hash_contains?: string | null;
  hash_not_contains?: string | null;
  hash_starts_with?: string | null;
  hash_not_starts_with?: string | null;
  hash_ends_with?: string | null;
  hash_not_ends_with?: string | null;
  block?: any | null;
  block_not?: any | null;
  block_gt?: any | null;
  block_lt?: any | null;
  block_gte?: any | null;
  block_lte?: any | null;
  block_in?: any[] | null;
  block_not_in?: any[] | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  quote?: any | null;
  quote_not?: any | null;
  quote_gt?: any | null;
  quote_lt?: any | null;
  quote_gte?: any | null;
  quote_lte?: any | null;
  quote_in?: any[] | null;
  quote_not_in?: any[] | null;
  share?: any | null;
  share_not?: any | null;
  share_gt?: any | null;
  share_lt?: any | null;
  share_gte?: any | null;
  share_lte?: any | null;
  share_in?: any[] | null;
  share_not_in?: any[] | null;
  fee?: any | null;
  fee_not?: any | null;
  fee_gt?: any | null;
  fee_lt?: any | null;
  fee_gte?: any | null;
  fee_lte?: any | null;
  fee_in?: any[] | null;
  fee_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface BidPosition_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  user?: string | null;
  user_not?: string | null;
  user_gt?: string | null;
  user_lt?: string | null;
  user_gte?: string | null;
  user_lte?: string | null;
  user_in?: string[] | null;
  user_not_in?: string[] | null;
  user_contains?: string | null;
  user_not_contains?: string | null;
  user_starts_with?: string | null;
  user_not_starts_with?: string | null;
  user_ends_with?: string | null;
  user_not_ends_with?: string | null;
  cp?: string | null;
  cp_not?: string | null;
  cp_gt?: string | null;
  cp_lt?: string | null;
  cp_gte?: string | null;
  cp_lte?: string | null;
  cp_in?: string[] | null;
  cp_not_in?: string[] | null;
  cp_contains?: string | null;
  cp_not_contains?: string | null;
  cp_starts_with?: string | null;
  cp_not_starts_with?: string | null;
  cp_ends_with?: string | null;
  cp_not_ends_with?: string | null;
  shares?: any | null;
  shares_not?: any | null;
  shares_gt?: any | null;
  shares_lt?: any | null;
  shares_gte?: any | null;
  shares_lte?: any | null;
  shares_in?: any[] | null;
  shares_not_in?: any[] | null;
  investedQuote?: any | null;
  investedQuote_not?: any | null;
  investedQuote_gt?: any | null;
  investedQuote_lt?: any | null;
  investedQuote_gte?: any | null;
  investedQuote_lte?: any | null;
  investedQuote_in?: any[] | null;
  investedQuote_not_in?: any[] | null;
  lastTxTime?: any | null;
  lastTxTime_not?: any | null;
  lastTxTime_gt?: any | null;
  lastTxTime_lt?: any | null;
  lastTxTime_gte?: any | null;
  lastTxTime_lte?: any | null;
  lastTxTime_in?: any[] | null;
  lastTxTime_not_in?: any[] | null;
  claimed?: boolean | null;
  claimed_not?: boolean | null;
  claimed_in?: boolean[] | null;
  claimed_not_in?: boolean[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface BlockChangedFilter {
  number_gte: number;
}

export interface Block_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  number?: any | null;
  number_not?: any | null;
  number_gt?: any | null;
  number_lt?: any | null;
  number_gte?: any | null;
  number_lte?: any | null;
  number_in?: any[] | null;
  number_not_in?: any[] | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  parentHash?: string | null;
  parentHash_not?: string | null;
  parentHash_gt?: string | null;
  parentHash_lt?: string | null;
  parentHash_gte?: string | null;
  parentHash_lte?: string | null;
  parentHash_in?: string[] | null;
  parentHash_not_in?: string[] | null;
  parentHash_contains?: string | null;
  parentHash_not_contains?: string | null;
  parentHash_starts_with?: string | null;
  parentHash_not_starts_with?: string | null;
  parentHash_ends_with?: string | null;
  parentHash_not_ends_with?: string | null;
  author?: string | null;
  author_not?: string | null;
  author_gt?: string | null;
  author_lt?: string | null;
  author_gte?: string | null;
  author_lte?: string | null;
  author_in?: string[] | null;
  author_not_in?: string[] | null;
  author_contains?: string | null;
  author_not_contains?: string | null;
  author_starts_with?: string | null;
  author_not_starts_with?: string | null;
  author_ends_with?: string | null;
  author_not_ends_with?: string | null;
  difficulty?: any | null;
  difficulty_not?: any | null;
  difficulty_gt?: any | null;
  difficulty_lt?: any | null;
  difficulty_gte?: any | null;
  difficulty_lte?: any | null;
  difficulty_in?: any[] | null;
  difficulty_not_in?: any[] | null;
  totalDifficulty?: any | null;
  totalDifficulty_not?: any | null;
  totalDifficulty_gt?: any | null;
  totalDifficulty_lt?: any | null;
  totalDifficulty_gte?: any | null;
  totalDifficulty_lte?: any | null;
  totalDifficulty_in?: any[] | null;
  totalDifficulty_not_in?: any[] | null;
  gasUsed?: any | null;
  gasUsed_not?: any | null;
  gasUsed_gt?: any | null;
  gasUsed_lt?: any | null;
  gasUsed_gte?: any | null;
  gasUsed_lte?: any | null;
  gasUsed_in?: any[] | null;
  gasUsed_not_in?: any[] | null;
  gasLimit?: any | null;
  gasLimit_not?: any | null;
  gasLimit_gt?: any | null;
  gasLimit_lt?: any | null;
  gasLimit_gte?: any | null;
  gasLimit_lte?: any | null;
  gasLimit_in?: any[] | null;
  gasLimit_not_in?: any[] | null;
  receiptsRoot?: string | null;
  receiptsRoot_not?: string | null;
  receiptsRoot_gt?: string | null;
  receiptsRoot_lt?: string | null;
  receiptsRoot_gte?: string | null;
  receiptsRoot_lte?: string | null;
  receiptsRoot_in?: string[] | null;
  receiptsRoot_not_in?: string[] | null;
  receiptsRoot_contains?: string | null;
  receiptsRoot_not_contains?: string | null;
  receiptsRoot_starts_with?: string | null;
  receiptsRoot_not_starts_with?: string | null;
  receiptsRoot_ends_with?: string | null;
  receiptsRoot_not_ends_with?: string | null;
  transactionsRoot?: string | null;
  transactionsRoot_not?: string | null;
  transactionsRoot_gt?: string | null;
  transactionsRoot_lt?: string | null;
  transactionsRoot_gte?: string | null;
  transactionsRoot_lte?: string | null;
  transactionsRoot_in?: string[] | null;
  transactionsRoot_not_in?: string[] | null;
  transactionsRoot_contains?: string | null;
  transactionsRoot_not_contains?: string | null;
  transactionsRoot_starts_with?: string | null;
  transactionsRoot_not_starts_with?: string | null;
  transactionsRoot_ends_with?: string | null;
  transactionsRoot_not_ends_with?: string | null;
  stateRoot?: string | null;
  stateRoot_not?: string | null;
  stateRoot_gt?: string | null;
  stateRoot_lt?: string | null;
  stateRoot_gte?: string | null;
  stateRoot_lte?: string | null;
  stateRoot_in?: string[] | null;
  stateRoot_not_in?: string[] | null;
  stateRoot_contains?: string | null;
  stateRoot_not_contains?: string | null;
  stateRoot_starts_with?: string | null;
  stateRoot_not_starts_with?: string | null;
  stateRoot_ends_with?: string | null;
  stateRoot_not_ends_with?: string | null;
  size?: any | null;
  size_not?: any | null;
  size_gt?: any | null;
  size_lt?: any | null;
  size_gte?: any | null;
  size_lte?: any | null;
  size_in?: any[] | null;
  size_not_in?: any[] | null;
  unclesHash?: string | null;
  unclesHash_not?: string | null;
  unclesHash_gt?: string | null;
  unclesHash_lt?: string | null;
  unclesHash_gte?: string | null;
  unclesHash_lte?: string | null;
  unclesHash_in?: string[] | null;
  unclesHash_not_in?: string[] | null;
  unclesHash_contains?: string | null;
  unclesHash_not_contains?: string | null;
  unclesHash_starts_with?: string | null;
  unclesHash_not_starts_with?: string | null;
  unclesHash_ends_with?: string | null;
  unclesHash_not_ends_with?: string | null;
}

export interface BrowserChainInput {
  chains: (number | null)[];
}

export interface ChartOhlcvInput {
  a: string;
  b: string;
  network: number;
  scope?: ChartKLineChartScope | null;
  scale: string;
}

export interface Coinmarketcap_tokenCoinmarketcapTokenListFilter {
  chainId?: number | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaporderCreateData {
  fromChainId?: number | null;
  fromAmount?: string | null;
  fromTokenAddress?: string | null;
  toChainId?: number | null;
  toAmount?: string | null;
  toTokenAddress?: string | null;
  fromAddress?: string | null;
  toAddress?: string | null;
  slippage?: number | null;
  hash?: string | null;
  product?: string | null;
  extend?: Cross_chain_swaporderCreateDataExtend | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaporderCreateDataExtend {
  lifiBridge?: string | null;
  productParams?: any | null;
  route?: Cross_chain_swaporderCreateDataExtendRoute | null;
}

export interface Cross_chain_swaporderCreateDataExtendRoute {
  toAmount?: string | null;
  feeUSD?: string | null;
  executionDuration?: number | null;
  step?: any | null;
}

export interface Cross_chain_swaporderDetailData {
  user?: string | null;
  orderId?: number | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaporderListData {
  user?: string | null;
  chainId?: number | null;
  page?: number | null;
  limit?: number | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaporderNewStatusData {
  user?: string | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaprouteData {
  fromChainId?: number | null;
  fromAmount?: string | null;
  fromTokenAddress?: string | null;
  toChainId?: number | null;
  toTokenAddress?: string | null;
  fromAddress?: string | null;
  toAddress?: string | null;
  options?: Cross_chain_swaprouteDataOptions | null;
  products?: (string | null)[] | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_swaprouteDataOptions {
  slippage?: number | null;
}

export interface Cross_chain_swaptransactionEncodeData {
  product?: string | null;
  encodeParams?: any | null;
  refreshNow?: boolean | null;
}

export interface Cross_chain_tokentokenlistFilter {
  refreshNow?: boolean | null;
}

export interface CrowdPoolingDayData_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  date?: number | null;
  date_not?: number | null;
  date_gt?: number | null;
  date_lt?: number | null;
  date_gte?: number | null;
  date_lte?: number | null;
  date_in?: number[] | null;
  date_not_in?: number[] | null;
  crowdPooling?: string | null;
  crowdPooling_not?: string | null;
  crowdPooling_gt?: string | null;
  crowdPooling_lt?: string | null;
  crowdPooling_gte?: string | null;
  crowdPooling_lte?: string | null;
  crowdPooling_in?: string[] | null;
  crowdPooling_not_in?: string[] | null;
  crowdPooling_contains?: string | null;
  crowdPooling_not_contains?: string | null;
  crowdPooling_starts_with?: string | null;
  crowdPooling_not_starts_with?: string | null;
  crowdPooling_ends_with?: string | null;
  crowdPooling_not_ends_with?: string | null;
  investedQuote?: any | null;
  investedQuote_not?: any | null;
  investedQuote_gt?: any | null;
  investedQuote_lt?: any | null;
  investedQuote_gte?: any | null;
  investedQuote_lte?: any | null;
  investedQuote_in?: any[] | null;
  investedQuote_not_in?: any[] | null;
  canceledQuote?: any | null;
  canceledQuote_not?: any | null;
  canceledQuote_gt?: any | null;
  canceledQuote_lt?: any | null;
  canceledQuote_gte?: any | null;
  canceledQuote_lte?: any | null;
  canceledQuote_in?: any[] | null;
  canceledQuote_not_in?: any[] | null;
  investCount?: any | null;
  investCount_not?: any | null;
  investCount_gt?: any | null;
  investCount_lt?: any | null;
  investCount_gte?: any | null;
  investCount_lte?: any | null;
  investCount_in?: any[] | null;
  investCount_not_in?: any[] | null;
  newcome?: any | null;
  newcome_not?: any | null;
  newcome_gt?: any | null;
  newcome_lt?: any | null;
  newcome_gte?: any | null;
  newcome_lte?: any | null;
  newcome_in?: any[] | null;
  newcome_not_in?: any[] | null;
  poolQuote?: any | null;
  poolQuote_not?: any | null;
  poolQuote_gt?: any | null;
  poolQuote_lt?: any | null;
  poolQuote_gte?: any | null;
  poolQuote_lte?: any | null;
  poolQuote_in?: any[] | null;
  poolQuote_not_in?: any[] | null;
  investors?: any | null;
  investors_not?: any | null;
  investors_gt?: any | null;
  investors_lt?: any | null;
  investors_gte?: any | null;
  investors_lte?: any | null;
  investors_in?: any[] | null;
  investors_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface CrowdPoolingHourData_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  hour?: number | null;
  hour_not?: number | null;
  hour_gt?: number | null;
  hour_lt?: number | null;
  hour_gte?: number | null;
  hour_lte?: number | null;
  hour_in?: number[] | null;
  hour_not_in?: number[] | null;
  crowdPooling?: string | null;
  crowdPooling_not?: string | null;
  crowdPooling_gt?: string | null;
  crowdPooling_lt?: string | null;
  crowdPooling_gte?: string | null;
  crowdPooling_lte?: string | null;
  crowdPooling_in?: string[] | null;
  crowdPooling_not_in?: string[] | null;
  crowdPooling_contains?: string | null;
  crowdPooling_not_contains?: string | null;
  crowdPooling_starts_with?: string | null;
  crowdPooling_not_starts_with?: string | null;
  crowdPooling_ends_with?: string | null;
  crowdPooling_not_ends_with?: string | null;
  investedQuote?: any | null;
  investedQuote_not?: any | null;
  investedQuote_gt?: any | null;
  investedQuote_lt?: any | null;
  investedQuote_gte?: any | null;
  investedQuote_lte?: any | null;
  investedQuote_in?: any[] | null;
  investedQuote_not_in?: any[] | null;
  canceledQuote?: any | null;
  canceledQuote_not?: any | null;
  canceledQuote_gt?: any | null;
  canceledQuote_lt?: any | null;
  canceledQuote_gte?: any | null;
  canceledQuote_lte?: any | null;
  canceledQuote_in?: any[] | null;
  canceledQuote_not_in?: any[] | null;
  investCount?: any | null;
  investCount_not?: any | null;
  investCount_gt?: any | null;
  investCount_lt?: any | null;
  investCount_gte?: any | null;
  investCount_lte?: any | null;
  investCount_in?: any[] | null;
  investCount_not_in?: any[] | null;
  newcome?: any | null;
  newcome_not?: any | null;
  newcome_gt?: any | null;
  newcome_lt?: any | null;
  newcome_gte?: any | null;
  newcome_lte?: any | null;
  newcome_in?: any[] | null;
  newcome_not_in?: any[] | null;
  poolQuote?: any | null;
  poolQuote_not?: any | null;
  poolQuote_gt?: any | null;
  poolQuote_lt?: any | null;
  poolQuote_gte?: any | null;
  poolQuote_lte?: any | null;
  poolQuote_in?: any[] | null;
  poolQuote_not_in?: any[] | null;
  investors?: any | null;
  investors_not?: any | null;
  investors_gt?: any | null;
  investors_lt?: any | null;
  investors_gte?: any | null;
  investors_lte?: any | null;
  investors_in?: any[] | null;
  investors_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface CrowdPooling_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  version?: string | null;
  version_not?: string | null;
  version_gt?: string | null;
  version_lt?: string | null;
  version_gte?: string | null;
  version_lte?: string | null;
  version_in?: string[] | null;
  version_not_in?: string[] | null;
  version_contains?: string | null;
  version_not_contains?: string | null;
  version_starts_with?: string | null;
  version_not_starts_with?: string | null;
  version_ends_with?: string | null;
  version_not_ends_with?: string | null;
  isOvercapStop?: string | null;
  isOvercapStop_not?: string | null;
  isOvercapStop_gt?: string | null;
  isOvercapStop_lt?: string | null;
  isOvercapStop_gte?: string | null;
  isOvercapStop_lte?: string | null;
  isOvercapStop_in?: string[] | null;
  isOvercapStop_not_in?: string[] | null;
  isOvercapStop_contains?: string | null;
  isOvercapStop_not_contains?: string | null;
  isOvercapStop_starts_with?: string | null;
  isOvercapStop_not_starts_with?: string | null;
  isOvercapStop_ends_with?: string | null;
  isOvercapStop_not_ends_with?: string | null;
  feeRate?: string | null;
  feeRate_not?: string | null;
  feeRate_gt?: string | null;
  feeRate_lt?: string | null;
  feeRate_gte?: string | null;
  feeRate_lte?: string | null;
  feeRate_in?: string[] | null;
  feeRate_not_in?: string[] | null;
  feeRate_contains?: string | null;
  feeRate_not_contains?: string | null;
  feeRate_starts_with?: string | null;
  feeRate_not_starts_with?: string | null;
  feeRate_ends_with?: string | null;
  feeRate_not_ends_with?: string | null;
  tokenCliffRate?: string | null;
  tokenCliffRate_not?: string | null;
  tokenCliffRate_gt?: string | null;
  tokenCliffRate_lt?: string | null;
  tokenCliffRate_gte?: string | null;
  tokenCliffRate_lte?: string | null;
  tokenCliffRate_in?: string[] | null;
  tokenCliffRate_not_in?: string[] | null;
  tokenCliffRate_contains?: string | null;
  tokenCliffRate_not_contains?: string | null;
  tokenCliffRate_starts_with?: string | null;
  tokenCliffRate_not_starts_with?: string | null;
  tokenCliffRate_ends_with?: string | null;
  tokenCliffRate_not_ends_with?: string | null;
  tokenClaimDuration?: string | null;
  tokenClaimDuration_not?: string | null;
  tokenClaimDuration_gt?: string | null;
  tokenClaimDuration_lt?: string | null;
  tokenClaimDuration_gte?: string | null;
  tokenClaimDuration_lte?: string | null;
  tokenClaimDuration_in?: string[] | null;
  tokenClaimDuration_not_in?: string[] | null;
  tokenClaimDuration_contains?: string | null;
  tokenClaimDuration_not_contains?: string | null;
  tokenClaimDuration_starts_with?: string | null;
  tokenClaimDuration_not_starts_with?: string | null;
  tokenClaimDuration_ends_with?: string | null;
  tokenClaimDuration_not_ends_with?: string | null;
  tokenVestingDuration?: string | null;
  tokenVestingDuration_not?: string | null;
  tokenVestingDuration_gt?: string | null;
  tokenVestingDuration_lt?: string | null;
  tokenVestingDuration_gte?: string | null;
  tokenVestingDuration_lte?: string | null;
  tokenVestingDuration_in?: string[] | null;
  tokenVestingDuration_not_in?: string[] | null;
  tokenVestingDuration_contains?: string | null;
  tokenVestingDuration_not_contains?: string | null;
  tokenVestingDuration_starts_with?: string | null;
  tokenVestingDuration_not_starts_with?: string | null;
  tokenVestingDuration_ends_with?: string | null;
  tokenVestingDuration_not_ends_with?: string | null;
  serialNumber?: any | null;
  serialNumber_not?: any | null;
  serialNumber_gt?: any | null;
  serialNumber_lt?: any | null;
  serialNumber_gte?: any | null;
  serialNumber_lte?: any | null;
  serialNumber_in?: any[] | null;
  serialNumber_not_in?: any[] | null;
  creator?: any | null;
  creator_not?: any | null;
  creator_in?: any[] | null;
  creator_not_in?: any[] | null;
  creator_contains?: any | null;
  creator_not_contains?: any | null;
  createTime?: any | null;
  createTime_not?: any | null;
  createTime_gt?: any | null;
  createTime_lt?: any | null;
  createTime_gte?: any | null;
  createTime_lte?: any | null;
  createTime_in?: any[] | null;
  createTime_not_in?: any[] | null;
  baseToken?: string | null;
  baseToken_not?: string | null;
  baseToken_gt?: string | null;
  baseToken_lt?: string | null;
  baseToken_gte?: string | null;
  baseToken_lte?: string | null;
  baseToken_in?: string[] | null;
  baseToken_not_in?: string[] | null;
  baseToken_contains?: string | null;
  baseToken_not_contains?: string | null;
  baseToken_starts_with?: string | null;
  baseToken_not_starts_with?: string | null;
  baseToken_ends_with?: string | null;
  baseToken_not_ends_with?: string | null;
  quoteToken?: string | null;
  quoteToken_not?: string | null;
  quoteToken_gt?: string | null;
  quoteToken_lt?: string | null;
  quoteToken_gte?: string | null;
  quoteToken_lte?: string | null;
  quoteToken_in?: string[] | null;
  quoteToken_not_in?: string[] | null;
  quoteToken_contains?: string | null;
  quoteToken_not_contains?: string | null;
  quoteToken_starts_with?: string | null;
  quoteToken_not_starts_with?: string | null;
  quoteToken_ends_with?: string | null;
  quoteToken_not_ends_with?: string | null;
  bidStartTime?: any | null;
  bidStartTime_not?: any | null;
  bidStartTime_gt?: any | null;
  bidStartTime_lt?: any | null;
  bidStartTime_gte?: any | null;
  bidStartTime_lte?: any | null;
  bidStartTime_in?: any[] | null;
  bidStartTime_not_in?: any[] | null;
  bidEndTime?: any | null;
  bidEndTime_not?: any | null;
  bidEndTime_gt?: any | null;
  bidEndTime_lt?: any | null;
  bidEndTime_gte?: any | null;
  bidEndTime_lte?: any | null;
  bidEndTime_in?: any[] | null;
  bidEndTime_not_in?: any[] | null;
  calmEndTime?: any | null;
  calmEndTime_not?: any | null;
  calmEndTime_gt?: any | null;
  calmEndTime_lt?: any | null;
  calmEndTime_gte?: any | null;
  calmEndTime_lte?: any | null;
  calmEndTime_in?: any[] | null;
  calmEndTime_not_in?: any[] | null;
  freezeDuration?: any | null;
  freezeDuration_not?: any | null;
  freezeDuration_gt?: any | null;
  freezeDuration_lt?: any | null;
  freezeDuration_gte?: any | null;
  freezeDuration_lte?: any | null;
  freezeDuration_in?: any[] | null;
  freezeDuration_not_in?: any[] | null;
  vestingDuration?: any | null;
  vestingDuration_not?: any | null;
  vestingDuration_gt?: any | null;
  vestingDuration_lt?: any | null;
  vestingDuration_gte?: any | null;
  vestingDuration_lte?: any | null;
  vestingDuration_in?: any[] | null;
  vestingDuration_not_in?: any[] | null;
  i?: any | null;
  i_not?: any | null;
  i_gt?: any | null;
  i_lt?: any | null;
  i_gte?: any | null;
  i_lte?: any | null;
  i_in?: any[] | null;
  i_not_in?: any[] | null;
  k?: any | null;
  k_not?: any | null;
  k_gt?: any | null;
  k_lt?: any | null;
  k_gte?: any | null;
  k_lte?: any | null;
  k_in?: any[] | null;
  k_not_in?: any[] | null;
  mtFeeRateModel?: any | null;
  mtFeeRateModel_not?: any | null;
  mtFeeRateModel_in?: any[] | null;
  mtFeeRateModel_not_in?: any[] | null;
  mtFeeRateModel_contains?: any | null;
  mtFeeRateModel_not_contains?: any | null;
  totalShares?: any | null;
  totalShares_not?: any | null;
  totalShares_gt?: any | null;
  totalShares_lt?: any | null;
  totalShares_gte?: any | null;
  totalShares_lte?: any | null;
  totalShares_in?: any[] | null;
  totalShares_not_in?: any[] | null;
  totalBase?: any | null;
  totalBase_not?: any | null;
  totalBase_gt?: any | null;
  totalBase_lt?: any | null;
  totalBase_gte?: any | null;
  totalBase_lte?: any | null;
  totalBase_in?: any[] | null;
  totalBase_not_in?: any[] | null;
  settled?: boolean | null;
  settled_not?: boolean | null;
  settled_in?: boolean[] | null;
  settled_not_in?: boolean[] | null;
  dvm?: string | null;
  dvm_not?: string | null;
  dvm_gt?: string | null;
  dvm_lt?: string | null;
  dvm_gte?: string | null;
  dvm_lte?: string | null;
  dvm_in?: string[] | null;
  dvm_not_in?: string[] | null;
  dvm_contains?: string | null;
  dvm_not_contains?: string | null;
  dvm_starts_with?: string | null;
  dvm_not_starts_with?: string | null;
  dvm_ends_with?: string | null;
  dvm_not_ends_with?: string | null;
  liquidator?: any | null;
  liquidator_not?: any | null;
  liquidator_in?: any[] | null;
  liquidator_not_in?: any[] | null;
  liquidator_contains?: any | null;
  liquidator_not_contains?: any | null;
  poolQuote?: any | null;
  poolQuote_not?: any | null;
  poolQuote_gt?: any | null;
  poolQuote_lt?: any | null;
  poolQuote_gte?: any | null;
  poolQuote_lte?: any | null;
  poolQuote_in?: any[] | null;
  poolQuote_not_in?: any[] | null;
  poolQuoteCap?: any | null;
  poolQuoteCap_not?: any | null;
  poolQuoteCap_gt?: any | null;
  poolQuoteCap_lt?: any | null;
  poolQuoteCap_gte?: any | null;
  poolQuoteCap_lte?: any | null;
  poolQuoteCap_in?: any[] | null;
  poolQuoteCap_not_in?: any[] | null;
  investorsCount?: any | null;
  investorsCount_not?: any | null;
  investorsCount_gt?: any | null;
  investorsCount_lt?: any | null;
  investorsCount_gte?: any | null;
  investorsCount_lte?: any | null;
  investorsCount_in?: any[] | null;
  investorsCount_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Crowd_poolingcrowdpoolingListFilter {
  chain?: string | null;
  address?: string | null;
  refreshNow?: boolean | null;
}

export interface Crowd_poolingcrowdpoolingUnvoteData {
  id?: number | null;
  account?: string | null;
  signature?: string | null;
  jwt?: string | null;
}

export interface Crowd_poolingcrowdpoolingVoteData {
  chainId?: number | null;
  account?: string | null;
  crowdpoolingAddress?: string | null;
  crowdpoolingCreator?: string | null;
  signature?: string | null;
  jwt?: string | null;
}

export interface Crowd_poolingcrowdpoolingVoteListFilter {
  chain?: string | null;
  accountAddress?: string | null;
  refreshNow?: boolean | null;
}

export interface Crowd_poolinglist_filter {
  top?: number | null;
  refreshNow?: boolean | null;
}

export interface Dashboardchain_daily_data_filter {
  day?: number | null;
  refreshNow?: boolean | null;
}

export interface Dashboardday_filter {
  pair_address: string;
  chain: string;
  day?: number | null;
  refreshNow?: boolean | null;
}

export interface Dashboardhour_filter {
  pair_address: string;
  chain: string;
  hour?: number | null;
  refreshNow?: boolean | null;
}

export interface Dashboardpair_detail_filter {
  chain: string;
  pair_address: string;
  refreshNow?: boolean | null;
}

export interface Dashboardrate24h_filter {
  network?: string | null;
  page?: number | null;
  limit?: number | null;
  order_by?: string | null;
  order_direction?: string | null;
  search?: string | null;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Dashboardsymbol_detail_filter {
  chain: string;
  keys: (string | null)[];
  order?: string | null;
  order_by?: string | null;
  refreshNow?: boolean | null;
}

export interface Dashboardtype_filter {
  chain?: string | null;
  type?: string | null;
  creator?: string | null;
  addresses?: (string | null)[] | null;
  refreshNow?: boolean | null;
}

export interface Dashboardtype_list_filter {
  chain: string;
  type?: string | null;
  page?: number | null;
  limit?: number | null;
  addresses?: (string | null)[] | null;
  creator?: string | null;
  refreshNow?: boolean | null;
}

export interface Dip_whitelistlistFilter {
  refreshNow?: boolean | null;
}

export interface DodoToken_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  tokens?: any | null;
  tokens_not?: any | null;
  tokens_gt?: any | null;
  tokens_lt?: any | null;
  tokens_gte?: any | null;
  tokens_lte?: any | null;
  tokens_in?: any[] | null;
  tokens_not_in?: any[] | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Dodo_app_versionnewFilter {
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Erc20listV2Filter {
  page?: number | null;
  pageSize?: number | null;
  chainId?: number | null;
  refreshNow?: boolean | null;
}

export interface Event_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  transaction?: string | null;
  transaction_not?: string | null;
  transaction_gt?: string | null;
  transaction_lt?: string | null;
  transaction_gte?: string | null;
  transaction_lte?: string | null;
  transaction_in?: string[] | null;
  transaction_not_in?: string[] | null;
  transaction_contains?: string | null;
  transaction_contains_nocase?: string | null;
  transaction_not_contains?: string | null;
  transaction_not_contains_nocase?: string | null;
  transaction_starts_with?: string | null;
  transaction_starts_with_nocase?: string | null;
  transaction_not_starts_with?: string | null;
  transaction_not_starts_with_nocase?: string | null;
  transaction_ends_with?: string | null;
  transaction_ends_with_nocase?: string | null;
  transaction_not_ends_with?: string | null;
  transaction_not_ends_with_nocase?: string | null;
  transaction_?: Transaction_filter | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Event_filter | null)[] | null;
  or?: (Event_filter | null)[] | null;
}

export interface Gas_feedergasPrices {
  chainId: number;
}

export interface Limit_and_rfqcreateLimitOrderInfo {
  makerToken?: string | null;
  takerToken?: string | null;
  makerAmount?: string | null;
  takerAmount?: string | null;
  maker?: string | null;
  taker?: string | null;
  expiration?: string | null;
  salt?: string | null;
}

export interface Limit_and_rfqgetPendingLimitOrderParam {
  address?: string | null;
}

export interface Limit_and_rfqlimitOrderAmountLimitParam {
  network?: string | null;
  token?: string | null;
}

export interface Limit_and_rfqtakerConfirmRfqOffer {
  orderId?: string | null;
  taker?: string | null;
  signature?: string | null;
}

export interface Limit_and_rfqtakerGetOrderListWithPage {
  network?: string | null;
  address?: string | null;
  page?: number | null;
  limit?: number | null;
}

export interface Limit_and_rfqtakerQueryRfqOffer {
  network?: string | null;
  makerToken?: string | null;
  takerToken?: string | null;
  takerAmount?: string | null;
}

export interface Limit_and_rfquserCancelLimitOrder {
  network?: string | null;
  address?: string | null;
  id?: string | null;
  signature?: string | null;
  signkey?: string | null;
  signtime?: number | null;
  signtype?: Limit_and_rfqlimitCancelSignType | null;
}

export interface Limit_and_rfquserCreateLimitOrder {
  order?: Limit_and_rfqcreateLimitOrderInfo | null;
  signature?: string | null;
  type?: string | null;
  network?: string | null;
}

export interface Limit_and_rfquserQueryLimitOrderListWithPage {
  network?: string | null;
  address?: string | null;
  orderType?: Limit_and_rfqlimitOrderQueryEnum | null;
  page?: number | null;
  limit?: number | null;
}

export interface Limit_and_rfquserRqFeeLimitQuery {
  network?: string | null;
  token?: string | null;
}

export interface LiquidityFilterState {
  filterASymbol?: string | null;
  filterBSymbol?: string | null;
  hideSmallPrice?: boolean | null;
  address?: string | null;
  viewOnlyOwn?: boolean | null;
  filterOutOwn?: boolean | null;
}

export interface LiquidityPosition_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  user?: string | null;
  user_not?: string | null;
  user_gt?: string | null;
  user_lt?: string | null;
  user_gte?: string | null;
  user_lte?: string | null;
  user_in?: string[] | null;
  user_not_in?: string[] | null;
  user_contains?: string | null;
  user_not_contains?: string | null;
  user_starts_with?: string | null;
  user_not_starts_with?: string | null;
  user_ends_with?: string | null;
  user_not_ends_with?: string | null;
  pair?: string | null;
  pair_not?: string | null;
  pair_gt?: string | null;
  pair_lt?: string | null;
  pair_gte?: string | null;
  pair_lte?: string | null;
  pair_in?: string[] | null;
  pair_not_in?: string[] | null;
  pair_contains?: string | null;
  pair_not_contains?: string | null;
  pair_starts_with?: string | null;
  pair_not_starts_with?: string | null;
  pair_ends_with?: string | null;
  pair_not_ends_with?: string | null;
  lpToken?: string | null;
  lpToken_not?: string | null;
  lpToken_gt?: string | null;
  lpToken_lt?: string | null;
  lpToken_gte?: string | null;
  lpToken_lte?: string | null;
  lpToken_in?: string[] | null;
  lpToken_not_in?: string[] | null;
  lpToken_contains?: string | null;
  lpToken_not_contains?: string | null;
  lpToken_starts_with?: string | null;
  lpToken_not_starts_with?: string | null;
  lpToken_ends_with?: string | null;
  lpToken_not_ends_with?: string | null;
  liquidityTokenBalance?: any | null;
  liquidityTokenBalance_not?: any | null;
  liquidityTokenBalance_gt?: any | null;
  liquidityTokenBalance_lt?: any | null;
  liquidityTokenBalance_gte?: any | null;
  liquidityTokenBalance_lte?: any | null;
  liquidityTokenBalance_in?: any[] | null;
  liquidityTokenBalance_not_in?: any[] | null;
  liquidityTokenInMining?: any | null;
  liquidityTokenInMining_not?: any | null;
  liquidityTokenInMining_gt?: any | null;
  liquidityTokenInMining_lt?: any | null;
  liquidityTokenInMining_gte?: any | null;
  liquidityTokenInMining_lte?: any | null;
  liquidityTokenInMining_in?: any[] | null;
  liquidityTokenInMining_not_in?: any[] | null;
  lastTxTime?: any | null;
  lastTxTime_not?: any | null;
  lastTxTime_gt?: any | null;
  lastTxTime_lt?: any | null;
  lastTxTime_gte?: any | null;
  lastTxTime_lte?: any | null;
  lastTxTime_in?: any[] | null;
  lastTxTime_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Liquiditycount_data_query {
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Liquiditylist_filter {
  chain: string;
  user?: string | null;
  currentPage?: number | null;
  pageSize?: number | null;
  filterState?: LiquidityFilterState | null;
  refreshNow?: boolean | null;
}

export interface Liquiditypool_apy_query {
  chain?: string | null;
  pools?: (string | null)[] | null;
  refreshNow?: boolean | null;
}

export interface Manage_serviceCautionTokensInput {
  network: number;
  tokens: string[];
}

export interface ManagechainFilter {
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface MinePool_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  timestamp?: string | null;
  timestamp_not?: string | null;
  timestamp_gt?: string | null;
  timestamp_lt?: string | null;
  timestamp_gte?: string | null;
  timestamp_lte?: string | null;
  timestamp_in?: string[] | null;
  timestamp_not_in?: string[] | null;
  timestamp_contains?: string | null;
  timestamp_not_contains?: string | null;
  timestamp_starts_with?: string | null;
  timestamp_not_starts_with?: string | null;
  timestamp_ends_with?: string | null;
  timestamp_not_ends_with?: string | null;
  creator?: string | null;
  creator_not?: string | null;
  creator_gt?: string | null;
  creator_lt?: string | null;
  creator_gte?: string | null;
  creator_lte?: string | null;
  creator_in?: string[] | null;
  creator_not_in?: string[] | null;
  creator_contains?: string | null;
  creator_not_contains?: string | null;
  creator_starts_with?: string | null;
  creator_not_starts_with?: string | null;
  creator_ends_with?: string | null;
  creator_not_ends_with?: string | null;
  pool?: any | null;
  pool_not?: any | null;
  pool_in?: any[] | null;
  pool_not_in?: any[] | null;
  pool_contains?: any | null;
  pool_not_contains?: any | null;
  stakeToken?: string | null;
  stakeToken_not?: string | null;
  stakeToken_gt?: string | null;
  stakeToken_lt?: string | null;
  stakeToken_gte?: string | null;
  stakeToken_lte?: string | null;
  stakeToken_in?: string[] | null;
  stakeToken_not_in?: string[] | null;
  stakeToken_contains?: string | null;
  stakeToken_not_contains?: string | null;
  stakeToken_starts_with?: string | null;
  stakeToken_not_starts_with?: string | null;
  stakeToken_ends_with?: string | null;
  stakeToken_not_ends_with?: string | null;
  isLpToken?: string | null;
  isLpToken_not?: string | null;
  isLpToken_gt?: string | null;
  isLpToken_lt?: string | null;
  isLpToken_gte?: string | null;
  isLpToken_lte?: string | null;
  isLpToken_in?: string[] | null;
  isLpToken_not_in?: string[] | null;
  isLpToken_contains?: string | null;
  isLpToken_not_contains?: string | null;
  isLpToken_starts_with?: string | null;
  isLpToken_not_starts_with?: string | null;
  isLpToken_ends_with?: string | null;
  isLpToken_not_ends_with?: string | null;
  platform?: string | null;
  platform_not?: string | null;
  platform_gt?: string | null;
  platform_lt?: string | null;
  platform_gte?: string | null;
  platform_lte?: string | null;
  platform_in?: string[] | null;
  platform_not_in?: string[] | null;
  platform_contains?: string | null;
  platform_not_contains?: string | null;
  platform_starts_with?: string | null;
  platform_not_starts_with?: string | null;
  platform_ends_with?: string | null;
  platform_not_ends_with?: string | null;
  rewardDetail?: string | null;
  rewardDetail_not?: string | null;
  rewardDetail_gt?: string | null;
  rewardDetail_lt?: string | null;
  rewardDetail_gte?: string | null;
  rewardDetail_lte?: string | null;
  rewardDetail_in?: string[] | null;
  rewardDetail_not_in?: string[] | null;
  rewardDetail_contains?: string | null;
  rewardDetail_not_contains?: string | null;
  rewardDetail_starts_with?: string | null;
  rewardDetail_not_starts_with?: string | null;
  rewardDetail_ends_with?: string | null;
  rewardDetail_not_ends_with?: string | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Nft_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  type?: string | null;
  type_not?: string | null;
  type_gt?: string | null;
  type_lt?: string | null;
  type_gte?: string | null;
  type_lte?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_contains?: string | null;
  type_contains_nocase?: string | null;
  type_not_contains?: string | null;
  type_not_contains_nocase?: string | null;
  type_starts_with?: string | null;
  type_starts_with_nocase?: string | null;
  type_not_starts_with?: string | null;
  type_not_starts_with_nocase?: string | null;
  type_ends_with?: string | null;
  type_ends_with_nocase?: string | null;
  type_not_ends_with?: string | null;
  type_not_ends_with_nocase?: string | null;
  address?: any | null;
  address_not?: any | null;
  address_gt?: any | null;
  address_lt?: any | null;
  address_gte?: any | null;
  address_lte?: any | null;
  address_in?: any[] | null;
  address_not_in?: any[] | null;
  address_contains?: any | null;
  address_not_contains?: any | null;
  uri?: string | null;
  uri_not?: string | null;
  uri_gt?: string | null;
  uri_lt?: string | null;
  uri_gte?: string | null;
  uri_lte?: string | null;
  uri_in?: string[] | null;
  uri_not_in?: string[] | null;
  uri_contains?: string | null;
  uri_contains_nocase?: string | null;
  uri_not_contains?: string | null;
  uri_not_contains_nocase?: string | null;
  uri_starts_with?: string | null;
  uri_starts_with_nocase?: string | null;
  uri_not_starts_with?: string | null;
  uri_not_starts_with_nocase?: string | null;
  uri_ends_with?: string | null;
  uri_ends_with_nocase?: string | null;
  uri_not_ends_with?: string | null;
  uri_not_ends_with_nocase?: string | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_gt?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_lte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Nft_filter | null)[] | null;
  or?: (Nft_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Notice_centernoticeCenterReadData {
  noticeIds?: (number | null)[] | null;
  user?: string | null;
  readAll?: boolean | null;
}

export interface Notice_centernoticeCenterUnreadFilter {
  user?: string | null;
  language?: string | null;
  refreshNow?: boolean | null;
}

export interface Notice_centersystemListFilter {
  page?: number | null;
  limit?: number | null;
  user?: string | null;
  isRead?: boolean | null;
  language?: string | null;
  refreshNow?: boolean | null;
}

export interface Notice_centertransactionListFilter {
  page?: number | null;
  limit?: number | null;
  user?: string | null;
  refreshNow?: boolean | null;
}

export interface Owner_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  tokens_?: Token_filter | null;
  numTokens?: any | null;
  numTokens_not?: any | null;
  numTokens_gt?: any | null;
  numTokens_lt?: any | null;
  numTokens_gte?: any | null;
  numTokens_lte?: any | null;
  numTokens_in?: any[] | null;
  numTokens_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Owner_filter | null)[] | null;
  or?: (Owner_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Pair_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  type?: string | null;
  type_not?: string | null;
  type_gt?: string | null;
  type_lt?: string | null;
  type_gte?: string | null;
  type_lte?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  source?: string | null;
  source_not?: string | null;
  source_gt?: string | null;
  source_lt?: string | null;
  source_gte?: string | null;
  source_lte?: string | null;
  source_in?: string[] | null;
  source_not_in?: string[] | null;
  source_contains?: string | null;
  source_not_contains?: string | null;
  source_starts_with?: string | null;
  source_not_starts_with?: string | null;
  source_ends_with?: string | null;
  source_not_ends_with?: string | null;
  creator?: any | null;
  creator_not?: any | null;
  creator_in?: any[] | null;
  creator_not_in?: any[] | null;
  creator_contains?: any | null;
  creator_not_contains?: any | null;
  baseToken?: string | null;
  baseToken_not?: string | null;
  baseToken_gt?: string | null;
  baseToken_lt?: string | null;
  baseToken_gte?: string | null;
  baseToken_lte?: string | null;
  baseToken_in?: string[] | null;
  baseToken_not_in?: string[] | null;
  baseToken_contains?: string | null;
  baseToken_not_contains?: string | null;
  baseToken_starts_with?: string | null;
  baseToken_not_starts_with?: string | null;
  baseToken_ends_with?: string | null;
  baseToken_not_ends_with?: string | null;
  quoteToken?: string | null;
  quoteToken_not?: string | null;
  quoteToken_gt?: string | null;
  quoteToken_lt?: string | null;
  quoteToken_gte?: string | null;
  quoteToken_lte?: string | null;
  quoteToken_in?: string[] | null;
  quoteToken_not_in?: string[] | null;
  quoteToken_contains?: string | null;
  quoteToken_not_contains?: string | null;
  quoteToken_starts_with?: string | null;
  quoteToken_not_starts_with?: string | null;
  quoteToken_ends_with?: string | null;
  quoteToken_not_ends_with?: string | null;
  baseSymbol?: string | null;
  baseSymbol_not?: string | null;
  baseSymbol_gt?: string | null;
  baseSymbol_lt?: string | null;
  baseSymbol_gte?: string | null;
  baseSymbol_lte?: string | null;
  baseSymbol_in?: string[] | null;
  baseSymbol_not_in?: string[] | null;
  baseSymbol_contains?: string | null;
  baseSymbol_not_contains?: string | null;
  baseSymbol_starts_with?: string | null;
  baseSymbol_not_starts_with?: string | null;
  baseSymbol_ends_with?: string | null;
  baseSymbol_not_ends_with?: string | null;
  quoteSymbol?: string | null;
  quoteSymbol_not?: string | null;
  quoteSymbol_gt?: string | null;
  quoteSymbol_lt?: string | null;
  quoteSymbol_gte?: string | null;
  quoteSymbol_lte?: string | null;
  quoteSymbol_in?: string[] | null;
  quoteSymbol_not_in?: string[] | null;
  quoteSymbol_contains?: string | null;
  quoteSymbol_not_contains?: string | null;
  quoteSymbol_starts_with?: string | null;
  quoteSymbol_not_starts_with?: string | null;
  quoteSymbol_ends_with?: string | null;
  quoteSymbol_not_ends_with?: string | null;
  i?: string | null;
  i_not?: string | null;
  i_gt?: string | null;
  i_lt?: string | null;
  i_gte?: string | null;
  i_lte?: string | null;
  i_in?: string[] | null;
  i_not_in?: string[] | null;
  i_contains?: string | null;
  i_not_contains?: string | null;
  i_starts_with?: string | null;
  i_not_starts_with?: string | null;
  i_ends_with?: string | null;
  i_not_ends_with?: string | null;
  k?: string | null;
  k_not?: string | null;
  k_gt?: string | null;
  k_lt?: string | null;
  k_gte?: string | null;
  k_lte?: string | null;
  k_in?: string[] | null;
  k_not_in?: string[] | null;
  k_contains?: string | null;
  k_not_contains?: string | null;
  k_starts_with?: string | null;
  k_not_starts_with?: string | null;
  k_ends_with?: string | null;
  k_not_ends_with?: string | null;
  lpFeeRate?: any | null;
  lpFeeRate_not?: any | null;
  lpFeeRate_gt?: any | null;
  lpFeeRate_lt?: any | null;
  lpFeeRate_gte?: any | null;
  lpFeeRate_lte?: any | null;
  lpFeeRate_in?: any[] | null;
  lpFeeRate_not_in?: any[] | null;
  baseLpToken?: string | null;
  baseLpToken_not?: string | null;
  baseLpToken_gt?: string | null;
  baseLpToken_lt?: string | null;
  baseLpToken_gte?: string | null;
  baseLpToken_lte?: string | null;
  baseLpToken_in?: string[] | null;
  baseLpToken_not_in?: string[] | null;
  baseLpToken_contains?: string | null;
  baseLpToken_not_contains?: string | null;
  baseLpToken_starts_with?: string | null;
  baseLpToken_not_starts_with?: string | null;
  baseLpToken_ends_with?: string | null;
  baseLpToken_not_ends_with?: string | null;
  quoteLpToken?: string | null;
  quoteLpToken_not?: string | null;
  quoteLpToken_gt?: string | null;
  quoteLpToken_lt?: string | null;
  quoteLpToken_gte?: string | null;
  quoteLpToken_lte?: string | null;
  quoteLpToken_in?: string[] | null;
  quoteLpToken_not_in?: string[] | null;
  quoteLpToken_contains?: string | null;
  quoteLpToken_not_contains?: string | null;
  quoteLpToken_starts_with?: string | null;
  quoteLpToken_not_starts_with?: string | null;
  quoteLpToken_ends_with?: string | null;
  quoteLpToken_not_ends_with?: string | null;
  baseReserve?: any | null;
  baseReserve_not?: any | null;
  baseReserve_gt?: any | null;
  baseReserve_lt?: any | null;
  baseReserve_gte?: any | null;
  baseReserve_lte?: any | null;
  baseReserve_in?: any[] | null;
  baseReserve_not_in?: any[] | null;
  quoteReserve?: any | null;
  quoteReserve_not?: any | null;
  quoteReserve_gt?: any | null;
  quoteReserve_lt?: any | null;
  quoteReserve_gte?: any | null;
  quoteReserve_lte?: any | null;
  quoteReserve_in?: any[] | null;
  quoteReserve_not_in?: any[] | null;
  lastTradePrice?: any | null;
  lastTradePrice_not?: any | null;
  lastTradePrice_gt?: any | null;
  lastTradePrice_lt?: any | null;
  lastTradePrice_gte?: any | null;
  lastTradePrice_lte?: any | null;
  lastTradePrice_in?: any[] | null;
  lastTradePrice_not_in?: any[] | null;
  untrackedBaseVolume?: any | null;
  untrackedBaseVolume_not?: any | null;
  untrackedBaseVolume_gt?: any | null;
  untrackedBaseVolume_lt?: any | null;
  untrackedBaseVolume_gte?: any | null;
  untrackedBaseVolume_lte?: any | null;
  untrackedBaseVolume_in?: any[] | null;
  untrackedBaseVolume_not_in?: any[] | null;
  untrackedQuoteVolume?: any | null;
  untrackedQuoteVolume_not?: any | null;
  untrackedQuoteVolume_gt?: any | null;
  untrackedQuoteVolume_lt?: any | null;
  untrackedQuoteVolume_gte?: any | null;
  untrackedQuoteVolume_lte?: any | null;
  untrackedQuoteVolume_in?: any[] | null;
  untrackedQuoteVolume_not_in?: any[] | null;
  volumeBaseToken?: any | null;
  volumeBaseToken_not?: any | null;
  volumeBaseToken_gt?: any | null;
  volumeBaseToken_lt?: any | null;
  volumeBaseToken_gte?: any | null;
  volumeBaseToken_lte?: any | null;
  volumeBaseToken_in?: any[] | null;
  volumeBaseToken_not_in?: any[] | null;
  volumeQuoteToken?: any | null;
  volumeQuoteToken_not?: any | null;
  volumeQuoteToken_gt?: any | null;
  volumeQuoteToken_lt?: any | null;
  volumeQuoteToken_gte?: any | null;
  volumeQuoteToken_lte?: any | null;
  volumeQuoteToken_in?: any[] | null;
  volumeQuoteToken_not_in?: any[] | null;
  volumeUSD?: any | null;
  volumeUSD_not?: any | null;
  volumeUSD_gt?: any | null;
  volumeUSD_lt?: any | null;
  volumeUSD_gte?: any | null;
  volumeUSD_lte?: any | null;
  volumeUSD_in?: any[] | null;
  volumeUSD_not_in?: any[] | null;
  feeBase?: any | null;
  feeBase_not?: any | null;
  feeBase_gt?: any | null;
  feeBase_lt?: any | null;
  feeBase_gte?: any | null;
  feeBase_lte?: any | null;
  feeBase_in?: any[] | null;
  feeBase_not_in?: any[] | null;
  feeQuote?: any | null;
  feeQuote_not?: any | null;
  feeQuote_gt?: any | null;
  feeQuote_lt?: any | null;
  feeQuote_gte?: any | null;
  feeQuote_lte?: any | null;
  feeQuote_in?: any[] | null;
  feeQuote_not_in?: any[] | null;
  feeUSD?: any | null;
  feeUSD_not?: any | null;
  feeUSD_gt?: any | null;
  feeUSD_lt?: any | null;
  feeUSD_gte?: any | null;
  feeUSD_lte?: any | null;
  feeUSD_in?: any[] | null;
  feeUSD_not_in?: any[] | null;
  txCount?: any | null;
  txCount_not?: any | null;
  txCount_gt?: any | null;
  txCount_lt?: any | null;
  txCount_gte?: any | null;
  txCount_lte?: any | null;
  txCount_in?: any[] | null;
  txCount_not_in?: any[] | null;
  createdAtTimestamp?: any | null;
  createdAtTimestamp_not?: any | null;
  createdAtTimestamp_gt?: any | null;
  createdAtTimestamp_lt?: any | null;
  createdAtTimestamp_gte?: any | null;
  createdAtTimestamp_lte?: any | null;
  createdAtTimestamp_in?: any[] | null;
  createdAtTimestamp_not_in?: any[] | null;
  createdAtBlockNumber?: any | null;
  createdAtBlockNumber_not?: any | null;
  createdAtBlockNumber_gt?: any | null;
  createdAtBlockNumber_lt?: any | null;
  createdAtBlockNumber_gte?: any | null;
  createdAtBlockNumber_lte?: any | null;
  createdAtBlockNumber_in?: any[] | null;
  createdAtBlockNumber_not_in?: any[] | null;
  liquidityProviderCount?: any | null;
  liquidityProviderCount_not?: any | null;
  liquidityProviderCount_gt?: any | null;
  liquidityProviderCount_lt?: any | null;
  liquidityProviderCount_gte?: any | null;
  liquidityProviderCount_lte?: any | null;
  liquidityProviderCount_in?: any[] | null;
  liquidityProviderCount_not_in?: any[] | null;
  mtFeeRateModel?: any | null;
  mtFeeRateModel_not?: any | null;
  mtFeeRateModel_in?: any[] | null;
  mtFeeRateModel_not_in?: any[] | null;
  mtFeeRateModel_contains?: any | null;
  mtFeeRateModel_not_contains?: any | null;
  maintainer?: any | null;
  maintainer_not?: any | null;
  maintainer_in?: any[] | null;
  maintainer_not_in?: any[] | null;
  maintainer_contains?: any | null;
  maintainer_not_contains?: any | null;
  mtFeeRate?: any | null;
  mtFeeRate_not?: any | null;
  mtFeeRate_gt?: any | null;
  mtFeeRate_lt?: any | null;
  mtFeeRate_gte?: any | null;
  mtFeeRate_lte?: any | null;
  mtFeeRate_in?: any[] | null;
  mtFeeRate_not_in?: any[] | null;
  mtFeeBase?: any | null;
  mtFeeBase_not?: any | null;
  mtFeeBase_gt?: any | null;
  mtFeeBase_lt?: any | null;
  mtFeeBase_gte?: any | null;
  mtFeeBase_lte?: any | null;
  mtFeeBase_in?: any[] | null;
  mtFeeBase_not_in?: any[] | null;
  mtFeeQuote?: any | null;
  mtFeeQuote_not?: any | null;
  mtFeeQuote_gt?: any | null;
  mtFeeQuote_lt?: any | null;
  mtFeeQuote_gte?: any | null;
  mtFeeQuote_lte?: any | null;
  mtFeeQuote_in?: any[] | null;
  mtFeeQuote_not_in?: any[] | null;
  mtFeeUSD?: any | null;
  mtFeeUSD_not?: any | null;
  mtFeeUSD_gt?: any | null;
  mtFeeUSD_lt?: any | null;
  mtFeeUSD_gte?: any | null;
  mtFeeUSD_lte?: any | null;
  mtFeeUSD_in?: any[] | null;
  mtFeeUSD_not_in?: any[] | null;
  traderCount?: any | null;
  traderCount_not?: any | null;
  traderCount_gt?: any | null;
  traderCount_lt?: any | null;
  traderCount_gte?: any | null;
  traderCount_lte?: any | null;
  traderCount_in?: any[] | null;
  traderCount_not_in?: any[] | null;
  isTradeAllowed?: boolean | null;
  isTradeAllowed_not?: boolean | null;
  isTradeAllowed_in?: boolean[] | null;
  isTradeAllowed_not_in?: boolean[] | null;
  isDepositBaseAllowed?: boolean | null;
  isDepositBaseAllowed_not?: boolean | null;
  isDepositBaseAllowed_in?: boolean[] | null;
  isDepositBaseAllowed_not_in?: boolean[] | null;
  isDepositQuoteAllowed?: boolean | null;
  isDepositQuoteAllowed_not?: boolean | null;
  isDepositQuoteAllowed_in?: boolean[] | null;
  isDepositQuoteAllowed_not_in?: boolean[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Swap_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  hash?: string | null;
  hash_not?: string | null;
  hash_gt?: string | null;
  hash_lt?: string | null;
  hash_gte?: string | null;
  hash_lte?: string | null;
  hash_in?: string[] | null;
  hash_not_in?: string[] | null;
  hash_contains?: string | null;
  hash_not_contains?: string | null;
  hash_starts_with?: string | null;
  hash_not_starts_with?: string | null;
  hash_ends_with?: string | null;
  hash_not_ends_with?: string | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  pair?: string | null;
  pair_not?: string | null;
  pair_gt?: string | null;
  pair_lt?: string | null;
  pair_gte?: string | null;
  pair_lte?: string | null;
  pair_in?: string[] | null;
  pair_not_in?: string[] | null;
  pair_contains?: string | null;
  pair_not_contains?: string | null;
  pair_starts_with?: string | null;
  pair_not_starts_with?: string | null;
  pair_ends_with?: string | null;
  pair_not_ends_with?: string | null;
  sender?: any | null;
  sender_not?: any | null;
  sender_in?: any[] | null;
  sender_not_in?: any[] | null;
  sender_contains?: any | null;
  sender_not_contains?: any | null;
  from?: any | null;
  from_not?: any | null;
  from_in?: any[] | null;
  from_not_in?: any[] | null;
  from_contains?: any | null;
  from_not_contains?: any | null;
  fromToken?: string | null;
  fromToken_not?: string | null;
  fromToken_gt?: string | null;
  fromToken_lt?: string | null;
  fromToken_gte?: string | null;
  fromToken_lte?: string | null;
  fromToken_in?: string[] | null;
  fromToken_not_in?: string[] | null;
  fromToken_contains?: string | null;
  fromToken_not_contains?: string | null;
  fromToken_starts_with?: string | null;
  fromToken_not_starts_with?: string | null;
  fromToken_ends_with?: string | null;
  fromToken_not_ends_with?: string | null;
  toToken?: string | null;
  toToken_not?: string | null;
  toToken_gt?: string | null;
  toToken_lt?: string | null;
  toToken_gte?: string | null;
  toToken_lte?: string | null;
  toToken_in?: string[] | null;
  toToken_not_in?: string[] | null;
  toToken_contains?: string | null;
  toToken_not_contains?: string | null;
  toToken_starts_with?: string | null;
  toToken_not_starts_with?: string | null;
  toToken_ends_with?: string | null;
  toToken_not_ends_with?: string | null;
  to?: any | null;
  to_not?: any | null;
  to_in?: any[] | null;
  to_not_in?: any[] | null;
  to_contains?: any | null;
  to_not_contains?: any | null;
  logIndex?: any | null;
  logIndex_not?: any | null;
  logIndex_gt?: any | null;
  logIndex_lt?: any | null;
  logIndex_gte?: any | null;
  logIndex_lte?: any | null;
  logIndex_in?: any[] | null;
  logIndex_not_in?: any[] | null;
  amountIn?: any | null;
  amountIn_not?: any | null;
  amountIn_gt?: any | null;
  amountIn_lt?: any | null;
  amountIn_gte?: any | null;
  amountIn_lte?: any | null;
  amountIn_in?: any[] | null;
  amountIn_not_in?: any[] | null;
  amountOut?: any | null;
  amountOut_not?: any | null;
  amountOut_gt?: any | null;
  amountOut_lt?: any | null;
  amountOut_gte?: any | null;
  amountOut_lte?: any | null;
  amountOut_in?: any[] | null;
  amountOut_not_in?: any[] | null;
  feeBase?: any | null;
  feeBase_not?: any | null;
  feeBase_gt?: any | null;
  feeBase_lt?: any | null;
  feeBase_gte?: any | null;
  feeBase_lte?: any | null;
  feeBase_in?: any[] | null;
  feeBase_not_in?: any[] | null;
  feeQuote?: any | null;
  feeQuote_not?: any | null;
  feeQuote_gt?: any | null;
  feeQuote_lt?: any | null;
  feeQuote_gte?: any | null;
  feeQuote_lte?: any | null;
  feeQuote_in?: any[] | null;
  feeQuote_not_in?: any[] | null;
  baseVolume?: any | null;
  baseVolume_not?: any | null;
  baseVolume_gt?: any | null;
  baseVolume_lt?: any | null;
  baseVolume_gte?: any | null;
  baseVolume_lte?: any | null;
  baseVolume_in?: any[] | null;
  baseVolume_not_in?: any[] | null;
  quoteVolume?: any | null;
  quoteVolume_not?: any | null;
  quoteVolume_gt?: any | null;
  quoteVolume_lt?: any | null;
  quoteVolume_gte?: any | null;
  quoteVolume_lte?: any | null;
  quoteVolume_in?: any[] | null;
  quoteVolume_not_in?: any[] | null;
  volumeUSD?: any | null;
  volumeUSD_not?: any | null;
  volumeUSD_gt?: any | null;
  volumeUSD_lt?: any | null;
  volumeUSD_gte?: any | null;
  volumeUSD_lte?: any | null;
  volumeUSD_in?: any[] | null;
  volumeUSD_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Third_party_tokenlistData {
  chainId?: number | null;
  fromNames?: (string | null)[] | null;
}

export interface TokenContract_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  name?: string | null;
  name_not?: string | null;
  name_gt?: string | null;
  name_lt?: string | null;
  name_gte?: string | null;
  name_lte?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_contains_nocase?: string | null;
  name_not_contains?: string | null;
  name_not_contains_nocase?: string | null;
  name_starts_with?: string | null;
  name_starts_with_nocase?: string | null;
  name_not_starts_with?: string | null;
  name_not_starts_with_nocase?: string | null;
  name_ends_with?: string | null;
  name_ends_with_nocase?: string | null;
  name_not_ends_with?: string | null;
  name_not_ends_with_nocase?: string | null;
  symbol?: string | null;
  symbol_not?: string | null;
  symbol_gt?: string | null;
  symbol_lt?: string | null;
  symbol_gte?: string | null;
  symbol_lte?: string | null;
  symbol_in?: string[] | null;
  symbol_not_in?: string[] | null;
  symbol_contains?: string | null;
  symbol_contains_nocase?: string | null;
  symbol_not_contains?: string | null;
  symbol_not_contains_nocase?: string | null;
  symbol_starts_with?: string | null;
  symbol_starts_with_nocase?: string | null;
  symbol_not_starts_with?: string | null;
  symbol_not_starts_with_nocase?: string | null;
  symbol_ends_with?: string | null;
  symbol_ends_with_nocase?: string | null;
  symbol_not_ends_with?: string | null;
  symbol_not_ends_with_nocase?: string | null;
  doAllAddressesOwnTheirIdByDefault?: boolean | null;
  doAllAddressesOwnTheirIdByDefault_not?: boolean | null;
  doAllAddressesOwnTheirIdByDefault_in?: boolean[] | null;
  doAllAddressesOwnTheirIdByDefault_not_in?: boolean[] | null;
  supportsEIP721Metadata?: boolean | null;
  supportsEIP721Metadata_not?: boolean | null;
  supportsEIP721Metadata_in?: boolean[] | null;
  supportsEIP721Metadata_not_in?: boolean[] | null;
  tokens_?: Token_filter | null;
  numTokens?: any | null;
  numTokens_not?: any | null;
  numTokens_gt?: any | null;
  numTokens_lt?: any | null;
  numTokens_gte?: any | null;
  numTokens_lte?: any | null;
  numTokens_in?: any[] | null;
  numTokens_not_in?: any[] | null;
  numOwners?: any | null;
  numOwners_not?: any | null;
  numOwners_gt?: any | null;
  numOwners_lt?: any | null;
  numOwners_gte?: any | null;
  numOwners_lte?: any | null;
  numOwners_in?: any[] | null;
  numOwners_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (TokenContract_filter | null)[] | null;
  or?: (TokenContract_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface TokenRegistry_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  tokens_?: Token_filter | null;
  _change_block?: BlockChangedFilter | null;
  and?: (TokenRegistry_filter | null)[] | null;
  or?: (TokenRegistry_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Token_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  contract?: string | null;
  contract_not?: string | null;
  contract_gt?: string | null;
  contract_lt?: string | null;
  contract_gte?: string | null;
  contract_lte?: string | null;
  contract_in?: string[] | null;
  contract_not_in?: string[] | null;
  contract_contains?: string | null;
  contract_contains_nocase?: string | null;
  contract_not_contains?: string | null;
  contract_not_contains_nocase?: string | null;
  contract_starts_with?: string | null;
  contract_starts_with_nocase?: string | null;
  contract_not_starts_with?: string | null;
  contract_not_starts_with_nocase?: string | null;
  contract_ends_with?: string | null;
  contract_ends_with_nocase?: string | null;
  contract_not_ends_with?: string | null;
  contract_not_ends_with_nocase?: string | null;
  contract_?: TokenContract_filter | null;
  tokenID?: any | null;
  tokenID_not?: any | null;
  tokenID_gt?: any | null;
  tokenID_lt?: any | null;
  tokenID_gte?: any | null;
  tokenID_lte?: any | null;
  tokenID_in?: any[] | null;
  tokenID_not_in?: any[] | null;
  owner?: string | null;
  owner_not?: string | null;
  owner_gt?: string | null;
  owner_lt?: string | null;
  owner_gte?: string | null;
  owner_lte?: string | null;
  owner_in?: string[] | null;
  owner_not_in?: string[] | null;
  owner_contains?: string | null;
  owner_contains_nocase?: string | null;
  owner_not_contains?: string | null;
  owner_not_contains_nocase?: string | null;
  owner_starts_with?: string | null;
  owner_starts_with_nocase?: string | null;
  owner_not_starts_with?: string | null;
  owner_not_starts_with_nocase?: string | null;
  owner_ends_with?: string | null;
  owner_ends_with_nocase?: string | null;
  owner_not_ends_with?: string | null;
  owner_not_ends_with_nocase?: string | null;
  owner_?: Owner_filter | null;
  mintTime?: any | null;
  mintTime_not?: any | null;
  mintTime_gt?: any | null;
  mintTime_lt?: any | null;
  mintTime_gte?: any | null;
  mintTime_lte?: any | null;
  mintTime_in?: any[] | null;
  mintTime_not_in?: any[] | null;
  tokenURI?: string | null;
  tokenURI_not?: string | null;
  tokenURI_gt?: string | null;
  tokenURI_lt?: string | null;
  tokenURI_gte?: string | null;
  tokenURI_lte?: string | null;
  tokenURI_in?: string[] | null;
  tokenURI_not_in?: string[] | null;
  tokenURI_contains?: string | null;
  tokenURI_contains_nocase?: string | null;
  tokenURI_not_contains?: string | null;
  tokenURI_not_contains_nocase?: string | null;
  tokenURI_starts_with?: string | null;
  tokenURI_starts_with_nocase?: string | null;
  tokenURI_not_starts_with?: string | null;
  tokenURI_not_starts_with_nocase?: string | null;
  tokenURI_ends_with?: string | null;
  tokenURI_ends_with_nocase?: string | null;
  tokenURI_not_ends_with?: string | null;
  tokenURI_not_ends_with_nocase?: string | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Token_filter | null)[] | null;
  or?: (Token_filter | null)[] | null;
  registry?: string | null;
  registry_not?: string | null;
  registry_gt?: string | null;
  registry_lt?: string | null;
  registry_gte?: string | null;
  registry_lte?: string | null;
  registry_in?: string[] | null;
  registry_not_in?: string[] | null;
  registry_contains?: string | null;
  registry_contains_nocase?: string | null;
  registry_not_contains?: string | null;
  registry_not_contains_nocase?: string | null;
  registry_starts_with?: string | null;
  registry_starts_with_nocase?: string | null;
  registry_not_starts_with?: string | null;
  registry_not_starts_with_nocase?: string | null;
  registry_ends_with?: string | null;
  registry_ends_with_nocase?: string | null;
  registry_not_ends_with?: string | null;
  registry_not_ends_with_nocase?: string | null;
  registry_?: TokenRegistry_filter | null;
  identifier?: any | null;
  identifier_not?: any | null;
  identifier_gt?: any | null;
  identifier_lt?: any | null;
  identifier_gte?: any | null;
  identifier_lte?: any | null;
  identifier_in?: any[] | null;
  identifier_not_in?: any[] | null;
  URI?: string | null;
  URI_not?: string | null;
  URI_gt?: string | null;
  URI_lt?: string | null;
  URI_gte?: string | null;
  URI_lte?: string | null;
  URI_in?: string[] | null;
  URI_not_in?: string[] | null;
  URI_contains?: string | null;
  URI_contains_nocase?: string | null;
  URI_not_contains?: string | null;
  URI_not_contains_nocase?: string | null;
  URI_starts_with?: string | null;
  URI_starts_with_nocase?: string | null;
  URI_not_starts_with?: string | null;
  URI_not_starts_with_nocase?: string | null;
  URI_ends_with?: string | null;
  URI_ends_with_nocase?: string | null;
  URI_not_ends_with?: string | null;
  URI_not_ends_with_nocase?: string | null;
  totalSupply?: any | null;
  totalSupply_not?: any | null;
  totalSupply_gt?: any | null;
  totalSupply_lt?: any | null;
  totalSupply_gte?: any | null;
  totalSupply_lte?: any | null;
  totalSupply_in?: any[] | null;
  totalSupply_not_in?: any[] | null;
  balances_?: Balance_filter | null;
  transfers_?: Transfer_filter | null;
  approvals_?: Transfer_filter | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  untrackedVolume?: any | null;
  untrackedVolume_not?: any | null;
  untrackedVolume_gt?: any | null;
  untrackedVolume_lt?: any | null;
  untrackedVolume_gte?: any | null;
  untrackedVolume_lte?: any | null;
  untrackedVolume_in?: any[] | null;
  untrackedVolume_not_in?: any[] | null;
  tradeVolume?: any | null;
  tradeVolume_not?: any | null;
  tradeVolume_gt?: any | null;
  tradeVolume_lt?: any | null;
  tradeVolume_gte?: any | null;
  tradeVolume_lte?: any | null;
  tradeVolume_in?: any[] | null;
  tradeVolume_not_in?: any[] | null;
  tradeVolumeBridge?: any | null;
  tradeVolumeBridge_not?: any | null;
  tradeVolumeBridge_gt?: any | null;
  tradeVolumeBridge_lt?: any | null;
  tradeVolumeBridge_gte?: any | null;
  tradeVolumeBridge_lte?: any | null;
  tradeVolumeBridge_in?: any[] | null;
  tradeVolumeBridge_not_in?: any[] | null;
  volumeUSD?: any | null;
  volumeUSD_not?: any | null;
  volumeUSD_gt?: any | null;
  volumeUSD_lt?: any | null;
  volumeUSD_gte?: any | null;
  volumeUSD_lte?: any | null;
  volumeUSD_in?: any[] | null;
  volumeUSD_not_in?: any[] | null;
  volumeUSDBridge?: any | null;
  volumeUSDBridge_not?: any | null;
  volumeUSDBridge_gt?: any | null;
  volumeUSDBridge_lt?: any | null;
  volumeUSDBridge_gte?: any | null;
  volumeUSDBridge_lte?: any | null;
  volumeUSDBridge_in?: any[] | null;
  volumeUSDBridge_not_in?: any[] | null;
  txCount?: any | null;
  txCount_not?: any | null;
  txCount_gt?: any | null;
  txCount_lt?: any | null;
  txCount_gte?: any | null;
  txCount_lte?: any | null;
  txCount_in?: any[] | null;
  txCount_not_in?: any[] | null;
  traderCount?: any | null;
  traderCount_not?: any | null;
  traderCount_gt?: any | null;
  traderCount_lt?: any | null;
  traderCount_gte?: any | null;
  traderCount_lte?: any | null;
  traderCount_in?: any[] | null;
  traderCount_not_in?: any[] | null;
  totalLiquidityOnDODO?: any | null;
  totalLiquidityOnDODO_not?: any | null;
  totalLiquidityOnDODO_gt?: any | null;
  totalLiquidityOnDODO_lt?: any | null;
  totalLiquidityOnDODO_gte?: any | null;
  totalLiquidityOnDODO_lte?: any | null;
  totalLiquidityOnDODO_in?: any[] | null;
  totalLiquidityOnDODO_not_in?: any[] | null;
  usdPrice?: any | null;
  usdPrice_not?: any | null;
  usdPrice_gt?: any | null;
  usdPrice_lt?: any | null;
  usdPrice_gte?: any | null;
  usdPrice_lte?: any | null;
  usdPrice_in?: any[] | null;
  usdPrice_not_in?: any[] | null;
  priceUpdateTimestamp?: any | null;
  priceUpdateTimestamp_not?: any | null;
  priceUpdateTimestamp_gt?: any | null;
  priceUpdateTimestamp_lt?: any | null;
  priceUpdateTimestamp_gte?: any | null;
  priceUpdateTimestamp_lte?: any | null;
  priceUpdateTimestamp_in?: any[] | null;
  priceUpdateTimestamp_not_in?: any[] | null;
  symbol?: string | null;
  symbol_not?: string | null;
  symbol_gt?: string | null;
  symbol_lt?: string | null;
  symbol_gte?: string | null;
  symbol_lte?: string | null;
  symbol_in?: string[] | null;
  symbol_not_in?: string[] | null;
  symbol_contains?: string | null;
  symbol_not_contains?: string | null;
  symbol_starts_with?: string | null;
  symbol_not_starts_with?: string | null;
  symbol_ends_with?: string | null;
  symbol_not_ends_with?: string | null;
  name?: string | null;
  name_not?: string | null;
  name_gt?: string | null;
  name_lt?: string | null;
  name_gte?: string | null;
  name_lte?: string | null;
  name_in?: string[] | null;
  name_not_in?: string[] | null;
  name_contains?: string | null;
  name_not_contains?: string | null;
  name_starts_with?: string | null;
  name_not_starts_with?: string | null;
  name_ends_with?: string | null;
  name_not_ends_with?: string | null;
  decimals?: any | null;
  decimals_not?: any | null;
  decimals_gt?: any | null;
  decimals_lt?: any | null;
  decimals_gte?: any | null;
  decimals_lte?: any | null;
  decimals_in?: any[] | null;
  decimals_not_in?: any[] | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
  creator?: string | null;
  creator_not?: string | null;
  creator_gt?: string | null;
  creator_lt?: string | null;
  creator_gte?: string | null;
  creator_lte?: string | null;
  creator_in?: string[] | null;
  creator_not_in?: string[] | null;
  creator_contains?: string | null;
  creator_not_contains?: string | null;
  creator_starts_with?: string | null;
  creator_not_starts_with?: string | null;
  creator_ends_with?: string | null;
  creator_not_ends_with?: string | null;
  type?: any | null;
  type_not?: any | null;
  type_gt?: any | null;
  type_lt?: any | null;
  type_gte?: any | null;
  type_lte?: any | null;
  type_in?: any[] | null;
  type_not_in?: any[] | null;
  holderCount?: any | null;
  holderCount_not?: any | null;
  holderCount_gt?: any | null;
  holderCount_lt?: any | null;
  holderCount_gte?: any | null;
  holderCount_lte?: any | null;
  holderCount_in?: any[] | null;
  holderCount_not_in?: any[] | null;
}

export interface Transaction_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  blockNumber?: any | null;
  blockNumber_not?: any | null;
  blockNumber_gt?: any | null;
  blockNumber_lt?: any | null;
  blockNumber_gte?: any | null;
  blockNumber_lte?: any | null;
  blockNumber_in?: any[] | null;
  blockNumber_not_in?: any[] | null;
  events_?: Event_filter | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Transaction_filter | null)[] | null;
  or?: (Transaction_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  from?: string | null;
  from_not?: string | null;
  from_gt?: string | null;
  from_lt?: string | null;
  from_gte?: string | null;
  from_lte?: string | null;
  from_in?: string[] | null;
  from_not_in?: string[] | null;
  from_contains?: string | null;
  from_not_contains?: string | null;
  from_starts_with?: string | null;
  from_not_starts_with?: string | null;
  from_ends_with?: string | null;
  from_not_ends_with?: string | null;
  to?: string | null;
  to_not?: string | null;
  to_gt?: string | null;
  to_lt?: string | null;
  to_gte?: string | null;
  to_lte?: string | null;
  to_in?: string[] | null;
  to_not_in?: string[] | null;
  to_contains?: string | null;
  to_not_contains?: string | null;
  to_starts_with?: string | null;
  to_not_starts_with?: string | null;
  to_ends_with?: string | null;
  to_not_ends_with?: string | null;
  sender?: string | null;
  sender_not?: string | null;
  sender_gt?: string | null;
  sender_lt?: string | null;
  sender_gte?: string | null;
  sender_lte?: string | null;
  sender_in?: string[] | null;
  sender_not_in?: string[] | null;
  sender_contains?: string | null;
  sender_not_contains?: string | null;
  sender_starts_with?: string | null;
  sender_not_starts_with?: string | null;
  sender_ends_with?: string | null;
  sender_not_ends_with?: string | null;
  type?: string | null;
  type_not?: string | null;
  type_gt?: string | null;
  type_lt?: string | null;
  type_gte?: string | null;
  type_lte?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  tokens?: string | null;
  tokens_not?: string | null;
  tokens_gt?: string | null;
  tokens_lt?: string | null;
  tokens_gte?: string | null;
  tokens_lte?: string | null;
  tokens_in?: string[] | null;
  tokens_not_in?: string[] | null;
  tokens_contains?: string | null;
  tokens_not_contains?: string | null;
  tokens_starts_with?: string | null;
  tokens_not_starts_with?: string | null;
  tokens_ends_with?: string | null;
  tokens_not_ends_with?: string | null;
  volumeUSD?: any | null;
  volumeUSD_not?: any | null;
  volumeUSD_gt?: any | null;
  volumeUSD_lt?: any | null;
  volumeUSD_gte?: any | null;
  volumeUSD_lte?: any | null;
  volumeUSD_in?: any[] | null;
  volumeUSD_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface Transfer_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  transaction?: string | null;
  transaction_not?: string | null;
  transaction_gt?: string | null;
  transaction_lt?: string | null;
  transaction_gte?: string | null;
  transaction_lte?: string | null;
  transaction_in?: string[] | null;
  transaction_not_in?: string[] | null;
  transaction_contains?: string | null;
  transaction_contains_nocase?: string | null;
  transaction_not_contains?: string | null;
  transaction_not_contains_nocase?: string | null;
  transaction_starts_with?: string | null;
  transaction_starts_with_nocase?: string | null;
  transaction_not_starts_with?: string | null;
  transaction_not_starts_with_nocase?: string | null;
  transaction_ends_with?: string | null;
  transaction_ends_with_nocase?: string | null;
  transaction_not_ends_with?: string | null;
  transaction_not_ends_with_nocase?: string | null;
  transaction_?: Transaction_filter | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  token?: string | null;
  token_not?: string | null;
  token_gt?: string | null;
  token_lt?: string | null;
  token_gte?: string | null;
  token_lte?: string | null;
  token_in?: string[] | null;
  token_not_in?: string[] | null;
  token_contains?: string | null;
  token_contains_nocase?: string | null;
  token_not_contains?: string | null;
  token_not_contains_nocase?: string | null;
  token_starts_with?: string | null;
  token_starts_with_nocase?: string | null;
  token_not_starts_with?: string | null;
  token_not_starts_with_nocase?: string | null;
  token_ends_with?: string | null;
  token_ends_with_nocase?: string | null;
  token_not_ends_with?: string | null;
  token_not_ends_with_nocase?: string | null;
  token_?: Token_filter | null;
  operator?: string | null;
  operator_not?: string | null;
  operator_gt?: string | null;
  operator_lt?: string | null;
  operator_gte?: string | null;
  operator_lte?: string | null;
  operator_in?: string[] | null;
  operator_not_in?: string[] | null;
  operator_contains?: string | null;
  operator_contains_nocase?: string | null;
  operator_not_contains?: string | null;
  operator_not_contains_nocase?: string | null;
  operator_starts_with?: string | null;
  operator_starts_with_nocase?: string | null;
  operator_not_starts_with?: string | null;
  operator_not_starts_with_nocase?: string | null;
  operator_ends_with?: string | null;
  operator_ends_with_nocase?: string | null;
  operator_not_ends_with?: string | null;
  operator_not_ends_with_nocase?: string | null;
  operator_?: Account_filter | null;
  from?: string | null;
  from_not?: string | null;
  from_gt?: string | null;
  from_lt?: string | null;
  from_gte?: string | null;
  from_lte?: string | null;
  from_in?: string[] | null;
  from_not_in?: string[] | null;
  from_contains?: string | null;
  from_contains_nocase?: string | null;
  from_not_contains?: string | null;
  from_not_contains_nocase?: string | null;
  from_starts_with?: string | null;
  from_starts_with_nocase?: string | null;
  from_not_starts_with?: string | null;
  from_not_starts_with_nocase?: string | null;
  from_ends_with?: string | null;
  from_ends_with_nocase?: string | null;
  from_not_ends_with?: string | null;
  from_not_ends_with_nocase?: string | null;
  from_?: Account_filter | null;
  fromBalance?: string | null;
  fromBalance_not?: string | null;
  fromBalance_gt?: string | null;
  fromBalance_lt?: string | null;
  fromBalance_gte?: string | null;
  fromBalance_lte?: string | null;
  fromBalance_in?: string[] | null;
  fromBalance_not_in?: string[] | null;
  fromBalance_contains?: string | null;
  fromBalance_contains_nocase?: string | null;
  fromBalance_not_contains?: string | null;
  fromBalance_not_contains_nocase?: string | null;
  fromBalance_starts_with?: string | null;
  fromBalance_starts_with_nocase?: string | null;
  fromBalance_not_starts_with?: string | null;
  fromBalance_not_starts_with_nocase?: string | null;
  fromBalance_ends_with?: string | null;
  fromBalance_ends_with_nocase?: string | null;
  fromBalance_not_ends_with?: string | null;
  fromBalance_not_ends_with_nocase?: string | null;
  fromBalance_?: Balance_filter | null;
  to?: string | null;
  to_not?: string | null;
  to_gt?: string | null;
  to_lt?: string | null;
  to_gte?: string | null;
  to_lte?: string | null;
  to_in?: string[] | null;
  to_not_in?: string[] | null;
  to_contains?: string | null;
  to_contains_nocase?: string | null;
  to_not_contains?: string | null;
  to_not_contains_nocase?: string | null;
  to_starts_with?: string | null;
  to_starts_with_nocase?: string | null;
  to_not_starts_with?: string | null;
  to_not_starts_with_nocase?: string | null;
  to_ends_with?: string | null;
  to_ends_with_nocase?: string | null;
  to_not_ends_with?: string | null;
  to_not_ends_with_nocase?: string | null;
  to_?: Account_filter | null;
  toBalance?: string | null;
  toBalance_not?: string | null;
  toBalance_gt?: string | null;
  toBalance_lt?: string | null;
  toBalance_gte?: string | null;
  toBalance_lte?: string | null;
  toBalance_in?: string[] | null;
  toBalance_not_in?: string[] | null;
  toBalance_contains?: string | null;
  toBalance_contains_nocase?: string | null;
  toBalance_not_contains?: string | null;
  toBalance_not_contains_nocase?: string | null;
  toBalance_starts_with?: string | null;
  toBalance_starts_with_nocase?: string | null;
  toBalance_not_starts_with?: string | null;
  toBalance_not_starts_with_nocase?: string | null;
  toBalance_ends_with?: string | null;
  toBalance_ends_with_nocase?: string | null;
  toBalance_not_ends_with?: string | null;
  toBalance_not_ends_with_nocase?: string | null;
  toBalance_?: Balance_filter | null;
  value?: any | null;
  value_not?: any | null;
  value_gt?: any | null;
  value_lt?: any | null;
  value_gte?: any | null;
  value_lte?: any | null;
  value_in?: any[] | null;
  value_not_in?: any[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (Transfer_filter | null)[] | null;
  or?: (Transfer_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface UserOperationHistory_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  type?: string | null;
  type_not?: string | null;
  type_gt?: string | null;
  type_lt?: string | null;
  type_gte?: string | null;
  type_lte?: string | null;
  type_in?: string[] | null;
  type_not_in?: string[] | null;
  type_contains?: string | null;
  type_not_contains?: string | null;
  type_starts_with?: string | null;
  type_not_starts_with?: string | null;
  type_ends_with?: string | null;
  type_not_ends_with?: string | null;
  user?: string | null;
  user_not?: string | null;
  user_gt?: string | null;
  user_lt?: string | null;
  user_gte?: string | null;
  user_lte?: string | null;
  user_in?: string[] | null;
  user_not_in?: string[] | null;
  user_contains?: string | null;
  user_not_contains?: string | null;
  user_starts_with?: string | null;
  user_not_starts_with?: string | null;
  user_ends_with?: string | null;
  user_not_ends_with?: string | null;
  amount?: any | null;
  amount_not?: any | null;
  amount_gt?: any | null;
  amount_lt?: any | null;
  amount_gte?: any | null;
  amount_lte?: any | null;
  amount_in?: any[] | null;
  amount_not_in?: any[] | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  superiorSpChange?: any | null;
  superiorSpChange_not?: any | null;
  superiorSpChange_gt?: any | null;
  superiorSpChange_lt?: any | null;
  superiorSpChange_gte?: any | null;
  superiorSpChange_lte?: any | null;
  superiorSpChange_in?: any[] | null;
  superiorSpChange_not_in?: any[] | null;
  superiorCreditChange?: any | null;
  superiorCreditChange_not?: any | null;
  superiorCreditChange_gt?: any | null;
  superiorCreditChange_lt?: any | null;
  superiorCreditChange_gte?: any | null;
  superiorCreditChange_lte?: any | null;
  superiorCreditChange_in?: any[] | null;
  superiorCreditChange_not_in?: any[] | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface UserStake_filter {
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  user?: any | null;
  user_not?: any | null;
  user_in?: any[] | null;
  user_not_in?: any[] | null;
  user_contains?: any | null;
  user_not_contains?: any | null;
  pool?: any | null;
  pool_not?: any | null;
  pool_in?: any[] | null;
  pool_not_in?: any[] | null;
  pool_contains?: any | null;
  pool_not_contains?: any | null;
  balance?: any | null;
  balance_not?: any | null;
  balance_gt?: any | null;
  balance_lt?: any | null;
  balance_gte?: any | null;
  balance_lte?: any | null;
  balance_in?: any[] | null;
  balance_not_in?: any[] | null;
  updatedAt?: any | null;
  updatedAt_not?: any | null;
  updatedAt_gt?: any | null;
  updatedAt_lt?: any | null;
  updatedAt_gte?: any | null;
  updatedAt_lte?: any | null;
  updatedAt_in?: any[] | null;
  updatedAt_not_in?: any[] | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
}

export interface User_filter {
  id?: string | null;
  id_not?: string | null;
  id_gt?: string | null;
  id_lt?: string | null;
  id_gte?: string | null;
  id_lte?: string | null;
  id_in?: string[] | null;
  id_not_in?: string[] | null;
  createdAt?: any | null;
  createdAt_not?: any | null;
  createdAt_gt?: any | null;
  createdAt_lt?: any | null;
  createdAt_gte?: any | null;
  createdAt_lte?: any | null;
  createdAt_in?: any[] | null;
  createdAt_not_in?: any[] | null;
  updatedAt?: string | null;
  updatedAt_not?: string | null;
  updatedAt_gt?: string | null;
  updatedAt_lt?: string | null;
  updatedAt_gte?: string | null;
  updatedAt_lte?: string | null;
  updatedAt_in?: string[] | null;
  updatedAt_not_in?: string[] | null;
  _change_block?: BlockChangedFilter | null;
  and?: (User_filter | null)[] | null;
  or?: (User_filter | null)[] | null;
  chain?: string | null;
  chain_not?: string | null;
  chain_gt?: string | null;
  chain_lt?: string | null;
  chain_gte?: string | null;
  chain_lte?: string | null;
  chain_in?: string[] | null;
  chain_not_in?: string[] | null;
  chain_contains?: string | null;
  chain_not_contains?: string | null;
  chain_starts_with?: string | null;
  chain_not_starts_with?: string | null;
  chain_ends_with?: string | null;
  chain_not_ends_with?: string | null;
  txCount?: any | null;
  txCount_not?: any | null;
  txCount_gt?: any | null;
  txCount_lt?: any | null;
  txCount_gte?: any | null;
  txCount_lte?: any | null;
  txCount_in?: any[] | null;
  txCount_not_in?: any[] | null;
  tradingRewardRecieved?: any | null;
  tradingRewardRecieved_not?: any | null;
  tradingRewardRecieved_gt?: any | null;
  tradingRewardRecieved_lt?: any | null;
  tradingRewardRecieved_gte?: any | null;
  tradingRewardRecieved_lte?: any | null;
  tradingRewardRecieved_in?: any[] | null;
  tradingRewardRecieved_not_in?: any[] | null;
  liquidityPositions?: string | null;
  liquidityPositions_not?: string | null;
  liquidityPositions_gt?: string | null;
  liquidityPositions_lt?: string | null;
  liquidityPositions_gte?: string | null;
  liquidityPositions_lte?: string | null;
  liquidityPositions_in?: string[] | null;
  liquidityPositions_not_in?: string[] | null;
  liquidityPositions_contains?: string | null;
  liquidityPositions_not_contains?: string | null;
  liquidityPositions_starts_with?: string | null;
  liquidityPositions_not_starts_with?: string | null;
  liquidityPositions_ends_with?: string | null;
  liquidityPositions_not_ends_with?: string | null;
  timestamp?: any | null;
  timestamp_not?: any | null;
  timestamp_gt?: any | null;
  timestamp_lt?: any | null;
  timestamp_gte?: any | null;
  timestamp_lte?: any | null;
  timestamp_in?: any[] | null;
  timestamp_not_in?: any[] | null;
  updatedAt_contains?: string | null;
  updatedAt_not_contains?: string | null;
  updatedAt_starts_with?: string | null;
  updatedAt_not_starts_with?: string | null;
  updatedAt_ends_with?: string | null;
  updatedAt_not_ends_with?: string | null;
  schemaName?: string | null;
  refreshNow?: boolean | null;
  stakingPower?: any | null;
  stakingPower_not?: any | null;
  stakingPower_gt?: any | null;
  stakingPower_lt?: any | null;
  stakingPower_gte?: any | null;
  stakingPower_lte?: any | null;
  stakingPower_in?: any[] | null;
  stakingPower_not_in?: any[] | null;
  superiorSP?: any | null;
  superiorSP_not?: any | null;
  superiorSP_gt?: any | null;
  superiorSP_lt?: any | null;
  superiorSP_gte?: any | null;
  superiorSP_lte?: any | null;
  superiorSP_in?: any[] | null;
  superiorSP_not_in?: any[] | null;
  superior?: any | null;
  superior_not?: any | null;
  superior_in?: any[] | null;
  superior_not_in?: any[] | null;
  superior_contains?: any | null;
  superior_not_contains?: any | null;
  credit?: any | null;
  credit_not?: any | null;
  credit_gt?: any | null;
  credit_lt?: any | null;
  credit_gte?: any | null;
  credit_lte?: any | null;
  credit_in?: any[] | null;
  credit_not_in?: any[] | null;
  creditOfSuperior?: any | null;
  creditOfSuperior_not?: any | null;
  creditOfSuperior_gt?: any | null;
  creditOfSuperior_lt?: any | null;
  creditOfSuperior_gte?: any | null;
  creditOfSuperior_lte?: any | null;
  creditOfSuperior_in?: any[] | null;
  creditOfSuperior_not_in?: any[] | null;
  spFromInvited?: any | null;
  spFromInvited_not?: any | null;
  spFromInvited_gt?: any | null;
  spFromInvited_lt?: any | null;
  spFromInvited_gte?: any | null;
  spFromInvited_lte?: any | null;
  spFromInvited_in?: any[] | null;
  spFromInvited_not_in?: any[] | null;
  creditFromInvited?: any | null;
  creditFromInvited_not?: any | null;
  creditFromInvited_gt?: any | null;
  creditFromInvited_lt?: any | null;
  creditFromInvited_gte?: any | null;
  creditFromInvited_lte?: any | null;
  creditFromInvited_in?: any[] | null;
  creditFromInvited_not_in?: any[] | null;
  mintAmount?: any | null;
  mintAmount_not?: any | null;
  mintAmount_gt?: any | null;
  mintAmount_lt?: any | null;
  mintAmount_gte?: any | null;
  mintAmount_lte?: any | null;
  mintAmount_in?: any[] | null;
  mintAmount_not_in?: any[] | null;
  redeemRecieveAmount?: any | null;
  redeemRecieveAmount_not?: any | null;
  redeemRecieveAmount_gt?: any | null;
  redeemRecieveAmount_lt?: any | null;
  redeemRecieveAmount_gte?: any | null;
  redeemRecieveAmount_lte?: any | null;
  redeemRecieveAmount_in?: any[] | null;
  redeemRecieveAmount_not_in?: any[] | null;
  redeemFeeAmount?: any | null;
  redeemFeeAmount_not?: any | null;
  redeemFeeAmount_gt?: any | null;
  redeemFeeAmount_lt?: any | null;
  redeemFeeAmount_gte?: any | null;
  redeemFeeAmount_lte?: any | null;
  redeemFeeAmount_in?: any[] | null;
  redeemFeeAmount_not_in?: any[] | null;
  redeemBurnAmount?: any | null;
  redeemBurnAmount_not?: any | null;
  redeemBurnAmount_gt?: any | null;
  redeemBurnAmount_lt?: any | null;
  redeemBurnAmount_gte?: any | null;
  redeemBurnAmount_lte?: any | null;
  redeemBurnAmount_in?: any[] | null;
  redeemBurnAmount_not_in?: any[] | null;
  tokens?: string | null;
  tokens_not?: string | null;
  tokens_gt?: string | null;
  tokens_lt?: string | null;
  tokens_gte?: string | null;
  tokens_lte?: string | null;
  tokens_in?: string[] | null;
  tokens_not_in?: string[] | null;
  tokens_contains?: string | null;
  tokens_not_contains?: string | null;
  tokens_starts_with?: string | null;
  tokens_not_starts_with?: string | null;
  tokens_ends_with?: string | null;
  tokens_not_ends_with?: string | null;
}

export interface User_swapswapFilter {
  page?: number | null;
  limit?: number | null;
  userAddress?: string | null;
  chainId?: number | null;
  refreshNow?: boolean | null;
}

export interface UserprofileAsset_filter {
  user: string;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface UserprofileReward_filter {
  user: string;
  chain?: string | null;
  refreshNow?: boolean | null;
}

export interface Userprofileprivate_pools_list_filter {
  chain: string;
  user: string;
  currentPage?: number | null;
  pageSize?: number | null;
}

export interface VdodoChainsInput {
  chains: number[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================
