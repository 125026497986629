import { ApolloClient, from, InMemoryCache, ApolloLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { logRequestDuration } from '@dodoex/mixpanel';
// import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import {
  ApolloGQLClientDevEndpoint,
  ApolloGQLClientEndpoint,
} from '@dodoex/utils';
import { isEqual, uniqWith } from 'lodash';
import { useMemo } from 'react';
import { customApolloFetch } from './utils';

// 解决sentry报错 ‘The user aborted a request’ 参考： https://github.com/apollographql/apollo-client/issues/6769
interface FetchOptions {
  signal?: AbortSignal;
}
const fetchOptions: FetchOptions = {};
if (AbortController) {
  const abortController = new AbortController();
  fetchOptions.signal = abortController.signal;
}

const retryGraphQLErrorOperations = ['FetchTokenListWidthSlippage'];

// Log any GraphQL errors or network error that occurred
// https://www.apollographql.com/docs/react/data/error-handling#on-graphql-errors
const errorLink = onError(
  // eslint-disable-next-line consistent-return
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
      if (retryGraphQLErrorOperations.includes(operation.operationName)) {
        // 如果 GraphQL 错误类型是语法或校验错误，apollo server 会返回 4xx 的网络错误，这种错误已经在 RetryLink 重试过了，不需要在这里再重试
        // https://www.apollographql.com/docs/react/data/error-handling#graphql-errors
        if (
          !(
            networkError &&
            (
              networkError as {
                statusCode: number;
              }
            ).statusCode >= 400
          )
        ) {
          // Retry the request, returning the new observable
          return forward(operation);
        }
      }
    }
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`);
    }
  },
);

export const useClient = ({
  isDev,
  accessToken,
}: {
  isDev?: boolean;
  accessToken?: string;
}) => {
  const uri = isDev ? ApolloGQLClientDevEndpoint : ApolloGQLClientEndpoint;
  const client = useMemo(() => {
    // https://www.apollographql.com/docs/react/api/link/apollo-link-batch-http
    const httpLink = new BatchHttpLink({
      uri,
      fetch: customApolloFetch,
      batchMax: 5,
      headers: accessToken
        ? {
            'access-token': accessToken,
          }
        : {},
      ...fetchOptions,
    });

    // 用于mixpanel记录
    const timeStartLink = new ApolloLink((operation, forward) => {
      operation.setContext({ start: performance.now() });
      return forward(operation);
    });
    const logTimeLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((data) => {
        // data from a previous link
        const time = performance.now() - operation.getContext().start;
        const duration = parseInt(String(time), 10);
        logRequestDuration(
          `${uri}?opname=${operation.operationName}`,
          Number(duration),
        );
        return data;
      });
    });

    // 这段代码是等待token 获取到之后再请求，但是现在后端放开了 token 的限制，所以先注释掉这段代码
    // https://www.apollographql.com/docs/react/api/link/apollo-link-context/#overview
    // const asyncAuthLink = setContext((operation, { headers }) => {
    //   return new Promise((resolve) => {
    //     if (accessToken) {
    //       resolve({
    //         headers: {
    //           ...headers,
    //           'access-token': accessToken,
    //         },
    //       });
    //     }
    //   });
    // });
    // https://www.apollographql.com/docs/react/data/error-handling#retrying-operations
    // errorLink 用于重试 graphql 的报错：这种报错通常需要后端主动处理，重试一次即可
    // RetryLink 用于重试网络错误：间隔不同时间重试，指数退避算法
    const link = from([
      timeStartLink,
      logTimeLink,
      // asyncAuthLink,
      errorLink,
      new RetryLink({
        attempts: {
          max: 5,
          retryIf(error) {
            // 429 被 rate-limit 了，不需要重试
            if (
              error &&
              (
                error as {
                  statusCode: number;
                }
              ).statusCode === 429
            ) {
              return false;
            }

            return !!error;
          },
        },
      }),
      // new RetryLink(),
      httpLink,
    ]);
    return new ApolloClient({
      link,
      // cache: new InMemoryCache(),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              aggregateFragments: {
                // https://www.apollographql.com/docs/react/pagination/key-args/
                // Don't cache separate results based on
                // any of this field's arguments.
                // first 参数相同的查询结果分为一组
                keyArgs: ['first'],
                // keyArgs: false,
                // Concatenate the incoming list items with
                // the existing list items.
                merge(existing = [], incoming, { args }) {
                  // 只有碎片市场界面有 loadMore 效果，参数中包含 timestamp_lt
                  if (args && args.where && args.where.timestamp_lt) {
                    return uniqWith([...existing, ...incoming], isEqual);
                  }
                  return incoming;
                },
              },
            },
          },
        },
      }),
      defaultOptions: {
        query: {
          variables: {
            where: {
              chain: 'bsc',
            },
          },
        },
      },
    });
  }, [accessToken, isDev]);

  return client;
};
