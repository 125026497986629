import BigNumber from 'bignumber.js';
import { Map, OrderedMap, Set } from 'immutable';
import { AnyAction } from 'redux';
import { ImmutableMap, Token, TokenList, Tokens } from '../types';

export type FetchStatus = 'loading' | 'complete' | 'failed';

export type TokenBalances = Map<string, BigNumber>; // key: tokenAddress
export type TokenAllowances = Map<string, BigNumber>; // key: tokenAddress
export type AccountBalance = ImmutableMap<{
  ethBalance?: BigNumber;
  tokenBalances?: TokenBalances;
  tokenAllowances?: Map<string, TokenAllowances>; // key: proxyAddress
}>;

export type State = ImmutableMap<{
  accountBalances: Map<string, AccountBalance>; // key: accountAddress
  accountChainBalances: Map<string, AccountBalance>; // key: `${accountAddress}_${chain}`
  balanceLoadings: Map<string, boolean>;
  /** 不区分 chain（因为 address 可能重复，所以不用 Tokens 类型） */
  fullTokens: Token[];
  tokens: Tokens;
  customTokens: Tokens;
  // 单个导入的第三方 token
  importTokens: Tokens;
  tokenLists: TokenList[];
  tokenListsStatus: FetchStatus;
  checkTokenAddresses: Set<string>;
}>;

export const initialState: State = Map({
  accountBalances: Map(),
  accountChainBalances: Map(),
  balanceLoadings: Map(),
  endpoints: Map(),
  fullTokens: [],
  tokens: OrderedMap(),
  customTokens: OrderedMap(),
  importTokens: OrderedMap(),
  tokenLists: [],
  tokenListsStatus: 'loading',
  checkTokenAddresses: Set(),
}) as State;

export default (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'SET_FULL_TOKENS':
      return state.mergeDeepIn(['fullTokens'], action.payload.tokens);
    case 'SET_TOKENS':
      return state.mergeDeepIn(['tokens'], action.payload.tokens);
    case 'SET_TOKEN_LISTS':
      return state.set('tokenLists', action.payload.tokenLists);
    case 'SET_TOKEN_LISTS_STATUS':
      return state.set('tokenListsStatus', action.payload.tokenListsStatus);
    case 'SET_CHECK_TOKEN_ADDRESSES':
      return state.mergeDeepIn(
        ['checkTokenAddresses'],
        action.payload.tokenAddresses,
      );
    case 'SET_CUSTOM_TOKENS':
      return state.mergeDeepIn(['customTokens'], action.payload.tokens);
    case 'SET_IMPORT_TOKENS':
      return state.mergeDeepIn(['importTokens'], action.payload.tokens);
    case 'CLEAR_ALL_CUSTOM_TOKENS':
      return state.set('customTokens', OrderedMap());
    case 'REMOVE_CUSTOM_TOKENS':
      return state.set(
        'customTokens',
        state.get('customTokens').remove(action.payload.token.get('address')),
      );

    case 'SET_ETH_BALANCE':
      return state.setIn(
        ['accountBalances', action.payload.account, 'ethBalance'],
        action.payload.balance,
      );
    case 'SET_TOKEN_BALANCES':
      return state.mergeDeepIn(
        ['accountBalances', action.payload.account, 'tokenBalances'],
        action.payload.tokenBalances,
      );
    case 'SET_TOKEN_CHAIN_BALANCES':
      return state.mergeDeepIn(
        ['accountChainBalances', action.payload.accountChain, 'tokenBalances'],
        action.payload.tokenBalances,
      );
    case 'SET_TOKEN_ALLOWANCES':
      return state.mergeDeepIn(
        [
          'accountBalances',
          action.payload.account,
          'tokenAllowances',
          action.payload.proxyAddress,
        ],
        action.payload.tokenAllowances,
      );
    case 'SET_BALANCE_LOADINGS':
      return state.mergeDeepIn(
        ['balanceLoadings'],
        action.payload.balanceLoadings,
      );
    default:
      return state;
  }
};
