import { gql } from '@apollo/client';

export const fetchNoticeCenterTransactionList = gql`
  query FetchNoticeCenterTransactionList(
    $where: Notice_centertransactionListFilter
  ) {
    notice_center_transactionList(where: $where) {
      list {
        chainId
        createTime
        extend
        from
        id
        key
        type
      }
      count
    }
  }
`;

export const fetchNoticeCenterSystemList = gql`
  query FetchNoticeCenterSystemList($where: Notice_centersystemListFilter) {
    notice_center_systemList(where: $where) {
      count
      list {
        content
        createTime
        extend
        id
        isRead
        language
        title
      }
    }
  }
`;

export const fetchNoticeCenterRead = gql`
  query FetchNoticeCenterRead($data: Notice_centernoticeCenterReadData) {
    notice_center_systemRead(data: $data) {
      success
    }
  }
`;

export const fetchNoticeUnread = gql`
  query FetchNoticeUnread($where: Notice_centernoticeCenterUnreadFilter) {
    notice_center_unread(where: $where) {
      systemCount
    }
  }
`;
