// import Fortmatic from 'fortmatic';
import { dbGet, dbSet } from '@dodoex/utils';
import { SafeAppWeb3Modal } from '@gnosis.pm/safe-apps-web3modal';
import { connector as uAuthConnector, getUAuth } from '@uauth/web3modal';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { CHAIN_DATA_LIST, IProviderOptions } from 'web3modal';
import { EIP712Message } from '@ledgerhq/hw-app-eth/lib/modules/EIP712';
import {
  logWallet,
  logWalletType,
  WalletEventLabel,
} from '@dodoex/mixpanel/dist/wallet';
import {
  setCurrentAccountType,
  setBlockNumber,
  setChainMismatchNotice,
  setCurrentAccount,
  setETHBlockNumber,
} from './actions';
import { loadBlockNumber, loadETHBlockNumber } from './batchV2';
import { getChainConfig, ValidChainIds } from './chainConfigs';
import CoinbaseLogo from './components/images/logos/coinbase.svg';
import { mixpanel, store, walletConfig } from './core';
import { ChainType } from './injects/chain/types';
import {
  getIsOpenBlockIframe,
  getWalletAppNameByType,
  WalletType,
} from './injects/wallet';
import { ConnectToWalletConnect } from './injects/wallet/walletConnect';
import {
  IS_LEDGER_LIVE_SEARCH,
  QUIT_CONNECT,
  setLastChainId,
} from './localstorage';
import { AccountType, ChainIdSource } from './reducer';
import LedgerWebProvider, {
  ILedgerProviderOptions,
  signEIP712Message,
} from './ledger/provider';
import {
  getCurrentAccountType,
  getCurrentAccount,
  getCurrentChainId,
  getCurrentChainIdAndSrc,
  getUpdateTimestamp,
  prepareChainEndpointUrl,
} from './selectors';
import {
  getChainIdWithNetworkName,
  getLayer1ChainIdForLayer2,
  getNetworkNameWithChainId,
  isETHOrBSCChain,
} from './utils';
import { logNetworkId } from './utils/mixpanel';
import { getLedgerUSBConnectInfo } from './db/ledgerUSBConnectInfo';
import {
  getInjectProvider,
  injectProviderOptions,
  ProviderConnector,
} from './injects/wallet/injectProvider';
import { UAuth } from './injects/wallet/constant';
import contractWalletABI from './abis/contractWalletABI';
import { getAccountType } from './wallet';

declare global {
  interface Window {
    BinanceChain: any;
    NaboxWallet: any;
  }
}
export let web3: Web3 | null;
let provider: any;
let Portis: any | null;
let WalletConnectProvider: any | null;
let WalletLinkProvider: any | null;
let LedgerProvider: any | null;
let isInjectedOpenBlockSDK = false;
let isInjectedOpenBlockIframeSDK = false;
let UAuthProvider: any | null;
// 用来避免重复发起连接
let isConnecting = false;
export let uAuth: any | undefined;
const cachedContracts: Map<any, Map<string, Contract>> = new Map();
export let web3Modal: SafeAppWeb3Modal;

let isSupportAllChain = false;

export const initIsSupportAllChain = (flag: boolean) => {
  if (flag) {
    isSupportAllChain = true;
  }
};

const search = new URLSearchParams(window.self.location.search);
if (search.get('embed')) {
  try {
    localStorage.setItem(IS_LEDGER_LIVE_SEARCH, '1');
  } catch (error) {
    sessionStorage.setItem(IS_LEDGER_LIVE_SEARCH, '1');
  }
}
const isLedgerSearchParams =
  localStorage.getItem(IS_LEDGER_LIVE_SEARCH) ||
  sessionStorage.getItem(IS_LEDGER_LIVE_SEARCH);
export const isLedger = isLedgerSearchParams && window.self !== window.top;

export type ProviderPackageOptions = Partial<ILedgerProviderOptions>;

export const initWeb3Modal = async (
  network?: ChainType,
  providerPackageOptions: ProviderPackageOptions = {},
) => {
  const rpc: { [key: number]: string } = {};
  ValidChainIds.map((validChainId) => {
    rpc[validChainId] = getChainConfig(validChainId).defaultEndpointUrl;
  });

  const chainId = network
    ? getChainIdWithNetworkName(network)
    : getCurrentChainId();
  console.log('[Init Web3 Modal] chainId', chainId);
  const providerOptions: IProviderOptions = injectProviderOptions();

  if (WalletConnectProvider) {
    providerOptions[WalletType.WalletConnect] = {
      display: {},
      package: WalletConnectProvider.default, // required
      options: { rpc, chainId, network: CHAIN_DATA_LIST[chainId]?.network },
      connector: ConnectToWalletConnect,
    };
  }

  if (WalletLinkProvider) {
    providerOptions['custom-walletlink'] = {
      package: WalletLinkProvider.default, // required
      options: {
        appName: 'Coinbase',
        chainId,
        rpc,
      },
      display: {
        logo: CoinbaseLogo,
        name: 'Coinbase Wallet',
        description: 'Scan with WalletLink to connect',
      },
      connector: async (_, options) => {
        const { appName, rpc, chainId } = options;
        const walletLink = new WalletLinkProvider.default({
          appName,
          appLogoUrl: CoinbaseLogo,
          darkMode: false,
        });
        const provider = walletLink.makeWeb3Provider(rpc, chainId);
        await provider.enable();
        return provider;
      },
    };
  }

  if (window && window.BinanceChain) {
    providerOptions['custom-bsc-injected'] = {
      display: {},
      package: window && window.BinanceChain,
      options: {},
      connector: async (ProviderPackage, options) => {
        await ProviderPackage.enable();
        return ProviderPackage;
      },
    };
  }

  if (walletConfig.PORTIS_ID && Portis) {
    providerOptions.portis = {
      package: Portis.default, // required
      options: {
        id: walletConfig.PORTIS_ID, // required
      },
    };
  }

  providerOptions[WalletType.LedgerUSB] = {
    display: {},
    // package: Web3.providers.HttpProvider,
    package: LedgerWebProvider,
    options: {},
    connector: async (ProviderPackage) => {
      const p = await new ProviderPackage({
        rpcUrl: rpc[chainId],
        chainId,
        ...providerPackageOptions,
      });
      return p;
    },
  };
  if (LedgerProvider) {
    providerOptions['custom-ledger'] = {
      display: {
        name: 'Ledger',
      },
      package: LedgerProvider.IFrameEthereumProvider,
      options: {},
      connector: async (ProviderPackage, options) => {
        const p = await new ProviderPackage(options);
        p.enable();
        return p;
      },
    };
  }

  if (isInjectedOpenBlockIframeSDK) {
    providerOptions[WalletType.openBlockIframe] = {
      options: {},
      connector: ProviderConnector,
      display: {
        name: 'OpenBlock',
      },
      package:
        getInjectProvider(WalletType.openBlockIframe) || window.obethereum,
    };
  }

  if (isInjectedOpenBlockSDK) {
    providerOptions[WalletType.openBlock] = {
      options: {},
      connector: ProviderConnector,
      display: {
        name: 'OpenBlock',
      },
      package: getInjectProvider(WalletType.openBlock) || window.ethereum,
    };
  }
  if (UAuthProvider) {
    const options = {
      clientID: 'fdad0caf-42d8-47e6-9e43-4d100d9c5484',
      redirectUri: window.location.origin,
    };
    providerOptions[WalletType.uAuth] = {
      display: {
        name: UAuth.showName,
      },
      package: UAuthProvider,
      options,
      connector: async (ProviderPackage) => {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
          if (isConnecting) return;
          isConnecting = true;
          try {
            const p = await uAuthConnector(ProviderPackage, options);
            isConnecting = false;
            uAuth = getUAuth(ProviderPackage, options);
            resolve(p);
          } catch (error) {
            isConnecting = false;
          }
        });
      },
    };
  }

  web3Modal = new SafeAppWeb3Modal({
    network: walletConfig.ETH_NETWORK, // optional
    cacheProvider: true, // optional
    providerOptions,
  });
};

export const detectBscInjectedWallet = () => {
  if (window && window.BinanceChain) {
    initWeb3Modal();
    if (web3Modal.cachedProvider === WalletType.BinanceChain) {
      connectToWallet(web3Modal.cachedProvider);
    }
    return;
  }
  setTimeout(detectBscInjectedWallet, 1000);
};

export const detectPortisInjectedWallet = async () => {
  if (walletConfig.PORTIS_ID) {
    Portis = await import(/* webpackChunkName: "portisWeb3" */ '@portis/web3');
    initWeb3Modal();
  }
};

export const detectWalletconnectWallet = async (network?: ChainType) => {
  WalletConnectProvider = await import(
    /* webpackChunkName: "walletconnect" */ '@walletconnect/web3-provider'
  );
  initWeb3Modal(network);
};

export const detectWalletLinkWallet = async (network?: ChainType) => {
  // has extension
  if (
    window.ethereum &&
    (window.ethereum.isWalletLink || window.ethereum.isToshi)
  )
    return;

  WalletLinkProvider = await import(
    /* webpackChunkName: "walletlink" */ '@coinbase/wallet-sdk'
  );
  initWeb3Modal(network);
};

export const detectLedgerWallet = async (network?: ChainType) => {
  if (isLedger) {
    LedgerProvider = await import(
      /* webpackChunkName: "ledger" */ '@ledgerhq/iframe-provider'
    );

    initWeb3Modal(network);
  }
};

export const detectOpenBlockWallet = async (network?: ChainType) => {
  await import(
    // @ts-ignore
    // eslint-disable-next-line import/no-unresolved
    /* webpackChunkName: "openBlockSDK" */ '@openblockhq/dappsdk'
  );
  isInjectedOpenBlockSDK = true;
  initWeb3Modal(network);
};

export const detectOpenBlockIframeWallet = async (network?: ChainType) => {
  const openBlockIframeSDK = (
    await import(
      // @ts-ignore
      // eslint-disable-next-line import/no-unresolved
      /* webpackChunkName: "openBlockIframeSDK" */ './lib/openBlock/inpage'
    )
  ).default;
  openBlockIframeSDK();
  isInjectedOpenBlockIframeSDK = true;
  await initWeb3Modal(network);
};

export const detectUAuthInjectedWallet = async () => {
  UAuthProvider = (await import(/* webpackChunkName: "uAuth" */ '@uauth/js'))
    .default;
  initWeb3Modal();
};

export const connectToWallet = async (
  type: WalletType,
  network?: ChainType,
  providerPackageOptions?: ProviderPackageOptions,
) => {
  console.log('[Connect to wallet] type:', type);
  if (type === WalletType.Portis) {
    await detectPortisInjectedWallet();
  }
  if (type === WalletType.WalletConnect) {
    await detectWalletconnectWallet(network);
  }
  if (type === WalletType.WalletLink) {
    await detectWalletLinkWallet(network);
  }
  if (providerPackageOptions) {
    await initWeb3Modal(network, providerPackageOptions);
  } else {
    // 因为某些浏览器插件注入可能慢一步，点击连接的时候再调用 initWeb3Modal 中的 injectProviderOptions 重置一下 provider
    initWeb3Modal();
  }
  if (type === WalletType.Ledger) {
    await detectLedgerWallet(network);
  }
  if (type === WalletType.openBlock) {
    await detectOpenBlockWallet(network);
  }
  if (type === WalletType.openBlockIframe) {
    await detectOpenBlockIframeWallet(network);
  }
  if (type === WalletType.uAuth) {
    await detectUAuthInjectedWallet();
  }

  if (type === WalletType.Gnosis) {
    provider = await web3Modal.requestProvider();
  } else {
    provider = await web3Modal.connectTo(type);
  }
  dbSet({
    path: QUIT_CONNECT,
    value: '0',
  });
  bindProviderEvent(provider);
  web3 = new Web3(provider);
  if (network) {
    const isBitKeep = navigator.userAgent.indexOf('BitKeep') > -1;
    let search = `?network=${network}`;
    const currentSearch = new URLSearchParams(window.self.location.search);
    if (currentSearch.get('embed')) {
      search += '&embed=true';
    }
    if (isBitKeep) {
      const chainId = getChainIdWithNetworkName(network);
      const _needChain =
        getChainConfig(chainId).basicToken.symbol.toLowerCase();
      search = `?network=${network}&_needChain=${_needChain}`;
    }
    window.location.search = search;
    return;
  }
  const accounts = await web3.eth.getAccounts();
  const chainId = await web3.eth.getChainId();
  logWalletType(getWalletAppNameByType(type));
  logNetworkId(chainId);
  onWeb3Connect(accounts[0]);
};

export async function getConnectedWalletType() {
  if (typeof window !== 'undefined' && window.top !== window) {
    const isSafeApp = await web3Modal.isSafeApp();
    if (isSafeApp) return WalletType.Gnosis;
  }
  return web3Modal.cachedProvider as WalletType;
}

export const autoConnectToWallet = async () => {
  detectBscInjectedWallet();
  const isSafeApp = await web3Modal.isSafeApp();
  const isOpenBlockIframe = await getIsOpenBlockIframe();
  const cachedProvider = web3Modal.cachedProvider as WalletType;
  if (isSafeApp) {
    connectToWallet(WalletType.Gnosis);
  } else if (isOpenBlockIframe) {
    connectToWallet(WalletType.openBlockIframe);
  } else if (
    cachedProvider &&
    ![WalletType.BinanceChain].includes(cachedProvider)
  ) {
    if (cachedProvider === WalletType.LedgerUSB) {
      const providerPackageOptions = await getLedgerUSBConnectInfo();
      if (!providerPackageOptions) return;
      connectToWallet(cachedProvider, undefined, providerPackageOptions);
    } else {
      connectToWallet(cachedProvider);
    }
  } else if (isLedger) {
    connectToWallet(WalletType.Ledger);
  }
};

export const disconnectWallet = async () => {
  if (provider && provider.close) {
    await provider.close();
  }
  if (uAuth) {
    await uAuth.logout();
  }
  web3Modal.clearCachedProvider();
  store.dispatch(setCurrentAccount());
  logWallet(WalletEventLabel.clickDisconnectWallet);
  dbSet({
    path: QUIT_CONNECT,
    value: '1',
  });
  reloadWindow(200);
};

const onWeb3Connect = async (account: string) => {
  const currentAccount = getCurrentAccount();
  let searchAccount;
  const search = new URLSearchParams(window.location.search);
  if (search.get('_account')) {
    searchAccount = search.get('_account') as string;
  }
  if (
    !searchAccount &&
    currentAccount &&
    account &&
    currentAccount.toLowerCase() !== account.toLowerCase()
  ) {
    reloadWindow(200); // 以目前的reducer结构，切换钱包地址或许不需要强制刷新页面了，余额等状态都做了account区分
  }

  const web3 = await getWeb3();
  const chainId = await web3.eth.getChainId();
  const [curChainId, curChainIdSrc] = getCurrentChainIdAndSrc();
  console.log(
    `onWeb3Connect: nextChainId: ${chainId}, currentChainId: ${curChainId}, currentAccount: ${currentAccount}, account: ${account}, curChainIdSrc: ${curChainIdSrc}`,
  );

  if (
    web3Modal.cachedProvider === WalletType.LedgerUSB &&
    ![1, 56, 137].includes(chainId)
  ) {
    store.dispatch(setChainMismatchNotice(true));
    return;
  }
  if (curChainId !== chainId && !isSupportAllChain) {
    if (
      (curChainIdSrc === ChainIdSource.query && !currentAccount) ||
      !ValidChainIds.includes(chainId)
    ) {
      store.dispatch(setChainMismatchNotice(true));
    } else {
      setLastChainId(chainId);
      setTimeout(() => {
        const network = getNetworkNameWithChainId(chainId).toLowerCase();
        if (network === 'unknown') {
          window.location.search = `?network=mainnet&unknown=true`;
          return;
        }
        window.location.search = `?network=${network}`;
      }, 100);
    }
  } else {
    let accountRes = account;
    if (!account) {
      const web3 = await getWeb3();
      const accounts = await web3.eth.getAccounts();
      [accountRes] = accounts;
    }
    store.dispatch(setCurrentAccount(accountRes));
    const accountType = await getAccountType(chainId, accountRes);
    store.dispatch(setCurrentAccountType(accountType));
  }
};

export const getProvider = () => {
  return new Promise((resolve) => {
    if (!provider) {
      let count = 0;
      const time = setInterval(() => {
        count++;
        if (provider || count === 5) {
          clearInterval(time);
          resolve(provider);
        }
      }, 200);
    }
    resolve(provider);
  });
};

export const getWeb3 = async ({
  notWaitConnect,
}: {
  /** 在没连钱包的情况下，不去尝试连接。因为如果没连接过， web3Modal.connect() 一直不会响应 */
  notWaitConnect?: boolean;
} = {}): Promise<Web3> => {
  if (web3) return web3;

  const quitConnect = dbGet({ path: QUIT_CONNECT, defaultValue: '0' });
  if (String(quitConnect) !== '1' && !notWaitConnect) {
    const isSafeApp = await web3Modal.isSafeApp();
    if (isSafeApp) {
      provider = await web3Modal.requestProvider();
    } else {
      provider = await web3Modal.connect();
    }
  } else if (!provider) {
    return await getWeb3BynotAccount();
  }
  bindProviderEvent(provider);
  web3 = new Web3(provider);
  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        // @ts-ignore
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  const accounts = await web3.eth.getAccounts();
  onWeb3Connect(accounts[0]);
  return web3;
};

export const getWeb3BynotAccount = async (
  chainIdProps?: number,
  noCache?: true,
) => {
  if (!noCache && web3) return web3;
  const chainId = chainIdProps || getCurrentChainId();
  const selectEndPoints: string = prepareChainEndpointUrl(chainId, true);
  const result = new Web3(selectEndPoints);
  result.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        // @ts-ignore
        outputFormatter: result.utils.hexToNumber,
      },
    ],
  });
  return result;
};

export const getCachedContract = async (
  contractAddress: string,
  ABI: any,
  web3WithRpcProxy?: Web3,
): Promise<Contract> => {
  // Get ABI group
  let inner = cachedContracts.get(ABI);
  if (!inner) {
    inner = new Map();
    cachedContracts.set(ABI, inner);
  }

  let result = inner.get(contractAddress);
  if (result) return result;

  if (web3WithRpcProxy) {
    result = new web3WithRpcProxy.eth.Contract(ABI, contractAddress);
    inner.set(contractAddress, result);
    return result;
  }

  if (!web3) {
    const readonlyWeb3 = await getWeb3BynotAccount();
    result = new readonlyWeb3.eth.Contract(ABI, contractAddress);
    return result;
  }
  result = new web3.eth.Contract(ABI, contractAddress);
  inner.set(contractAddress, result);
  return result;
};

export const isImToken = (): boolean => {
  return typeof window.ethereum !== 'undefined' && !!window.ethereum.isImToken;
};

export const loadLatestBlockNumber = async (
  chainId: number,
  {
    notCheckTime,
  }: {
    notCheckTime?: boolean;
  } = {},
): Promise<BigNumber | null> => {
  try {
    const updateTimestamp = getUpdateTimestamp();
    const now = Date.now();
    if (!notCheckTime && now - updateTimestamp < 12000) return null;
    const blockNumber = await loadBlockNumber();
    const blockNumberNG = new BigNumber(blockNumber);
    if (blockNumber) store.dispatch(setBlockNumber(blockNumberNG));
    const { isLayer2 } = isETHOrBSCChain(chainId);
    if (isLayer2) {
      // layer2 要用到 layer1 的块高度计算挖矿开始是否开始
      // layer2 测试网也需要用到对应 layer1 测试网的块高
      const layer1ChainId = getLayer1ChainIdForLayer2(chainId);
      const ethBlockNumber = await loadETHBlockNumber(layer1ChainId);
      if (ethBlockNumber)
        store.dispatch(setETHBlockNumber(new BigNumber(ethBlockNumber)));
    }

    return blockNumberNG;
  } catch (e) {
    console.error(e);
  }

  return null;
};

const bindProviderEvent = (provider: any) => {
  if (!provider.on) return;
  provider.on('close', onWeb3Close);
  provider.on('accountsChanged', onWeb3AccountsChanged);
  provider.on('chainChanged', onWeb3ChainChanged);
  provider.on('networkChanged', onWeb3NetworkChanged);
  provider.on('connect', onWeb3ConnectChanged);
};
const onWeb3Close = () => {
  console.log('onWeb3Close');
  store.dispatch(setCurrentAccount());
  web3 = null;
  reloadWindow();
};

const onWeb3AccountsChanged = async (accounts: string[]) => {
  onWeb3Connect(accounts[0]);
};

const onWeb3ChainChanged = async (chainId: number) => {
  const account = getCurrentAccount();
  if (account) onWeb3Connect(account);
};

const onWeb3NetworkChanged = async (networkId: number) => {
  const account = getCurrentAccount();
  if (account) onWeb3Connect(account);
};

// 看起来会触发两次，所以放在 timeout 里面
let onWeb3ConnectChangedTime: NodeJS.Timeout | undefined;
const onWeb3ConnectChanged = ({ chainId }: { chainId: string }) => {
  clearTimeout(onWeb3ConnectChangedTime);
  onWeb3ConnectChangedTime = setTimeout(() => {
    const currentChainId = getCurrentChainId();
    // 监听更改 rpc 节点的情况
    if (currentChainId === parseInt(chainId, 16)) {
      loadLatestBlockNumber(currentChainId, {
        notCheckTime: true,
      });
    }
  }, 500);
};

const reloadWindow = (interval?: number) => {
  setTimeout(() => {
    window.location.reload();
  }, interval || 100);
};

/**
 * web3.eth.sign签的内容，用户看不到，也不鼓励使用了
 * 使用 基于结构化数据进行的签名： https://docs.metamask.io/guide/signing-data.html#sign-typed-data-v4
 */
export async function signTypedData({
  account,
  typedData,
}: {
  account: string;
  typedData: Record<string, unknown>;
}) {
  try {
    const web3Instance = await getWeb3();
    if (
      web3Instance &&
      web3Instance.currentProvider &&
      typeof web3Instance.currentProvider !== 'string'
    ) {
      let result: any;
      if (web3Modal.cachedProvider === WalletType.LedgerUSB) {
        result = await signEIP712Message(typedData as EIP712Message);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = await web3Instance.currentProvider.request({
          method: 'eth_signTypedData_v4',
          params: [account, JSON.stringify(typedData)],
        });
      }
      console.log('web3 eth_signTypedData_v4 result: ', result);
      return result;
    }
  } catch (error) {
    console.error('web3 eth_signTypedData_v4 error: ', error);
    if ((error as { message: string }).message) {
      throw new Error((error as { message: string }).message);
    }
  }
  return null;
}

export async function getTransactionCount(accountProps?: string) {
  let nonce: number | undefined;
  const accountType = getCurrentAccountType();
  const account = accountProps || getCurrentAccount();
  if (!account) return nonce;
  const web3 = await getWeb3();
  if (accountType === AccountType.EOA) {
    nonce = await web3.eth.getTransactionCount(account);
  } else {
    try {
      const contract = await getCachedContract(account, contractWalletABI);
      nonce = await contract.methods.nonce().call();
    } catch (error) {
      console.error(error);
      nonce = await web3.eth.getTransactionCount(account);
    }
  }
  return nonce;
}
