import { useTranslation } from 'react-i18next';
import { ReactComponent as NotifyIcon } from 'icons/normal/announcement.svg';
import { ReactComponent as ArticleIcon } from '../images/other-nav/article_24dp.svg';
import { ReactComponent as HelpIcon } from '../images/other-nav/help_circle_24dp.svg';
import { ReactComponent as CareersIcon } from '../images/other-nav/Careers_24dp.svg';
import { ReactComponent as RedirectIcon } from '../images/other-nav/swap_horizontal_circle_black_24dp.svg';

import { ReactComponent as HomeIcon } from '../images/medium-nav/home_18dp.svg';
import { ReactComponent as GithubIcon } from '../images/medium-nav/github_18dp.svg';
import { ReactComponent as TwitterIcon } from '../images/medium-nav/twitter_18dp.svg';
import { ReactComponent as DiscordIcon } from '../images/medium-nav/discord_18dp.svg';
import { ReactComponent as TelegramIcon } from '../images/medium-nav/telegram_18dp.svg';
import { ReactComponent as MediumIcon } from '../images/medium-nav/medium_18dp.svg';

export enum DocLanguageName {
  enUS = 'English',
  zhCN = '中文',
  ruRU = 'Pусский',
  jaJP = '日本語',
  msMY = 'Bahasa Melayu',
  viVN = 'Tiếng Việt',
  // frFR = 'frFR',
  koKR = '한국인',
  // zhHK = 'zhHK',
  esES = 'Español',
  // idID = 'idID',
  tsTR = 'Türk',
}

interface SupportLink {
  name: string;
  link: string;
}
export interface MoreNavItem {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  name: string;
  link: string | SupportLink[];
  multiLanguageKey?: string;
  tooltip?: string;
}

export const useMoreNav = () => {
  const { t } = useTranslation();
  const otherNavList: MoreNavItem[] = [
    { icon: HelpIcon, name: t('header.help'), link: t('header.help.link') },
    {
      icon: ArticleIcon,
      name: t('header.document'),
      link: t('header.document.link'),
    },
    {
      icon: NotifyIcon,
      name: t('header.announcement'),
      link: t('header.announcement.link'),
    },
    {
      icon: CareersIcon,
      name: t('header.careers'),
      link: t('header.careers.link'),
    },
    {
      icon: RedirectIcon,
      name: t('header.redirect-nft-module'),
      link: t('header.redirect-nft-module.link'),
    },
  ];

  const telegramSupportLinks: SupportLink[] = [
    {
      name: DocLanguageName.enUS,
      link: 'https://t.me/dodoex_official',
    },
    {
      name: DocLanguageName.zhCN,
      link: 'https://t.me/dodozoo',
    },
    {
      name: DocLanguageName.jaJP,
      link: 'https://t.me/DODODEXNFTJapan',
    },
    {
      name: DocLanguageName.viVN,
      link: 'https://t.me/DODOVietnam',
    },
    {
      name: DocLanguageName.ruRU,
      link: 'https://t.me/DodoexRU',
    },
    {
      name: DocLanguageName.tsTR,
      link: 'https://t.me/DODOTurkish',
    },
    {
      name: DocLanguageName.msMY,
      link: 'https://t.me/DODOMalaysia',
    },
    {
      name: DocLanguageName.koKR,
      link: 'https://t.me/DODOKorea',
    },
    {
      name: DocLanguageName.esES,
      link: 'https://t.me/dodoesp',
    },
  ];
  const twitterSupportLinks: SupportLink[] = [
    {
      name: DocLanguageName.enUS,
      link: 'https://twitter.com/BreederDodo',
    },
    {
      name: DocLanguageName.zhCN,
      link: 'https://twitter.com/DODOCN',
    },
    {
      name: DocLanguageName.jaJP,
      link: 'https://twitter.com/DODODEXNFTJapan',
    },
  ];
  const discordSupportLinks: SupportLink[] = [
    {
      name: DocLanguageName.enUS,
      link: 'https://discord.com/invite/tyKReUK',
    },
    {
      name: DocLanguageName.zhCN,
      link: 'https://discord.com/invite/UmeA8bJ5nq',
    },
  ];

  const mediumNav: MoreNavItem[] = [
    {
      icon: HomeIcon,
      name: t('header.nav.home'),
      link: t('header.nav.home.link'),
      tooltip: 'Home',
    },
    {
      icon: GithubIcon,
      name: t('header.nav.github'),
      link: t('header.nav.github.link'),
      tooltip: 'GitHub',
    },
    {
      icon: TwitterIcon,
      name: t('header.nav.twitter'),
      link: twitterSupportLinks,
    },
    {
      icon: DiscordIcon,
      name: t('header.nav.discord'),
      link: discordSupportLinks,
    },
    {
      icon: TelegramIcon,
      name: t('header.nav.telegram'),
      link: telegramSupportLinks,
    },
    {
      icon: MediumIcon,
      name: t('header.nav.medium'),
      link: t('header.nav.medium.link'),
      tooltip: 'Medium',
    },
  ];

  return {
    otherNavList,
    mediumNav,
  };
};
