import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  useQuery,
} from '@apollo/client';
import { request, useRefetch } from '@dodoex/utils';
import { OrderedMap } from 'immutable';
import { useEffect, useMemo, useState } from 'react';
import { fetchDIPWhitelist } from '../../../server/dip/data/queries';
import {
  FetchDIPWhitelist,
  FetchDIPWhitelistVariables,
} from '../../../server/dip/data/__generated__/FetchDIPWhitelist';
import { fetchProposalList, fetchVotesList } from '../data/queries';
import {
  FetchProposalList,
  FetchProposalListVariables,
  FetchProposalList_proposals,
} from '../data/__generated__/FetchProposalList';
import {
  FetchVotesList,
  FetchVotesListVariables,
  FetchVotesList_votes,
} from '../data/__generated__/FetchVotesList';

export interface IopDipData {
  snapshotID: string;
  deadlineVotes: number;
}

export enum ProposalStatus {
  Active = 'active',
  Passed = 'pass',
  Rejected = 'rejected',
  Invalid = 'discard',
}
export interface Proposal extends FetchProposalList_proposals {
  status?: ProposalStatus;
  subtitle: string;
}

export async function loadScores(
  voterList: (FetchVotesList_votes | null)[] | null,
  snapshot?: number | null,
): Promise<[number, number]> {
  if (!voterList || voterList.length === 0) {
    return [0, 0];
  }

  try {
    const res = await request({
      url: 'https://score.snapshot.org/api/scores',
      method: 'post',
      data: {
        params: {
          addresses: voterList.map((d) => (d ? d.voter : null)),
          network: '1',
          space: 'dodobird.eth',
          snapshot,
          strategies: [
            {
              name: 'erc20-balance-of',
              params: {
                symbol: 'DODO',
                address: '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
                decimals: 18,
              },
              __typename: 'Strategy',
            },
            {
              name: 'erc20-balance-of-coeff',
              params: {
                coeff: 100,
                symbol: 'vDODO',
                address: '0xc4436fBAE6eBa5d95bf7d53Ae515F8A707Bd402A',
                decimals: 18,
              },
              __typename: 'Strategy',
            },
          ],
        },
      },
    });
    const { scores } = res.data.result;
    let approvalScore = 0;
    let rejectedScore = 0;
    voterList.forEach((voter) => {
      if (voter?.choice === 1) {
        approvalScore += scores[0][voter.voter] ?? 0;
        approvalScore += scores[1][voter.voter] ?? 0;
      } else if (voter?.choice === 2) {
        rejectedScore += scores[0][voter.voter] ?? 0;
        rejectedScore += scores[1][voter.voter] ?? 0;
      }
    });

    return [approvalScore, rejectedScore];
  } catch (e) {
    console.error(e);
    return [0, 0];
  }
}

function generateFormatted({
  proposals,
  whitelist,
}: {
  proposals: (FetchProposalList_proposals | null)[];
  whitelist: FetchDIPWhitelist | undefined;
}) {
  const formatted = proposals.map((proposal) => {
    let subtitle = '';
    const titleList = proposal?.title.split(':') as string[];
    if (titleList.length === 1) {
      [subtitle] = titleList;
    } else {
      [, subtitle] = titleList;
    }
    const whitelistItem = whitelist?.dip_whitelist_list?.find(
      (i) => i?.snapshotId === proposal?.id,
    );

    return {
      ...proposal,
      title: whitelistItem?.sn,
      subtitle,
    } as Proposal;
  });

  return formatted;
}

export function useIopDipList() {
  const [loading, setLoading] = useState(false);
  const [proposals, setProposals] = useState<
    (FetchProposalList_proposals | null)[]
  >([]);
  const [votesList, setVotesList] = useState<(FetchVotesList_votes | null)[]>(
    [],
  );
  const [dataList, setDataList] = useState<Proposal[]>([]);
  const [error, setError] = useState<any | undefined>(undefined);
  const { loading: whitelistLoading, data: whitelist } = useQuery<
    FetchDIPWhitelist,
    FetchDIPWhitelistVariables
  >(fetchDIPWhitelist, {
    variables: {
      where: {},
    },
  });

  const proposalList = useMemo(() => {
    return whitelist?.dip_whitelist_list?.map((dip) => dip?.snapshotId ?? '');
  }, [whitelist?.dip_whitelist_list]);

  const client = useMemo(() => {
    return new ApolloClient({
      uri: 'https://hub.snapshot.org/graphql',
      cache: new InMemoryCache(),
    });
  }, []);

  const { refetch, refetchTimes } = useRefetch();

  useEffect(() => {
    async function recursiveQueryVotes({ skip }: { skip: number }) {
      const { data: votesData } = await client.query<
        FetchVotesList,
        FetchVotesListVariables
      >({
        query: fetchVotesList,
        variables: {
          where: { proposal_in: proposalList },
          first: 1000,
          skip,
        },
      });
      if (votesData.votes) {
        setVotesList((prev) => prev.concat(votesData.votes));

        if (votesData.votes.length === 1000) {
          await recursiveQueryVotes({
            skip: skip + 1000,
          });
        }
      }
    }

    if (!proposalList || proposalList.length <= 0) {
      return;
    }
    setVotesList([]);
    recursiveQueryVotes({
      skip: 0,
    });
  }, [client, proposalList]);

  useEffect(() => {
    async function requestDIPRowList() {
      setLoading(true);
      try {
        const { data, error: clientError } = await client.query<
          FetchProposalList,
          FetchProposalListVariables
        >({
          query: fetchProposalList,
          variables: {
            where: { id_in: proposalList },
          },
        });
        if (clientError) throw clientError;
        if (!data || !data.proposals || !data.proposals.length) {
          setLoading(false);
          return;
        }
        setProposals(data.proposals);
        const formatted = generateFormatted({
          proposals: data.proposals,
          whitelist,
        });
        setDataList(formatted);
        setError(undefined);
        setLoading(false);
      } catch (e) {
        console.error('[request dip list error]', e);
        setError(e);
      }
      setLoading(false);
    }

    if (!proposalList || proposalList.length <= 0) {
      return;
    }
    requestDIPRowList();
  }, [client, proposalList, whitelist, refetchTimes]);

  useEffect(() => {
    async function requestScores() {
      if (
        !votesList ||
        votesList.length <= 0 ||
        !proposals ||
        proposals.length <= 0
      ) {
        return;
      }
      let scores: OrderedMap<string, [number, number]> = OrderedMap();
      const scorePL: Array<Promise<[number, number]>> = [];
      for (let i = 0; i < proposals.length; i++) {
        const proposal = proposals[i];
        scorePL.push(
          loadScores(
            votesList.filter((d) => d?.proposal?.id === proposal?.id),
            Number(proposal?.snapshot),
          ),
        );
      }
      const scoresL = await Promise.all(scorePL);
      for (let i = 0; i < proposals.length; i++) {
        const proposal = proposals[i];
        const score = scoresL[i];
        scores = scores.set(proposal?.id ?? '', score);
      }
      const formatted = generateFormatted({
        proposals,
        whitelist,
      });
      const withStatus = formatted.map((f) => {
        const deadlineVotes = whitelist?.dip_whitelist_list?.find(
          (i) => i?.snapshotId === f.id,
        )?.deadlineVotes;
        if (!deadlineVotes) return f;
        const score = scores.get(f.id);
        let status;
        if (!score) return f;
        if (f.state !== 'closed') status = ProposalStatus.Active;
        else if (deadlineVotes && score[0] + score[1] < Number(deadlineVotes))
          status = ProposalStatus.Invalid;
        else if (score[0] >= score[1]) status = ProposalStatus.Passed;
        else status = ProposalStatus.Rejected;
        return {
          ...f,
          status,
        } as Proposal;
      });
      setDataList(withStatus);
    }

    requestScores();
  }, [proposals, votesList, whitelist]);

  return {
    loading: loading || whitelistLoading,
    dataList,
    refetch,
    error,
  };
}
