import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { getWalletTypeNameByType } from '../injects/wallet';
import { WalletType } from '../injects/wallet/types';
import { getConnectedWalletType, web3Modal } from '../web3';

export enum WalletPageType {
  AccountPage = 1,
  ConnectPage,
  NoWalletPage,
}

export const useConnectWalletStatus = (
  currentAccount?: string,
  initConnectPageShow?: boolean, // 默认展示ConnectPage
) => {
  const [resetCount, setResetCount] = useState(1);
  const [walletTypeName, setWalletTypeName] = useState(
    getWalletTypeNameByType(
      web3Modal?.cachedProvider.toLowerCase() as WalletType,
    ),
  );
  const [pageType, setPageType] = useState<WalletPageType>(
    initConnectPageShow
      ? WalletPageType.ConnectPage
      : WalletPageType.AccountPage,
  );

  useEffect(() => {
    getConnectedWalletType().then((walletType) => {
      const currentType = walletType.toLowerCase();
      setWalletTypeName(getWalletTypeNameByType(currentType as WalletType));
    });
  }, [currentAccount]);

  useEffect(() => {
    if (!currentAccount) {
      setPageType(WalletPageType.NoWalletPage);
    } else if (initConnectPageShow) {
      setPageType(WalletPageType.ConnectPage);
    } else {
      setPageType(WalletPageType.AccountPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, resetCount]);

  const reset = useCallback(() => {
    setResetCount((prev: number) => prev + 1);
  }, []);

  return {
    pageType,
    setPageType,
    walletTypeName,
    reset,
  };
};
