/* eslint-disable react/jsx-props-no-spreading */
import { Tabs as TabsOrigin, Tab as TabOrigin, TabProps } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { merge } from 'lodash';

// export const Tabs = ({ children }: { children: React.ReactNode }) => {
//   return <TabsOrigin>{children}</TabsOrigin>;
// };

export const TabButtonsWeak = styled(TabsOrigin)(({ theme }) => {
  const { palette } = theme;
  const { background } = palette;
  const borderRadius = 4;
  return {
    minHeight: 'auto',
    width: 'max-content',
    borderRadius,
    backgroundColor: background.paper,
    '& .MuiTabs-scroller': {
      borderRadius,
    },
    '& .MuiTabs-indicator': {
      opacity: 0,
    },
    '& .MuiTabs-flexContainer': {
      borderRadius,
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: theme.palette.custom.border.default,
    },
    '& .Mui-selected:first-child': {
      marginLeft: -1,
    },
    '& .Mui-selected:last-child': {
      marginRight: -1,
    },
  };
});

export const TabButtonWeak = ({ sx, ...props }: TabProps) => {
  const theme = useTheme();
  const { spacing, palette } = theme;
  const { text, custom } = palette;
  return (
    <TabOrigin
      {...props}
      sx={merge(
        {
          padding: spacing(4, 7),
          extTransform: 'none',
          backgroundColor: 'transparent',
          borderRadius: 4,
          color: text.secondary,
          typography: 'h6',
          '&.Mui-selected': {
            backgroundColor: custom.border.default,
            color: text.primary,
          },
          minHeight: 'auto',
          minWidth: 'auto',
          maxWidth: 'auto',
          height: 24,
        },
        sx,
      )}
    />
  );
};
