import { gql } from '@apollo/client';

export const fetchLiquidityCountData = gql`
  query FetchLiquidityCountData($where: Liquiditycount_data_query) {
    liquidity_count_data(where: $where) {
      tvl
      users
      pools
    }
  }
`;

export const fetchLiquidityPoolApyUser = gql`
  query FetchLiquidityPoolApyUser($where: Liquiditypool_apy_query) {
    liquidity_pool_apy_user(where: $where) {
      pool
      apy
      users
    }
  }
`;
