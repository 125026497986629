import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider } from '@ethersproject/providers';
import erc20HelperABI from '../../abis/erc20Helper';
import multicalABIABI from '../../abis/multicalABI';
import { getConfig, getEndpointUrl } from './endpoint';

export enum ABIName {
  erc20Helper = 'erc20Helper',
  multicalABI = 'multicalABI',
}
export async function getABI(abiName: ABIName) {
  const contractAddressMap = {
    [ABIName.erc20Helper]: erc20HelperABI,
    [ABIName.multicalABI]: multicalABIABI,
  };
  return contractAddressMap[abiName];
  // switch (abiName) {
  //   case ABIName.erc20Helper:
  //     return (await import('../../abis/erc20Helper')).default;
  //   case ABIName.multicalABI:
  //     return (await import('../../abis/multicalABI')).default;

  //   default:
  //     throw new Error('abiName is not valid.');
  // }
}

export async function getContractAddress(chainId: number, abiName: ABIName) {
  const config = await getConfig(chainId);
  const contractAddressMap = {
    [ABIName.erc20Helper]: config.ERC20_HELPER,
    [ABIName.multicalABI]: config.MULTI_CALL,
  };
  return contractAddressMap[abiName];
}

const cachedContracts: Map<string, Map<string, Contract>> = new Map();
export const getCachedContract = async (
  chainId: number,
  abiName: ABIName,
): Promise<Contract> => {
  const ABI = await getABI(abiName);
  const contractAddress = await getContractAddress(chainId, abiName);
  let inner = cachedContracts.get(contractAddress);
  if (!inner) {
    inner = new Map();
    cachedContracts.set(contractAddress, inner);
  }

  let result = inner.get(contractAddress);
  if (result) return result;
  const endpoint = await getEndpointUrl(chainId);
  const provider = new JsonRpcProvider(endpoint, chainId);
  result = new Contract(contractAddress, ABI, provider);
  // result = new web3.eth.Contract(ABI, contractAddress) as Contract;
  inner.set(contractAddress, result);
  return result;
};
