import { memo } from 'react';
import { Snackbar, Slide, useTheme, Box, Fade } from '@mui/material';
import { Alert, AlertVariant } from './Alert';
import { useDevices } from '../hooks';

export enum NotifVariant {
  success = 1,
  error,
  warn,
}

export type Notif = {
  type?: NotifVariant;
  msg: string;
  progress?: number;
  timeout?: number;
  hint?: string | JSX.Element;
  link?: {
    text: string;
    onClick: () => void;
  };
  isToast?: boolean;
  onClose?: () => void;
};

interface Props extends Notif {
  onClose: () => void;
}

function TransitionComponent(props: any) {
  const { isMobile } = useDevices();
  return (
    <Fade in timeout={{ enter: 560, exit: 160 }}>
      <div>
        <Slide
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          timeout={{ enter: 400, exit: 160 }}
          direction={isMobile ? 'down' : 'left'}
        />
      </div>
    </Fade>
  );
}

function Entry({
  onClose,
  type = NotifVariant.success,
  timeout,
  msg,
  link,
  hint,
}: Props) {
  const theme = useTheme();

  return (
    <Snackbar
      open
      autoHideDuration={timeout}
      onClose={(evt, reason) => {
        if (reason === 'clickaway') return;
        onClose();
      }}
      key={msg}
      message={msg}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        top: 20,
        right: 20,
        // submission loading: 5000
        zIndex: 5100,
        [theme.breakpoints.up('tablet')]: {
          left: 'auto',
        },
      }}
      TransitionComponent={TransitionComponent}
    >
      <Box>
        <Alert
          on
          onClose={onClose}
          type={type as unknown as AlertVariant}
          timeout={timeout}
          msg={msg}
          link={link}
          hint={hint}
          sx={{
            width: {
              mobile: '90vw',
              tablet: 335,
            },
          }}
        />
      </Box>
    </Snackbar>
  );
}

export default memo(Entry);
