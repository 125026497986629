import BigNumber from 'bignumber.js';
import { Set, Map } from 'immutable';
import { FetchStatus, TokenAllowances, TokenBalances } from './reducer';
import { Token, TokenList, Tokens } from '../types';

export const setEthBalance = (account: string, balance: BigNumber) => {
  return {
    type: 'SET_ETH_BALANCE',
    payload: { account, balance },
  };
};

export const setFullTokens = (tokens: Token[]) => {
  return {
    type: 'SET_FULL_TOKENS',
    payload: { tokens },
  };
};

export const setTokens = (tokens: Tokens) => {
  return {
    type: 'SET_TOKENS',
    payload: { tokens },
  };
};

export const setTokenLists = (tokenLists: TokenList[]) => {
  return {
    type: 'SET_TOKEN_LISTS',
    payload: { tokenLists },
  };
};

export const setTokenListsStatus = (tokenListsStatus: FetchStatus) => {
  return {
    type: 'SET_TOKEN_LISTS_STATUS',
    payload: { tokenListsStatus },
  };
};

export const setCustomTokens = (tokens: Tokens) => {
  return {
    type: 'SET_CUSTOM_TOKENS',
    payload: { tokens },
  };
};

export const setImportTokens = (tokens: Tokens) => {
  return {
    type: 'SET_IMPORT_TOKENS',
    payload: { tokens },
  };
};

export const setCheckTokenAddresses = (tokenAddresses: Set<string>) => {
  return {
    type: 'SET_CHECK_TOKEN_ADDRESSES',
    payload: { tokenAddresses },
  };
};

export const clearAllCustomTokens = () => {
  return {
    type: 'CLEAR_ALL_CUSTOM_TOKENS',
  };
};

export const removeCustomTokens = (token: Token) => {
  return {
    type: 'REMOVE_CUSTOM_TOKENS',
    payload: { token },
  };
};

export const setTokenBalances = (
  account: string,
  tokenBalances: TokenBalances,
) => {
  return {
    type: 'SET_TOKEN_BALANCES',
    payload: { account, tokenBalances },
  };
};

export const setTokenChainBalances = (
  accountChain: string,
  tokenBalances: TokenBalances,
) => {
  return {
    type: 'SET_TOKEN_CHAIN_BALANCES',
    payload: { accountChain, tokenBalances },
  };
};

export const setTokenAllowances = (
  account: string,
  tokenAllowances: TokenAllowances,
  proxyAddress: string,
) => {
  return {
    type: 'SET_TOKEN_ALLOWANCES',
    payload: { account, tokenAllowances, proxyAddress },
  };
};

export const setBalanceLoadings = (balanceLoadings: Map<string, boolean>) => {
  return {
    type: 'SET_BALANCE_LOADINGS',
    payload: { balanceLoadings },
  };
};
