/* eslint-disable radix */
import { useQueryTokenByAllToken } from '@dodoex/token';
import { byWei } from '@dodoex/utils';
import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { useRootQuery } from '../apollo/query';
import { fetchUserprofileReward } from './data/queries';
import { FetchUserprofileReward } from './data/__generated__/FetchUserprofileReward';
import {
  getPreminingClaimed,
  getPreminingIsClaimed,
  getPreminingPendingClaimable,
  getPreminingTotal,
} from './walletMining';

const defaultLockedReward = {
  claimableRewards: new BigNumber(0),
  claimedRewards: new BigNumber(0),
  totalRewards: new BigNumber(0),
};

enum RewardVersion {
  merkle = 'merkle',
}

interface LockedReward {
  /** 可领取的 */
  claimableRewards: BigNumber;
  /** 已领取的 */
  claimedRewards: BigNumber;
  /** 总共可以领取的 */
  totalRewards: BigNumber;
}

export interface Reward extends LockedReward {
  id: string;
  contractAddress: string;
  symbol: string;
  nameKey: string;
  showDecimals: number;
  address: string;
  lockedRewards: BigNumber;
  merkle?: any;
}

/**
 * 每一个活动对应着一个空投合约，计算可领取、已领取、总共可领取数量
 * @param contractAddress 空投合约地址
 * @returns
 */
async function computeLockedRewards(
  contractAddress: string,
  tokenDecimals: number,
  account: string,
): Promise<LockedReward> {
  if (!account) {
    return defaultLockedReward;
  }
  const currentAccount = account.toLowerCase();
  const promisedList: Array<Promise<BigNumber>> = [];
  try {
    promisedList.push(getPreminingTotal(currentAccount, contractAddress));
    promisedList.push(
      getPreminingPendingClaimable(currentAccount, contractAddress),
    );
    promisedList.push(getPreminingClaimed(currentAccount, contractAddress));
    const [totalRewards, claimableRewards, claimedRewards] = await Promise.all(
      promisedList,
    );

    return {
      claimableRewards: claimableRewards.div(`1e${tokenDecimals}`),
      claimedRewards: claimedRewards.div(`1e${tokenDecimals}`),
      totalRewards: totalRewards.div(`1e${tokenDecimals}`),
    };
  } catch (error) {
    console.error('computeLockedRewardsList error', error);
    return defaultLockedReward;
  }
}

export const useFetchReward = ({
  account,
  skip,
}: {
  account?: string;
  skip?: boolean;
}) => {
  const getToken = useQueryTokenByAllToken();
  const [rewardList, setRewardList] = useState<Reward[]>([]);

  const { data, refetch } = useRootQuery<FetchUserprofileReward>(
    fetchUserprofileReward,
    {
      variables: {
        where: {
          user: account,
        },
      },
      skip: !account || skip,
      pollInterval: 30000,
    },
  );

  useEffect(() => {
    (async () => {
      if (
        !account ||
        !data ||
        !data.userprofile_reward ||
        !data.userprofile_reward.length
      ) {
        setRewardList([]);
        return;
      }
      const promiseList = data.userprofile_reward.map(async (item) => {
        const {
          contract_address: contractAddress,
          token_address: tokenAddress,
          name_key: nameKey,
          locking,
          version,
          merkle,
        } = item;
        const id = `${nameKey}-${contractAddress}`;
        if (!tokenAddress) return Promise.resolve();
        const token = getToken(tokenAddress);
        if (!token) return Promise.resolve();
        const symbol = token.get('symbol');
        const decimals = Number(token.get('decimals'));
        // merkle 由后端单独配置，查询和领取方式都不相同
        if (version === RewardVersion.merkle) {
          let totalRewards = 0;
          let isClaimed = false;
          if (contractAddress && merkle) {
            const { index, amout } = merkle;
            totalRewards = amout ? byWei(parseInt(amout), 18).toNumber() : 0;
            const indexNum = index ? parseInt(index) : 0;
            isClaimed = await getPreminingIsClaimed(indexNum, contractAddress);
          }
          return {
            id,
            symbol,
            nameKey,
            contractAddress,
            showDecimals: decimals > 6 ? 6 : 4,
            address: tokenAddress,
            claimableRewards: new BigNumber(isClaimed ? 0 : totalRewards ?? 0),
            claimedRewards: new BigNumber(0),
            totalRewards: new BigNumber(isClaimed ? 0 : totalRewards ?? 0),
            merkle,
          };
        }
        if (contractAddress) {
          return {
            id,
            symbol,
            nameKey,
            contractAddress,
            showDecimals: decimals > 6 ? 6 : 4,
            address: tokenAddress,
            ...(await computeLockedRewards(contractAddress, decimals, account)),
          };
        }
        return {
          id,
          symbol,
          nameKey,
          contractAddress,
          showDecimals: decimals > 6 ? 6 : 4,
          address: tokenAddress,
          claimableRewards: new BigNumber(0),
          claimedRewards: new BigNumber(0),
          totalRewards: new BigNumber(locking ?? 0),
        };
      });
      const list = (await Promise.all(promiseList)) as Reward[];
      const rewardListRes: Reward[] = [];
      list.forEach((item) => {
        if (item && (item.claimableRewards.gt(0) || item.totalRewards.gt(0))) {
          rewardListRes.push({
            ...item,
            lockedRewards: item.totalRewards
              .minus(item.claimableRewards)
              .minus(item.claimedRewards),
          });
        }
      });
      setRewardList(rewardListRes.reverse());
    })();
  }, [data, account]);

  return {
    rewardList,
    refetch,
  };
};
