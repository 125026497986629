import { useMemo, useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { useQuery } from '@apollo/client';
import { getCurrentChainId } from '@dodoex/wallet';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { fetchKChartDataV2 } from './data/queries';
import { formatChartData, fixTimeStampByTimeScale } from './utils';
import { ChartKLineChartScope, TimeScaleType } from './chartTypes';
import {
  FetchKChartDataV2,
  FetchKChartDataV2Variables,
} from './data/__generated__/FetchKChartDataV2';

const FIXED_NUMBER = 1;
const INITIAL_K_BAR_NUMBER = 48;
export const K_BAR_NUMBER = INITIAL_K_BAR_NUMBER + FIXED_NUMBER; // 初始k线数据个数

export const TimeScaleTextMap: { [key in TimeScaleType]: string } = {
  [TimeScaleType['15M']]: '15m',
  [TimeScaleType['1H']]: '1h',
  [TimeScaleType['4H']]: '4h',
  [TimeScaleType['24H']]: '24h',
  [TimeScaleType['1W']]: '7d',
};

export const useTimeRange = ({
  timeScale,
  barNumber,
}: {
  timeScale: TimeScaleType;
  barNumber: number;
}) => {
  const timeRange = useMemo(() => {
    // 时间范围最大为1年 && 必须为timeScale的整数倍
    const endTime = moment().unix();
    const maxTimeGap = Math.floor((365 * 24 * 60 * 60) / timeScale) * timeScale;
    const timeSpan = Math.min(barNumber * timeScale, maxTimeGap);
    return [fixTimeStampByTimeScale(endTime - timeSpan, timeScale), endTime];
  }, [timeScale]);
  return { timeRange };
};

export const useKChartData = ({
  timeScale,
  sourceType,
  currentPrice,
  toTokenAddress,
  fromTokenAddress,
}: {
  timeScale: TimeScaleType;
  toTokenAddress: string;
  fromTokenAddress: string;
  sourceType: ChartKLineChartScope;
  currentPrice?: BigNumber;
}) => {
  const chainId = useSelector(getCurrentChainId);
  const [isCoinGeckoTokenNotExist, setIsCoinGeckoTokenNotExist] =
    useState<boolean>(false);
  const scale = useMemo(() => TimeScaleTextMap[timeScale], [timeScale]);

  const { data, loading, error } = useQuery<
    FetchKChartDataV2,
    FetchKChartDataV2Variables
  >(fetchKChartDataV2, {
    variables: {
      where: {
        network: chainId,
        scope: sourceType,
        a: fromTokenAddress,
        b: toTokenAddress,
        scale,
      },
    },
    skip: !toTokenAddress || !fromTokenAddress,
    pollInterval: 5 * 60 * 1000, // 每次查询间隔(后端缓存为5分钟刷新, 前端保持一致)
  });

  useEffect(() => {
    setIsCoinGeckoTokenNotExist(false);
  }, [toTokenAddress, fromTokenAddress]);

  useEffect(() => {
    const isCoinGeckoError = error?.graphQLErrors.some(
      (o) => o.message === 'CoinGeckoTokenNotFound',
    );
    if (isCoinGeckoError && sourceType === ChartKLineChartScope.ALL) {
      setIsCoinGeckoTokenNotExist(true);
    }
  }, [error, sourceType]);

  const chartData = useMemo(() => {
    if (!data || !data.chart_getOhlcvData) return [];
    return data.chart_getOhlcvData;
  }, [data]);

  const formattedData = useMemo(() => formatChartData(chartData), [chartData]);

  const isLowDataVolume = useMemo(() => {
    const totalVolume = formattedData.reduce((pre, cur) => pre + cur.volume, 0);
    return !(totalVolume > 0);
  }, [formattedData]);

  const growthRate = useMemo(() => {
    const len = formattedData.length;
    if (formattedData.length > 1 && currentPrice) {
      const lastChartPrice = new BigNumber(formattedData[len - 1].close);
      const rate = currentPrice.minus(lastChartPrice).dividedBy(lastChartPrice);
      const ratePercentage = rate.isFinite()
        ? rate.multipliedBy(100).toFixed(2)
        : 0; // fix decimals
      return new BigNumber(ratePercentage);
    }
    return new BigNumber(0);
  }, [formattedData, currentPrice]);

  return {
    loading,
    growthRate,
    isLowDataVolume,
    data: formattedData,
    isCoinGeckoTokenNotExist,
  };
};
