import { getInjectedProviderName } from 'web3modal';
import * as walletListOrigin from './constant';
import * as injectWalletListOrigin from './injectedConstant';
import { Wallet, WalletType } from './types';
import { getIsMobile } from '../../utils/web';

export { getSafeHashDetailUrl } from './safeWalletConnect';

function walletSort(list: Wallet[]) {
  return list.sort((a, b) => (a.sort || 1) - (b.sort || 1));
}

function filterSupportMobileList(list: Wallet[]) {
  return list.filter((wallet) => !!wallet.supportMobile);
}

export { WalletType } from './types';

export { ReactComponent as InjectDefaultLogo } from '../../components/images/logos/metamask.svg';
export const walletList = walletSort(
  Object.values(walletListOrigin) as Wallet[],
);

const allInjectWalletList = walletSort(Object.values(injectWalletListOrigin));
const mobileInjectedWalletList = walletSort(
  walletList.filter((wallet) => wallet.isMobileInjected),
);
export const injectWalletList = getIsMobile()
  ? [...allInjectWalletList, ...mobileInjectedWalletList]
  : allInjectWalletList;

export const followInjectWalletList = allInjectWalletList.filter(
  (wallet) => wallet.isFollowInject,
);

export const supportMobileWalletList = [
  ...filterSupportMobileList(allInjectWalletList),
  ...filterSupportMobileList(walletList),
  // coinbase 排序特殊处理，放到前面
].sort((a) => (a.showName === walletListOrigin.Coinbase.showName ? -1 : 1));

export * from './types';

export * from './openBlock';

export const getWalletTypeNameByType = (type: WalletType) => {
  let result = '';
  if (type === WalletType.injected) {
    injectWalletList.some((wallet) => {
      if (wallet.checked()) {
        result = wallet.typeName;
        return true;
      }
      return false;
    });
    if (!result) {
      result = getInjectedProviderName() || 'MetaMask';
    }
  } else {
    walletList.some((wallet) => {
      if (wallet.type === type) {
        result = wallet.typeName;
        return true;
      }
      return false;
    });
    if (type === WalletType.openBlockIframe) {
      result = 'OpenBlock';
    }
    if (!result) {
      result = 'MetaMask';
    }
  }
  return result;
};

export const getWalletAppNameByType = (type: WalletType) => {
  let result = '';
  if (type === WalletType.injected) {
    injectWalletList.some((wallet) => {
      if (wallet.checked()) {
        result = wallet.showName;
        return true;
      }
      return false;
    });
    if (!result) {
      result =
        window.ethereum && window.ethereum.isMetaMask
          ? 'MetaMask'
          : 'Unknown Injected Wallet';
    }
  } else {
    walletList.some((wallet) => {
      if (wallet.checked()) {
        result = wallet.showName;
        return true;
      }
    });
    if (type === WalletType.openBlockIframe) {
      result = 'OpenBlock';
    }
    if (!result) {
      result = 'Unknown Wallet';
    }
  }
  return result;
};
