// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const customApolloFetch = (uri: string, options: any) => {
  let operationName = '';
  try {
    const body = JSON.parse(options.body);
    if (Array.isArray(body)) {
      body.forEach((item) => {
        operationName += `${!operationName ? '' : '+'}${item.operationName}`;
      });
    } else {
      operationName = body.operationName;
    }
  } catch (error) {
    console.error(error);
  }
  return fetch(operationName ? `${uri}?opname=${operationName}` : uri, options);
};
