export const isInternalTestUrl =
  process.env.REACT_APP_PROD_API_DOMAIN !==
  process.env.REACT_APP_TEST_API_DOMAIN; // 是否是内部的测试链接

const DEV_ENVIRONMENT_KEY = 'devEnvironment';

export function getServiceDomain() {
  const prodApiDomain = process.env.REACT_APP_PROD_API_DOMAIN || 'dodoex.io';
  if (window && window.location && window.location.hostname)
    return window.location.hostname.includes('dodoex.co')
      ? 'dodoex.co'
      : prodApiDomain;
  return prodApiDomain;
}

export function getServiceDomainDev() {
  return process.env.REACT_APP_TEST_API_DOMAIN || 'dodoex.io';
}

export function getIsDevEnvironment() {
  if (isInternalTestUrl) {
    const isDevEnvironment = window.localStorage.getItem(DEV_ENVIRONMENT_KEY);
    if (isDevEnvironment) {
      return true;
    }
    return false;
  }

  return false;
}

export function setDevEnvironment(isDev: boolean) {
  window.localStorage.setItem(DEV_ENVIRONMENT_KEY, isDev ? '1' : '');
}
