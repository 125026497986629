import {
  fetchTokenDetail,
  getBalanceLoadings,
  setTokenAllowances,
} from '@dodoex/token';
import { BIG_ALLOWANCE, ETH_ADDRS, GetConfig, SDKToken } from '@dodoex/utils';
import { isETHOrBSCChain, Submission } from '@dodoex/wallet';
import BigNumber from 'bignumber.js';
import Immutable from 'immutable';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertToToken, getTokenSymbolDisplay } from '.';
import { useGetAllowance } from './useGetAllowance';
import { useGetBalance } from './useGetBalance';

export enum ApprovalState {
  Loading = 'Loading',
  Insufficient = 'ApprovalInsufficient',
  Approving = 'Approving',
  Sufficient = 'Sufficient',
  Unchecked = 'Unchecked',
}

export enum BalanceState {
  Loading = 'Loading',
  Insufficient = 'BalanceInsufficient',
  Sufficient = 'Sufficient',
  Unchecked = 'Unchecked',
}

const { OpCode, State, useInflights, useSubmission, ExecutionResult } =
  Submission;

export const useGetTokenStatus = ({
  account,
  chainId,
  offset,
  contractAddress,
}: {
  account?: string;
  chainId: number;
  offset?: BigNumber;
  contractAddress?: string;
}) => {
  const contract = contractAddress ?? GetConfig(chainId).DODO_APPROVE ?? null;
  const getAllowance = useGetAllowance(contract);
  const getBalance = useGetBalance();
  const inflights = useInflights();
  const balanceLoadings = useSelector(getBalanceLoadings);

  const getApprovalState = useCallback(
    (
      token: SDKToken | null,
      value: string | number | BigNumber,
      overrideBalance?: BigNumber,
    ) => {
      if (!token) {
        return ApprovalState.Loading;
      }
      const inflightsFindToken = inflights.find(
        (_v, k) =>
          k.spec.opcode === OpCode.Approval &&
          k.spec.token.address === token?.address &&
          k.spec.contract === contract, // 授权中同时检查授权合约地址是否匹配（一个代币可能会授权给多个合约，没在授权中的合约不需要授权中的状态，只需要检查授权数量是否充足）
      );
      const isApproving = inflightsFindToken === State.Running;
      const balance =
        overrideBalance ?? (token ? getBalance(convertToToken(token)) : null);
      const allowance = token ? getAllowance(convertToToken(token)) : null;

      const parsed = new BigNumber(value ?? 0);
      if (!account) return ApprovalState.Unchecked;
      if (!balance || parsed.minus(offset ?? 0).gt(balance))
        return ApprovalState.Unchecked;

      if (parsed.isZero()) return ApprovalState.Unchecked;
      const isEth = !!token && ETH_ADDRS.includes(token.address);
      if (isEth) return ApprovalState.Sufficient;
      if (isApproving) return ApprovalState.Approving;
      if (!allowance) {
        if (account) {
          if (
            balanceLoadings.size &&
            !balanceLoadings.get(token.address.toLocaleLowerCase())
          ) {
            fetchTokenDetail(account, chainId, [token.address], undefined, {
              proxyAddress: contract,
            });
          }
        }
        return ApprovalState.Loading;
      }
      if (parsed.minus(offset ?? 0).gt(allowance))
        return ApprovalState.Insufficient;
      return ApprovalState.Sufficient;
    },
    [
      inflights,
      getBalance,
      getAllowance,
      account,
      offset,
      contract,
      chainId,
      balanceLoadings,
    ],
  );

  const getPendingRest = useCallback(
    (token?: SDKToken | null) => {
      const allowance = token ? getAllowance(convertToToken(token)) : null;
      const isUSDT =
        token?.symbol === 'USDT' ||
        token?.address.toLowerCase() ===
          '0x6426e6017968377529487E0ef0aA4E7759724e05'.toLowerCase();
      return isUSDT && allowance !== null && allowance.gt(0);
    },
    [getAllowance],
  );

  const getBalanceState = useCallback(
    (
      parsed: BigNumber,
      token?: SDKToken | null,
      overrideBalance?: BigNumber,
    ) => {
      const balance =
        overrideBalance ?? (token ? getBalance(convertToToken(token)) : null);
      // if ((!checked && !overrideBalance) || !account) return BalanceState.Unchecked;
      if (!account) return BalanceState.Unchecked;
      if (!balance) {
        if (token) {
          if (
            balanceLoadings.size &&
            !balanceLoadings.get(token.address.toLocaleLowerCase())
          ) {
            fetchTokenDetail(account, chainId, [token.address]);
          }
        }
        return BalanceState.Loading;
      }
      if (parsed.minus(offset ?? 0).gt(balance))
        return BalanceState.Insufficient;
      return BalanceState.Sufficient;
    },
    [account, getBalance, offset, chainId, balanceLoadings],
  );

  const submission = useSubmission();
  const dispatch = useDispatch();
  const submitApprove = useCallback(
    async (token: SDKToken | null, isReset?: boolean) => {
      if (!contract || !account || !token) return;
      const tokenDisp = getTokenSymbolDisplay(convertToToken(token));
      const amt = isReset ? new BigNumber(0) : undefined;
      const prefix = isReset
        ? 'common.approve.resetBrief'
        : 'common.approve.brief';
      const result = await submission.execute(
        `${prefix} ${tokenDisp}`,
        {
          opcode: OpCode.Approval,
          token,
          contract,
          amt,
        },
        undefined,
        undefined,
        {
          approveToken: 1,
        },
      );

      if (result !== ExecutionResult.Success) return;

      // Manually updates allowance
      // TODO(meow): This might race, investigate more
      dispatch(
        setTokenAllowances(
          account,
          Immutable.Map([
            [token.address.toLowerCase(), amt ?? BIG_ALLOWANCE] as [
              string,
              BigNumber,
            ],
          ]),
          contract,
        ),
      );
      // 用户可能手动修改了上链数据，此处重新查询
      fetchTokenDetail(account, chainId, [token.address], undefined, {
        proxyAddress: contract,
        forced: true,
      });
    },
    [contract, account, chainId, submission, dispatch],
  );

  const getMaxBalance = useCallback(
    (token: SDKToken | null) => {
      const defaultVal = new BigNumber(0);
      if (!token) return defaultVal;
      const balance = getBalance(convertToToken(token));
      if (!balance) return defaultVal;
      let val = balance;
      const { isETH } = isETHOrBSCChain(chainId);
      const keepChanges = isETH ? 0.1 : 0.02;
      if (ETH_ADDRS.includes(token.address))
        val = balance.gt(keepChanges) ? balance.minus(keepChanges) : defaultVal;

      return val.toNumber();
    },
    [chainId, getBalance],
  );

  return {
    getApprovalState,
    getPendingRest,
    getBalanceState,
    submitApprove,
    getMaxBalance,
  };
};
