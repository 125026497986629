import { getWeb3 } from '@dodoex/wallet';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Decoder from 'abi-decoder';
import BigNumber from 'bignumber.js';
import { TransactionReceipt } from 'web3-eth';

const erc20TransferEvent = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
];

Decoder.addABI(erc20TransferEvent);

type DecodeResult = {
  paid: BigNumber;
  recv: BigNumber;
};

export async function decodeLog(
  txid: string,
  toToken?: string,
): Promise<DecodeResult> {
  const web3 = await getWeb3();
  let receipt: TransactionReceipt | null = null;
  for (let i = 0; i < 10; ++i) {
    receipt = await web3.eth.getTransactionReceipt(txid);
    if (receipt) break;
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
  console.log('[Decoder] receipt', receipt);
  const result = {
    paid: new BigNumber(0),
    recv: new BigNumber(0),
  };
  // fix: https://www.notion.so/dodotopia/Cannot-read-property-filter-of-undefined-ed0a517205ea4ebc9183a44f265b3a1d
  if (receipt && receipt.logs) {
    // https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html#gettransactionreceipt
    // https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html#getpastlogs
    // https://github.com/ConsenSys/abi-decoder/blob/master/index.js
    const decoded = Decoder.decodeLogs(receipt.logs);
    console.log('[Decoder] decoded', decoded);
    for (const entry of decoded)
      if (entry.name === 'Transfer') {
        if (entry.events[2]?.name !== 'value') continue;
        const val = new BigNumber(entry.events[2].value);
        if (
          entry.events[0].name === 'from' &&
          entry.events[0].value.toLowerCase() === receipt.from
        )
          result.paid = result.paid.plus(val);
        if (
          entry.events[1].name === 'to' &&
          entry.events[1].value.toLowerCase() === receipt.from &&
          // https://web3js.readthedocs.io/en/v1.2.11/web3-eth.html#eth-getpastlogs-return -> address - String: From which this event originated from.
          entry.address.toLowerCase() === toToken?.toLowerCase()
        )
          result.recv = result.recv.plus(val);
      }
  }

  console.log(
    '[Decoder] result',
    result.paid.toString(),
    result.recv.toString(),
  );
  return result;
}
