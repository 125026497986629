import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { HoverOpacity } from '../Hover/HoverOpacity';

export function RefreshTextByFailed({ refresh }: { refresh?: () => void }) {
  if (!refresh) return null;
  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        color: 'text.secondary',
        userSelect: 'none',
      }}
    >
      <Trans
        i18nKey="common.failed.text.refresh-msg"
        components={[
          <HoverOpacity
            key={1}
            component="span"
            color="primary.main"
            weak
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              refresh();
            }}
          />,
        ]}
      />
    </Typography>
  );
}
