import { ContractConfigDataI } from '../type';

export const MOONRIVER_CONFIG_DATA: ContractConfigDataI = {
  WETH: {
    symbol: 'WMOVR',
    address: '0x98878b06940ae243284ca214f92bb71a2b032b8a',
    decimals: 18,
    name: 'Wrapped Moonriver',
    source: 'dodo',
  },
  MULTI_CALL: '0x2ff2CeE6E9359F9eA1cF2f51d18bF9F2045447E4',
  DODO_TOKEN: '',

  ERC20_HELPER: '0x6373ceB657C83C91088d328622573FB766064Ac4',
  ERC20V3_FACTORY: '0x1518e34156F12049b387620a7778685bf4d0D61B',

  DODO_APPROVE: '0xE8C9A78725D0451FA19878D5f8A3dC0D55FECF25',

  DODO_PROXY: '0xd9deC7c3C06e62a4c1BeEB07CadF568f496b14c2',
  DODO_DSP_PROXY: '0x9f015aa5557ffBb47850c045Df67Bf229B07f2eA',
  DODO_DPP_PROXY: '0xB8605027F9a29D6a68eC489561c9a7ec9180aECC',
  DODO_CP_PROXY_V2: '0x161Af953C652ae3807F0B0428B63727f1d5D0F90',
  DODO_MINEV3_PROXY: '0xb159260989012fA98af560A3Fa6D9cd11a64cf6E',
  DODO_STARTER_PROXY: '0x97f6eec23D92fF85363deA771fC3c1A200370A22',

  DVM_FACTORY: '0x738Ebf387A0CE0eb46b0eF8Fa5DEa2EaE6B1Df51',
  DSP_FACTORY: '0xB76de21f04F677f07D9881174a1D8E624276314C',
  CP_FACTORY_V2: '0x1B75C99c3Ab14764aEA1511e68B33f5b9138A241',

  ROUTE_V2_DATA_FETCH: '0xaDd26239D4Cd21DCE2412EDD82a42D9b0315ce41',
  ROUTE_V1_DATA_FETCH: '0x3CD6D7F5fF977bf8069548eA1F9441b061162b42',
  CALLEE_HELPER: '0x533AF8ad419fB3082df9C80BE2ad903912a817FB',
  FEE_RATE_IMPL: '0x048B8926bb0eE9c52e05D61fDffbCCffbeE06Fc2',
};

export const MOONRIVER_SWAPINFO = [
  // SushiSwap
  {
    name: 'SushiSwap',
    factoryAddress: '0xc35DADB65012eC5796536bD9864eD8773aBc74C4',
    initHash:
      '0xe18a34eb0e04b04f7a0ac29a6e80748dca96319b42c54d679cb821dca90c6303',
  },
  {
    name: 'Solarbeam',
    factoryAddress: '0x049581aEB6Fe262727f290165C29BDAB065a1B68',
    initHash:
      '0x9a100ded5f254443fbd264cb7e87831e398a8b642e061670a9bc35ba27293dbf',
    fee: 25,
  },
];
